import {Component, EventEmitter, forwardRef, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {TerminalId} from '@models/terminalId';
import {InlineControlComponent} from '../inline-edit/inline-control.component';

const INLINE_EDIT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TerminalIdEditorComponent),
  multi: true
};


@Component({
  selector: 'app-terminal-id-edit',
  templateUrl: './terminal-id-editor.component.html',
  providers: [INLINE_EDIT_CONTROL_VALUE_ACCESSOR],
  styleUrls: ['./terminal-id-editor.component.scss']
})
export class TerminalIdEditorComponent extends InlineControlComponent<TerminalId>  {
  @Output() blur = new EventEmitter<TerminalIdEditorComponent>();

  get terminalId(): string {
    return (this.value as TerminalId)?.terminalId ?? '';
  }

  set terminalId(newId: string) {
    this.value = {...this.value, terminalId: newId};
  }

  get merchantId(): string {
    return (this.value as TerminalId)?.merchantId ?? '';
  }

  set merchantId(newId: string) {
    this.value = {...this.value, merchantId: newId};
  }

  constructor() {
    super();
  }
}
