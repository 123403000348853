<h2 mat-dialog-title [class.warning]="data.isWarning">  {{data.title}}</h2>

<mat-dialog-content>
  <p [class.warning]="data.isWarning">{{data.message}}</p>
  <mat-checkbox *ngIf="data.option" class="option" (change)="setClearResults($event.checked)" [checked]="data.option?.defaultValue" [disabled]="canClearResult | async">{{data.option?.text}}</mat-checkbox>
</mat-dialog-content>

<mat-dialog-actions>
  <div>
    <button mat-button [mat-dialog-close]="false">{{data.noText}}</button>
    <button mat-button [mat-dialog-close]="data.option ? {dialogOption: true, clearResults: clearResults} : true">{{data.yesText}}</button>
  </div>
</mat-dialog-actions>

