export interface ClientAddress {
  id?: number;
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  addressLine4?: string;
  city: string;
  province: string;
  country: string;
  zipCode: string;
}
