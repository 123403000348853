export enum AllProjectCategory {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED'
}

export const AllProjectCategories = [AllProjectCategory.Active, AllProjectCategory.Draft, AllProjectCategory.Closed, AllProjectCategory.Cancelled];

export const AllProjectCategoryDescriptions: Record<AllProjectCategory, string> = {
  [AllProjectCategory.Active]: 'Active',
  [AllProjectCategory.Draft]: 'Draft',
  [AllProjectCategory.Closed]: 'Closed',
  [AllProjectCategory.Cancelled]: 'Cancelled',
}

export interface ProjectInCategory {
  endDate: Date | null;
  name: string;
  onHold: boolean;
  projectId: string;
  startDate: Date;
}

export type AllProjectsReport =
  {
    [category: string]: {
      averageDays: number;
      branches: Array<ProjectInCategory>;
      onHold: number;
      projectCount: number;
      totalDays: number;
    }
  }
