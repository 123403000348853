import {Injectable, OnDestroy} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {ProjectPhase} from '@models/projectPhase';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {debounceTime, filter} from 'rxjs/operators';

@Injectable()
export class SpinnerService implements OnDestroy {

  private isVisible = new BehaviorSubject<boolean>(false);
  private routerSubscription: Subscription;
  private isCentred = new BehaviorSubject<boolean>(false);
  private spinnerMessage = new BehaviorSubject<string>('Working');

  constructor(private router: Router) {
    this.routerSubscription = router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe( _ => this.hide());
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  get isVisible$(): Observable<boolean> {
    return this.isVisible.asObservable().pipe(debounceTime(500));
  }

  get isCentred$(): Observable<boolean> {
    return this.isCentred.asObservable().pipe(debounceTime(500));
  }

  get spinnerMessage$(): Observable<string> {
    return this.spinnerMessage.asObservable().pipe(debounceTime(500));
  }

  /**
   * Show the spinner
   */
  show(centred = false, message = 'Working') {
    this.isCentred.next(centred);
    this.spinnerMessage.next(message);
    this.isVisible.next(true);
  }

  /**
   * Hide the spinner
   */
  hide() {
    this.isVisible.next(false);
    this.isCentred.next(false);
    this.spinnerMessage.next('Working');
  }

  isPreValidation(object: any): boolean {
    if (object != null && typeof object === 'object' && 'phase' in object && !('state' in object)) {
      return object.phase === ProjectPhase.PreValidation;
    }
    return false;
  }

  isTestExecution(object: any): boolean {
    if (object != null && typeof object === 'object' && 'phase' in object && !('state' in object)) {
      return object.phase === ProjectPhase.TestExecution && !object.isProgress;
    }
    return false;
  }

  isRestoreRequest(url: string): boolean {
    return url !== undefined && url.endsWith('restore');
  }
}
