namespace ProjectFilter {

  export enum SearchField {
    Client = 'merchantId',
    ProjectId = 'projectId',
    Platform = 'frontEnd',
    HostMessageSpecification = 'authSpec',
    ProjectName = 'id',
    TerminalId = 'terminalId',
    Phase = 'phase',
    Status = 'state',
    CertLeadId = 'certLeadId',
    ClientLeadId = 'clientLeadId',
    IpLeadId = 'ipLeadId',
    CommonFormStatus = 'commonStatus',
    ContactFormStatus = 'contactStatus',
    ContactlessFormStatus = 'contactlessStatus',
    BeforeCreationDate = 'creationDateBefore',
    AfterCreationDate = 'creationDateAfter',
    BeforeKickOffDate = 'kickOffDateBefore',
    AfterKickOffDate = 'kickOffDateAfter',
    BeforePlanningDate = 'planningDateBefore',
    AfterPlanningDate = 'planningDateAfter',
    BeforeTestCertifyDate = 'testCertifyDateBefore',
    AfterTestCertifyDate = 'testCertifyDateAfter',
    BeforeImplementationDate = 'implementationDateBefore',
    AfterImplementationDate = 'implementationDateAfter',
    BeforeValidationDate = 'validationDateBefore',
    AfterValidationDate = 'validationDateAfter',
    BeforeApprovalDate = 'approvalDateBefore',
    AfterApprovalDate = 'approvalDateAfter',
    BeforeClosedDate = 'closedDateBefore',
    AfterClosedDate = 'closedDateAfter',
    BeforeStartDate = 'startDateBefore',
    AfterStartDate = 'startDateAfter',
    BackupAnalystId = 'backupAnalystId',
    TestTool = 'testTool',
    CycleTimeGreaterThan = 'cycleTimeGreaterThan',
    CycleTimeLessThan = 'cycleTimeLessThan',
    SandboxFormStatus = 'sandboxStatus',
    SandboxProjectId = 'sandboxProjectId',
    BeforeLastRunDate = 'lastRunDateBefore',
    AfterLastRunDate = 'lastRunDateAfter'
  }


  export interface SearchType {
    field: SearchField;
    fieldDescription: string;
    nonSandbox: boolean;
    sandbox: boolean;
  }

  export type SearchParameterType = string | number | Date;

  export interface SearchParameter {
    type: SearchType;
    value: SearchParameterType;
  }

  export const ALLSEARCHTYPES: Array<SearchType> = [
    {
      field: SearchField.Client,
      fieldDescription: 'Client',
      nonSandbox: true,
      sandbox: true
    },
    {
      field: SearchField.ProjectId,
      fieldDescription: 'Project ID',
      nonSandbox: true,
      sandbox: true
    },
    {
      field: SearchField.Platform,
      fieldDescription: 'Platform',
      nonSandbox: true,
      sandbox: true
    },
    {
      field: SearchField.HostMessageSpecification,
      fieldDescription: 'Host Message Spec',
      nonSandbox: true,
      sandbox: true
    },
    {
      field: SearchField.ProjectName,
      fieldDescription: 'Project Name',
      nonSandbox: true,
      sandbox: true
    },
    {
      field: SearchField.TerminalId,
      fieldDescription: 'Terminal ID',
      nonSandbox: true,
      sandbox: true
    },
    {
      field: SearchField.Phase,
      fieldDescription: 'Project Phase',
      nonSandbox: true,
      sandbox: true
    },
    {
      field: SearchField.Status,
      fieldDescription: 'Project Status',
      nonSandbox: true,
      sandbox: true
    },
    {
      field: SearchField.CertLeadId,
      fieldDescription: 'Cert Lead',
      nonSandbox: true,
      sandbox: true
    },
    {
      field: SearchField.IpLeadId,
      fieldDescription: 'IP Lead',
      nonSandbox: true,
      sandbox: true
    },
    {
      field: SearchField.ClientLeadId,
      fieldDescription: 'Client Lead',
      nonSandbox: true,
      sandbox: true
    },
    {
      field: SearchField.CommonFormStatus,
      fieldDescription: 'Common Form',
      nonSandbox: true,
      sandbox: false
    },
    {
      field: SearchField.ContactFormStatus,
      fieldDescription: 'Contact Form',
      nonSandbox: true,
      sandbox: false
    },
    {
      field: SearchField.ContactlessFormStatus,
      fieldDescription: 'Contactless Form',
      nonSandbox: true,
      sandbox: false
    },
    {
      field: SearchField.AfterCreationDate,
      fieldDescription: 'After Creation Date',
      nonSandbox: true,
      sandbox: true
    },
    {
      field: SearchField.BeforeCreationDate,
      fieldDescription: 'Before Creation Date',
      nonSandbox: true,
      sandbox: true
    },
    {
      field: SearchField.AfterKickOffDate,
      fieldDescription: 'After Kick-Off Date',
      nonSandbox: true,
      sandbox: true
    },
    {
      field: SearchField.BeforeKickOffDate,
      fieldDescription: 'Before Kick-Off Date',
      nonSandbox: true,
      sandbox: true
    },
    {
      field: SearchField.AfterPlanningDate,
      fieldDescription: 'After Req, Plan, & Setup Date',
      nonSandbox: true,
      sandbox: true
    },
    {
      field: SearchField.BeforePlanningDate,
      fieldDescription: 'Before Req, Plan & Setup Date',
      nonSandbox: true,
      sandbox: true
    },
    {
      field: SearchField.AfterTestCertifyDate,
      fieldDescription: 'After Test Certify Date',
      nonSandbox: true,
      sandbox: true
    },
    {
      field: SearchField.BeforeTestCertifyDate,
      fieldDescription: 'Before Test Certify Date',
      nonSandbox: true,
      sandbox: true
    },
    {
      field: SearchField.AfterImplementationDate,
      fieldDescription: 'After Implemenetation Date',
      nonSandbox: true,
      sandbox: false
    },
    {
      field: SearchField.BeforeImplementationDate,
      fieldDescription: 'Before Implementation Date',
      nonSandbox: true,
      sandbox: false
    },
    {
      field: SearchField.AfterValidationDate,
      fieldDescription: 'After Validation Date',
      nonSandbox: true,
      sandbox: false
    },
    {
      field: SearchField.BeforeValidationDate,
      fieldDescription: 'BeforeValidation Date',
      nonSandbox: true,
      sandbox: false
    },
    {
      field: SearchField.AfterApprovalDate,
      fieldDescription: 'After Approval Date',
      nonSandbox: true,
      sandbox: false
    },
    {
      field: SearchField.BeforeApprovalDate,
      fieldDescription: 'Before Approval Date',
      nonSandbox: true,
      sandbox: false
    },
    {
      field: SearchField.AfterClosedDate,
      fieldDescription: 'After Closed Date',
      nonSandbox: true,
      sandbox: true
    },
    {
      field: SearchField.BeforeClosedDate,
      fieldDescription: 'Before Closed Date',
      nonSandbox: true,
      sandbox: true
    },
    {
      field: SearchField.AfterStartDate,
      fieldDescription: 'After Start Date',
      nonSandbox: true,
      sandbox: true
    },
    {
      field: SearchField.BeforeStartDate,
      fieldDescription: 'Before Start Date',
      nonSandbox: true,
      sandbox: true
    },
    {
      field: SearchField.BackupAnalystId,
      fieldDescription: 'Backup Analyst',
      nonSandbox: true,
      sandbox: true
    },
    {
      field: SearchField.TestTool,
      fieldDescription: 'Test Tool',
      nonSandbox: true,
      sandbox: true
    },
    {
      field: SearchField.CycleTimeGreaterThan,
      fieldDescription: 'Cycle Time Greater Than',
      nonSandbox: true,
      sandbox: true
    },
    {
      field: SearchField.CycleTimeLessThan,
      fieldDescription: 'Cycle Time Less Than',
      nonSandbox: true,
      sandbox: true
    },
    {
      field: SearchField.SandboxFormStatus,
      fieldDescription: 'Sandbox Form',
      nonSandbox: false,
      sandbox: true
    },
    {
      field: SearchField.SandboxProjectId,
      fieldDescription: 'Sandbox Project ID',
      nonSandbox: true,
      sandbox: false
    },
    {
      field: SearchField.AfterLastRunDate,
      fieldDescription: 'After Last Run Date',
      nonSandbox: true,
      sandbox: true
    },
    {
      field: SearchField.BeforeLastRunDate,
      fieldDescription: 'Before Last Run Date',
      nonSandbox: true,
      sandbox: true
    }
  ];
}

export default ProjectFilter;
