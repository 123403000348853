<div class="table-container">
  <div class="controls-row">
    <div class="controls">
      <div class="filters">
        <div class="filter-buttons" *ngIf="showAdvancedFilter">
          <button mat-raised-button color="primary" (click)="updateFilter()">Advanced Filter</button>
          <button mat-raised-button (click)="onClearPressed()" [disabled]="hasNoFilters()">Clear</button>
        </div>
        <mat-form-field>
          <input matInput #filterInput placeholder="Filter">
        </mat-form-field>
        <mat-checkbox #favouriteProjectsCheckbox>Only show favorites</mat-checkbox>
      </div>

      <div class="add-new" *ngIf="canAddProjects">
        <button *ngIf="canCopyFromSandbox" id="copyFromSandbox" mat-raised-button color="primary"
                (click)="copySandboxProject.emit(0)">Copy From Sandbox Project
        </button>
        <button id="createProject" mat-raised-button color="primary" (click)="createProject.emit()">Create Project
        </button>
      </div>
    </div>
    <mat-paginator [showFirstLastButtons]="true"
                   [length]="totalRows$ | async"
                   [pageSize]="25"
                   [pageSizeOptions]="[10, 25, 50, 100]">
    </mat-paginator>
  </div>

  <table mat-table matSort matSortActive="creationDate"
         matSortDisableClear matSortDirection="desc" [dataSource]="rows$ | async">

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let project">
        <em *ngIf="project.state === ProjectState.OnHold" matTooltip="On-hold"
            class="fa fa-pause-circle paused-icon"></em>
        <em *ngIf="project.state === ProjectState.Cancelled" matTooltip="Cancelled"
            class="fa fa-times-circle cancelled-icon"></em>
        {{project.name}}</td>
    </ng-container>

    <!-- ProjectId Column -->
    <ng-container matColumnDef="projectId">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Project ID</th>
      <td mat-cell *matCellDef="let project"> {{project.projectId}} </td>
    </ng-container>


    <!-- Platform Column -->
    <ng-container matColumnDef="platform">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Platform</th>
      <td mat-cell *matCellDef="let project"> {{project.platform}}</td>
    </ng-container>

    <!-- Phase Column -->
    <ng-container matColumnDef="phase">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Phase</th>
      <td mat-cell *matCellDef="let project"> {{project.phase | projectPhaseDescription}}</td>
    </ng-container>

    <!-- Creation Date Column -->
    <ng-container matColumnDef="creationDate">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Creation Date</th>
      <td mat-cell *matCellDef="let project"> {{project.creationDate | estDate}}</td>
    </ng-container>

    <!-- Completion Column -->
    <ng-container matColumnDef="completion">
      <th mat-header-cell *matHeaderCellDef>Completion</th>
      <td mat-cell *matCellDef="let project">{{project.testStatistics.completion | number:'1.0-2'}}
        %
      </td>
    </ng-container>

    <!-- Test Progress Column -->
    <ng-container matColumnDef="test-progress">
      <th mat-header-cell *matHeaderCellDef>Test Progress</th>
      <td mat-cell *matCellDef="let project">
        <app-project-test-case-status-bar *ngIf="project.phase !== ProjectPhase.Onboarding"
                                          [passed]="project.testStatistics?.passedTests"
                                          [failed]="project.testStatistics?.failedTests"
                                          [waived]="project.testStatistics?.waivedTests"
                                          [overridden]="project.testStatistics?.overriddenTests"
                                          [notStarted]="project.testStatistics?.uninitiatedTests"
                                          [inProgress]="project.testStatistics?.initiatedTests"
                                          (clicked)="goToProjectTests(project.id)">
        </app-project-test-case-status-bar>
        <span *ngIf="project.phase === ProjectPhase.Onboarding">Begin testing to see progress here</span>
      </td>
    </ng-container>

    <!-- Cycle time -->
    <ng-container matColumnDef="cycleTime">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Cycle Time</th>
      <td mat-cell *matCellDef="let project">{{project.cycleTime}}</td>
    </ng-container>

    <!-- Favourite Column -->
    <ng-container matColumnDef="favourite">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let project" (click)="$event.stopPropagation()">
        <em *ngIf="!project.favourite" matTooltip="Mark as Favorite"
            class="fa fa-star non-favourite-icon" (click)="markFavourite(project.id, true)"></em>
        <em *ngIf="project.favourite" matTooltip="Unfavorite"
            class="fa fa-star favourite-icon" (click)="markFavourite(project.id, false)"></em>
      </td>
    </ng-container>

    <!-- Clone Column -->
    <ng-container matColumnDef="clone">
      <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let project">
        <button mat-raised-button
                (click)="copySandboxProject.emit(project.id); $event.cancelBubble = true">Clone
        </button>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let project; columns: displayedColumns;" [class.selectable]="canReadProjects"
        (click)="projectDetail(project.id)"></tr>
  </table>
</div>
