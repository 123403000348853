import {Component, Inject} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Role} from '@models/security';
import {NewPasswordValidationService} from '@services/auth/new-password-validation.service/new-password-validation.service';
import {RoleDescriptionService} from '@services/data/role-description.service';
import {UniqueUsernameService} from '@services/data/unique-username.service';
import {EmailValidationService} from '@services/validation/email-validation/email-validation.service';
import {NonAlphanumericValidationService} from '@services/validation/non-alphanumeric-validation/non-alphanumeric-validation.service';
import {NonNumericValidationService} from '@services/validation/non-number-validation/non-numeric-validation.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

interface RoleSelection {
  name: string;
  role: Role;
}

export interface CreateUserDialogParams {
  roles: Array<Role>;
}

export interface CreateUserDialogResult {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  role: Role;
  eid?: string;
  lanId: string;
}

@Component({
  selector: 'app-create-user-dialog',
  templateUrl: './create-user-dialog.component.html',
  styleUrls: ['./create-user-dialog.component.scss'],
  providers: [UniqueUsernameService]
})
export class CreateUserDialogComponent {
  hide = true;
  userForm: UntypedFormGroup;

  minimumPasswordLength = this.newPasswordValidationService.minimumPasswordLength;

  roles: Array<RoleSelection>;

  constructor(@Inject(MAT_DIALOG_DATA) params: CreateUserDialogParams,
              formBuilder: UntypedFormBuilder,
              roleDescriptionService: RoleDescriptionService,
              private newPasswordValidationService: NewPasswordValidationService,
              private uniqueUsernameService: UniqueUsernameService,
              private nonNumericValidationService: NonNumericValidationService,
              private emailValidationService: EmailValidationService,
              private nonAlphanumericValidationService: NonAlphanumericValidationService) {

    this.roles = params.roles.map(r => ({name: roleDescriptionService.getRoleDescription(r), role: r}));

    // Pre-select the role if only one is given
    const initialRole = params.roles.length === 1 ? params.roles[0] : '';

    this.userForm = formBuilder.group({
      email: ['', [Validators.required, emailValidationService.emailValidator], [this.isUniqueUsername.bind(this)]],
      password: ['', [Validators.required, this.isValidPassword.bind(this)]],
      firstName: ['', [Validators.required, nonNumericValidationService.nonNumberValidator]],
      lastName: ['', [Validators.required, nonNumericValidationService.nonNumberValidator]],
      role: [initialRole, Validators.required],
      eid: ['', [Validators.maxLength(20), this.nonAlphanumericValidationService.nonAlphaNumericValidator]],
      lanId: ['', [Validators.maxLength(10), this.nonAlphanumericValidationService.nonAlphaNumericValidator]]
    });
  }

  isUniqueUsername(control: AbstractControl): Observable<ValidationErrors | null> {
    return this.uniqueUsernameService.isUniqueUsername(control.value)
      .pipe(
        map(valid => valid ? null : {alreadyUsed: true})
      );
  }

  get email() {
    return this.userForm.get('email');
  }

  get password() {
    return this.userForm.get('password');
  }

  get firstName() {
    return this.userForm.get('firstName');
  }

  get lastName() {
    return this.userForm.get('lastName');
  }

  private isValidPassword(control: AbstractControl): { [key: string]: any } | null {
    return this.newPasswordValidationService.validatePassword(this.userForm?.get('email')?.value ?? '', control.value);
  }
}
