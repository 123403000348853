import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormStatus, IntakeFormStatus} from '@models/intakeFormStatus';
import {ProjectDetail} from '@models/projectDetail';
import {ProjectPhase} from '@models/projectPhase';
import {ProjectState} from '@models/projectState';
import {Action, Resource} from '@models/security';
import {PermissionsService} from '@services/auth/permissions.service/permissions.service';
import {ProjectsService} from '@services/data/projects.service/projects.service';
import {NotificationService} from '@services/notification.service/notification.service';
import {combineLatest, Observable, Subscription, map} from 'rxjs';
import {ProjectDetailStateService} from '../../pages/projects/project-details/project-details-state.service/project-detail-state.service';
import {EmailNoteDialogService} from '../email-note-dialog/email-note-dialog-service';
import {EmailNoteText} from '../email-note-dialog/email-note-dialog.component';

@Component({
  selector: 'app-notify-icc-email',
  templateUrl: './notify-icc-email.component.html',
  styleUrls: ['./notify-icc-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotifyIccEmailComponent implements OnInit, OnDestroy {

  ProjectPhase = ProjectPhase;
  ProjectState = ProjectState;
  FormStatus = FormStatus;

  canNotifyICC$: Observable<boolean>;
  projectDetail: ProjectDetail;
  intakeFormsStatus: IntakeFormStatus;
  sendNotification: string;

  readonly disable$: Observable<boolean>;

  private subscription: Subscription;

  constructor(private projectDetailStateService: ProjectDetailStateService, private notificationService: NotificationService,
              private permissionsService: PermissionsService, private emailNoteDialogService: EmailNoteDialogService,
              private projectService: ProjectsService, private changeDetectorRef: ChangeDetectorRef) {
    this.canNotifyICC$ = permissionsService.hasPermission([Resource.iccNotification, Action.edit]);

    this.disable$ = combineLatest([
                                    this.projectDetailStateService.projectDetail$,
                                    this.projectDetailStateService.isEditing$
                                  ])
      .pipe(
        map(([projectDetail, isEditing]: [ProjectDetail, boolean]) => {
          return isEditing || this.isDisabled(projectDetail);
        })
      );
  }

  ngOnInit(): void {
    this.subscription = this.projectDetailStateService.projectDetail$.subscribe(projectDetail => {
      this.projectDetail = projectDetail;
      this.intakeFormsStatus = projectDetail.intakeFormStatus;
      this.sendNotification = projectDetail.iccNotificationSent ? 'Resend ICC Notification' : 'Send ICC Notification';
      this.changeDetectorRef.detectChanges();
    });
  }

  async notifyICC() {
    const response =  await this.emailNoteDialogService.getEmailNote();
    if (response) {
      try{
        this.SendNotificationEmail(response);
        this.notificationService.success('ICC Notification Email Sent');
      } catch {
        this.notificationService.error('There was an error while sending ICC notification email');
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private isDisabled(projectDetail: ProjectDetail): boolean {
    return projectDetail.state === ProjectState.Cancelled ||
           (projectDetail.intakeFormStatus.commonStatus !== FormStatus.Submitted
            && projectDetail.intakeFormStatus.contactStatus !== FormStatus.Submitted
            && projectDetail.intakeFormStatus.contactlessStatus !== FormStatus.Submitted);
  }

  private async SendNotificationEmail(response: EmailNoteText) {
    const newProjectDetails = await this.projectService.notifyICC(this.projectDetail.id, response.note).toPromise();
    this.projectDetailStateService.setProject(newProjectDetails);
  }

}
