<div class="inline-input">
  <div [hidden]="!editing">
    <mat-form-field style="width: 100%">
      <mat-label>{{label}}</mat-label>
      <mat-select #inlineEditControl (closed)="onClosed()" [(ngModel)]="value"
                  [name]="value">
        <mat-option *ngFor="let option of options" [value]="option.value">
          {{option.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div [class.editable]="!readonly" [hidden]="editing" class="non-edit-mode">
    <mat-form-field style="width: 100%">
      <input (focus)="edit()" [value]="getValueLabel(value)" [name]="value" [placeholder]="label" [required]="required" matInput
             readonly>
    </mat-form-field>
  </div>
</div>
