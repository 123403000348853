import {Injectable} from '@angular/core';

export interface IBlobDownloadService {
  /**
   * Download a blob of data
   * @param fileBlob
   * @param {string} filename
   */
  downloadExcelBlob(fileBlob: any, filename: string): void;
  downloadFileBlob(fileBlob: any, filename: string): void;
}

@Injectable()
export class BlobDownloadService implements IBlobDownloadService {
  /**
   * Download a blob of data
   * @param fileBlob
   * @param {string} filename
   */
  downloadExcelBlob(fileBlob: any, filename: string) {
    const blob = new Blob([fileBlob], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
  }

  downloadFileBlob(fileBlob: any, filename: string) {
    const blob = new Blob([fileBlob], {type: 'application/octet-stream'});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
  }
}
