import {HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CardLogUploadResult} from '@models/cardLogUploadResult';
import {HostLog} from '@models/hostLog';
import {PagedResponse} from '@models/pagedResponse';
import {ProjectFile, ProjectFileType} from '@models/projectFile';
import {ProvisionalTestCaseResult} from '@models/provisionalTestCaseResult';
import {TestRun} from '@models/testRun';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AppConfigService} from '../../configuration/app-config.service/app-config.service';
import {IProjectFilesService} from './iProjectFilesService';

@Injectable({
  providedIn: 'root'
})
export class ProjectFilesService implements IProjectFilesService {

  private readonly hostLogsUrl = this.configService.getRestUrl('/hostlogs');
  private readonly testRunsUrl = this.configService.getRestUrl('/testruns');
  private readonly testRunsSnapshotUrl = this.configService.getRestUrl('/testruns/snapshot');
  private readonly projectFilesUrl = this.configService.getRestUrl('/projectfile');

  constructor(private http: HttpClient, private configService: AppConfigService) {
  }

  /**
   * Upload a host log file
   * @param {File} file - file to upload
   * @returns {Observable<any>}
   */
  uploadHostLog(file: File): Observable<any> {
    return this.upload(this.hostLogsUrl, file);
  }

  uploadCardLog(projectId: number, testRun: Partial<TestRun>, file: File): Observable<any> {
    const url = `${this.testRunsUrl}?projectId=${projectId}&projectTestCaseId=${testRun.projectTestCaseId}`;
    return this.upload(url, file);
  }

  uploadCardLogs(projectId: number, fileList: FileList): Observable<CardLogUploadResult[]> {
    const url = `${this.testRunsUrl}?projectId=${projectId}`;
    return this.uploadList(url, fileList);
  }

  /**
   * Get the files associated with a project
   * @param {string} projectId - ID of the project
   * @param {string} sort - Name of field to sort by
   * @param {string} dir - Direction of sort (asc/desc)
   * @param {number} page - Index of page of data
   * @param {number} pageSize - Size of data pages
   * @returns {Observable<PagedResponse<ProjectFile>>} - API response
   */
  getProjectFiles(projectId: string, sort = 'originalFileName', dir = 'desc',
                  page = 0, pageSize = 99999): Observable<PagedResponse<ProjectFile>> {
    return this.http.get<PagedResponse<ProjectFile>>(
      `${this.projectFilesUrl}?projectId=${projectId}&page=${page}&size=${pageSize}&sort=${sort},${dir}`);
  }

  /**
   * Get the host logs in the system
   * @param {string} sort - Name of field to sort by
   * @param {string} dir - Direction of sort (asc/desc)
   * @param {number} page - Index of page of data
   * @param {number} pageSize - Size of data pages
   * @param {string} filter - Optional filter
   * @returns {Observable<PagedResponse<HostLog>>} - API response
   */
  getHostLogs(sort: string, dir: string, page: number, pageSize: number, filter: string): Observable<PagedResponse<HostLog>> {
    let url = `${this.hostLogsUrl}?page=${page}&size=${pageSize}&sort=${sort},${dir}`;
    if (filter?.length) {
      url = url + `&originalFileName=${filter}`;
    }
    return this.http.get<PagedResponse<HostLog>>(url);
  }

  /**
   * Get a host log from the system
   * @param {string} hostLogId - ID of the host log to get
   * @returns {Observable<HostLog>} - API response
   */
  getHostLog(hostLogId: string): Observable<HostLog> {
    return this.http.get<HostLog>(`${this.hostLogsUrl}/${hostLogId}`);
  }

  /**
   * Get the latest Test Run for the given project test case
   * @param {number} projectTestCaseId - id of the project test case
   * @param {boolean} includeMessages - should we include messages?
   * @returns {Observable<CardLog>} - API response
   */
  getLatestTestRun(projectTestCaseId: number, includeMessages: boolean = false): Observable<TestRun> {
    const params = new HttpParams()
      .set('includeMessages', String(includeMessages))
      .append('includeHostLogTransaction', 'true')
      .append('includeResult', 'true')
      .append('projectTestCaseId', projectTestCaseId.toString())
      .append('sort', 'uploadTime,DESC')
      .append('size', '1');

    return this.http.get<PagedResponse<TestRun>>(this.testRunsUrl, {params})
      .pipe(map((logs) => logs.data[0]));
  }

  /**
   * Get the latest Test Run Snapshot for the given project test case
   * @param {number} projectTestCaseId - id of the project test case
   * @param {boolean} includeMessages - should we include messages?
   * @returns {Observable<CardLog>} - API response
   */
  getLatestTestRunSnapshot(projectTestCaseId: number, includeMessages: boolean = false): Observable<TestRun> {
    const params = new HttpParams()
      .set('includeMessages', String(includeMessages))
      .append('includeHostLogTransaction', 'true')
      .append('includeResult', 'true')
      .append('projectTestCaseId', projectTestCaseId.toString())
      .append('sort', 'uploadTime,DESC')
      .append('size', '1');

    return this.http.get<PagedResponse<TestRun>>(this.testRunsSnapshotUrl, {params})
      .pipe(map((logs) => logs.data[0]));
  }

  /**
   * Download a project file
   * @param {number} fileId - ID of file to download
   */
  downloadProjectFile(fileId: number): Observable<any> {
    return this.downloadFile(fileId).pipe(
      map(resp => resp.body)
    );
  }

  /**
   * Download a project file
   * @param {number} fileId - ID of file to download
   * @param {ProjectFileType} requestedFileType - Type of test file requested (in case a file needs converting by the API)
   */
  downloadConvertedTestFile(projectId: number, requestedFileType: ProjectFileType): Observable<any> {
    return this.downloadTestPlansFile(projectId, requestedFileType);
  }

  downloadHostLog(fileId: number): Observable<any> {
    const url = `${this.hostLogsUrl}/${fileId}/content`;
    const hTTPOptions = {
      headers: new HttpHeaders(),
      responseType: 'blob' as 'json'
    };
    return this.http.get(url, hTTPOptions);
  }

  /**
   * Upload a project file
   * @param projectFile - details of file to up load
   * @param file - file information for upload
   */
  uploadProjectFile(projectFile: Partial<ProjectFile>, file: any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('type', projectFile.fileType);

    const req = new HttpRequest('POST', `${this.projectFilesUrl}/${projectFile.projectId}`, formData, {
      reportProgress: true
    });

    return this.http.request(req);
  }

  /**
   * Get a list of tests that will be added/removed/stay the same or are not recognised in the given file
   * @param {number} projectId - ID of the project that we are adding the test file to
   * @param {File} file - The test file
   * @returns {Observable<ProvisionalTestCaseResult>} - Analysis result
   */
  generateProvisionalTestCaseList(projectId: number, file: File): Observable<ProvisionalTestCaseResult> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<ProvisionalTestCaseResult>(`${this.projectFilesUrl}/${projectId}/provisional`, formData);
  }

  /**
   * Upload a test case file to a project
   * @param {number} projectId - ID of the project to add the file to
   * @param {File} file - File to upload
   * @param {ProjectFileType} fileType - Type of the file
   */
  uploadProjectTestCases(projectId: number, file: File, fileType: ProjectFileType): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('type', fileType);

    return this.http.post(`${this.projectFilesUrl}/${projectId}`, formData);
  }

  /**
   * Delete a project file
   * @param projectFileId - ID of the project file to delete
   */
  deleteProjectFile(projectFileId: number): Observable<any> {
    return this.http.delete(`${this.projectFilesUrl}/${projectFileId}`);
  }

  uploadList(url: string, files: FileList): Observable<any> {
    const formData: FormData = new FormData();
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      formData.append(file.name, file);

    }

    return this.http.post(url, formData);
  }

  upload(url: string, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post(url, formData);
  }

  downloadFile(fileId: number): Observable<HttpResponse<any>> {
    const url = `${this.projectFilesUrl}/${fileId}`;
    return this.http.get<any>(url, {
      responseType: 'blob' as 'json',
      observe: 'response'
    });
  }

  downloadTestPlansFile(projectId: number, requestedFileType?: ProjectFileType): Observable<HttpResponse<any>> {
    const url = `${this.projectFilesUrl}/testPlans/${projectId}`;
    return this.http.get<any>(url, {
      responseType: 'blob' as 'json',
      observe: 'response',
      params: requestedFileType ? new HttpParams().set('type', requestedFileType) : null
    });
  }
}
