import {Injectable} from '@angular/core';
import {TestsOverviewReport} from '@models/reports/testsOverviewReport';
import commonTableStyle from '../../commonTableStyle';

const columnAlignments = ['left', 'center', 'right', 'left', 'right', 'left', 'right', 'left', 'right', 'left', 'right', 'left', 'right', 'left'];

export interface ITestsReportTableProviderService {drawTable(doc: any, testsReport: TestsOverviewReport, yPos: number): void;}

@Injectable()
export class TestsReportTableProviderService implements ITestsReportTableProviderService {

  drawTable(doc: any, testsReport: TestsOverviewReport, yPos: number) {
    doc.autoTable({
      ...this.buildTestsReport(testsReport),
      ...commonTableStyle,
      startY: yPos,
      didParseCell: data => {
        data.cell.styles.halign = columnAlignments[data.column.index];
        if (data.row.index === 0 && data.row.section === 'body') {
          data.cell.styles.fontStyle = 'bold';
          data.cell.styles.fontSize = 8;
        }
      },
      columnStyles: {
        name: {
          width: 50
        }
      }
    });
  }

  private buildTestsReport(testsReportData: TestsOverviewReport): { head: Array<any>, body: Array<Array<string>> } {
    const head = [{
      name: 'Name',
      total: 'Test Cases',
      notStartedTotal: 'Not Started',
      notStartedPercent: '%',
      inProgressTotal: 'In Progress',
      inProgressPercent: '%',
      passedTotal: 'Passed',
      passedPercent: '%',
      failedTotal: 'Failed',
      failedPercent: '%',
      waivedTotal: 'Waived',
      waivedPercent: '%',
      overriddenTotal: 'Overridden',
      overriddenPercent: '%'
    }];
    const totalRow = ['Total',
      testsReportData.totalTests.toString(),
      testsReportData.uninitiatedTests.toString(),
      TestsReportTableProviderService.roundedNumber(testsReportData.uninitiatedPercentage, 2),
      testsReportData.initiatedTests.toString(),
      TestsReportTableProviderService.roundedNumber(testsReportData.initiatedPercentage, 2),
      testsReportData.passedTests.toString(),
      TestsReportTableProviderService.roundedNumber(testsReportData.passedPercentage, 2),
      testsReportData.failedTests.toString(),
      TestsReportTableProviderService.roundedNumber(testsReportData.failedPercentage, 2),
      testsReportData.waivedTests.toString(),
      TestsReportTableProviderService.roundedNumber(testsReportData.waivedPercentage, 2),
      testsReportData.overriddenTests.toString(),
      TestsReportTableProviderService.roundedNumber(testsReportData.overriddenPercentage, 2)
    ];
    const planRows = testsReportData.testCaseStatisticsList.map<Array<string>>(plan => [
      plan.testName,
      plan.totalTests.toString(),
      plan.uninitiatedTests.toString(),
      TestsReportTableProviderService.roundedNumber(plan.uninitiatedPercentage, 2),
      plan.initiatedTests.toString(),
      TestsReportTableProviderService.roundedNumber(plan.initiatedPercentage, 2),
      plan.passedTests.toString(),
      TestsReportTableProviderService.roundedNumber(plan.passedPercentage, 2),
      plan.failedTests.toString(),
      TestsReportTableProviderService.roundedNumber(plan.failedPercentage, 2),
      plan.waivedTests.toString(),
      TestsReportTableProviderService.roundedNumber(plan.waivedPercentage, 2),
      plan.overriddenTests.toString(),
      TestsReportTableProviderService.roundedNumber(plan.overriddenPercentage, 2)
    ]);
    return {head, body: [totalRow, ...planRows]};
  }

  private static roundedNumber(value: number, decimalPlaces: number): string {
    return parseFloat(value.toFixed(decimalPlaces)).toString();
  }
}
