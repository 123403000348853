import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, publishReplay, refCount} from 'rxjs/operators';
import {UsersService} from './users.service/users.service';

interface UsernameLookup {
  id: number;
  username: string;
}

@Injectable()
export class UniqueUsernameService {

  private usernames$: Observable<Array<UsernameLookup>>;

  constructor(usersService: UsersService) {
    this.usernames$ = usersService.listUsers().pipe(
      map(({data}) => data.map<UsernameLookup>(u => ({id: u.id, username: u.username}))),
      publishReplay(1),
      refCount());
  }

  isUniqueUsername(username: string, excludingUserId: number = 0): Observable<boolean> {
    return this.usernames$.pipe(
      map(users => users.every(u => this.toLowerCase(u.username) !== this.toLowerCase(username) || u.id === excludingUserId))
    );
  }

  private toLowerCase(name: string): string {
    return name.toLocaleLowerCase();
  }
}
