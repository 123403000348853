import {Injectable} from '@angular/core';
import {est_timezone, momentDateTimeFormat} from '@models/constants';
import {ProjectDetail} from '@models/projectDetail';
import * as moment from 'moment';

export interface IProjectInfoTableProviderService {renderInfo(doc: any, projectDetail: ProjectDetail, ypos: number): Promise<number>; }

@Injectable()
export class ProjectInfoTableProviderService implements IProjectInfoTableProviderService {

  async renderInfo(doc: any, projectDetail: ProjectDetail, ypos: number): Promise<number> {
    const creationDate: string = moment(projectDetail.creationDate).tz(est_timezone).format(momentDateTimeFormat);

    doc.autoTable({
      head: [{projectName: 'Project Name', client: 'Client', projectId: 'Fiserv Project ID', created: 'Created'}],
      headStyles: {
        fillColor: [230, 230, 230],
        fontSize: 9,
        textColor: [80, 80, 80]
      },
      body: [[projectDetail.name, projectDetail.merchantName, projectDetail.projectId, creationDate]],
      startY: ypos,
      theme: 'grid',
    });
    return doc.lastAutoTable.finalY;
  }
}
