import {Injectable} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class MerchantIdValidationService {
  merchantIdRevert$ = new BehaviorSubject<AbstractControl>(null);
  private oldValue: any;
  private message: string = null;

  setConflict(oldValue: any, message: string) {
    this.oldValue = oldValue;
    this.message = message;
  }

  getConflict() {
    return this.message;
  }

  revertToOldValue() {
    this.merchantIdRevert$.next(this.oldValue);
    this.message = null;
    this.oldValue = null;
  }
}
