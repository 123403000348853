import {Injectable, OnDestroy} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Client} from '@models/clients';
import {Project} from '@models/project';
import {Role} from '@models/security';
import {User} from '@models/security/user';
import {AuthenticationService} from '@services/auth/authentication.service/authentication.service';
import {ClientsService} from '@services/data/clients.service/clients.service';
import {FilterParameters} from '@services/data/filterParameters';
import {ProjectsService} from '@services/data/projects.service/projects.service';
import {BehaviorSubject, Observable, ReplaySubject, Subscription} from 'rxjs';
import {shareReplay, switchMap, tap} from 'rxjs/operators';
import {
  WaiverFilterDialogComponent,
  WaiverFilterDialogParameters
} from './waiver-filter-parameter/waiver-filter-dialog/waiver-filter-dialog.component';
import WaiverFilter from './waiver-filter-parameter/waiverFilter';
import SearchField = WaiverFilter.SearchField;
import SearchParameterType = WaiverFilter.SearchParameterType;

export interface IWaiverFilterService {
  allProjects$: Observable<Array<Project>>;
  allClients$: Observable<Array<Client>>;
  updateFilterParameters(filters: FilterParameters, dialogTitle: string, isSandbox: boolean): Promise<any>;
  refreshAllClients(lazy: boolean): void;
  refreshAllProjects(lazy: boolean): void;
  refreshAll(lazy: boolean): void;
  clearCaches();
}

@Injectable()
export class WaiverFilterService implements IWaiverFilterService, OnDestroy{
  private projectRefresh$ = new ReplaySubject<void>(1);
  private projectsLoaded = false;
  private clientRefresh$ = new ReplaySubject<void>(1);
  private clientsLoaded = false;
  allClients$: Observable<Array<Client>>;
  allProjects$: Observable<Array<Project>>;

  private userRole: Role;
  private authenticationSubscription: Subscription;
  _clientLeadUsers = new BehaviorSubject<Array<User>>([]);

  private _currentClientId: SearchParameterType;

  constructor(private dialog: MatDialog,
              projectsService: ProjectsService,
              private clientsService: ClientsService,
              authenticationService: AuthenticationService) {

    this.allClients$ = this.clientRefresh$.pipe(
      switchMap(_ => clientsService.getAllClients()),
      tap(_ => this.clientsLoaded = true),
      shareReplay(1)
    );

    this.allProjects$ = this.projectRefresh$.pipe(
      switchMap(_ => projectsService.getProjectSummaries('name', 'asc')),
      tap(_ => this.projectsLoaded = true),
      shareReplay(1)
    );

    this.authenticationSubscription = authenticationService.authInfo$.subscribe(authInfo => this.userRole = authInfo?.role);
  }

  set currentClientId(value: WaiverFilter.SearchParameterType) {
    this._currentClientId = value;
    if (value) {
      this.clientsService.getClientLeadUsers(value as number).toPromise().then(users => this._clientLeadUsers.next(users));
    }
  }

  ngOnDestroy(): void {
    this.authenticationSubscription.unsubscribe();
  }

  refreshAllClients(lazy: boolean = true): void {
    if (!lazy || !this.clientsLoaded) {
      this.clientRefresh$.next();
    }
  }

  refreshAllProjects(lazy: boolean = true): void {
    if (!lazy || !this.projectsLoaded) {
      this.projectRefresh$.next();
    }
  }

  refreshAll(lazy: boolean = true): void {
    this.refreshAllClients(lazy);
    this.refreshAllProjects(lazy);
  }

  clearCaches(): void {
    this.clientsLoaded = false;
    this.projectsLoaded = false;
  }

  updateFilterParameters(filters: Map<string, string | Date>, dialogTitle: string, isSandbox: boolean): Promise<any> {
    this._currentClientId = filters.get(SearchField.Client);
    return this.dialog.open<WaiverFilterDialogComponent, WaiverFilterDialogParameters>(WaiverFilterDialogComponent, {
      width: '700px',
      data: {
        existingParameters: filters,
        title: dialogTitle
      }
    }).afterClosed().toPromise();
  }
}
