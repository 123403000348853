import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {PermissionsService} from '@services/auth/permissions.service/permissions.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  clearResults: boolean;
  canClearResult: Observable<boolean>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogParams, private permissionsService: PermissionsService) {
    this.clearResults = data.option?.defaultValue;
    this.canClearResult = permissionsService.isAnalyst();
  }

  setClearResults(value: boolean): void {
    this.clearResults = value;
  }
}

export interface DialogExtraOption {
  defaultValue: boolean;
  text: string;
  tooltip?: string;
}

export interface ConfirmDialogParams {
  title: string;
  message: string;
  yesText: string;
  noText: string;
  isWarning: boolean;
  option?: DialogExtraOption;
}

export interface ConfirmDialogResult {
  dialogOption: boolean;
  clearResults: boolean;
}

export function instanceOfConfirmResult(object: any): object is ConfirmDialogResult {
  if (typeof object === 'object') {
    return 'clearResults' in object;
  }
  return false;
}
