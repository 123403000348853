<div class="left-container">
  <ul>
    <li *ngFor="let button of buttons | async" routerLinkActive="active" class="icon-container" [id]="button.iconName">
      <a [routerLink]="button.routerLink">
        <app-svg [iconName]="button.iconName"></app-svg>
        <div class="button-title">{{button.title}}</div>
      </a>
    </li>
  </ul>
</div>
