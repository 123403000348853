import {Pipe, PipeTransform} from '@angular/core';
import {est_timezone, shortDateFormat} from '@models/constants';
import * as moment from 'moment-timezone';

export interface IDatePipe {transform(value: unknown, format: string, ...args: unknown[]): unknown;}

@Pipe({
    name: 'estDate'
})
export class EstDatePipe implements PipeTransform, IDatePipe {
  transform(value: unknown, format: string = shortDateFormat, ...args: unknown[]): unknown {
    const date = moment(value);
    return date.isValid() ? date.tz(est_timezone).format(format) : value;
  }
}
