import {Injectable} from '@angular/core';
import {TestsOverviewReport} from '@models/reports/testsOverviewReport';
import {BehaviorSubject, Observable} from 'rxjs';
import {ITestsOverviewReportStateService} from './ITestsOverviewReportStateService';

@Injectable()
export class TestsOverviewReportStateService implements ITestsOverviewReportStateService {

  private testOverview$ = new BehaviorSubject<TestsOverviewReport>(null);

  get testsOverviewReport$(): Observable<TestsOverviewReport> {
    return this.testOverview$.asObservable();
  }


  setTestsOverviewReport(testOverviewReport: TestsOverviewReport) {
    this.testOverview$.next(testOverviewReport);
  }

  resetTests() {
    this.testOverview$.next(null);
  }
}
