import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpSentEvent} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import HttpStatusCode from '../httpStatusCode';
import {SpinnerService} from './spinner.service/spinner.service';

enum WaitingMessage {
  PhasePreValidation = 'Waiting for card log parsing to complete before trying again',
  TestsArchiveMessage = 'Generating Test Certify Archive',
  TestsRestoreMessage = 'Restoring From Test Certify Archive'
}

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {

  count = 0;
  retryableCount = 0;
  spinnerMessage = 'Waiting';
  timeout;
  retryMessage = WaitingMessage.TestsArchiveMessage;

  constructor(private spinnerService: SpinnerService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Avoid displaying spinner for background request type API calls
    if (request.headers.get('background-request')) {
      return next.handle(request);
    }
    if (this.isRetryable(request)) {
      this.spinnerService.show(true, this.retryMessage); // show the spinner in the centre the page
      if (this.retryableCount > 0) {
        this.retryableCount--; // reset retryable if error caught on previous attempt
      }
    } else {
      this.spinnerService.show();
    }
    this.count++;

    if (environment.production) {
      return next.handle(request)
        .pipe(
          finalize(() => {
            this.count--;
            if (this.count === 0 && this.retryableCount === 0) {
              this.spinnerService.hide();
              this.spinnerMessage = 'Waiting';
            }
          }),
          catchError((err: any): Observable<HttpSentEvent> => {
            if (this.isRetryable(request)) {
              this.retryableCount++; // Add retryable count
              if (err?.status !== HttpStatusCode.LOCKED) {
                this.spinnerService.show(true, this.spinnerMessage);
              }
              this.setRetryTimeout();
            }
            throw err;
          })
        );
    }
    return next.handle(request)
      .pipe(
        finalize(() => {
          this.count--;
          if (this.count === 0 && this.retryableCount === 0) {
            this.spinnerService.hide();
          }
        }),
        catchError((err: any): Observable<HttpSentEvent> => {
          if (this.isRetryable(request)) {
            this.retryableCount++; // Add retryable count
            this.spinnerService.show(true, this.spinnerMessage);
            this.setRetryTimeout();
          }
          throw err;
        })
      );
  }

  isRetryable(request: any): boolean {
    // Check if endpoint is retryable
    if (this.spinnerService.isRestoreRequest(request.url)) {
      this.retryMessage = WaitingMessage.TestsRestoreMessage;
      return true;
    } else if (this.spinnerService.isPreValidation(request.body)) {
      this.spinnerMessage = WaitingMessage.PhasePreValidation;
      this.retryMessage = WaitingMessage.TestsArchiveMessage;
      return true;
    } else if (this.spinnerService.isTestExecution(request.body)) {
      this.retryMessage = WaitingMessage.TestsRestoreMessage;
      return true;
    }
    return false;
  }

  setRetryTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.retryableCount = 0;
      if (this.count === 0) {
        this.spinnerService.hide();
      }
    }, 5000);
  }
}
