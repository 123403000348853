<div class="row">
  <div class="col-6">
    <app-inline-edit [(ngModel)]="terminalId"
                     [required]="true"
                     (blur)="blur.emit(this)"
                     [enabledSpaces]="false"
                     [readonly]="readonly"
                     label="Terminal ID"
                     errorMessage="You must enter a terminal ID"></app-inline-edit>
  </div>
  <div class="col-6">
    <app-inline-edit [(ngModel)]="merchantId"
                     [required]="true"
                     (blur)="blur.emit(this)"
                     [enabledSpaces]="false"
                     [readonly]="readonly"
                     label="Merchant ID"
                     errorMessage="You must enter a merchant ID"></app-inline-edit>
  </div>
</div>
