import {Pipe, PipeTransform} from '@angular/core';
import {shortDateFormat} from '@models/constants';
import * as moment from 'moment-timezone';
import {Moment} from 'moment-timezone';

export interface IDatePipe {transform(value: unknown, format: string, ...args: unknown[]): unknown; }

@Pipe({
  name: 'utcDate'
})
export class UtcDatePipe implements PipeTransform, IDatePipe {
  transform(value: Moment, format: string = shortDateFormat, ...args: unknown[]): string {
    const date = moment();
    return date.utc()
      .date(value.date())
      .month(value.month())
      .year(value.year())
      .hours(0)
      .minute(0)
      .second(0)
      .format();
  }
}
