import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Comment} from '@models/comment';
import {angularDatePipeFormat} from '@models/constants';
import HttpStatusCode from '@services/httpStatusCode';
import {NotificationService} from '@services/notification.service/notification.service';
import {merge, Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {TestCaseCommentsDataSourceService} from '../../pages/projects/project-tests/project-test-case-detail/test-case-comments-view/test-case-comments-data-source.service/test-case-comments-data-source.service';
import {ConfirmDialogService} from '../confirm-dialog/confirm-dialog.service';
import {CommentDialogComponent, CommentDialogParams, CommentDialogResult} from './comment-dialog.component';
import {ICommentsDataSource} from './iCommentsDataSource';

@Component({
  selector: 'app-comments-list',
  templateUrl: './comments-list.component.html',
  styleUrls: ['./comments-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentsListComponent implements OnInit, OnDestroy {

  @Input() commentsDataSourceService: ICommentsDataSource;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  readonly commentDateFormat = angularDatePipeFormat;
  dataSource$: Observable<MatTableDataSource<Comment>>;
  private subscription: Subscription;
  commentCount$: Observable<number>;
  canCreateComments$: Observable<boolean>;
  private isSnapshot: boolean;

  displayedColumns = ['creationDate', 'author', 'lastModifiedDate', 'commentText', 'delete', 'edit'];

  constructor(private dialog: MatDialog,
              private confirmDialogService: ConfirmDialogService,
              private notificationService: NotificationService,
              private testCasesCommentsDataSourceService: TestCaseCommentsDataSourceService) {
    this.isSnapshot = testCasesCommentsDataSourceService.isSnapshot;
  }

  ngOnInit(): void {
    this.commentCount$ = this.commentsDataSourceService.commentCount$;
    this.canCreateComments$ = this.commentsDataSourceService.canCreateComments();

    this.updatePagingInfo();
    this.dataSource$ = this.commentsDataSourceService.comments$.pipe(map(comments => new MatTableDataSource(comments)));

    this.subscription = merge(
      this.sort.sortChange, this.paginator.page
    ).subscribe(_ => this.updatePagingInfo());
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private updatePagingInfo() {
    this.commentsDataSourceService.setPaging(this.sort.active, this.sort.direction,
      this.paginator.pageIndex, this.paginator.pageSize ?? 25);
  }

  async deleteComment(comment: Comment) {
    const response = await this.confirmDialogService.confirm('Delete Comment', 'Are you sure you want to delete this comment?').toPromise();
    if (response) {
      try {
        await this.commentsDataSourceService.deleteComment(comment);
        this.notificationService.success('Comment deleted');
      } catch (error) {
        const errorMessage = error?.status === HttpStatusCode.LOCKED ? error?.error?.message : 'Comment could not be deleted';
        this.notificationService.error(errorMessage);
      }
    }
  }

  async editComment(comment: Comment) {
    const response = await this.commentDialog(comment);
    if (response) {
      try {
        await this.commentsDataSourceService.updateComment({...comment, commentText: response.comment});
        this.notificationService.success('Comment amended');
      } catch (error) {
        const errorMessage = error?.status === HttpStatusCode.LOCKED ? error?.error?.messagee : 'Comment could not be amended';
        this.notificationService.error(errorMessage);
      }
    }
  }

  async addComment() {
    const response = await this.commentDialog(undefined);
    if (response) {
      try {
        await this.commentsDataSourceService.addComment(response.comment, this.isSnapshot);
        this.notificationService.success('Comment added');
      } catch (error) {
        const errorMessage = error?.status === HttpStatusCode.LOCKED ? error?.error?.message : 'Comment could not be added';
        this.notificationService.error(errorMessage);
      }
    }
  }

  private commentDialog(comment: Comment | undefined): Promise<CommentDialogResult> {
    const params: MatDialogConfig<CommentDialogParams> = {
      minWidth: '300px',
      width: '75%',
      data: {
        commentToEdit: comment
      }
    };
    return this.dialog.open<CommentDialogComponent, CommentDialogParams>(CommentDialogComponent,
      params).afterClosed().toPromise() as Promise<CommentDialogResult>;
  }

  canEditComment(comment: Comment): Observable<boolean> {
    return this.commentsDataSourceService.canEditComment(comment);
  }

  canDeleteComment(comment: Comment): Observable<boolean> {
    return this.commentsDataSourceService.canDeleteComment(comment);
  }
}
