import {NgModule} from '@angular/core';
import {PdfDrawingService} from '../pdf-drawing.service/pdf-drawing.service';
import {DownloadReportService} from './dowload-report.service/download-report.service';
import {ActiveProjectsTableProviderService} from './overview-report-provider.service/active-projects-table-provider.service/active-projects-table-provider.service';
import {AllProjectsTableProviderService} from './overview-report-provider.service/all-projects-table-provider.service/all-projects-table-provider.service';
import {AnalystInfoTableProviderService} from './overview-report-provider.service/analyst-info-table-provider.service/analyst-info-table-provider.service';
import {ClientInfoTableProviderService} from './overview-report-provider.service/client-info-table-provider.service/client-info-table-provider.service';
import {CompletedProjectsTableProviderService} from './overview-report-provider.service/completed-projects-table-provider.service/completed-projects-table-provider.service';
import {OverviewReportProviderService} from './overview-report-provider.service/overview-report-provider.service';
import {PhaseReportTableProviderService} from './project-report-provider.service/phase-report-table-provider.service/phase-report-table-provider.service';
import {ProjectInfoTableProviderService} from './project-report-provider.service/project-info-table-provider.service/project-info-table-provider.service';
import {ProjectReportProviderService} from './project-report-provider.service/project-report-provider.service';
import {TestsReportTableProviderService} from './project-report-provider.service/tests-report-table-provider.service/tests-report-table-provider.service';

@NgModule({
  providers: [
    ProjectReportProviderService,
    ProjectInfoTableProviderService,
    TestsReportTableProviderService,
    PhaseReportTableProviderService,
    ActiveProjectsTableProviderService,
    AllProjectsTableProviderService,
    CompletedProjectsTableProviderService,
    OverviewReportProviderService,
    ClientInfoTableProviderService,
    AnalystInfoTableProviderService,
    PdfDrawingService,
    DownloadReportService
  ]
})
export class ReportsServiceModule { }
