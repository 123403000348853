import {Injectable} from '@angular/core';
import {AuthInfo} from '@models/security';
import {SessionStorageService} from '../../session-storage.service/session-storage.service';

@Injectable()
export class AuthenticationStorageService extends SessionStorageService<AuthInfo> {
    constructor() {
        super('auth');
    }
}
