import {CertQuestion} from '@models/intake-forms';
import {Renderer} from './renderer';

const AfterQuestionSpace = 8;
const SpaceBetweenQuestionAndOptions = 6;
const SpaceBetweenOptions = 4;
const CheckboxIndent = 14;
const OptionIndent = 10;

export class MultiSelectCheckboxRenderer extends Renderer {
  private questionHeight: number;

  constructor(q: CertQuestion) {super(q);}

  layout(doc: any) {
    this.questionHeight = this.height = doc.getWrappedTextSize(this.question.QuestionText).height + SpaceBetweenQuestionAndOptions;
    const possibleValues = this.question.PossibleValues.string as Array<string>;
    if (possibleValues) {
      possibleValues.forEach(value => this.height += doc.getWrappedTextSize(value).height + SpaceBetweenOptions);
    }
    this.height += AfterQuestionSpace;
  }

  render(doc: any, ypos: number) {
    doc.drawWrappedText(this.question.QuestionText, ypos);
    ypos += this.questionHeight;

    const possibleValues = this.question.PossibleValues.string as Array<string>;
    if (possibleValues) {
      possibleValues.forEach(value => {
          doc.drawWrappedText(value, ypos, CheckboxIndent + OptionIndent);
          doc.drawRect(doc.LeftPageMargin + OptionIndent, ypos - 6, 8, 8, '#a0a0a0');
          if (this.question.Result.string?.includes(value)) {
            doc.drawRect(doc.LeftPageMargin + OptionIndent + 2, ypos - 4, 4, 4, '#000000', '#000000');
          }
          ypos += doc.getWrappedTextSize(value).height + SpaceBetweenOptions;
        }
      );
    }

  }
}
