import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {HostLogOption} from '@models/hostLogOption';
import {PagedResponse} from '@models/pagedResponse';
import {ActiveProjectsReport} from '@models/reports/activeProjectsReport';
import {AllProjectsReport} from '@models/reports/allProjectsReport';
import {CompletedProjectsReport} from '@models/reports/completedProjectsReport';
import {CustomReportColumn, CustomReportResponse, SearchConfiguration} from '@models/reports/customReportModels';
import {DaysInPhaseReport} from '@models/reports/daysInPhaseReport';
import {GlobalWaiverReport} from '@models/reports/globalWaiverReport';
import {IccBrand} from '@models/reports/iccBrands';
import {ProjectMileStones} from '@models/reports/projectMileStones';
import {TestsOverviewReport} from '@models/reports/testsOverviewReport';
import {SchemeBrand} from '@models/schemeBrand';
import {TestCaseStatus} from '@models/TestCaseStatus';
import {Observable} from 'rxjs';
import {AppConfigService} from '../../configuration/app-config.service/app-config.service';
import {FilterParameters, populateFilterParameters} from '../filterParameters';
import {SortDirection} from '../sortDirection';

export interface IReportsService {
  getDaysInPhaseReport(projectId: number): Observable<Array<DaysInPhaseReport>>;

  getTestsOverviewReport(projectId: number): Observable<TestsOverviewReport>;

  /**
   * Get the all projects report (optionally for a client)
   * @param {number} clientId - ID of client, or null if for whole system
   * @param {number} analystId - ID of analyst, or null if all analysts
   * @returns {Observable<any>}
   */
  getAllProjectsReport(clientId?: number, analystId?: number, sandbox?: boolean): Observable<any>;

  /**
   * Get the active projects report (optionally for a client)
   * @param {number} clientId - ID of client, or null if for whole system
   * @param {number} analystId - ID of analyst, or null if all analysts
   * @returns {Observable<any>}
   */
  getActiveProjectsReport(clientId?: number, analystId?: number, sandbox?: boolean): Observable<any>;

  /**
   * Get the completed projects report (optionally for a client)
   * @param {number} clientId - ID of client, or null if for whole system
   * @param {number} analystId - ID of analyst, or null if all analysts
   * @param {boolean} sandbox - sandbox, or false for non sandbox projects
   * @returns {Observable<any>}
   */
  getCompletedProjects(clientId?: number, analystId?: number, sandbox?: boolean): Observable<any>;

  /**
   * Get a custom report for the system
   * @param {string} sort - sort parameter
   * @param {string} dir - sort direction
   * @param {number} page - page index
   * @param {number} pageSize - size of pages
   * @param {Array<CustomReportColumn>} columns - required column configuration
   * @param {FilterParameters} searchParams - any filter parameters
   * @returns {Observable<PagedResponse<CustomReportResponse>>}
   */
  getCustomReport(sort, dir, page, pageSize, columns: Array<CustomReportColumn>, searchParams: FilterParameters): Observable<CustomReportResponse>;

  /**
   * Download an excel file of the custom report
   * @param {FilterParameters} searchParams - any filter parameters
   * @param sort - Sort by column
   * @param sortDirection - Sort direction
   * @param {Array<CustomReportColumn>} columns - required column configuration
   * @returns {Observable<any>}
   */
  getCustomReportExcelFile(searchParams: FilterParameters, sort: string, sortDirection: SortDirection,
                           columns: Array<CustomReportColumn>, sandbox: boolean): Observable<any>;

  /**
   * Download an excel file of project test cases status report
   * @returns {Observable<any>}
   * @param projectId
   */
  getTestCaseReportExcelFile(projectId: number, brand: SchemeBrand, status: TestCaseStatus, hostLogOption: HostLogOption): Observable<any>;

  /**
   * Get an ICC brand report
   * @param {IccBrand} brand - brand of report to generate
   * @returns {Observable<any>}
   */
  getBrandReport(brand: IccBrand): Observable<any>;

  /**
   * Get the users report
   * @returns {Observable<any>}
   */
  getUsersReport(): Observable<any>;

  /**
   * Get the dates infos for a project (phase end date, project start date..)
   * @param {number} projectId - Id of project
   * @returns {Observable<ProjectMileStones>}
   */
  getProjectMileStones(projectId: number): Observable<Array<ProjectMileStones>>;

  getUsersSearchConfigs(): Observable<Array<SearchConfiguration>>;

  saveSearchConfig(searchConfig: Partial<SearchConfiguration>): Observable<SearchConfiguration>;

  deleteSearchConfig(searchConfig: SearchConfiguration): any;

  updateSearchConfig(searchConfig: SearchConfiguration): Observable<SearchConfiguration>;

  /**
   * Get global waivers report records
   * @param sort - sort for list
   * @param {SortDirection} dir - sort direction for list
   * @param page - page index for paging
   * @param pageSize - page size for paging
   * @returns {Observable<PagedResponse<any>>}
   */
  getGlobalWaivers(sort, dir, page, pageSize, searchParameter, filter): Observable<PagedResponse<GlobalWaiverReport>>;
  getGlobalWaiverExcelFile(searchParams: FilterParameters, sort: string, sortDirection: SortDirection, filter): Observable<GlobalWaiverReport>;
}

@Injectable()
export class ReportsService implements IReportsService {

  constructor(private http: HttpClient,
              appConfig: AppConfigService) {
    this.projectReportsUrl = appConfig.getRestUrl('/projects');
    this.reportsUrl = appConfig.getRestUrl('/reports');
  }

  private readonly projectReportsUrl: string;
  private reportsUrl: string;

  private static getFilterParams(clientId?: number, analystId?: number, sandbox?: boolean): HttpParams {
    let params = new HttpParams();
    if (clientId) {
      params = params.append('merchantId', clientId.toString());
    }
    if (analystId) {
      params = params.append('analystId', analystId.toString());
    }
    if (sandbox) {
      params = params.append('sandbox', sandbox.toString());
    }
    return params;
  }

  /**
   * Get the report for the days that a project has been in each phase
   * @param {number} projectId - Id of project
   * @returns {Observable<Array<DaysInPhaseReport>>}
   */
  getDaysInPhaseReport(projectId: number): Observable<Array<DaysInPhaseReport>> {
    return this.http.get<Array<DaysInPhaseReport>>(`${this.projectReportsUrl}/${projectId}/reports/daysInPhase`);
  }

  /**
   * Get the report that gives a summary of test statuses for a project
   * @param {number} projectId - Id of project
   * @returns {Observable<TestsOverviewReport>}
   */
  getTestsOverviewReport(projectId: number): Observable<TestsOverviewReport> {
    return this.http.get<TestsOverviewReport>(`${this.projectReportsUrl}/${projectId}/reports/testsOverview`);
  }

  /**
   * Get the all projects report (optionally for a client)
   * @param {number} clientId - ID of client, or null if for whole system
   * @param {number} analystId - ID of analyst, or null if all analysts
   * @param {boolean} sandbox - sandbox, or false for non sandbox projects
   * @returns {Observable<any>}
   */
  getAllProjectsReport(clientId?: number, analystId?: number, sandbox?: boolean): Observable<AllProjectsReport> {
    const params = ReportsService.getFilterParams(clientId, analystId, sandbox);
    return this.http.get<AllProjectsReport>(`${this.projectReportsUrl}/reports/projectTotalsOverview`, {params});
  }

  /**
   * Get the active projects report (optionally for a client)
   * @param {number} clientId - ID of client, or null if for whole system
   * @param {number} analystId - ID of analyst, or null if all analysts
   * @param {boolean} sandbox - sandbox, or false for non sandbox projects
   * @returns {Observable<any>}
   */
  getActiveProjectsReport(clientId?: number, analystId?: number, sandbox?: boolean): Observable<ActiveProjectsReport> {
    const params = ReportsService.getFilterParams(clientId, analystId, sandbox);
    return this.http.get<ActiveProjectsReport>(`${this.projectReportsUrl}/reports/activeProjectPhases`, {params});
  }

  /**
   * Get the completed projects report (optionally for a client)
   * @param {number} clientId - ID of client, or null if for whole system
   * @param {number} analystId - ID of analyst, or null if all analysts
   * @param {boolean} sandbox - sandbox, or false for non sandbox projects
   * @returns {Observable<any>}
   */
  getCompletedProjects(clientId?: number, analystId?: number, sandbox?: boolean): Observable<CompletedProjectsReport> {
    const params = ReportsService.getFilterParams(clientId, analystId, sandbox);
    return this.http.get<CompletedProjectsReport>(`${this.projectReportsUrl}/reports/completedProjects`, {params});
  }

  /**
   * Get a custom report for the system
   * @param {string} sort - sort parameter
   * @param {string} dir - sort direction
   * @param {number} page - page index
   * @param {number} pageSize - size of pages
   * @param {Array<CustomReportColumn>} columns - required column configuration
   * @param {FilterParameters} searchParams - any filter parameters
   * @returns {Observable<PagedResponse<CustomReportResponse>>}
   */
  getCustomReport(sort = 'name', dir: SortDirection = 'desc', page = 0, pageSize = 99999, columns: Array<CustomReportColumn> = [],
                  searchParams: FilterParameters = null, sandbox: boolean = false): Observable<CustomReportResponse> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('size', pageSize.toString())
      .set('sort', `${sort},${dir}`)
      .set('sandbox', sandbox.toString());

    if (searchParams) {
      // Copy the source Map to remove its reference
      const paramsCopy: FilterParameters = new Map(searchParams);

      if (paramsCopy.has('authSpec')) {
        const authSpecValue: string = paramsCopy.get('authSpec').toString();

        paramsCopy.set('authSpec', encodeURIComponent(authSpecValue));
      }

      params = populateFilterParameters(paramsCopy, params);
    }

    return this.http.post<CustomReportResponse>(`${this.projectReportsUrl}/reports/customReport`,
      columns.map(col => ({name: col.name, visible: col.visible})), {params});
  }

  /**
   * Download an excel file of the custom report
   * @param {FilterParameters} searchParams - any filter parameters
   * @param {string} sort - sort parameter
   * @param {string} sortDirection - sort direction
   * @param {Array<CustomReportColumn>} columns - required column configuration
   * @returns {Observable<any>}
   */
  getCustomReportExcelFile(searchParams: FilterParameters, sort: string, sortDirection: SortDirection,
                           columns: Array<CustomReportColumn>, sandbox = false): Observable<any> {
    let params = new HttpParams()
      .set('sort', `${sort},${sortDirection}`)
      .set('sandbox', sandbox.toString());

    if (searchParams) {
      params = populateFilterParameters(searchParams, params);
    }
    return this.http.post(`${this.projectReportsUrl}/reports/generateCustomReport`,
      columns,
      {
        params,
        responseType: 'blob'
      });
  }

  /**
   * Download an excel file of project test cases status report
   * @param {number} projectId - the project id
   * @returns {Observable<any>}
   */
  getTestCaseReportExcelFile(projectId: number, brand: any, status: any, hostLogOption: any): Observable<any> {
    const params = new HttpParams()
      .set('status', status)
      .set('brand', brand)
      .set('hostLogOption', hostLogOption);
    return this.http.get(`${this.projectReportsUrl}/${projectId}/reports/testCasesReport`,
      {params, responseType: 'blob'});
  }

  /**
   * Get an ICC Brand report
   * @param {IccBrand} brand - the brand of report to generate
   * @returns {Observable<any>}
   */
  getBrandReport(brand: IccBrand): Observable<any> {
    const resolvedUrl = `${this.projectReportsUrl}/reports/generateIccBrandReport?iccBrand=${brand}`;
    return this.http.get(resolvedUrl, {responseType: 'blob'});
  }

  /**
   * Get the users report
   * @returns {Observable<any>}
   */
  getUsersReport(): Observable<any> {
    return this.http.get(`${this.projectReportsUrl}/reports/userReport`, {responseType: 'blob'});
  }

  /**
   * Get the dates infos for a project (phase end date, project start date..)
   * @param {number} projectId - Id of project
   * @returns {Observable<ProjectMileStones>}
   */
  getProjectMileStones(projectId: number): Observable<Array<ProjectMileStones>> {
    return this.http.get<Array<ProjectMileStones>>(`${this.projectReportsUrl}/${projectId}/reports/projectMileStones`);
  }

  getUsersSearchConfigs(): Observable<Array<SearchConfiguration>> {
    return this.http.get<Array<SearchConfiguration>>(`${this.projectReportsUrl}/searchConfig`);
  }

  saveSearchConfig(searchConfig: Partial<SearchConfiguration>): Observable<SearchConfiguration> {
    return this.http.post<SearchConfiguration>(`${this.projectReportsUrl}/searchConfig`, searchConfig);
  }

  deleteSearchConfig(searchConfig: SearchConfiguration) {
    return this.http.delete(`${this.projectReportsUrl}/searchConfig/${searchConfig.id}?shared=${searchConfig.shared}`);
  }

  updateSearchConfig(searchConfig: SearchConfiguration): Observable<SearchConfiguration> {
    return this.http.put<SearchConfiguration>(`${this.projectReportsUrl}/searchConfig/${searchConfig.id}`, searchConfig);
  }

  /**
   * Get global waivers report records
   * @param sort - sort for list
   * @param {SortDirection} dir - sort direction for list
   * @param page - page index for paging
   * @param pageSize - page size for paging
   * @returns {Observable<PagedResponse<any>>}
   */
  getGlobalWaivers(sort = 'name', dir: SortDirection = 'desc', page = 0, pageSize = 99999,
                   searchParams: FilterParameters = null, filter ='', sandbox = false): Observable<PagedResponse<GlobalWaiverReport>> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('size', pageSize.toString())
      .set('sort', `${sort},${dir}`)
      .set('filter', filter)
      .set('status', 'WAIVED')
      .set('sandbox', sandbox.toString());

    if (searchParams) {
      params = populateFilterParameters(searchParams, params);
    }
    return this.http.get<PagedResponse<GlobalWaiverReport>>(`${this.projectReportsUrl}/reports/globalWaiverReport`, {params});
  }

  /**
   * Download an excel file of the custom report
   * @param {FilterParameters} searchParams - any filter parameters
   * @param {string} sort - sort parameter
   * @param {string} sortDirection - sort direction
   * @param {Array<GlobalWaiverReport>} columns - required column configuration
   * @returns {Observable<any>}
   */
  getGlobalWaiverExcelFile(searchParams: FilterParameters, sort: string, sortDirection: SortDirection, filter: string): Observable<any> {
    let params = new HttpParams()
      .set('sort', `${sort},${sortDirection}`)
      .set('status', 'WAIVED')
      .set('sandbox', 'false')
      .set('filter', filter);

    if (searchParams) {
      params = populateFilterParameters(searchParams, params);
    }
    return this.http.get(`${this.projectReportsUrl}/reports/generateGlobalWaiverReport`, {params, responseType: 'blob' });
  }
}
