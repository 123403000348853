<div [className]="isSubTitle ? 'sub-title-row':'title-row'">

  <div [className]="isSubTitle ? 'sub-title-text':'title-text'">
    <span *ngIf="!isSubTitle"><app-svg [iconName]="iconUrl" *ngIf="iconUrl" (load)="loaded = true"></app-svg></span>
    <span *ngIf="!isSubTitle">{{title}}</span>
  </div>

  <!-- We can have content displayed in the page title here -->
  <div class="title-content">
    <ng-content></ng-content>
  </div>
</div>
