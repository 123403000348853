import {Injectable} from '@angular/core';
import {HostLogMessage} from '@models/hostLogMessage';
import {BehaviorSubject, Observable} from 'rxjs';
import {IHostLogStateService} from './iHostLogStateService';

@Injectable()
export class HostLogStateService implements IHostLogStateService {

  private _hiddenMessages = new BehaviorSubject<HostLogMessage[]>([]);
  private allMessages: HostLogMessage[];

  getHiddenMessages(): Observable<HostLogMessage[]> {
    return this._hiddenMessages.asObservable();
  }

  toggleSize(hostLogMessage: HostLogMessage) {
    const hidden = this._hiddenMessages.value;
    if (hidden.includes(hostLogMessage)) {
      this._hiddenMessages.next(hidden.filter(m => m !== hostLogMessage));
    } else {
      this._hiddenMessages.next([...hidden, hostLogMessage]);
    }
  }

  collapseAllButThis(hostLogMessage: HostLogMessage) {
    this._hiddenMessages.next(this.allMessages.filter(hlm => hlm.id !== hostLogMessage.id));
  }

  resetHiddenMessages(allMessages: HostLogMessage[]) {
    this.allMessages = allMessages;
    this._hiddenMessages.next([]);
  }

  canHideMessage(): boolean {
    return this._hiddenMessages.value.length < this.allMessages.length - 1;
  }

  unHideAll() {
    this._hiddenMessages.next([]);
  }
}
