import {Pipe, PipeTransform} from '@angular/core';
import {ClientTypeDescriptionService} from '../data/client-type-description.service/client-type-description.service';

@Pipe({
  name: 'clientTypeDescription'
})
export class ClientTypeDescriptionPipe implements PipeTransform {

  constructor(private clientTypeDescriptionService: ClientTypeDescriptionService) {}
  transform(value: any, ...args: unknown[]): unknown {
    return this.clientTypeDescriptionService.getDescription(value);
  }
}
