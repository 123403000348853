import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {TOTPDeviceStatus, UserState} from '@models/security/user';
import {UsersService} from '@services/data/users.service/users.service';
import {BehaviorSubject, fromEvent, merge, Observable} from 'rxjs';
import {debounceTime, switchMap, tap} from 'rxjs/operators';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss']
})
export class UsersTableComponent implements OnInit, AfterViewInit {

  @Input() canCreateUsers: boolean;
  @Input() canReadUsers: boolean;
  @Input() filterClientId: number;

  @Output() createUser = new EventEmitter();
  @Output() assignUser = new EventEmitter();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('filterInput', {static: true}) filterInput: ElementRef;
  private filterChanged$: Observable<any>;

  UserState = UserState;

  readonly TOTPDeviceStatus = TOTPDeviceStatus;

  private readonly refreshToken$ = new BehaviorSubject(undefined);

  dataSource = new MatTableDataSource<any>();
  totalUsers = 0;
  displayedColumns = [];
  filterDisabled = false;

  constructor(private usersService: UsersService,
              private changeDetectorRef: ChangeDetectorRef,
              private router: Router) { }

  ngOnInit(): void {
    this.displayedColumns = this.filterClientId
      ? ['username', 'firstName', 'lastName', 'role', 'lanId', 'eid', 'lastSuccessfulLoginTime', 'startDate']
      : ['username', 'firstName', 'lastName', 'merchantId', 'role', 'lanId', 'eid', 'lastSuccessfulLoginTime', 'startDate'];
  }

  ngAfterViewInit(): void {
    this.filterChanged$ = fromEvent(this.filterInput.nativeElement, 'input');
    merge(
      this.filterChanged$.pipe(debounceTime(250), tap(() => this.paginator.pageIndex = 0)),
      this.refreshToken$.pipe(debounceTime(500)),
      this.sort.sortChange, this.paginator.page
    ).pipe(switchMap(() =>
        this.getUserData()),
      tap(res => this.totalUsers = res.totalResults))
      .subscribe(({data}) => this.dataSource.data = data);
    this.changeDetectorRef.detectChanges();
  }

  private getUserData() {
    if (this.filterClientId) {
      return this.usersService.listUsersForClient(
        this.filterClientId,
        this.filterDisabled,
        this.sort.active,
        this.sort.direction,
        this.paginator.pageIndex,
        this.paginator.pageSize,
        this.filterInput.nativeElement.value);
    }
    return this.usersService.listUsers(
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.filterInput.nativeElement.value);
  }

  refresh() {
    this.refreshToken$.next(undefined);
  }

  userDetail(id: number) {
    if (this.canReadUsers) {
      this.router.navigate(['users', id]);
    }
  }
}
