export interface IntakeFormStatus {
    commonStatus: FormStatus;
    contactStatus: FormStatus;
    contactlessStatus: FormStatus;
    sandboxStatus: FormStatus;
}

export enum FormStatus {
  None = 'NONE',
  Draft = 'DRAFT',
  Submitted = 'SUBMITTED'
}
