import {Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';

@Directive({
  selector: '[appExpandable]'
})
export class ExpandableDirective implements OnChanges {
  @Input() isExpanded = false;

  constructor(private elementRef: ElementRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isExpanded.currentValue) {
      this.expandElement();
    } else {
      this.collapseElement();
    }
  }

  collapseElement() {
    const element = this.elementRef.nativeElement;
    const elementHeight = element.scrollHeight;

    const elementTransition = element.style.transition;
    element.style.transition = '';

    requestAnimationFrame(function() {
      element.style.height = elementHeight + 'px';
      element.style.transition = elementTransition;
      requestAnimationFrame(function() {
        element.style.height = 0 + 'px';
      });
    });
  }

  expandElement() {
    const element = this.elementRef.nativeElement;
    const elementHeight = element.scrollHeight;
    element.style.height = elementHeight + 'px';
  }
}
