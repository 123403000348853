import {Injectable} from '@angular/core';
import {PagedResponse} from '@models/pagedResponse';
import {Project} from '@models/project';
import {FilterParameters} from '@services/data/filterParameters';
import {ProjectsService} from '@services/data/projects.service/projects.service';
import {SortDirection} from '@services/data/sortDirection';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export interface IProjectTableDataSourceService {
  readonly rows$: Observable<Array<Project>>;
  readonly totalRows$: Observable<number>;

  setTable(sort: string,
           direction: SortDirection,
           pageIndex: number,
           pageSize: number,
           showFavouritesOnly: boolean,
           filter: string): Promise<void>;

  setFilters(filter: FilterParameters): Promise<void>;

  updateTable(): Promise<void>;

  resetFiltersAndTable(): void;
}

@Injectable()
export class ProjectTableDataSourceService implements IProjectTableDataSourceService {

  _apiResponse = new BehaviorSubject<PagedResponse<Project>>({
    data: [],
    pageNumber: 0,
    pageSize: 0,
    totalPages: 0,
    totalResults: 0
  });
  pageIndex = 0;
  pageSize = 0;
  sort = '';
  sortDirection: SortDirection = '';
  filters: FilterParameters = null;

  filterClientId = 0;
  showFavouritesOnly = false;
  filter = '';
  sandboxProjects = false;

  constructor(private projectService: ProjectsService) {
    this.resetFiltersAndTable();
  }

  get rows$(): Observable<Array<Project>> {
    return this._apiResponse.pipe(map(({data}) => data));
  }

  get totalRows$(): Observable<number> {
    return this._apiResponse.pipe(map(({totalResults}) => totalResults));
  }

  setTable(sort: string,
           direction: SortDirection,
           pageIndex: number,
           pageSize: number,
           showFavouritesOnly: boolean,
           filter: string): Promise<void> {
    this.sort = sort;
    this.sortDirection = direction;
    this.pageSize = pageSize;
    this.pageIndex = pageIndex;
    this.showFavouritesOnly = showFavouritesOnly;
    this.filter = filter;
    return this.updateTable();
  }

  setFilters(filter: FilterParameters): Promise<void> {
    this.filters = filter;
    return this.updateTable();
  }

  async updateTable() {
    const filters = this.filters;
    if (this.filterClientId) {
      filters.set('merchantId', this.filterClientId.toString());
    }
    if (this.sandboxProjects) {
      filters.set('sandbox', 'true');
    }
    const response = await this.projectService.getProjects(this.sort, this.sortDirection, this.pageIndex, this.pageSize,
      this.filters, this.showFavouritesOnly, this.filter).toPromise();
    this.pageIndex = response?.pageNumber;
    if (response) {
      this._apiResponse.next(response);
    }
  }

  resetFiltersAndTable() {
    this.filters = new Map<string, string>();
    this.sort = 'name';
    this.sortDirection = 'asc';
    this.pageIndex = 0;
    this.pageSize = 25;
    this._apiResponse.next({data: undefined, pageNumber: 0, pageSize: 0, totalPages: 0, totalResults: 0});
  }
}
