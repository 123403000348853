import {CertQuestion} from '@models/intake-forms';
import * as _ from 'lodash';
import {FormPdf} from '../formPdf';
import {Renderer} from './renderer';

export class LabelRenderer extends Renderer {
  readonly ParagraphSpacing = 10;
  private paragraphs: string[];

  constructor(q: CertQuestion) {
    super(q);
    this.paragraphs = this.question.QuestionText.split('\\r\\n');
  }

  layout(doc: FormPdf) {
    this.determineStyle(doc);
    this.height = _.sum(
      this.paragraphs.map(para => doc.getWrappedTextSize(para).height + this.ParagraphSpacing));
  }

  render(doc: FormPdf, ypos: number) {
    this.determineStyle(doc);
    this.paragraphs.forEach(para => {
      doc.drawWrappedText(para, ypos);
      ypos += doc.getWrappedTextSize(para).height + this.ParagraphSpacing;
    });
  }

  private determineStyle(doc: FormPdf) {
    if (this.question.PossibleValues && this.question.PossibleValues.string) {
      const labelStyles = this.question.PossibleValues.string;
      if (typeof labelStyles[0] === 'number') {
        doc.setFontSize(labelStyles[0] + 2);
      }
      const hasBold = ('' + labelStyles[1]).includes('bold');
      const hasItalic = ('' + labelStyles[1]).includes('italic');
      doc.setFontType(hasBold, hasItalic);
    }
  }
}

