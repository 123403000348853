import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors
} from '@angular/forms';
import {TerminalId} from '@models/terminalId';
import {MerchantIdValidationService} from '@services/data/projects.service/merchantIdValidation.service';
import {TerminalIdConflict, UniqueTerminalIDService} from '@services/data/unique-terminal-id.service';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {ConfirmDialogService} from '../confirm-dialog/confirm-dialog.service';
import {TerminalIdEditorComponent} from './terminal-id-editor.component';

const MIN_MERCHANT_ID_LENGTH = 0;
const MAX_MERCHANT_ID_LENGTH = 15;

const MIN_TERMINAL_ID_LENGTH = 0;
const MAX_TERMINAL_ID_LENGTH = 8;

@Component({
  selector: 'app-terminal-ids-editor',
  templateUrl: './terminal-ids-editor.component.html',
  styleUrls: ['./terminal-ids-editor.component.scss'],
  providers: [UniqueTerminalIDService]
})
export class TerminalIdsEditorComponent implements OnInit, OnChanges {
  @Input() projectTerminalIds: Array<TerminalId> = [];
  @Input() height = '150px';
  @Input() projectId = 0;
  @Input() platform = '';

  form: UntypedFormGroup;
  minMerchantLength = MIN_MERCHANT_ID_LENGTH;
  maxMerchantLength = MAX_MERCHANT_ID_LENGTH;
  minTerminalLength = MIN_TERMINAL_ID_LENGTH;
  maxTerminalLength = MAX_TERMINAL_ID_LENGTH;

  constructor(private formBuilder: UntypedFormBuilder,
              private uniqueTerminalIDService: UniqueTerminalIDService,
              private confirmDialogService: ConfirmDialogService,
              private merchantIdService: MerchantIdValidationService) {
  }

  private _editable: boolean;

  @Input() get editable(): boolean {
    return this._editable;
  }

  set editable(value: boolean) {
    this._editable = value;
  }

  get valid(): boolean {
    return this.form && this.form.valid;
  }

  get terminalIds(): UntypedFormArray {
    return this.form?.get('projectTerminalIds') as UntypedFormArray;
  }

  ngOnInit() {
    this.buildForm();

    this.merchantIdService.merchantIdRevert$.subscribe((oldValue) => {
      if (oldValue) {
        this.terminalIds.controls[0].setValue(oldValue);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.projectTerminalIds) {
      this.buildForm();
    }
    if (changes.platform) {
      this.terminalIds.controls.forEach(ctrl => ctrl.updateValueAndValidity());
    }
  }

  addId() {
    if (this.editable) {
      this.terminalIds.push(this.formBuilder.control({terminalId: '', merchantId: ''} as TerminalId, {
        validators: [
          this.checkRequiredFields.bind(this),
          this.checkIdLengths.bind(this)
        ], asyncValidators: this.checkTerminalIdIsNotUsed.bind(this),
        updateOn: 'blur'
      }));
    }
  }

  deleteId(i: number) {
    if (this.editable) {
      this.terminalIds.removeAt(i);

      this.terminalIds.controls.forEach(ctrl => ctrl.updateValueAndValidity());
    }
  }

  getInactiveConflictMessageByIndex(i: number): Observable<string> {
    return this.getInactiveConflictMessage(this.terminalIds.at(i));
  }

  private getInactiveConflictMessage(terminal: AbstractControl): Observable<string | null> {
    return this.getInactiveConflict(terminal).pipe(map(conflicted => {
      if (conflicted) {
        let conflictDescription: string;
        if (conflicted.conflictType === 'MID') {
          conflictDescription = `merchant ID, ${conflicted.terminalId.merchantId}`;
        } else {
          conflictDescription = `merchant ID and terminal ID, ${conflicted.terminalId.merchantId} and ${conflicted.terminalId.terminalId}`;
        }
        return `The ${conflictDescription}, is already being used in an inactive project: ${conflicted.terminalId.longProjectId}, ${conflicted.terminalId.projectName}.`;
      } else {
        return null;
      }
    }));
  }

  private getInactiveConflict(terminal: AbstractControl): Observable<TerminalIdConflict | null> {
    if ((terminal as any)._pendingDirty) {
      return this.uniqueTerminalIDService.isUniqueInactiveTerminalId(
        (terminal as any)._pendingValue || terminal.value,
        this.projectId,
        this.platform);
    } else {
      return of(null);
    }
  }

  checkRequiredFields(control: AbstractControl): ValidationErrors | null {
    const currentTerminalId = (control.value as TerminalId);
    if (!currentTerminalId.terminalId) {
      return {requiredTerminal: true};
    }
    if (!currentTerminalId.merchantId) {
      return {requiredMerchant: true};
    }
    return null;
  }

  checkIdLengths(control: AbstractControl): ValidationErrors | null {
    const terminal = (control.value as TerminalId);
    if (terminal.merchantId && terminal.merchantId.length < MIN_MERCHANT_ID_LENGTH) {
      return {tooShortMerchant: true};
    }
    if (terminal?.merchantId.length > MAX_MERCHANT_ID_LENGTH) {
      return {tooLongMerchant: true};
    }
    if (terminal.terminalId && terminal.terminalId.length < MIN_TERMINAL_ID_LENGTH) {
      return {tooShortTerminal: true};
    }
    if (terminal?.terminalId.length > MAX_TERMINAL_ID_LENGTH) {
      return {tooLongTerminal: true};
    }
    return null;
  }

  checkTerminalIdIsNotUsed(control: AbstractControl): Observable<ValidationErrors | null> {
    if (!this._editable) {
      return of(null);
    }

    const currentTerminalId = (control.value as TerminalId);
    const terminalIdsInThisProject = this.terminalIds?.controls
      .filter(ctrl => ctrl !== control)
      .map(ctrl => (ctrl.value as TerminalId)) ?? [];

    if (this.uniqueTerminalIDService.hasMatch(currentTerminalId, terminalIdsInThisProject)) {
      return of({alreadyEntered: true});
    }

    return this.uniqueTerminalIDService.isUniqueActiveTerminalId(currentTerminalId, this.platform, this.projectId).pipe(
      map(isUnique => isUnique ? null : {alreadyExists: true})
    );
  }

  getTerminalIds() {
    return this.form.value.projectTerminalIds;
  }

  private buildForm() {
    this.projectTerminalIds.sort((t1, t2) => t1.id - t2.id);

    this.form = this.formBuilder.group({
      projectTerminalIds: this.formBuilder.array(
        this.projectTerminalIds.map(term =>
          new UntypedFormControl(term, {
            validators: [this.checkRequiredFields.bind(this), this.checkIdLengths.bind(this)],
            asyncValidators: this.checkTerminalIdIsNotUsed.bind(this),
            updateOn: 'blur'
          })))
    });
  }

  updateTerminalValidity(terminal: AbstractControl, event: TerminalIdEditorComponent) {
    const oldValue = terminal.value;
    const newValue = event.value;
    terminal.setValue(newValue);
    if ((oldValue.terminalId === newValue.terminalId && oldValue.merchantId === newValue.merchantId)
        || terminal.errors) {
      // if neither value updated, do nothing
      return;
    }
    // if there are any conflicts with inactive projects,
    // ask the user if they would like to continue.
    // if they say no, revert the value to the previous one.
    this.getInactiveConflictMessage(terminal).subscribe((message) => {
      this.merchantIdService.setConflict(oldValue, message);
    });
  }
}
