import {StorageTypes, StorageUtilities} from 'browser-storage-utilities';
import {BehaviorSubject, Observable} from 'rxjs';

export interface ISessionStorageService<T> {
  readonly observable$: Observable<T>;
  get(): T;
  set(t: T): void;
  clear(): void;
}

/**
 * Base class for storing an typed item (T) in the session storage
 */
export abstract class SessionStorageService<T>  extends StorageUtilities<T> implements ISessionStorageService<T> {
  private _observable$: BehaviorSubject<T>;

  protected constructor(private key: string) {
    super({type: StorageTypes.SESSION});
    this._observable$ = new BehaviorSubject<T>(this.get());
  }

  get(): T {
    return super.getItem(this.key);
  }

  set(t: T) {
    super.addItem(this.key, t);
    this._observable$.next(t);
  }

  get observable$(): Observable<T> {
    return this._observable$.asObservable();
  }

  clear() {
    super.removeItem(this.key);
  }
}


