<div class="version-dialog-container">
  <div class="banner"><span>About</span><img class="logo" src="assets/t3-cert-logo.svg" alt="t3Cert logo"/></div>
  <div class="content">
    <div class="c1">
      <span>Version:</span>
      <span>API:</span>
    </div>
    <div>
      <span><strong>{{uiVersionInfo}}</strong></span>
      <span><strong>{{apiVersionInfo}}</strong></span>
    </div>
  </div>
</div>
