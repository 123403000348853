import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

export interface EmailNoteText {
  note: string;
}

@Component({
  selector: 'app-email-text',
  templateUrl: './email-note-dialog.component.html',
  styleUrls: ['./email-note-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailNoteDialogComponent implements OnInit {

  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      note: ''
    });
  }

}
