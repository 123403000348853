<div class="chart-row-wrapper">
  <div class="chart-tooltip">
    <span class="tip-not-started">Not Started: {{notStarted}}</span>
    <span class="tip-in-progress">In Progress: {{inProgress}}</span>
    <span class="tip-passed">Passed: {{passed}}</span>
    <span class="tip-waived">Waived: {{waived}}</span>
    <span class="tip-failed">Failed: {{failed}}</span>
    <span class="tip-overridden">Overridden: {{overridden}}</span>
  </div>
  <div class="chart-bar chart-not-started" [style]="getAmount(notStarted)"></div>
  <div class="chart-bar chart-in-progress" [style]="getAmount(inProgress)"></div>
  <div class="chart-bar chart-passed" [style]="getAmount(passed)"></div>
  <div class="chart-bar chart-waived" [style]="getAmount(waived)"></div>
  <div class="chart-bar chart-failed" [style]="getAmount(failed)"></div>
  <div class="chart-bar chart-overridden" [style]="getAmount(overridden)"></div>
</div>
