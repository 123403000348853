import {Component, Inject, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {AssignedUser} from '@models/assignedUser';
import {ClientSummary} from '@models/clients';
import {allHostMessageSpecifications, allTerminalPlatforms} from '@models/constants';
import {Project} from '@models/project';
import {ProjectDetail} from '@models/projectDetail';
import {Action, Resource} from '@models/security';
import {PermissionsService} from '@services/auth/permissions.service/permissions.service';
import {ClientsService} from '@services/data/clients.service/clients.service';
import {UniqueProjectAttributesService} from '@services/data/unique-project-attributes.service';
import {UsersService} from '@services/data/users.service/users.service';
import {merge, Observable} from 'rxjs';
import {map, startWith, switchMap, tap} from 'rxjs/operators';
import {TerminalIdsEditorComponent} from '../../components/terminal-id-editor/terminal-ids-editor.component';

export interface NewProjectDialogResult {
  project: Project;
  isDraft: boolean;
}

export interface NewProjectDialogParam {
  isSandboxProject: boolean;
  sandboxSourceProject: ProjectDetail;
}

@Component({
  selector: 'app-new-project-dialog',
  templateUrl: './new-project-dialog.component.html',
  styleUrls: ['./new-project-dialog.component.scss'],
  providers: [UniqueProjectAttributesService]
})
export class NewProjectDialogComponent {
  @ViewChild('terminalIdsEditor') terminalIdsEditor: TerminalIdsEditorComponent;
  projectForm: UntypedFormGroup;
  clients$: Observable<ClientSummary[]>;
  users$: Observable<AssignedUser[]>;
  ipUsers$: Observable<AssignedUser[]>;
  selectedClientUsers$: Observable<AssignedUser[]>;
  backupAnalysts$: Observable<AssignedUser[]>;
  availableHostSpecs$: Observable<string[]>;
  minTargetDate$: Date;
  platforms = allTerminalPlatforms;
  canCreateClients$: Observable<boolean>;
  gettingUsers: boolean;
  isSandboxProject: boolean;

  constructor(private formBuilder: UntypedFormBuilder,
              clientsService: ClientsService,
              private usersService: UsersService,
              private dialogRef: MatDialogRef<NewProjectDialogComponent>,
              private router: Router,
              private uniqueProjectAttributeService: UniqueProjectAttributesService,
              permissionsService: PermissionsService,
              @Inject(MAT_DIALOG_DATA) public params: NewProjectDialogParam) {
    this.minTargetDate$ = new Date();
    this.isSandboxProject = this.params?.isSandboxProject;
    this.clients$ = clientsService.getClientSummaries().pipe(map(({data}) => data
       .filter(client => !client.disabled)));
    this.users$ = usersService.listLeadUsers()
      .pipe(map(({data}) => data.map<AssignedUser>(u => ({userId: u.id, username: u.username, role: u.role}))));
    this.ipUsers$ = usersService.listIntegratedPartnerUsers(true)
      .pipe(map(({data}) => data.map<AssignedUser>(u => ({userId: u.id, username: u.username, role: u.role}))));
    this.canCreateClients$ = permissionsService.hasPermission([Resource.client, Action.create]);
    this.backupAnalysts$ = this.usersService.listBackupAnalyst()
      .pipe(map(({data}) => data.map<AssignedUser>(u => ({userId: u.id, username: u.username, role: u.role}))));

    let formFields = {
      // Details
      name: [, [Validators.required],
        [this.isUniqueProjectName.bind(this)]],
      merchantId: [this.params.sandboxSourceProject?.merchantId ?? '', Validators.required],
      clientLead: new UntypedFormControl({value: '', disabled: true}, Validators.required),
      certLead: [this.params.sandboxSourceProject?.certLead ?? null, Validators.required],
      backupAnalyst: this.params.sandboxSourceProject?.backupAnalyst ?? null ,
      ipLead: this.params.sandboxSourceProject?.ipLead ?? null,
      targetDate: null,

      // Terminal information
      terminalBrand: [this.params.sandboxSourceProject?.terminalBrand ?? '', Validators.required],
      terminalModel: [this.params.sandboxSourceProject?.terminalModel ?? '', Validators.required],
      paymentSoftwareVersion: [this.params.sandboxSourceProject?.paymentSoftwareVersion ?? '', Validators.required],
      platform: [this.params.sandboxSourceProject?.platform ?? '', Validators.required],
      hostMessageSpecification: [this.params.sandboxSourceProject?. hostMessageSpecification ?? '', Validators.required],
    };

    if (!this.isSandboxProject) {
      formFields = {
        ...formFields,
        ...{
          projectId: ['', [Validators.required], [this.isUniqueProjectId.bind(this)]],
          sandboxProjectId: [this.params.sandboxSourceProject?.projectId ?? '', [Validators.required]]
        }
      };
    }

    this.projectForm = this.formBuilder.group(formFields);

    this.selectedClientUsers$ = this.projectForm.get('merchantId').valueChanges.pipe(
      tap(() => this.gettingUsers = true),
      switchMap(value => {
        const clientEmailField = this.projectForm.get('clientLead');
        clientEmailField.setValue(null);
        clientEmailField.enable();
        return this.usersService.listUsersForClient(value, true);
      }),
      map(({data}) => data.map<AssignedUser>(u => ({userId: u.id, username: u.username, role: u.role}))),
      tap(() => this.gettingUsers = false),
      startWith([])
    );

    if (this.params.sandboxSourceProject) {
      this.selectedClientUsers$ = merge(this.selectedClientUsers$,
        this.usersService.listUsersForClient(this.params.sandboxSourceProject.merchantId, true)
          .pipe(
            map(({data}) => data.map<AssignedUser>(u => ({userId: u.id, username: u.username, role: u.role}))),
            tap( _ => {
              const clientEmailField = this.projectForm.get('clientLead');
              clientEmailField.enable();
              clientEmailField.setValue(this.params.sandboxSourceProject.clientLead);
            })
          )
      )
    }

    this.availableHostSpecs$ = this.projectForm.get('platform').valueChanges.pipe(
      map(value => {
          const hostSpecs = this.platforms.find(p => p.name === value)?.hostMessageSpecifications ?? [];
          const hostMessageSelection = this.projectForm.get('hostMessageSpecification');
          if (!hostSpecs.includes(hostMessageSelection.value)) {
            hostMessageSelection.setValue(null);
          }
          return hostSpecs;
        }
      ),
      startWith(allHostMessageSpecifications));
  }

  get formValue(): any {
    return {...this.projectForm?.value, ...this.terminalIdsEditor?.form?.value, sandbox: this.isSandboxProject};
  }

  isUniqueProjectName(control: AbstractControl): Observable<ValidationErrors | null> {
    return this.uniqueProjectAttributeService.isUniqueProjectName(control.value)
      .pipe(
        map(valid => valid ? null : {alreadyExists: true})
      );
  }

  isUniqueProjectId(control: AbstractControl): Observable<ValidationErrors | null> {
    return this.uniqueProjectAttributeService.isUniqueProjectId(control.value)
      .pipe(
        map(valid => valid ? null : {alreadyExists: true})
      );
  }

  onNewClientClick($event: MouseEvent) {
    // Prevent keyboard click triggering the new client
    if ($event.screenX && $event.screenY) {
      this.onCreateNewClient();
    }
  }

  onNewClientKeyDown($event: KeyboardEvent) {
    if ($event.code === 'Space' || $event.code === 'Enter') {
      this.onCreateNewClient();
    }
  }

  private async onCreateNewClient() {
    this.dialogRef.close();
    await this.router.navigate(['clients']);
  }

  getCertLeadErrorMessage(): string {
    const control = this.projectForm.get('certLead');
    return control?.errors?.required ? 'You must select a certification lead analyst' : '';
  }

  getClientLeadErrorMessage(): string {
    const control = this.projectForm.get('clientLead');
    return control?.errors?.required ? 'You must select a lead client user' : '';
  }
}
