import {Component, Input, OnInit} from '@angular/core';
import {SiteSection} from '@services/auth/permissions.service/permissions.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Icon} from '../components/svg/svg.component';

interface NavButton {
  title: string;
  iconName: Icon;
  routerLink: string;
}

const pageArea: Record<SiteSection, NavButton> = {
  [SiteSection.Projects]: {
    title: 'Projects',
    iconName: Icon.PROJECTS,
    routerLink: 'projects'
  },
  [SiteSection.TestPlans]: {
    title: 'Test Plans',
    iconName: Icon.TESTPLANS,
    routerLink: 'test-plans'
  },
  [SiteSection.HostLogs]: {
    title: 'Host Logs',
    iconName: Icon.HOSTLOGS,
    routerLink: 'host-logs'
  },
  [SiteSection.Clients]: {
    title: 'Clients',
    iconName: Icon.CLIENTS,
    routerLink: 'clients'
  },
  [SiteSection.Users]: {
    title: 'Users',
    iconName: Icon.USERS,
    routerLink: 'users'
  },
  [SiteSection.Reports]: {
    title: 'Reports',
    iconName: Icon.REPORTS,
    routerLink: 'reports'
  },
  [SiteSection.Search]: {
    title: 'Search',
    iconName: Icon.SEARCH,
    routerLink: 'search'
  }
};

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  @Input() sections: Observable<SiteSection[]>;

  buttons: Observable<NavButton[]>;

  ngOnInit(): void {
    this.buttons = this.sections.pipe(map(sections => sections.map(section => pageArea[section])));
  }
}
