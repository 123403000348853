import {ControlValueAccessor} from '@angular/forms';

export abstract class BaseControlClass<T> implements ControlValueAccessor {
    public onChange = Function.prototype;
    public onTouched = Function.prototype;

    protected _value: T;

    get value(): T {
        return this._value;
    }

    set value(v: T) {
        if (v !== this._value) {
            this._value = v;
            this.onChange(v);
        }
    }

    writeValue(value: T) {
        this._value = value;
    }

    public registerOnChange(fn: (_: any) => {}): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }
}
