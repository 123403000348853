<div class="transaction-message-container" *ngIf="transaction; else noTransaction">
  <div *ngFor="let message of messageList; let i = index" class="host-log-message"
       [class.hidden]="hiddenMessages.includes(message)"
       [class.shaded]="i % 2 === 1">
    <app-host-log-message class="message" [message]="message"></app-host-log-message>
    <div class="overlay">
      <div class="toggle-size-button" (click)="restoreSize(message, $event)"><em class="fas fa-expand-arrows-alt"></em></div>
    </div>
  </div>
</div>
<ng-template #noTransaction>
  Select a transaction using the buttons above to view the message contents here.
</ng-template>
