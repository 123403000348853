import {Component, Input} from '@angular/core';
import {ControlValueAccessor} from '@angular/forms';

/**
 * Base class for the inline controls
 */
@Component({selector: 'app-inline-control', template: ''})
export class InlineControlComponent<T> implements ControlValueAccessor {
  @Input() label = '';
  @Input() required = false;
  @Input() readonly = false;
  @Input() min = null;
  @Input() max = null;
  public onChange = Function.prototype;
  public onTouched = Function.prototype;
  editing = false; // Is Component in edit mode?

  private _value: T;

  get value(): T {
    return this._value;
  }

  set value(v: T) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  writeValue(value: any) {
    this._value = value;
  }

  public registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }
}
