import {Injectable} from '@angular/core';
import {Association} from '@models/hostLogMessage';

@Injectable({
  providedIn: 'root'
})
export class AssociationDescriptionService {

  private readonly descriptions: Record<Association, string> = {
    [Association.AmericanExpress]: 'American Express',
    [Association.Discover]: 'Discover',
    [Association.MasterCard]: 'MasterCard',
    [Association.UMF]: 'UMF',
    [Association.Visa]: 'Visa',
    [Association.DlHost]: 'DL Host',
    [Association.EPC]: 'ATL105',
    [Association.IsoGlobal]: 'ISO Global',
    [Association.DebitGateway]: 'Debit Gateway',
    [Association.Maestro]: 'Maestro',
    [Association.Interlink]: 'Interlink',
    [Association.Pulse]: 'Pulse',
    [Association.Jeanie]: 'Jeanie',
    [Association.NYCE]: 'NYCE',
    [Association.BuypassISO]: 'Buypass ISO',
    [Association.EDC]: 'EDC',
    [Association.HostAndController]: 'Host and Controller',
    [Association.EtcPlus]: 'ETC Plus',
    [Association.IsoDual]: 'ISO Dual',
    [Association.IsoDualVar]: 'ISO Dual VAR',
    [Association.NYCE_BuyPass]: 'NYCE',
    [Association.Voyager]: 'VOYAGER',
  };

  getAssociationDescription(association?: Association): string {
    return association ? this.descriptions[association] : 'Un-recognised';
  }
}
