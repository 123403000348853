import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {HostLogMessage} from '@models/hostLogMessage';
import {HostLogTransaction} from '@models/hostLogTrans';
import {TransactionLog} from '@models/transactionLog';
import {Subscription} from 'rxjs';
import {HostLogStateService} from './host-log-state.service/host-log-state.service';

export type TransactionLogTypes = HostLogTransaction | TransactionLog

@Component({
  selector: 'app-host-log-transaction-view',
  templateUrl: './host-log-transaction-view.component.html',
  styleUrls: ['./host-log-transaction-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HostLogTransactionViewComponent implements OnInit, OnDestroy {
  _transaction: TransactionLogTypes;

  get transaction() {
    return this._transaction;
  }

  @Input()
  set transaction(transaction: TransactionLogTypes) {
    this._transaction = transaction;
    if (transaction) {
      this.messageList = [...transaction.onlineMessageList].sort((message1, message2) => message1.legNumber - message2.legNumber);
      this.hostLogStateService.resetHiddenMessages(this.messageList);
    } else {
      this.messageList = null;
    }
  }

  subscription = new Subscription();
  messageList: HostLogMessage[];
  hiddenMessages: HostLogMessage[];

  constructor(private hostLogStateService: HostLogStateService) {
  }

  ngOnInit() {
    this.subscription.add(this.hostLogStateService.getHiddenMessages().subscribe(hm => this.hiddenMessages = hm));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  restoreSize(message: HostLogMessage, event: MouseEvent) {
    if (event.altKey) {
      this.hostLogStateService.collapseAllButThis(message);
    } else {
      this.hostLogStateService.toggleSize(message);
    }
  }
}
