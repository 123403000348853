<div #itemContainer class="item-container">

  <div>{{value.type.fieldDescription}}</div>

  <div [ngSwitch]="value.type.field">
    <ng-container *ngSwitchCase="SearchField.ProjectName" [ngTemplateOutlet]="projectNameInput"></ng-container>
    <ng-container *ngSwitchCase="SearchField.ProjectId" [ngTemplateOutlet]="projectIdInput"></ng-container>
    <ng-container *ngSwitchCase="SearchField.Client" [ngTemplateOutlet]="clientInput"></ng-container>
    <ng-container *ngSwitchCase="SearchField.CommonFormStatus" [ngTemplateOutlet]="booleanInput"></ng-container>
    <ng-container *ngSwitchCase="SearchField.ContactFormStatus" [ngTemplateOutlet]="booleanInput"></ng-container>
    <ng-container *ngSwitchCase="SearchField.ContactlessFormStatus" [ngTemplateOutlet]="booleanInput"></ng-container>
    <ng-container *ngSwitchCase="SearchField.SandboxFormStatus" [ngTemplateOutlet]="booleanInput"></ng-container>
    <ng-container *ngSwitchCase="SearchField.Phase" [ngTemplateOutlet]="phaseInput"></ng-container>
    <ng-container *ngSwitchCase="SearchField.Status" [ngTemplateOutlet]="statusInput"></ng-container>
    <ng-container *ngSwitchCase="SearchField.HostMessageSpecification"
                  [ngTemplateOutlet]="hostMessageSpecInput"></ng-container>
    <ng-container *ngSwitchCase="SearchField.Platform" [ngTemplateOutlet]="platformInput"></ng-container>
    <ng-container *ngSwitchCase="SearchField.CertLeadId" [ngTemplateOutlet]="certInput"></ng-container>
    <ng-container *ngSwitchCase="SearchField.IpLeadId" [ngTemplateOutlet]="ipLeadInput"></ng-container>
    <ng-container *ngSwitchCase="SearchField.ClientLeadId" [ngTemplateOutlet]="clientLeadInput"></ng-container>
    <ng-container *ngSwitchCase="SearchField.BeforeCreationDate" [ngTemplateOutlet]="beforeDate"></ng-container>
    <ng-container *ngSwitchCase="SearchField.AfterCreationDate" [ngTemplateOutlet]="afterDate"></ng-container>
    <ng-container *ngSwitchCase="SearchField.BeforeKickOffDate" [ngTemplateOutlet]="beforeDate"></ng-container>
    <ng-container *ngSwitchCase="SearchField.AfterKickOffDate" [ngTemplateOutlet]="afterDate"></ng-container>
    <ng-container *ngSwitchCase="SearchField.BeforeStartDate" [ngTemplateOutlet]="beforeDate"></ng-container>
    <ng-container *ngSwitchCase="SearchField.AfterStartDate" [ngTemplateOutlet]="afterDate"></ng-container>
    <ng-container *ngSwitchCase="SearchField.BeforePlanningDate" [ngTemplateOutlet]="beforeDate"></ng-container>
    <ng-container *ngSwitchCase="SearchField.AfterPlanningDate" [ngTemplateOutlet]="afterDate"></ng-container>
    <ng-container *ngSwitchCase="SearchField.BeforeTestCertifyDate" [ngTemplateOutlet]="beforeDate"></ng-container>
    <ng-container *ngSwitchCase="SearchField.AfterTestCertifyDate" [ngTemplateOutlet]="afterDate"></ng-container>
    <ng-container *ngSwitchCase="SearchField.BeforeImplementationDate" [ngTemplateOutlet]="beforeDate"></ng-container>
    <ng-container *ngSwitchCase="SearchField.AfterImplementationDate" [ngTemplateOutlet]="afterDate"></ng-container>
    <ng-container *ngSwitchCase="SearchField.BeforeValidationDate" [ngTemplateOutlet]="beforeDate"></ng-container>
    <ng-container *ngSwitchCase="SearchField.AfterValidationDate" [ngTemplateOutlet]="afterDate"></ng-container>
    <ng-container *ngSwitchCase="SearchField.BeforeApprovalDate" [ngTemplateOutlet]="beforeDate"></ng-container>
    <ng-container *ngSwitchCase="SearchField.AfterApprovalDate" [ngTemplateOutlet]="afterDate"></ng-container>
    <ng-container *ngSwitchCase="SearchField.BeforeClosedDate" [ngTemplateOutlet]="beforeDate"></ng-container>
    <ng-container *ngSwitchCase="SearchField.AfterClosedDate" [ngTemplateOutlet]="afterDate"></ng-container>
    <ng-container *ngSwitchCase="SearchField.BackupAnalystId" [ngTemplateOutlet]="backupAnalyst"></ng-container>
    <ng-container *ngSwitchCase="SearchField.TestTool" [ngTemplateOutlet]="testTool"></ng-container>
    <ng-container *ngSwitchCase="SearchField.CycleTimeGreaterThan" [ngTemplateOutlet]="cycleTime"></ng-container>
    <ng-container *ngSwitchCase="SearchField.CycleTimeLessThan" [ngTemplateOutlet]="cycleTime"></ng-container>
    <ng-container *ngSwitchCase="SearchField.BeforeLastRunDate" [ngTemplateOutlet]="beforeDate"></ng-container>
    <ng-container *ngSwitchCase="SearchField.AfterLastRunDate" [ngTemplateOutlet]="afterDate"></ng-container>
    <ng-container *ngSwitchDefault [ngTemplateOutlet]="textInput"></ng-container>
  </div>

  <div>
    <app-overlay-button imageUrl="assets/icons/delete.svg"
                        (click)="delete.emit()"
                        rolloverImageUrl="assets/icons/delete-hilight.svg"></app-overlay-button>
  </div>
</div>

<!-- General Text Field Parameter -->
<ng-template #textInput>
  <mat-form-field>
    <mat-label>Value</mat-label>
    <input type="text" matInput [(ngModel)]="paramValue" required/>
  </mat-form-field>
</ng-template>

<!-- Project ID Selector Parameter -->
<ng-template #projectIdInput>
  <mat-form-field>
    <input #projectId matInput placeholder="Project ID" [matAutocomplete]="autoProject" (focus)="onProjectIdFocus($event)"
           (blur)="onProjectIdBlur($event)">
    <mat-autocomplete #autoProject="matAutocomplete" [displayWith]="getProjectId"
                      (optionSelected)="paramValue = $event.option.value.projectId">
      <mat-option *ngFor="let project of (filteredProjects$ | async)" [value]="project">
        {{project.projectId}} ({{project.name}})
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-template>

<!-- Project Name Parameter -->
<ng-template #projectNameInput>
  <mat-form-field>
    <input #projectName matInput placeholder="Project" [matAutocomplete]="autoProject" (focus)="onProjectNameFocus($event)"
           (blur)="onProjectNameBlur($event)">
    <mat-autocomplete #autoProject="matAutocomplete" [displayWith]="getProjectName"
                      (optionSelected)="paramValue = $event.option.value.id">
      <mat-option *ngFor="let project of (filteredProjects$ | async)" [value]="project">
        {{project.name}}</mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-template>

<!-- Client Selector Parameter -->
<ng-template #clientInput>
  <mat-form-field>
    <input #client matInput placeholder="Client" [matAutocomplete]="autoClient" (focus)="onClientNameFocus($event)"
           (blur)="onClientNameBlur($event)">
    <mat-autocomplete #autoClient="matAutocomplete" [displayWith]="getClientName"
                      (optionSelected)="paramValue = $event.option.value.id">
      <mat-option *ngFor="let client of (filteredClients$ | async)" [value]="client">
        {{client.merchantName}}</mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-template>

<!-- Boolean input -->
<ng-template #booleanInput>
  <mat-radio-group [(ngModel)]="paramValue" class="radio-group">
    <mat-radio-button class="radio-button" value="SUBMITTED">Submitted</mat-radio-button>
    <mat-radio-button class="radio-button" value="NONE">Not submitted</mat-radio-button>
  </mat-radio-group>
</ng-template>

<!-- Phase input -->
<ng-template #phaseInput>
  <mat-form-field>
    <mat-label>Phase</mat-label>
    <mat-select [(ngModel)]="paramValue">
      <mat-option *ngFor="let phase of AllPhases" [value]="phase">
        {{phase | projectPhaseDescription}}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<!-- Status input -->
<ng-template #statusInput>
  <mat-form-field>
    <mat-label>Status</mat-label>
    <mat-select [(ngModel)]="paramValue">
      <mat-option *ngFor="let state of AllStates" [value]="state">
        {{state | projectStateDescription}}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<!-- Host Message Spec input -->
<ng-template #hostMessageSpecInput>
  <mat-form-field>
    <mat-label>Host Message Specification</mat-label>
    <mat-select [(ngModel)]="paramValue">
      <mat-option *ngFor="let spec of allHostMessageSpecifications" [value]="spec">
        {{spec}}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<!-- Platform input -->
<ng-template #platformInput>
  <mat-form-field>
    <mat-label>Platform</mat-label>
    <mat-select [(ngModel)]="paramValue">
      <mat-option *ngFor="let platform of allPlatforms" [value]="platform.name">
        {{platform.name}}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<!-- Cert Lead input -->
<ng-template #certInput>
  <mat-form-field>
    <input #certLead  matInput placeholder="Cert Lead" [matAutocomplete]="autoCert" (focus)="onCertLeadFocus($event)"
           (blur)="onCertLeadBlur($event)">
    <mat-autocomplete #autoCert="matAutocomplete" [displayWith]="getUserName"
                      (optionSelected)="paramValue = $event.option.value.id">
      <mat-option *ngFor="let user of (filteredCertLeads$ | async)" [value]="user">
        {{user.username}} ({{user.firstName}} {{user.lastName}})
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-template>

<!-- IP Lead input -->
<ng-template #ipLeadInput>
  <mat-form-field>
    <input #ipLead matInput placeholder="IP Lead" [matAutocomplete]="autoLead" (focus)="onIpLeadFocus($event)"
           (blur)="onIpLeadBlur($event)">
    <mat-autocomplete #autoLead="matAutocomplete" [displayWith]="getUserName"
                      (optionSelected)="paramValue = $event.option.value.id">
      <mat-option *ngFor="let user of (filteredIpLeads$ | async)" [value]="user">
        {{user.username}} ({{user.firstName}} {{user.lastName}})
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-template>

<!-- Client Lead input -->
<ng-template #clientLeadInput>
  <mat-form-field>
    <input #clientLead matInput placeholder="Client Lead" [matAutocomplete]="autoClientLead" (focus)="onClientLeadFocus($event)"
           (blur)="onClientLeadBlur($event)">
    <mat-autocomplete #autoClientLead="matAutocomplete" [displayWith]="getUserName"
                      (optionSelected)="paramValue = $event.option.value.id">
      <mat-option *ngFor="let user of (filteredClientLeads$ | async)" [value]="user">
        {{user.username}}</mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-template>

<!-- Backup Analyst input -->
<ng-template #backupAnalyst>
  <mat-form-field>
    <input #backup matInput placeholder="Backup Analyst" [matAutocomplete]="autoBackup" (focus)="onBackupAnalystFocus($event)"
           (blur)="onBackupAnalystBlur($event)">
    <mat-autocomplete #autoBackup="matAutocomplete" [displayWith]="getUserName"
                      (optionSelected)="paramValue = $event.option.value.id">
      <mat-option *ngFor="let user of (filteredBackupAnalysts$ | async)" [value]="user">
        {{user.username}} ({{user.firstName}} {{user.lastName}})
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-template>

<!-- Before Date Select -->
<ng-template #beforeDate>
  <mat-form-field style="width: 100%">
    <input matInput [ngxMatDatetimePicker]="beforeDate" [(ngModel)]="paramValue"
           placeholder="Before:" (dateChange)="onDateChange($event)">
    <mat-datepicker-toggle matSuffix [for]="$any(beforeDate)">
    </mat-datepicker-toggle>
    <ngx-mat-datetime-picker #beforeDate [hideTime]="true"></ngx-mat-datetime-picker>
  </mat-form-field>
</ng-template>

<!-- After Date Select -->
<ng-template #afterDate>
  <mat-form-field style="width: 100%">
    <input matInput [ngxMatDatetimePicker]="afterDate" [(ngModel)]="paramValue"
           placeholder="After:" (dateChange)="onDateChange($event)">
    <mat-datepicker-toggle matSuffix [for]="$any(afterDate)">
    </mat-datepicker-toggle>
    <ngx-mat-datetime-picker #afterDate [hideTime]="true"></ngx-mat-datetime-picker>
  </mat-form-field>
</ng-template>

<!-- CardLog Test Tool select -->
<ng-template #testTool>
  <mat-form-field>
    <mat-label>Test Tool</mat-label>
    <mat-select [(ngModel)]="paramValue">
      <mat-option *ngFor="let testTool of AllTestTools" [value]="testTool">
        {{testTool | testToolDescription}}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<!-- CycleTime Field Parameter -->
<ng-template #cycleTime>
  <mat-form-field>
    <mat-label>Value (days)</mat-label>
    <input type="number" matInput [(ngModel)]="paramValue" required/>
  </mat-form-field>
</ng-template>


