import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PagedResponse} from '@models/pagedResponse';
import {TestCase} from '@models/tests/testCase';
import {ValidationCategory} from '@models/ValidationCategory';
import {Observable} from 'rxjs';
import {AppConfigService} from '../../configuration/app-config.service/app-config.service';
import {ITestCasesService} from './iTestCasesService';

@Injectable()
export class TestCasesService implements ITestCasesService {
  private readonly baseUrl: string;
  private readonly validationCategoriesUrl: string;

  constructor(
      private http: HttpClient,
      configService: AppConfigService) {
    this.baseUrl = configService.getRestUrl('/testcases');
    this.validationCategoriesUrl = configService.getRestUrl('/validationcategories');
  }

  getTestCase(testCaseId: number): Observable<TestCase> {
    const url = this.baseUrl + `/${testCaseId}`;
    return this.http.get<TestCase>(url);
  }

  getTestCases(testPlanId: number, full: boolean = false): Observable<PagedResponse<TestCase>> {
    const params = new HttpParams()
      .set('testPlanId', String(testPlanId))
      .set('full', String(full))
      .set('size', String(999999));
    return this.http.get<PagedResponse<TestCase>>(this.baseUrl, {params});
  }

  getTestCaseVerbiage(testCaseId: number): Observable<TestCase> {
    const url = this.baseUrl + `/${testCaseId}/verbiage`;
    return this.http.get<TestCase>(url);
  }

  getValidationCategories(): Observable<Array<ValidationCategory>> {
    return this.http.get<Array<ValidationCategory>>(this.validationCategoriesUrl, {});
  }
}
