import {Component, Input} from '@angular/core';
import {ProjectDetail} from '@models/projectDetail';
import {ProjectPhase} from '@models/projectPhase';
import {ProjectState} from '@models/projectState';

@Component({
  selector: 'app-project-details-phase',
  templateUrl: './project-details-phase.component.html',
  styleUrls: ['./project-details-phase.component.scss']
})
export class ProjectDetailsPhaseComponent {

  ProjectState = ProjectState;
  ProjectPhase = ProjectPhase;

  currentPhaseIndex = -1;
  projectDetail: ProjectDetail;
  isSandboxProject: boolean;
  displayedPhases: Array<ProjectPhase>;

  @Input() set project(value: ProjectDetail) {
    this.projectDetail = value;
    this.isSandboxProject = this.projectDetail?.sandbox;
    this.setDisplayedPhases();
    this.currentPhaseIndex = value ? this.displayedPhases.indexOf(value.phase) : -1;
  }


  setDisplayedPhases(): void {
    this.displayedPhases = this.isSandboxProject ?
      [ProjectPhase.Onboarding, ProjectPhase.TestExecution] :
      [ProjectPhase.Onboarding, ProjectPhase.TestExecution, ProjectPhase.PreValidation, ProjectPhase.Validation, ProjectPhase.Approval];
  }
}
