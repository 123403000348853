import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IAppConfig} from '../app.config';
import {defaultConfig} from '../default-configuration';

export interface IAppConfigService {
  readonly current: IAppConfig;

  loadAppConfig(): any;

  /**
   * Get the URL of a rest service endpoint
   * @param endpoint - REST service endpoint
   */
  getRestUrl(endpoint: string): string;
}

/**
 * load the configuration details as part of the application
 * start up
 */
@Injectable()
export class AppConfigService implements IAppConfigService {
  private configUrl = '/assets/config/appConfig.json';
  private appConfig: IAppConfig = defaultConfig;

  constructor(private http: HttpClient) {
  }

  loadAppConfig() {
    return this.http.get<IAppConfig>(this.configUrl)
      .toPromise()
      .then(data => {
        // Combine the loaded data on top of the default configuration
        this.appConfig = {...this.appConfig, ...data};
      });
  }

  get current(): IAppConfig {
    return this.appConfig;
  }

  /**
   * Get the URL of a rest service endpoint
   * @param endpoint - REST service endpoint
   */
  getRestUrl(endpoint: string): string {
    if (endpoint.startsWith('/')) {
      return `${this.appConfig.restUrl}${endpoint}`;
    } else {
      return `${this.appConfig.restUrl}/${endpoint}`;
    }
  }
}
