import {Injectable} from '@angular/core';
import {ProjectPhase} from '@models/projectPhase';
import {IProjectPhaseDescriptionService} from './iProjectPhaseDescriptionService';

interface description {
  description: string;
  colour: {
    red: number;
    green: number;
    blue: number;
  },
  labelColour: string
}

const descriptions: Record<ProjectPhase, description> = {
  [ProjectPhase.Draft]: {description: 'Draft', colour: {red: 208, green: 255, blue: 154}, labelColour: 'black'},
  [ProjectPhase.Onboarding]: {description: 'Req, Plan &  Setup', colour: {red:102, green: 153, blue: 204}, labelColour: 'white'},
  [ProjectPhase.TestExecution]: {description: 'Test Certify', colour: {red: 43, green: 86, blue: 129}, labelColour: 'white'},
  [ProjectPhase.PreValidation]: {description: 'Implementation', colour: {red: 102, green: 102, blue: 102}, labelColour: 'white'},
  [ProjectPhase.Validation]: {description: 'Validation', colour: {red: 155, green: 155, blue: 155}, labelColour: 'white'},
  [ProjectPhase.Approval]: {description: 'Approval', colour: {red: 255, green: 198, blue: 38}, labelColour: 'black'},
  [ProjectPhase.Closed]: {description: 'Closed', colour: {red: 208, green: 255, blue: 154}, labelColour: 'black'}
};


@Injectable()
export class ProjectPhaseDescriptionService implements IProjectPhaseDescriptionService {
  getDescription(phase: ProjectPhase): string {
    return descriptions[phase].description;
  }

  getColourAsString(phase: ProjectPhase): string {
    const description = descriptions[phase];
    return `rgba(${description.colour.red},${description.colour.green},${description.colour.blue},1)`;
  }

  getLabelColourAsString(phase: ProjectPhase): string {
    return descriptions[phase].labelColour;
  }
}
