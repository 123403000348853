export enum ProjectPhase {
  Draft = 'DRAFT',
  Onboarding = 'ONBOARDING',
  TestExecution = 'TEST_EXECUTION',
  PreValidation = 'PRE_VALIDATION',
  Validation = 'VALIDATION',
  Approval = 'APPROVAL',
  Closed = 'CLOSED'
}

export const AllPhases = [
  ProjectPhase.Draft,
  ProjectPhase.Onboarding,
  ProjectPhase.TestExecution,
  ProjectPhase.PreValidation,
  ProjectPhase.Validation,
  ProjectPhase.Approval,
  ProjectPhase.Closed
];

export const AllPhasesExceptClosed = [
  ProjectPhase.Draft,
  ProjectPhase.Onboarding,
  ProjectPhase.TestExecution,
  ProjectPhase.PreValidation,
  ProjectPhase.Validation,
  ProjectPhase.Approval
];

export const AllSandboxPhases  = [
  ProjectPhase.Onboarding,
  ProjectPhase.TestExecution
];

export const AllPhasesExceptClosedAndDraft = [
  ProjectPhase.Onboarding,
  ProjectPhase.TestExecution,
  ProjectPhase.PreValidation,
  ProjectPhase.Validation,
  ProjectPhase.Approval
];
