import {Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-project-test-case-status-bar',
  templateUrl: './project-test-case-status-bar.component.html',
  styleUrls: ['./project-test-case-status-bar.component.scss']
})
export class ProjectTestCaseStatusBarComponent implements OnChanges {

  totalTests: number;
  @Input()
  passed: number;
  @Input()
  failed: number;
  @Input()
  notStarted: number;
  @Input()
  inProgress: number;
  @Input()
  waived: number;
  @Input()
  overridden: number;

  @Output()
  clicked = new EventEmitter();

  @HostListener('click', ['$event']) onClick($event: MouseEvent) {
    $event.cancelBubble = true;
    this.clicked.emit();
  }

  ngOnChanges(change: SimpleChanges) {
    this.totalTests = this.passed + this.failed + this.notStarted + this.inProgress + this.waived + this.overridden;
  }

  getAmount(count: number): string {
    return `width: ${(count / this.totalTests * 100)}%`;
  }
}
