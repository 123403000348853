import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ExcelDownloadDialogComponent, ExcelDownloadDialogParams} from './excel-download-dialog/excel-download-dialog.component';

export interface IExcelDownloadDialogService {getDownloadName(): Promise<string | null>;}

@Injectable()
export class ExcelDownloadDialogService implements IExcelDownloadDialogService {

  constructor(private dialog: MatDialog) { }

  async getDownloadName(dialogTitle = 'Download Excel File', defaultFilename: string = 'report.xlsx', validExtensions = ['.xlsx']): Promise<string | null> {
    const response = await this.dialog.open<ExcelDownloadDialogComponent, ExcelDownloadDialogParams>(ExcelDownloadDialogComponent,
      {
        width: '600px',
        data: {
          title: dialogTitle,
          defaultFilename,
          validExtensions
        }
      }).afterClosed().toPromise();
    const filename = response as string;
    if (filename && !this.hasAcceptableExcelType(filename, validExtensions)) {
      const extension = validExtensions[0] || '.xlsx';
      return filename + extension;
    }
    return filename;
  }

  private hasAcceptableExcelType(filename: string, validExtensions: Array<string>): boolean {
    return validExtensions.length >= 1
      ? validExtensions.some(type => filename.toLowerCase().endsWith(type))
      : filename.endsWith('.xlsx');
  }
}
