<div class="content">

  <a class="title" routerLink="home">
    <img class="logo" src="/assets/client-logo.svg" alt="Fiserv logo"/>
    <img src="assets/t3-cert-logo.svg"/>
  </a>

  <div *ngIf="isLoggedIn"
       class="right-button-container">
    <button (click)="openVersionDialog()" class="info-button"
            mat-icon-button>
      <mat-icon>info</mat-icon>
    </button>

    <div [matMenuTriggerFor]="avatarMenu" class="avatar-menu-trigger"
         matRipple>
      <mat-icon>person</mat-icon>
      <span>{{ username }}</span>
      </div>
  </div>
</div>

<mat-menu #avatarMenu="matMenu">
  <button [routerLink]="['change-password']"
          mat-menu-item>
    <mat-icon>password</mat-icon>
    <span>Change Password</span>
  </button>

  <button (click)="removeMFADevice()"
          *ngIf="hasMFADeviceConfigured"
          mat-menu-item>
    <mat-icon>phonelink_lock</mat-icon>
    <span>Remove Authenticator</span>
  </button>

  <button (click)="onLogoutClick()"
          mat-menu-item>
    <mat-icon>logout</mat-icon>
    <span>Logout</span>
  </button>
</mat-menu>
