<table *ngIf="displayedColumns?.length"
  mat-table [dataSource]="dataSource" multiTemplateDataRows [ngClass]="formatElevation()">

  <div *ngFor="let column of displayedColumns; first as isFirst; index as i;">
    <ng-container matColumnDef="{{column}}">
      <th mat-header-cell *matHeaderCellDef [ngClass]="{'vertical-separator': options.verticalSeparator}" id="cell-header">
        <ng-container
          *ngIf="columnDefinitions.length && columnDefinitions[i].header; else columnHeader">{{columnDefinitions[i].header}}</ng-container>
        <ng-template #columnHeader>{{options.capitalisedHeader ? (column | titlecase) : column}}</ng-template>
      </th>
      <td mat-cell *matCellDef="let element" [ngClass]="{'vertical-separator': options.verticalSeparator}">
        <div *ngIf="isFirst; else nonTreeCell">
          <div class="value-cell">
            <div [ngStyle]="{'width': (element.depth * 10) + 'px'}"></div>
            <em class="fa tree-arrow" [ngStyle]="{'visibility': element.children.length ? 'visible' : 'hidden'}"
               [class.fa-chevron-right]="!element.isExpanded" [class.fa-chevron-down]="element.isExpanded"
               (click)="onNodeClick(element)"></em>
            <ng-container *ngIf="columnDefinitions.length && columnDefinitions[i].template; else defaultTemplate">
              <template [ngTemplateOutlet]="columnDefinitions[i].template"
                        [ngTemplateOutletContext]="{value: element.value[column], element: element, tree: this}"></template>
            </ng-container>
            <ng-template #defaultTemplate>{{element.value[column]}}</ng-template>
            <em class="validation-error fa fa-exclamation fa-sm" matTooltip="Show Validation Result"
                matTooltipPosition="above"
                [class.audit-row-expanded]="expandedElement === element"
                [ngStyle]="{'visibility': hasValidationError(element.value['validationResult']) ? 'visible' : 'hidden'}"
                (click)="expandedElement = expandedElement === element ? null : element"></em>
          </div>
        </div>
        <ng-template #nonTreeCell>
          <ng-container *ngIf="columnDefinitions.length && columnDefinitions[i].template; else defaultTemplate">
            <template [ngTemplateOutlet]="columnDefinitions[i].template"
                      [ngTemplateOutletContext]="{value: element.value[column]}"></template>
          </ng-container>

          <ng-template #defaultTemplate>{{element.value[column]}}</ng-template>
        </ng-template>
      </td>
    </ng-container>
  </div>
  <ng-container #expanded matColumnDef="expandedDetail" class="validation-result-row">
    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" style="padding: 0">
      <div class="expanded-element"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="validation-result-text">
          <ul>
            <li *ngFor="let error of splitIntoParagraph(element.value['validationResult'])">
              <span>{{error}}</span>
            </li>
          </ul>
        </div>
      </div>
    </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row [ngClass]="{'highlight-on-hover': options.highlightRowOnHover}"
      *matRowDef="let row; columns: displayedColumns;"></tr>
  <tr mat-row *matRowDef="let sub; columns: ['expandedDetail']" class="validation-result-row"></tr>

</table>
