import {Injectable} from '@angular/core';
import {ProjectState} from '@models/projectState';
import {Action, AuthInfo, Resource, Role} from '@models/security';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthenticationService} from '../authentication.service/authentication.service';
import {IPermissionsService} from './iPermissionsService';

export type Permission = [Resource, Action];
const permissionString = ([resource, action]: Permission) => `${resource}:${action}`;

interface SiteSectionAccessRequirements {
  permittedRoles: Role[];
  requiredPermissions: Permission[];
}

export enum SiteSection {Projects, Clients, Users, HostLogs, TestPlans, Search, Reports}

const siteSectionAccessRequirements: Record<SiteSection, SiteSectionAccessRequirements> = {
  [SiteSection.Projects]: {
    permittedRoles: [Role.admin, Role.client, Role.analyst, Role.gatekeeper, Role.manager, Role.iccManager,
      Role.integratedPartner, Role.ipManager, Role.thirdPartyClient],
    requiredPermissions: [
      [Resource.project, Action.list]
    ]
  },
  [SiteSection.Clients]: {
    permittedRoles: [Role.admin],
    requiredPermissions: [
      [Resource.client, Action.list]
    ]
  },
  [SiteSection.Users]: {
    permittedRoles: [Role.admin],
    requiredPermissions: [
      [Resource.user, Action.list]
    ]
  },
  [SiteSection.HostLogs]: {
    permittedRoles: [Role.admin, Role.manager, Role.gatekeeper, Role.analyst, Role.iccManager],
    requiredPermissions: [
      [Resource.hostlog, Action.list]
    ]
  },
  [SiteSection.TestPlans]: {
    permittedRoles: [Role.admin, Role.manager, Role.gatekeeper, Role.analyst, Role.iccManager, Role.ipManager, Role.integratedPartner],
    requiredPermissions: [
      [Resource.testplan, Action.list], [Resource.testplan, Action.read]
    ]
  },
  [SiteSection.Reports]: {
    permittedRoles: [Role.admin, Role.manager, Role.gatekeeper, Role.iccManager, Role.ipManager, Role.gatekeeper, Role.integratedPartner, Role.analyst],
    requiredPermissions: []
  },
  [SiteSection.Search]: {
    permittedRoles: [Role.admin, Role.analyst, Role.gatekeeper, Role.manager, Role.iccManager],
    requiredPermissions: []
  }
};

@Injectable()
export class PermissionsService implements IPermissionsService {

  constructor(private authenticationService: AuthenticationService) {
  }

  hasPermission(permission: Permission): Observable<boolean> {
    return this.authenticationService.authInfo$.pipe(
      map(auth => auth?.permissions.includes(permissionString(permission)) ?? false)
    );
  }

  canAccessSiteSection(info: AuthInfo, section: SiteSection): boolean {
    const requirements = siteSectionAccessRequirements[section];
    return requirements.permittedRoles.includes(info?.role) &&
      requirements.requiredPermissions.every(permission => info?.permissions.includes(permissionString(permission)));
  }

  currentUserCanAccessSiteSection(section: SiteSection): Observable<boolean> {
    return this.authenticationService.authInfo$
      .pipe(map(info => this.canAccessSiteSection(info, section)));
  }

  canChangeState(info: AuthInfo, desiredState: ProjectState): boolean {
    if (info && desiredState === ProjectState.Cancelled) {
      const allowedToChange = [Role.admin, Role.gatekeeper, Role.manager, Role.analyst];
      return allowedToChange.includes(info.role);
    } else {
      return true;
    }
  }

  canAccessLoaArchive(): Observable<boolean> {
    const permittedRoles = [Role.admin, Role.analyst, Role.iccManager];
    return this.authenticationService.authInfo$.pipe(
      map(auth => permittedRoles.includes(auth?.role))
    );
  }

  canAccessReports(): Observable<boolean> {
    const permittedRoles = [Role.client, Role.thirdPartyClient];
    return this.authenticationService.authInfo$.pipe(
      map(auth => !permittedRoles.includes(auth?.role))
    );
  }

  currentUserCanChangeProjectState(desiredState: ProjectState): Observable<boolean> {
    return this.authenticationService.authInfo$.pipe(
      map(info => this.canChangeState(info, desiredState))
    );
  }

  canFilterReports(): Observable<boolean> {
    const permittedRoles = [Role.admin, Role.gatekeeper, Role.manager];
    return this.authenticationService.authInfo$.pipe(
      map(auth => permittedRoles.includes(auth?.role))
    );
  }

  canRestoreTests(): Observable<boolean> {
    const allowedToRestore = [Role.admin, Role.iccManager, Role.manager];
    return this.authenticationService.authInfo$.pipe(
      map(auth => allowedToRestore.includes(auth?.role))
    );
  }

  isAnalyst(): Observable<boolean> {
    return this.authenticationService.authInfo$.pipe(
      map(auth => Role.analyst === auth?.role)
    );
  }

  isAdmin(): Observable<boolean> {
    return this.authenticationService.authInfo$.pipe(
      map(auth => Role.admin === auth?.role)
    );
  }
}

