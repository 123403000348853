import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidationErrors} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Action, Resource} from '@models/security';
import {PermissionsService} from '@services/auth/permissions.service/permissions.service';
import {FilterParameters} from '@services/data/filterParameters';
import * as _ from 'lodash';
import {Observable, take} from 'rxjs';
import WaiverFilter from '../waiverFilter';
import ALLSEARCHTYPE = WaiverFilter.ALLSEARCHTYPES;
import SearchField = WaiverFilter.SearchField;
import SearchParameter = WaiverFilter.SearchParameter;
import SearchType = WaiverFilter.SearchType;

export interface WaiverFilterDialogParameters {
  existingParameters: FilterParameters;
  title: string;
}

@Component({
  selector: 'app-waiver-filter-dialog',
  templateUrl: './waiver-filter-dialog.component.html',
  styleUrls: ['./waiver-filter-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WaiverFilterDialogComponent implements OnInit {

  form: UntypedFormGroup;
  SearchField = SearchField;
  existingParams: SearchParameter[];

  availableParameters: Array<SearchType>;
  private canReadClients$: Observable<boolean>;

  constructor(private formBuilder: UntypedFormBuilder,
              @Inject(MAT_DIALOG_DATA) public params: WaiverFilterDialogParameters, private permissionService: PermissionsService) {
    this.canReadClients$ = this.permissionService.hasPermission([Resource.client, Action.read]);
  }

  private get AllSearchTypes(): Array<WaiverFilter.SearchType> {
    return ALLSEARCHTYPE;
  }

  get sortedAvailableParameters() {
    return _.sortBy(this.availableParameters, p => p.fieldDescription);
  }

  get parameterArray(): UntypedFormArray {
    return this.form?.get('parameters') as UntypedFormArray;
  }

  get canAddClientLeadFilter() {
    return _.some(this.parameterArray.value, p => p.type.field === SearchField.Client && p.value);
  }

  ngOnInit(): void {
    this.existingParams = this.params.existingParameters ? this.mapExistingParameters(this.params.existingParameters) : [];
    const userFields: SearchField[] = this.existingParams.map<SearchField>(param => param.type.field);

    this.availableParameters = [...this.AllSearchTypes.filter(st => !userFields.includes(st.field))];
    this.canReadClients$.pipe(take(1))
        .subscribe(canReadClients => {
          if (!canReadClients) {
            this.removeClientFilters(this.availableParameters);
          }
        });

    this.form = this.formBuilder.group({
      newParameter: '',
      parameters: this.formBuilder.array(this.existingParams)
    }, {validators: this.mustHaveOneSearchParameter.bind(this)});
  }

  private mapExistingParameters(existingParameters: FilterParameters): Array<SearchParameter> {
    const parameters: Array<SearchParameter> = [];
    existingParameters.forEach((value, key) => {
      const searchType = this.AllSearchTypes.find(st => st.field === key);
      if (searchType) {
        parameters.push({
          type: searchType,
          value
        });
      }
    });
    return parameters;
  }

  removeClientFilters(availableParameters): void {
    let found = 0;
    const removeFieldDescriptions = ['Client'];

    for (let i = availableParameters.length - 1; i >= 0; i--) {
      if (removeFieldDescriptions.includes(availableParameters[i].fieldDescription)) {
        this.availableParameters.splice(i, 1);
        if (++found === removeFieldDescriptions.length) {
          break;
        }
      }
    }
  }

  mustHaveOneSearchParameter(): ValidationErrors | null {
    return this.parameterArray?.length > 0 ? null : {needParam: true};
  }

  onAddParameterClicked() {
    const newParameterControl = this.form.get('newParameter');
    const newParameterField = newParameterControl.value;
    const newParameterIndex = this.availableParameters.findIndex(p => p.field === newParameterField);
    const newParameter = this.availableParameters[newParameterIndex];


    if (newParameterIndex >= 0) {
      const newSearchParameter: SearchParameter = {
        type: newParameter,
        value: ''
      };
      this.parameterArray.push(this.formBuilder.control(newSearchParameter, {validators: [this.hasSearchParamValue.bind(this)]}));
      this.availableParameters.splice(newParameterIndex, 1);

      newParameterControl.setValue('');
    }
  }

  hasSearchParamValue(control: AbstractControl) {
    const controlValue = control.value as SearchParameter;
    return controlValue.value ? null : {required: true};
  }

  onRemoveParameter(parameterIndex: number) {
    const control = this.parameterArray.at(parameterIndex);
    const removedParameter: SearchParameter = control.value as SearchParameter;

    this.parameterArray.removeAt(parameterIndex);
    this.availableParameters.push(removedParameter.type);
  }

  onSearchParameterChanged(searchParameter: SearchParameter) {
    if (searchParameter.type.field === SearchField.Client) {
      // // reset any fields that are the client lead user
      // for (let i = 0; i < this.parameterArray.length; i++) {
      //   const parameter = this.parameterArray.at(i);
      //   const parameterValue = parameter.value as SearchParameter;
      //   if (parameterValue.type.field === SearchField.ClientLeadId) {
      //     parameter.setValue({...parameterValue, value: undefined});
      //   }
      // }
    }
  }

}
