import {Overlay, OverlayPositionBuilder, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {ComponentRef, Directive, ElementRef, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {ProjectTestSummaryTooltip} from './project-test-summary-tooltip.component';

@Directive({
  selector: '[appTestSummaryTooltip]'
})
export class ProjectTestSummaryTooltipDirective implements OnInit, OnDestroy {

  private overlayRef: OverlayRef;

  @Input() testsNotStarted = 0;
  @Input() testsInProgress = 0;
  @Input() testsPassed = 0;
  @Input() testsFailed = 0;
  @Input() testsWaived = 0;
  @Input() testsOverridden = 0;

  @HostListener('mouseenter')
  show() {
    const tooltipPortal = new ComponentPortal(ProjectTestSummaryTooltip);
    const tooltipRef: ComponentRef<ProjectTestSummaryTooltip> = this.overlayRef.attach(tooltipPortal);

    tooltipRef.instance.testsNotStarted = this.testsNotStarted;
    tooltipRef.instance.testsInProgress = this.testsInProgress;
    tooltipRef.instance.testsPassed = this.testsPassed;
    tooltipRef.instance.testsFailed = this.testsFailed;
    tooltipRef.instance.testsWaived = this.testsWaived;
    tooltipRef.instance.testsOverridden = this.testsOverridden;
  }

  @HostListener('mouseout')
  hide() {
    this.overlayRef.detach();
  }

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef
  ) {
  }

  ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder.flexibleConnectedTo(this.elementRef).withPositions([
      {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom'
      }
    ]);
    this.overlayRef = this.overlay.create({positionStrategy, backdropClass: 'tooltip-backdrop', panelClass: 'tooltip-panel'});
  }

  ngOnDestroy(): void {
    this.overlayRef.detach();
  }
}
