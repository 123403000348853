<div class="page-container">
  <header>
    <app-header-bar (logout)="onLogout()"></app-header-bar>
  </header>
  <aside [class.isVisible]="showSideNavigation$ | async">
    <app-side-menu [sections]="sections$"></app-side-menu>
  </aside>
  <main>
    <router-outlet></router-outlet>
  </main>
  <div class="padding" [class.isVisible]="showSideNavigation$ | async"></div>
</div>
<app-spinner></app-spinner>
<app-time-out-alert></app-time-out-alert>
