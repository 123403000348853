import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PagedResponse} from '@models/pagedResponse';
import {Role} from '@models/security';
import {User} from '@models/security/user';
import {Observable} from 'rxjs';
import {AppConfigService} from '../../configuration/app-config.service/app-config.service';
import {IUsersService} from './iUsersService';

@Injectable()
export class UsersService implements IUsersService {

  private usersUrl = this.configService.getRestUrl('/users');
  private availableUsersUrl = this.configService.getRestUrl('/users/availableUsers');

  constructor(private http: HttpClient, private configService: AppConfigService) { }

  /**
   * Create a new user in the system
   * @param {User} user - new user details
   * @returns {Observable<User>}
   */
  createUser(user: User): Observable<User> {
    return this.http.post<User>(this.usersUrl, user);
  }

  /**
   * Assign a user to a client
   * @param {number} clientId - id of client
   * @param {number} userId - id of user
   * @returns {Observable<any>}
   */
  assignUser(clientId: number, userId: number): Observable<any> {
    return this.http.patch(`${this.usersUrl}/${userId}/assignClient/${clientId}`, { });
  }

  /**
   * Get all users in the system
   * @returns {Observable<PagedResponse<User>>}
   */
  listUsers(sort = 'username', dir = 'asc', page = 0, pageSize = 99999, filter = ''): Observable<PagedResponse<User>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', pageSize.toString())
      .set('sort', `${sort},${dir}`);
    return this.http.post<PagedResponse<User>>(`${this.usersUrl}/list`, {filter}, {params});
  }

  /**
   * Get all the user that are either an admin or an analyst in the system
   * @param {string} sort
   * @param {string} dir
   * @param {number} page
   * @param {number} pageSize
   * @returns {Observable<PagedResponse<User>>}
   */
  listLeadUsers(sort = 'username', dir = 'asc', page = 0, pageSize = 99999): Observable<PagedResponse<User>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', pageSize.toString())
      .set('sort', `${sort},${dir}`)
      .set('isDisabled', String(false))
      .append('role', Role.admin)
      .append('role', Role.manager)
      .append('role', Role.analyst);
    return this.http.post<PagedResponse<User>>(`${this.usersUrl}/list`, {}, {params});
  }

  /**
   * Get all of the analyst users
   * @param {string} sort
   * @param {string} dir
   * @param {number} page
   * @param {number} pageSize
   * @returns {Observable<PagedResponse<User>>}
   */
  listAnalystUsers(sort = 'username', dir = 'asc', page = 0, pageSize = 99999): Observable<PagedResponse<User>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', pageSize.toString())
      .set('sort', `${sort},${dir}`)
      .set('isDisabled', String(false))
      .set('role', Role.analyst);
    return this.http.post<PagedResponse<User>>(`${this.usersUrl}/list`, {}, {params});
  }

  /**
   * Get all of the integrated partner users
   * @param includeManagers - Include people in the role of IP Manager
   * @param {string} sort
   * @param {string} dir
   * @param {number} page
   * @param {number} pageSize
   * @returns {Observable<PagedResponse<User>>}
   */
  listIntegratedPartnerUsers(includeManagers = false, sort = 'username', dir = 'asc', page = 0, pageSize = 99999): Observable<PagedResponse<User>> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('size', pageSize.toString())
      .set('sort', `${sort},${dir}`)
      .set('isDisabled', String(false))
      .set('role', Role.integratedPartner);

    if (includeManagers) {
      params = params.append('role', Role.ipManager);
    }
    return this.http.post<PagedResponse<User>>(`${this.usersUrl}/list`, {}, {params});
  }

  /**
   * Get all of the third party client users
   * @param {string} sort
   * @param {string} dir
   * @param {number} page
   * @param {number} pageSize
   * @returns {Observable<PagedResponse<User>>}
   */
  listThirdPartyClientUsers(sort = 'username', dir = 'asc', page = 0, pageSize = 99999): Observable<PagedResponse<User>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', pageSize.toString())
      .set('sort', `${sort},${dir}`)
      .set('isDisabled', String(false))
      .set('role', Role.thirdPartyClient);
    return this.http.post<PagedResponse<User>>(`${this.usersUrl}/list`, {}, {params});
  }

  /**
   * Get all the (client) users associated with the given client
   * @param {number} clientId
   * @param {string} sort
   * @param {string} dir
   * @param {number} page
   * @param {number} pageSize
   * @returns {Observable<PagedResponse<User>>}
   */
  listUsersForClient(clientId: number, filterDisabled: boolean, sort = 'username', dir = 'asc', page = 0,
                     pageSize = 99999, filter = ''): Observable<PagedResponse<User>> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('size', pageSize.toString())
      .set('sort', `${sort},${dir}`)
      .set('merchantId', clientId.toString())
    if (filterDisabled) {
      params = params.append('isDisabled', String(false));
    }

    return this.http.post<PagedResponse<User>>(`${this.usersUrl}/list`, {filter}, {params});
  }

  /**
   * Get all the user that are either an ipManager or an integratedPartner or an analyst in the system
   * @param {string} sort
   * @param {string} dir
   * @param {number} page
   * @param {number} pageSize
   * @returns {Observable<PagedResponse<User>>}
   */
  listBackupAnalyst(sort = 'username', dir = 'asc', page = 0, pageSize = 99999): Observable<PagedResponse<User>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', pageSize.toString())
      .set('sort', `${sort},${dir}`)
      .set('isDisabled', String(false))
      .append('role', Role.analyst);
    return this.http.post<PagedResponse<User>>(`${this.usersUrl}/list`, {}, {params});
  }

  getUser(userId: number): Observable<User> {
    return this.http.get<User>(`${this.usersUrl}/${userId}`);
  }

  updateUser(user: User): Observable<User> {
    return this.http.put<User>(`${this.usersUrl}/${user.id}`, user);
  }

  switchUserDisabledStatus(userId: number, disable: boolean): Observable<any> {
      return this.http.patch(`${this.usersUrl}/switchDisabledStatus/${userId}?disable=${disable}`, { });
  }

  /**
   * Assign a temporary password to a user
   * @param {number} userId
   * @param {string} temporaryPassword
   * @returns {Observable<any>}
   */
  resetPassword(userId: number, temporaryPassword: string): Observable<any> {
    return this.http.put(`${this.usersUrl}/${userId}/password?action=SET`, {
      newPassword: temporaryPassword,
      requireChange: true
    });
  }

  /**
   * Delete a user
   * @param {number} userId - ID of user to delete
   * @returns {Observable<any>}
   */
  deleteUser(userId: number): Observable<any> {
    return this.http.delete(`${this.usersUrl}/${userId}`);
  }

  /**
   * Get available users with client role
   * @param {string} sort
   * @param {string} dir
   * @param {number} page
   * @param {number} pageSize
   * @returns {Observable<PagedResponse<User>>}
   */
  listClientUsers(sort = 'username', dir = 'asc', page = 0, pageSize = 99999): Observable<PagedResponse<User>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', pageSize.toString())
      .set('sort', `${sort},${dir}`)
      .append('role', Role.client);
    return this.http.get<PagedResponse<User>>(this.usersUrl, {params});
  }

  /**
   * Get available users with client role
   * @param {string} sort
   * @param {string} dir
   * @param {number} page
   * @param {number} pageSize
   * @returns {Observable<PagedResponse<User>>}
   */
  listAvailableClientUsers(merchantId: number, sort = 'username', dir = 'asc', page = 0,
                           pageSize = 99999): Observable<PagedResponse<User>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', pageSize.toString())
      .set('sort', `${sort},${dir}`)
      .append('role', Role.client)
      .append('clientId', String(merchantId));
    return this.http.get<PagedResponse<User>>(this.availableUsersUrl, {params});
  }

  updateUsersFavouriteProject(isFavourite: boolean, projectId: number): Observable<any>{
    const params = new HttpParams().set('favourite', `${isFavourite}`);
    return this.http.patch<any>(`${this.usersUrl}/favouriteProject/${projectId}`, {}, {params});
  }

  /**
   * Get all the user that are either an ipManager or an integratedPartner or an analyst in the system
   * @param {string} sort
   * @param {string} dir
   * @param {number} page
   * @param {number} pageSize
   * @returns {Observable<PagedResponse<User>>}
   */
  listIpLead(sort = 'username', dir = 'asc', page = 0, pageSize = 99999): Observable<PagedResponse<User>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', pageSize.toString())
      .set('sort', `${sort},${dir}`)
      .set('isDisabled', String(false))
      .append('role', Role.integratedPartner)
      .append('role', Role.ipManager);
    return this.http.post<PagedResponse<User>>(`${this.usersUrl}/list`, {}, {params});
  }

  removeMFADevice(userId: number): Observable<void> {
    return this.http.delete<void>(`${this.usersUrl}/${userId}/mfadevice`);
  }
}
