import {Injectable} from '@angular/core';
import {AllPhasesExceptClosedAndDraft, AllSandboxPhases} from '@models/projectPhase';
import {ActiveProjectsReport} from '@models/reports/activeProjectsReport';
import {ProjectPhaseDescriptionService} from '../../../data/project-phase-description.service/project-phase-description.service';
import commonTableStyle from '../../commonTableStyle';

const columnAlignments = ['left', 'center', 'center'];

export interface IActiveProjectsTableProviderService {
  drawTable(doc: any, activeProjectsReport: ActiveProjectsReport, yPos: number, sandbox: boolean): void;
}

@Injectable()
export class ActiveProjectsTableProviderService implements IActiveProjectsTableProviderService {

  constructor(private phaseDescriptionService: ProjectPhaseDescriptionService) {}

  drawTable(doc: any, activeProjectsReport: ActiveProjectsReport, yPos: number, sandbox: boolean) {
    doc.autoTable({
      ...this.buildActiveProjectsReport(activeProjectsReport, sandbox),
      ...commonTableStyle,
      startY: yPos,
      didParseCell: data => {
        data.cell.styles.halign = columnAlignments[data.column.index];
      }
    });
  }

  private buildActiveProjectsReport(activeProjectsReport: ActiveProjectsReport, sandbox: boolean):
    { head: Array<any>, body: Array<Array<string>> } {
    const head = [{
      phase: 'Project Step',
      projectCount: 'Project Count',
      onHold: 'On Hold'
    }];

    const phases = sandbox ? AllSandboxPhases : AllPhasesExceptClosedAndDraft;
    const body = phases.map<Array<string>>(phase => {
      const reportPhase = activeProjectsReport[phase];
      return [
        this.phaseDescriptionService.getDescription(phase),
        (reportPhase?.phaseCount ?? 0).toString(),
        (reportPhase?.onHoldCount ?? 0).toString(),
      ];
    });

    return {head, body};
  }
}
