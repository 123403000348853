import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ProjectTestCase, ProjectTestCaseStatus} from '@models/tests/projectTestCase';
import {TestResultUpdate} from '@models/tests/testResultUpdate';
import {Observable} from 'rxjs';
import {AppConfigService} from '../../configuration/app-config.service/app-config.service';
import {IProjectTestCasesService} from './iProjectTestCases.service';

@Injectable()
export class ProjectTestCasesService implements IProjectTestCasesService {
  private readonly projectsUrl: string;

  constructor(
    private http: HttpClient,
    configService: AppConfigService
  ) {
    this.projectsUrl = configService.getRestUrl('/projects');
  }

  getProjectTestCase(projectId: number, testCaseId: number): Observable<ProjectTestCase> {
    const url = this.buildUrl(projectId, testCaseId);
    return this.http.get<ProjectTestCase>(url);
  }

  /**
   * Get an update on the test results for a project
   * @param {number} projectId - ID of project
   * @returns {Observable<Array<TestResultUpdate>>}
   */
  getTestResults(projectId: number): Observable<Array<TestResultUpdate>> {
    const headers = new HttpHeaders().append('background-request', 'true');
    return this.http.get<Array<TestResultUpdate>>(`${this.projectsUrl}/${projectId}/tests/results`, {headers});
  }

  /**
   * Get an update on the test results for a project
   * @param {number} projectId - ID of project
   * @returns {Observable<Array<TestResultUpdate>>}
   */
  getTestResultsSnapshot(projectId: string): Observable<Array<TestResultUpdate>> {
    const headers = new HttpHeaders().append('background-request', 'true');
    return this.http.get<Array<TestResultUpdate>>(`${this.projectsUrl}/${projectId}/tests/snapshotResults`, {headers});
  }

  /**
   * Set the waived state of a test case
   * @param {number} projectId - id of project
   * @param {number} projectTestCaseId - id of project test case
   * @param {boolean} waived - waived flag
   * @param {string} statusNumber - status number must be set if waiving a test case
   * @param {string} statusComment - optional comment when waiving test cases
   * @returns {Observable<any>}
   */
  setProjectTestCaseWaivedState(projectId: number, projectTestCaseId: number, waived: boolean, statusNumber?: string,
                                statusComment?: string, statusExpiryDate?: Date): Observable<any> {
    if (waived && statusNumber == null) {
      throw new Error('A waiver number must be assigned when waiving a test case');
    }
    if (waived && statusExpiryDate == null) {
      throw new Error('A waiver expiry date must be selected when waiving a test case');
    }
    return this.http.patch<ProjectTestCase>(`${this.projectsUrl}/${projectId}/tests/${projectTestCaseId}`,
      {
        status: waived ? ProjectTestCaseStatus.Waived : ProjectTestCaseStatus.None,
        statusNumber,
        statusComment,
        statusExpiryDate,
      });
  }

  setProjectTestCaseOverrideState(projectId: number, projectTestCaseId: number, isOverridden: boolean, comment?: string) {
    return this.http.patch<ProjectTestCase>(`${this.projectsUrl}/${projectId}/tests/${projectTestCaseId}`,
      {
        status: isOverridden ? ProjectTestCaseStatus.Overridden : ProjectTestCaseStatus.None,
        statusComment: comment,
      });
  }

  private buildUrl(projectId: number, testCaseId?: number) {
    let url = `${this.projectsUrl}/${projectId}/tests`;
    if (testCaseId) {
      url += `/${testCaseId}`;
    }
    return url;
  }

}
