import {NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {NGX_DATE_TIME_FORMAT_DATE_ONLY} from '@constants/NgxDateTimeFormat';
import {Client} from '@models/clients';
import {allTerminalPlatforms, TerminalPlatform} from '@models/constants';
import {Project} from '@models/project';
import {fromEvent, Observable} from 'rxjs';
import {map, startWith, switchMap, take} from 'rxjs/operators';
import {UtcDatePipe} from '../../../../app/services/pipes/utc-date.pipe';
import {BaseControlClass} from '../../base-control.class';
import {WaiverFilterService} from '../waiver-filter.service';
import WaiverFilter from './waiverFilter';
import SearchField = WaiverFilter.SearchField;
import SearchParameter = WaiverFilter.SearchParameter;
import SearchParameterType = WaiverFilter.SearchParameterType;

const SEARCH_PARAMETER_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => WaiverFilterParameterComponent),
  multi: true
};

const VALUE_STR = 'value';

@Component({
             selector: 'app-waiver-filter-parameter',
             templateUrl: './waiver-filter-parameter.component.html',
             styleUrls: ['./waiver-filter-parameter.component.scss'],
             changeDetection: ChangeDetectionStrategy.OnPush,
             providers: [
               SEARCH_PARAMETER_VALUE_ACCESSOR,
               {
                 provide: NGX_MAT_DATE_FORMATS,
                 useValue: NGX_DATE_TIME_FORMAT_DATE_ONLY
               }
             ]
           })
export class WaiverFilterParameterComponent extends BaseControlClass<SearchParameter> implements AfterViewInit{

  @Input() params: SearchParameter;

  @Output() delete = new EventEmitter();

  @Output() valueChanged = new EventEmitter<SearchParameter>();

  SearchField = SearchField;

  filteredClients$: Observable<Array<Client>>;

  filteredProjects$: Observable<Array<Project>>;

  allPlatforms = allTerminalPlatforms;

  @ViewChild('client') clientInput: ElementRef;

  @ViewChild('projectId') projectIdInput: ElementRef;

  @ViewChild('projectPlatform') projectPlatform: ElementRef;

  constructor(private waiverFilterService: WaiverFilterService,
              private changeDetector: ChangeDetectorRef,
              private utcDate: UtcDatePipe) {
    super();
  }

  ngAfterViewInit() {
    switch (this.params?.type.field) {
      case SearchField.Client:
        this.getFilteredClient('')
            .pipe(take(1))
            .subscribe(v => {
              const selected: Client = v.filter(el => el.id.toString() === this.paramValue)[0];
              this.clientInput.nativeElement.value = this.getClientName(selected);
              this.changeDetector.detectChanges();
            });
        break;
      case SearchField.ProjectId:
        this.getFilteredProjects('', (p: Project, id: string) => p.projectId.toLowerCase().indexOf(id) >= 0)
            .pipe(take(1))
            .subscribe(v => {
              const selected: Project = v.filter(el => el.projectId.toString() === this.paramValue)[0];
              this.projectIdInput.nativeElement.value = this.getProjectId(selected);
              this.changeDetector.detectChanges();
            });
        break;
      case SearchField.ProjectName:
        this.getFilteredProjects('', (p: Project, id: string) => p.name.toLowerCase().indexOf(id) >= 0)
            .pipe(take(1))
            .subscribe(v => {
              const selected: Project = v.filter(el => el.name.toString() === this.paramValue)[0];
              this.projectIdInput.nativeElement.value = this.getProjectName(selected);
              this.changeDetector.detectChanges();
            });
        break;
      case SearchField.ProjectPlatform:
        const selected: TerminalPlatform = this.allPlatforms.filter(el => el.name.toString() === this.paramValue)[0];
        this.projectPlatform.nativeElement.value = this.getPlatformName(selected);
        this.changeDetector.detectChanges();
        break;
      default:
        break;
    }
  }

  get paramValue(): SearchParameterType {
    return this.value.value;
  }

  set paramValue(newValue: SearchParameterType) {
    this.value = {...this.value, value: newValue};
    if (this.value.type.field === SearchField.Client) {
      this.waiverFilterService.currentClientId = newValue;
    }
    this.valueChanged.emit(this.value);
  }

  async onDateChange($event: any) {
    const selectedDate = $event[VALUE_STR];
    if (selectedDate instanceof Date) {
      let offset = 0;
      if (selectedDate.getTimezoneOffset() < 0) {
        offset = Math.abs(selectedDate.getTimezoneOffset());
        selectedDate.setMinutes(offset);
      } else {
        selectedDate.setMinutes(selectedDate.getMinutes() - selectedDate.getTimezoneOffset());
      }
      this.paramValue = selectedDate.toISOString();
    } else {
      this.paramValue = this.utcDate.transform(selectedDate);
    }
  }

  getClientName(client: Client) {
    return client?.merchantName ?? '';
  }

  getProjectId(project: Project): string {
    return project ? `${project.projectId} (${project.name})` : '';
  }

  getProjectName(project: Project): string {
    return project?.name ?? '';
  }

  getPlatformName(platform: TerminalPlatform): string {
    return platform?.name ?? '';
  }

  onClientFocus($event: Event) {
    this.waiverFilterService.refreshAllClients();
    this.filteredClients$ = fromEvent($event.target, 'keyup')
      .pipe(
        map(ev => ev.target[VALUE_STR]),
        startWith(''),
        switchMap(value => this.getFilteredClient(value))
      );
  }

  async onClientBlur($event: FocusEvent) {
    if (!this.paramValue) {
      const allClients = await this.waiverFilterService.allClients$.toPromise();
      const clientName = $event.target[VALUE_STR].toLowerCase();
      const selectedClient = allClients.find(client => client.merchantName.toLowerCase() === clientName);
      if (selectedClient) {
        this.paramValue = selectedClient.id;
      }
    }
  }

  onProjectIdFocus($event: Event) {
    this.waiverFilterService.refreshAllProjects();
    this.setProjectFilter($event.target, (p: Project, id: string) => p.projectId.toLowerCase().indexOf(id) >= 0);
  }

  onProjectNameFocus($event: Event) {
    this.waiverFilterService.refreshAllProjects();
    this.setProjectFilter($event.target, (p: Project, id: string) => p.name.toLowerCase().indexOf(id) >= 0);
  }

  async onProjectBlur($event: FocusEvent) {
    if (!this.paramValue) {
      this.paramValue = $event.target[VALUE_STR];
    }
  }

  private getFilteredClient(value: string): Observable<Array<Client>> {
    const filterValue = value.toLowerCase();
    return this.waiverFilterService.allClients$.pipe(
      map(clients => clients.filter(c => c.merchantName.toLowerCase().indexOf(filterValue) >= 0))
    );
  }

  private setProjectFilter(target: EventTarget, filter: (p: Project, v: string) => boolean) {
    this.filteredProjects$ = fromEvent(target, 'keyup')
      .pipe(
        map(ev => ev.target[VALUE_STR]),
        startWith(''),
        switchMap(value => this.getFilteredProjects(value, filter))
      );
  }

  private getFilteredProjects(value: string, filter: (p: Project, v: string) => boolean): Observable<Array<Project>> {
    const filterValue = value.toLowerCase();
    return this.waiverFilterService.allProjects$.pipe(
      map(projects => projects.filter(p => filter(p, filterValue)))
    );
  }
}
