import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {HostLogMessage} from '@models/hostLogMessage';

@Component({
  selector: 'app-host-log-message',
  templateUrl: './host-log-message.component.html',
  styleUrls: ['./host-log-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HostLogMessageComponent {
  @Input()
  message: HostLogMessage;
}
