import {Injectable, OnDestroy} from '@angular/core';
import {Comment} from '@models/comment';
import {PagedResponse} from '@models/pagedResponse';
import PagingCriteria from '@models/pagingCriteria';
import {ProjectDetail} from '@models/projectDetail';
import {Action, AuthInfo, Resource, Role} from '@models/security';
import {AuthenticationService} from '@services/auth/authentication.service/authentication.service';
import {PermissionsService} from '@services/auth/permissions.service/permissions.service';
import {CommentsService} from '@services/data/comments.service/comments.service';
import {SortDirection} from '@services/data/sortDirection';
import {BehaviorSubject, merge, Observable, of, Subject, Subscription} from 'rxjs';
import {catchError, map, shareReplay, switchMap} from 'rxjs/operators';
import {ICommentsDataSource} from '../../../../../components/comments/iCommentsDataSource';
import {IProjectCommentsDataSourceService} from './iProjectCommentsDataSourceService';

@Injectable()
export class ProjectCommentsDataSourceService implements OnDestroy, IProjectCommentsDataSourceService, ICommentsDataSource {

  private _pagingCriteria = new BehaviorSubject<PagingCriteria>(null);
  private refresh$ = new Subject<void>();

  private _commentResponse$: Observable<PagedResponse<Comment>>;

  private authInfo: AuthInfo;

  project: ProjectDetail;
  private authSubscription: Subscription;

  constructor(private commentsService: CommentsService,
              private permissionsService: PermissionsService,
              authService: AuthenticationService) {
    this.authSubscription = authService.authInfo$.subscribe(authInfo => this.authInfo = authInfo);

    // @ts-ignore
    this._commentResponse$ = merge(this.refresh$, this._pagingCriteria).pipe(
      switchMap(_ => {
        const pagingInfo = this._pagingCriteria.value;
        return this.project ? this.commentsService.getCommentsForProject(this.project.id,
                                                                         pagingInfo.sort,
                                                                         pagingInfo.direction,
                                                                         pagingInfo.pageIndex,
                                                                         pagingInfo.pageSize
          ) : of(null);
      }),
      catchError(_ => of<PagedResponse<Comment>>({data: [], totalResults: 0, totalPages: 0, pageSize: 10, pageNumber: 0})),
      shareReplay()
    );
    this.setPaging('creationDate', 'desc', 0, 1);
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
  }

  get comments$(): Observable<Comment[]> {
    return this._commentResponse$.pipe(map(res => res?.data ?? []));
  }

  get commentCount$(): Observable<number> {
    return this._commentResponse$.pipe(map(res => res?.totalResults ?? 0));
  }

  async setProject(project: ProjectDetail) {
    this.project = project;
    this.refresh$.next();
  }

  setPaging(sort: string, direction: SortDirection, pageIndex: number, pageSize: number) {
    this._pagingCriteria.next({sort, direction, pageIndex, pageSize});
  }


  async addComment(comment: string) {
    await this.commentsService.createProjectComment(this.project.id, {
      id: 0,
      author: null,
      commentText: comment,
      creationDate: new Date(),
      projectId: this.project.id,
      lastModifiedDate: undefined
    }).toPromise();
    this.refresh$.next();
  }

  async updateComment(comment: Comment) {
    await this.commentsService.updateProjectComment(this.project.id,
      {...comment, lastModifiedDate: new Date()}).toPromise();
    this.refresh$.next();
  }

  async deleteComment(comment: Comment) {
    await this.commentsService.deleteProjectComment(this.project.id, comment).toPromise();
    this.refresh$.next();
  }

  canEditComment(comment: Comment): Observable<boolean> {
    return this.permissionsService.hasPermission([Resource.project, Action.edit]).pipe(
      map((canDelete) => canDelete &&
        (!this.isClient() && this.authInfo?.userId === comment.author?.id) || this.isAdmin())
    );
  }

  canDeleteComment(comment: Comment): Observable<boolean> {
    return this.canEditComment(comment);
  }

  canCreateComments(): Observable<boolean> {
    return this.permissionsService.hasPermission([Resource.project, Action.edit]).pipe(
      map(canCreate => canCreate && !this.isClient())
    );
  }

  private isAdmin(): boolean {
    return this.authInfo?.role === Role.admin;
  }

  private isClient(): boolean {
    return this.authInfo?.role === Role.client ||
      this.authInfo?.role === Role.thirdPartyClient;
  }
}
