export enum Role {
  client = 'CLIENT',
  analyst = 'ANALYST',
  gatekeeper = 'GATEKEEPER',
  manager = 'MANAGER',
  iccManager = 'ICC_MANAGER',
  admin = 'ADMIN',
  integratedPartner = 'INTEGRATED_PARTNER',
  ipManager = 'IP_MANAGER',
  thirdPartyClient= 'THIRD_PARTY_CLIENT'
}

