import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NewPasswordValidationService} from '@services/auth/new-password-validation.service/new-password-validation.service';
import {Subscription} from 'rxjs';
import {Icon} from '../svg/svg.component';

export interface ChangePasswordFormResult {
  currentPassword: string;
  newPassword: string;
}

@Component({
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss']
})
export class ChangePasswordFormComponent implements AfterViewInit, OnDestroy {
  @ViewChild('currentPasswordField') currentPasswordField: ElementRef;

  @Input() username: string = '';

  @Output() passwordChangeClick = new EventEmitter<ChangePasswordFormResult>();

  minimumPasswordLength = this.newPasswordValidationService.minimumPasswordLength;
  changePasswordFormGroup: UntypedFormGroup;

  iconName: Icon = Icon.CHANGE_PW;

  private subscriptions = new Subscription();

  constructor(formBuilder: UntypedFormBuilder,
              private newPasswordValidationService: NewPasswordValidationService) {
    this.changePasswordFormGroup = formBuilder.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, this.isValidPassword.bind(this),
        this.checkDuplicateOfCurrentPassword.bind(this)]],
      confirmPassword: ['', [Validators.required, this.checkDuplicatePassword.bind(this)]]
    });

    this.subscriptions.add(this.currentPassword.valueChanges.subscribe(() => {
      if (this.newPassword.value !== '') {
        this.newPassword.updateValueAndValidity();
      }
    }));

    this.subscriptions.add(this.newPassword.valueChanges.subscribe(() => {
        if (this.confirmPassword.value !== '') {
          this.confirmPassword.updateValueAndValidity();
        }
      }
    ));
  }

  get currentPassword(): AbstractControl | null {
    return this.changePasswordFormGroup?.get('currentPassword');
  }

  get newPassword(): AbstractControl | null {
    return this.changePasswordFormGroup?.get('newPassword');
  }

  get confirmPassword(): AbstractControl | null {
    return this.changePasswordFormGroup?.get('confirmPassword');
  }

  private isValidPassword(control: AbstractControl): { [key: string]: any } | null {
    return this.newPasswordValidationService.validatePassword(this.username, control.value);
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.currentPasswordField.nativeElement.focus(), 0);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private checkDuplicateOfCurrentPassword(): { [key: string]: any } | null {
    if (this.currentPassword?.valid && this.newPassword?.valid) {
      return this.currentPassword.value === this.newPassword.value ? {sameAsOriginal: true} : null;
    }

    return null;
  }

  private checkDuplicatePassword(): { [key: string]: any } | null {
    if (this.newPassword?.valid && this.confirmPassword?.valid) {
      return this.newPassword.value !== this.confirmPassword.value ? {notMatched: true} : null;
    }
    return null;
  }

  onChangePasswordClicked() {
    this.passwordChangeClick.emit({
      currentPassword: this.currentPassword.value,
      newPassword: this.newPassword.value
    });
  }
}
