import {Role} from './role';
import {TOTPDeviceStatus} from "@models/security/user";

export interface AuthInfo {
    username: string;
    userId: number;
    role: Role;
    token: string;
    refreshToken: string;
    firstName: string;
    lastName: string;
    merchantId: number;
    permissions: string[];
    totpDeviceStatus?: TOTPDeviceStatus;
}
