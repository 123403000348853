import {CertQuestion} from '@models/intake-forms';
import {Renderer} from './renderer';

const AfterQuestionSpace = 8;
const CheckboxIndent = 14;

const defaultCheckedValue = 'YES';

export class CheckboxRenderer extends Renderer {
  constructor(q: CertQuestion) {super(q);}

  get answer(): boolean {
    if (this.question.PossibleValues && this.question.PossibleValues.string.length) {
      return (this.question.Result.string === this.question.PossibleValues.string[0]);
    }
    return this.question.Result.string === defaultCheckedValue;
  }

  layout(doc: any) {
    this.height = doc.getWrappedTextSize(this.question.QuestionText).height + AfterQuestionSpace;
  }

  render(doc: any, ypos: number) {
    doc.drawWrappedText(this.question.QuestionText, ypos, CheckboxIndent);

    doc.drawRect(doc.LeftPageMargin, ypos - 6, 8, 8, '#a0a0a0');
    if (this.answer) {
      doc.drawRect(doc.LeftPageMargin + 2, ypos - 4, 4, 4, '#000000', '#000000');
    }
  }
}
