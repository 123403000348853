import {Pipe, PipeTransform} from '@angular/core';
import {TOTPDeviceStatus} from "@models/security/user";

@Pipe({
  name: 'mfaDeviceStatus'
})
export class MfaDeviceStatusDescriptionPipe implements PipeTransform {
  transform(status: TOTPDeviceStatus): string | undefined {
    switch (status) {
      case TOTPDeviceStatus.NONE: {
        return 'No Authenticator Configured';
      }
      case TOTPDeviceStatus.UNVERIFIED: {
        return 'Authenticator Unverified';
      }
      case TOTPDeviceStatus.VERIFIED: {
        return 'Authenticator Configured';
      }
    }
  }
}
