<mat-form-field>
  <mat-label>{{label}}</mat-label>
  <mat-select [(ngModel)]="selectedId" [required]="required" [disabled]="disabled">
    <mat-option *ngFor="let user of users" [value]="user.userId">
      {{user.username}}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="errorMessage">
    {{errorMessage}}
  </mat-error>

</mat-form-field>
