import {Injectable} from '@angular/core';
import {isEmpty, xor} from 'lodash';
import {Node} from '../../models';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  validateCustomOrder<T, K extends Node<T>>(node: K, customColumnOrder: Array<keyof T> & string[]): { valid: boolean, xor: string[] } {
    const xorN = xor(Object.keys(node.value), customColumnOrder);
    return {
      valid: isEmpty(xorN),
      xor: xorN
    };
  }

}
