import {Injectable} from '@angular/core';
import {ClientTypeDescriptionService} from '../../../data/client-type-description.service/client-type-description.service';
import {ClientsService} from '../../../data/clients.service/clients.service';

export interface IClientInfoTableProviderService {renderInfo(doc: any, clientId: number, ypos: number): Promise<void>;}

@Injectable()
export class ClientInfoTableProviderService implements IClientInfoTableProviderService {

  constructor(private clientService: ClientsService,
              private clientTypeDescriptionService: ClientTypeDescriptionService) { }

  async renderInfo(doc: any, clientId: number, ypos: number) {
    const clientInfo = await this.clientService.getClient(clientId).toPromise();
    doc.autoTable({
      head: [{clientName: 'Client Name', clientId: 'Client ID', clientRef: 'Client Ref', clientType: 'Client Type'}],
      headStyles: {
        fillColor: [230, 230, 230],
        fontSize: 9,
        textColor: [80, 80, 80]
      },
      body: [[clientInfo.merchantName, clientInfo.companyId, clientInfo.merchantRef, this.clientTypeDescriptionService.getDescription(clientInfo.clientType)]],
      startY: ypos,
      theme: 'grid',
    });
  }
}
