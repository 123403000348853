import {ClientAddress} from './clientAddress';
import {ClientType} from './clientType';

export interface Client {
    id?: number;
    merchantName: string;
    merchantRef: string;
    website: string;
    adminName: string;
    adminEmail: string;
    companyId: string;
    clientType: ClientType;
    primaryName: string;
    primaryEmail: string;
    secondaryName: string;
    secondaryEmail: string;
    primaryNumber: string;
    secondaryNumber: string;
    clientAddress: ClientAddress;
    disabled: boolean;
}
