import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-hex-display',
  templateUrl: './hex-display.component.html',
  styleUrls: ['./hex-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HexDisplayComponent  {
  valuesToDisplay = Array<string>();

  @Input()
  set value(newValue: string) {
    newValue = newValue?.trim();
    if (newValue?.length > 0) {
      if ((newValue.length % 2) === 1 ){ newValue = '0' + newValue; }
      while (newValue.length) {
        this.valuesToDisplay.push(newValue.substr(0, 2));
        newValue = newValue.substr(2);
      }
    }
  }
}
