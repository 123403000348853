import {MatDatetimePickerInputEvent, NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {NGX_DATE_TIME_FORMAT_DATE_ONLY} from '@constants/NgxDateTimeFormat';
import {AssignedUser} from '@models/assignedUser';
import {Client} from '@models/clients';
import {allHostMessageSpecifications, allTerminalPlatforms} from '@models/constants';
import {Project} from '@models/project';
import {AllPhases} from '@models/projectPhase';
import {AllStates} from '@models/projectState';
import {User} from '@models/security/user';
import {AllTestTools} from '@models/TestTool';
import {EstDateFormatPipe} from '@services/pipes/est-date-format.pipe';
import {fromEvent, Observable} from 'rxjs';
import {map, startWith, switchMap, take} from 'rxjs/operators';
import {BaseControlClass} from '../../base-control.class';
import {ProjectFilterService} from '../project-filter.service';
import ProjectFilter from './projectFilter';
import SearchField = ProjectFilter.SearchField;
import SearchParameter = ProjectFilter.SearchParameter;
import SearchParameterType = ProjectFilter.SearchParameterType;

const SEARCH_PARAMETER_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ProjectFilterParameterComponent),
  multi: true
};

const VALUE_STR = 'value';

type ParamTypes = User | Client | Project;

enum ValueField {
  username,
  clientName,
  projectId,
  projectName
}

@Component({
  selector: 'app-project-filter-parameter',
  templateUrl: './project-filter-parameter.component.html',
  styleUrls: ['./project-filter-parameter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    SEARCH_PARAMETER_VALUE_ACCESSOR,
    {
      provide: NGX_MAT_DATE_FORMATS,
      useValue: NGX_DATE_TIME_FORMAT_DATE_ONLY
    }
  ]
})
export class ProjectFilterParameterComponent extends BaseControlClass<SearchParameter> implements AfterViewInit {

  constructor(private projectFilterService: ProjectFilterService,
              private changeDetector: ChangeDetectorRef,
              private estDate: EstDateFormatPipe) {
    super();
  }

  get paramValue(): SearchParameterType {
    return this.value.value;
  }

  set paramValue(newValue: SearchParameterType) {
    this.value = {...this.value, value: newValue};
    if (this.value.type.field === SearchField.Client) {
      this.projectFilterService.currentClientId = newValue;
    }
    this.valueChanged.emit(this.value);
  }
  @Input() params: SearchParameter;
  @Input() sandbox: boolean;
  @Output() delete = new EventEmitter();
  @Output() valueChanged = new EventEmitter<SearchParameter>();

  SearchField = SearchField;
  AllPhases = AllPhases;
  AllStates = AllStates;
  allHostMessageSpecifications = allHostMessageSpecifications;
  allPlatforms = allTerminalPlatforms;
  filteredProjects$: Observable<Array<Project>>;
  filteredClients$: Observable<Array<Client>>;
  filteredClientLeads$: Observable<Array<User>>;
  filteredCertLeads$: Observable<Array<User>>;
  filteredBackupAnalysts$: Observable<Array<User>>;
  filteredIpLeads$: Observable<Array<User>>;
  AllTestTools = AllTestTools;

  @ViewChild('itemContainer') container: ElementRef;
  @ViewChild('ipLead') ipLeadInput: ElementRef;
  @ViewChild('client') clientInput: ElementRef;
  @ViewChild('clientLead') clientLeadInput: ElementRef;
  @ViewChild('certLead') certLeadInput: ElementRef;
  @ViewChild('backup') backupInput: ElementRef;
  @ViewChild('projectId') projectIdInput: ElementRef;
  @ViewChild('projectName') projectNameInput: ElementRef;

  private static matchesUser(value: string, user: User) {
    return user.username.toLowerCase().indexOf(value) >= 0 ||
      user.firstName.toLowerCase().indexOf(value) >= 0 ||
      user.lastName.toLowerCase().indexOf(value) >= 0;
  }

  ngAfterViewInit() {
    switch (this.params?.type.field) {
      case SearchField.BackupAnalystId:
        this.getFilteredBackup('').pipe(take(1))
          .subscribe(v => this.restoreValue(v, this.backupInput, ValueField.username));
        break;
      case SearchField.CertLeadId:
        this.getFilteredCertLeads('').pipe(take(1))
          .subscribe(v => this.restoreValue(v, this.certLeadInput, ValueField.username));
        break;
      case SearchField.Client:
        this.getFilteredClient('').pipe(take(1))
          .subscribe(v => this.restoreValue(v, this.clientInput, ValueField.clientName));
        break;
      case SearchField.ClientLeadId:
        this.getFilteredClientLeads('').pipe(take(1))
          .subscribe(v => this.restoreValue(v, this.clientLeadInput, ValueField.username));
        break;
      case SearchField.IpLeadId:
        this.getFilteredIptLeads('').pipe(take(1))
          .subscribe(v => this.restoreValue(v, this.ipLeadInput, ValueField.username));
        break;
      case SearchField.ProjectId:
        this.getFilteredProjects('', (p: Project, id: string) => p.projectId.toLowerCase().indexOf(id) >= 0)
          .pipe(take(1))
          .subscribe(v => this.restoreValue(v, this.projectIdInput, ValueField.projectId));
        break;
      case SearchField.ProjectName:
        this.getFilteredProjects('', (p: Project, name: string) => p.name.toLowerCase().indexOf(name) >= 0)
          .pipe(take(1))
          .subscribe(v => this.restoreValue(v, this.projectNameInput, ValueField.projectName));
        break;
      default:
        // Only required for User Role filters. Other filters have preloaded data.
        break;
    }
  }

  private restoreValue(v: ParamTypes[], field: ElementRef, valueField: ValueField): void {
    if (field) {
      let foundItem: ParamTypes;
      if (valueField === ValueField.projectId){
        const p = v as Project[];
        foundItem = p.filter(el => {
          return el.projectId.toString() === this.paramValue;
        })[0];
      } else {
        foundItem  = v.filter(el => {
          return el.id.toString() === this.paramValue;
        })[0];
      }

      switch (valueField){
        case ValueField.username:
          const user = foundItem as User;
          field.nativeElement.value = this.getUserName(user);
          break;
        case ValueField.clientName:
          const client = foundItem as Client;
          field.nativeElement.value = this.getClientName(client);
          break;
        case ValueField.projectId:
          const projectId = foundItem as Project;
          field.nativeElement.value = this.getProjectId(projectId);
          break;
        case ValueField.projectName:
          const projectName = foundItem as Project;
          field.nativeElement.value = this.getProjectName(projectName);
          break;
      }
      this.changeDetector.detectChanges();
    }
  }

  onClientNameFocus($event: Event) {
    this.projectFilterService.refreshAllClients();
    this.filteredClients$ = fromEvent($event.target, 'keyup')
      .pipe(
        map(ev => ev.target[VALUE_STR]),
        startWith(''),
        switchMap(value => this.getFilteredClient(value))
      );
  }

  async onClientNameBlur($event: FocusEvent) {
    if (!this.paramValue) {
      const allClients = await this.projectFilterService.allClients$.toPromise();
      const clientName = $event.target[VALUE_STR].toLowerCase();
      const selectedClient = allClients.find(client => client.merchantName.toLowerCase() === clientName);
      if (selectedClient) {
        this.paramValue = selectedClient.id;
      }
    }
  }

  async onDateChange($event: MatDatetimePickerInputEvent<any>) {
    if ($event[VALUE_STR] !== null) {
      const selectedDate = $event[VALUE_STR];
      this.paramValue = this.estDate.transform(selectedDate);
    }
  }

  keepLocalTime(): boolean {
    return [
      SearchField.AfterStartDate,
      SearchField.BeforeStartDate,
      SearchField.AfterCreationDate,
      SearchField.BeforeCreationDate,
    ].includes(this._value.type.field);
  }

  getClientName(client: Client) {
    return client?.merchantName ?? '';
  }

  getUserName(user: AssignedUser | User): string {
    return user?.username ?? '';
  }

  getProjectName(project: Project): string {
    return project?.name ?? '';
  }

  getProjectId(project: Project): string {
    return project ? `${project.projectId} (${project.name})` : '';
  }

  onProjectNameFocus($event: Event) {
    this.projectFilterService.refreshAllProjects(this.sandbox);
    this.setProjectFilter($event.target, (p: Project, name: string) => p.name.toLowerCase().indexOf(name) >= 0);
  }

  async onProjectNameBlur($event: FocusEvent) {
    if (!this.paramValue) {
      const allProjects = await this.projectFilterService.allProjects$.toPromise();
      const projectName = $event.target[VALUE_STR].toLowerCase();
      const selectedProject = allProjects.find(proj => proj.name.toLowerCase() === projectName);
      if (selectedProject) {
        this.paramValue = selectedProject.id;
      }
    }
  }

  onProjectIdFocus($event: Event) {
    this.setProjectFilter($event.target, (p: Project, id: string) => p.projectId.toLowerCase().indexOf(id) >= 0);
  }

  async onProjectIdBlur($event: FocusEvent) {
    if (!this.paramValue) {
      this.paramValue = $event.target[VALUE_STR];
    }
  }

  onClientLeadFocus($event: FocusEvent) {
    this.filteredClientLeads$ = fromEvent($event.target, 'keyup')
      .pipe(
        map(ev => ev.target[VALUE_STR]),
        startWith(''),
        switchMap(value => this.getFilteredClientLeads(value))
      );
  }

  async onClientLeadBlur($event: FocusEvent) {
    if (!this.paramValue) {
      const allClientLeadUsers = await this.projectFilterService.allClientLeadUsers$.pipe(take(1)).toPromise();
      const userName = $event.target[VALUE_STR].toLowerCase();
      const selectedClientLead = allClientLeadUsers.find(user => user.username.toLowerCase() === userName);
      if (selectedClientLead) {
        this.paramValue = selectedClientLead.id;
      }
    }
  }

  onCertLeadFocus($event: FocusEvent) {
    this.filteredCertLeads$ = fromEvent($event.target, 'keyup')
      .pipe(
        map(ev => ev.target[VALUE_STR]),
        startWith(''),
        switchMap(value => this.getFilteredCertLeads(value))
      );
  }

  async onCertLeadBlur($event: FocusEvent) {
    if (!this.paramValue) {
      const allCertLeadUsers = await this.projectFilterService.allLeadUsers$.toPromise();
      const userName = $event.target[VALUE_STR].toLowerCase();
      const selectedCertLead = allCertLeadUsers.find(user => user.username.toLowerCase() === userName);
      if (selectedCertLead) {
        this.paramValue = selectedCertLead.id;
      }
    }
  }

  onBackupAnalystFocus($event: FocusEvent) {
    this.filteredBackupAnalysts$ = fromEvent($event.target, 'keyup')
      .pipe(
        map(ev => ev.target[VALUE_STR]),
        startWith(''),
        switchMap(value => this.getFilteredBackup(value))
      );
  }

  async onBackupAnalystBlur($event: FocusEvent) {
    if (!this.paramValue) {
      const allBackupUsers = await this.projectFilterService.allBackupUsers$.toPromise();
      const userName = $event.target[VALUE_STR].toLowerCase();
      const selectedBackupAnalyst = allBackupUsers.find(user => user.username.toLowerCase() === userName);
      if (selectedBackupAnalyst) {
        this.paramValue = selectedBackupAnalyst.id;
      }
    }
  }

  private setProjectFilter(target: EventTarget, filter: (p: Project, v: string) => boolean) {
    this.filteredProjects$ = fromEvent(target, 'keyup')
      .pipe(
        map(ev => ev.target[VALUE_STR]),
        startWith(''),
        switchMap(value => this.getFilteredProjects(value, filter))
      );
  }

  private getFilteredProjects(value: string, filter: (p: Project, v: string) => boolean): Observable<Array<Project>> {
    const filterValue = value.toLowerCase();
    return this.projectFilterService.allProjects$.pipe(
      map(projects => projects.filter(p => filter(p, filterValue)).reverse())
    );
  }

  private getFilteredClient(value: string): Observable<Array<Client>> {
    const filterValue = value.toLowerCase();
    return this.projectFilterService.allClients$.pipe(
      map(clients => clients.filter(c => c.merchantName.toLowerCase().indexOf(filterValue) >= 0))
    );
  }

  private getFilteredClientLeads(value: string): Observable<Array<User>> {
    const filterValue = value.toLowerCase();
    return this.projectFilterService.allClientLeadUsers$.pipe(
      map(users => users.filter(u => ProjectFilterParameterComponent.matchesUser(filterValue, u))));
  }

  private getFilteredCertLeads(value: string) {
    const filterValue = value.toLowerCase();
    return this.projectFilterService.allLeadUsers$.pipe(
      map(users => users.filter(u => ProjectFilterParameterComponent.matchesUser(filterValue, u))));
  }

  onIpLeadFocus($event: FocusEvent) {
    this.filteredIpLeads$ = fromEvent($event.target, 'keyup')
      .pipe(
        map(ev => ev.target[VALUE_STR]),
        startWith(''),
        switchMap(value => this.getFilteredIptLeads(value))
      );
  }

  async onIpLeadBlur($event: FocusEvent) {
    if (!this.paramValue) {
      const allIpLeadUsers = await this.projectFilterService.allIpLeadUsers$.toPromise();
      const userName = $event.target[VALUE_STR].toLowerCase();
      const selectedIpLead = allIpLeadUsers.find(user => user.username.toLowerCase() === userName);
      if (selectedIpLead) {
        this.paramValue = selectedIpLead.id;
      }
    }
  }

  private getFilteredIptLeads(value: string) {
    const filterValue = value.toLowerCase();
    return this.projectFilterService.allIpLeadUsers$.pipe(
      map(users => users.filter(u => ProjectFilterParameterComponent.matchesUser(filterValue, u))));
  }

  private getFilteredBackup(value: string) {
    const filterValue = value.toLowerCase();
    return this.projectFilterService.allBackupUsers$.pipe(
      map(users => users.filter(u => ProjectFilterParameterComponent.matchesUser(filterValue, u))));
  }
}

