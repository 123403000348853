export enum CompletedProjectsMonth {
  January = "JANUARY",
  February = "FEBRUARY",
  March = "MARCH",
  April = "APRIL",
  May = "MAY",
  June = "JUNE",
  July = "JULY",
  August = "AUGUST",
  September = "SEPTEMBER",
  October = "OCTOBER",
  November = "NOVEMBER",
  December = "DECEMBER"
}

export const AllCompletedProjectMonths = [
  CompletedProjectsMonth.January,
  CompletedProjectsMonth.February,
  CompletedProjectsMonth.March,
  CompletedProjectsMonth.April,
  CompletedProjectsMonth.May,
  CompletedProjectsMonth.June,
  CompletedProjectsMonth.July,
  CompletedProjectsMonth.August,
  CompletedProjectsMonth.September,
  CompletedProjectsMonth.October,
  CompletedProjectsMonth.November,
  CompletedProjectsMonth.December
]

export const CompletedProjectMonthDescriptions: Record<CompletedProjectsMonth, string> = {
  [CompletedProjectsMonth.January]: 'January',
  [CompletedProjectsMonth.February]: 'February',
  [CompletedProjectsMonth.March]: 'March',
  [CompletedProjectsMonth.April]: 'April',
  [CompletedProjectsMonth.May]: 'May',
  [CompletedProjectsMonth.June]: 'June',
  [CompletedProjectsMonth.July]: 'July',
  [CompletedProjectsMonth.August]: 'August',
  [CompletedProjectsMonth.September]: 'September',
  [CompletedProjectsMonth.October]: 'October',
  [CompletedProjectsMonth.November]: 'November',
  [CompletedProjectsMonth.December]: 'December'
}
export type CompletedProjectsReport = {
  [year: string]: {
    [month: string]: number
  }
}
