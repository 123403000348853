import {Pipe, PipeTransform} from '@angular/core';
import {est_timezone, momentDateTimeFormat} from '@models/constants';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'estDateTime'
})
export class EstDateTimePipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    const date = moment(value);
        return date.isValid() ? date.tz(est_timezone).format(momentDateTimeFormat) : value;
  }
}

