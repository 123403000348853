import {CertQuestion, TypeOfQuestion} from '@models/intake-forms';
import {CheckboxRenderer} from './checkboxRenderer';
import {LabelRenderer} from './labelRenderer';
import {LineRenderer} from './lineRenderer';
import {MultiSelectCheckboxRenderer} from './multiSelectCheckboxRenderer';
import {Renderer} from './renderer';
import {TextBoxAndDropDownRenderer} from './textBoxAndDropDownRenderer';

const renderFactories: Record<TypeOfQuestion, (q: CertQuestion) => Renderer> = {
  [TypeOfQuestion.Label]: (q) => new LabelRenderer(q),
  [TypeOfQuestion.Line]: (q) => new LineRenderer(q),
  [TypeOfQuestion.TextBox]: (q) => new TextBoxAndDropDownRenderer(q),
  [TypeOfQuestion.MultiLineTextBox]: (q) => new TextBoxAndDropDownRenderer(q),
  [TypeOfQuestion.Checkbox]: (q) => new CheckboxRenderer(q),
  [TypeOfQuestion.DropDown]: (q) => new TextBoxAndDropDownRenderer(q),
  [TypeOfQuestion.MultiSelectCheckbox]: (q) => new MultiSelectCheckboxRenderer(q)
};


export class RendererFactory {
  getRenderers(questions: Array<CertQuestion>): Array<Renderer> {
    return questions.map<Renderer>(q => renderFactories[q.TypeOfQuestion](q));
  }
}
