import {Pipe, PipeTransform} from '@angular/core';
import {ProjectPhaseDescriptionService} from '../data/project-phase-description.service/project-phase-description.service';

@Pipe({
  name: 'projectPhaseDescription'
})
export class ProjectPhaseDescriptionPipe implements PipeTransform {

  constructor(private projectPhaseDescriptionService: ProjectPhaseDescriptionService) {}
  transform(value: any, ...args: unknown[]): unknown {
    return this.projectPhaseDescriptionService.getDescription(value);
  }
}
