import {Pipe, PipeTransform} from '@angular/core';
import {TestToolDescriptionService} from '../data/test-tool-description.service';

@Pipe({
  name: 'testToolDescription'
})
export class TestToolDescriptionPipe implements PipeTransform {

  constructor(private testToolDescriptionService: TestToolDescriptionService) {}
  transform(value: any, ...args: unknown[]): unknown {
    return this.testToolDescriptionService.getDescription(value);
  }

}
