<ng-container [formGroup]="form">
  <div class="terminal-editor-container" formArrayName="projectTerminalIds" [style.height]="height">
    <div *ngIf="editable" class="editor-prompt">
      <p>Add terminal IDs and merchant IDs using the button below. Fill-in the IDs in the fields that are provided.</p>
      <button mat-button mat-raised-button (click)="addId()">Add Terminal ID/Merchant ID</button>
    </div>
    <div class="terminal-id-container">
      <div *ngFor="let terminal of terminalIds?.controls; let i = index">
        <div class="terminal-row">
          <div style="width: 100%">
            <app-terminal-id-edit type="text" [formControlName]="i"
                                  (blur)="updateTerminalValidity(terminal, $event)"
                                  [readonly]="!editable">
            </app-terminal-id-edit>
            <div class="error" *ngIf="terminal.hasError('requiredTerminal')">
              Please specify the terminal ID
            </div>
            <div class="error" *ngIf="terminal.hasError('tooShortMerchant')">
              Merchant ID must have at least {{minMerchantLength}} characters.
            </div>
            <div class="error" *ngIf="terminal.hasError('tooLongMerchant')">
              Merchant ID must have no more than {{maxMerchantLength}} characters.
            </div>
            <div class="error" *ngIf="terminal.hasError('tooShortTerminal')">
              Terminal ID must have at least {{minTerminalLength}} characters.
            </div>
            <div class="error" *ngIf="terminal.hasError('tooLongTerminal')">
              Terminal ID must have no more than {{maxTerminalLength}} characters.
            </div>
            <div class="error" *ngIf="terminal.hasError('requiredMerchant')">
              Please specify the merchant ID
            </div>
            <div class="error" *ngIf="terminal.hasError('alreadyExists')">
              This terminal ID/merchant ID combination is already being used by an active project
            </div>
            <div class="error" *ngIf="terminal.hasError('alreadyEntered')">
              You have already used this terminal ID/merchant ID combination in this project
            </div>
          </div>
          <app-overlay-button imageUrl="assets/icons/delete.svg"
                              style="width: 36px; margin-left: 16px;"
                              (click)="deleteId(i)"
                              *ngIf="editable"
                              rolloverImageUrl="assets/icons/delete-hilight.svg"></app-overlay-button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
