import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EmvTag} from '@models/emv';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import IEmvTagService from './iEmvTagService';

@Injectable()
export class EmvTagService implements IEmvTagService {
  private tagsDataUrl = '/assets/emv-tags.json';
  private tags: Observable<EmvTag[]>;

  constructor(http: HttpClient) {
    this.tags = http.get<EmvTag[]>(this.tagsDataUrl).pipe(
      map(tags => tags.map<EmvTag>(t => ({...t, code: t.code.toUpperCase()}))),
      shareReplay());
  }

  findEmvTag(code: string): Observable<Array<EmvTag>> {
    if (code?.toUpperCase) { code = code.toUpperCase();}
    return this.tags.pipe(map(tags => tags.filter(t => t.code.includes(code))));
  }
}
