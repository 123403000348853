import {Pipe, PipeTransform} from '@angular/core';
import {ProjectStateDescriptionService} from '../data/project-state-description.service/project-state-description.service';

@Pipe({
  name: 'projectStateDescription'
})
export class ProjectStateDescriptionPipe implements PipeTransform {

  constructor(private projectStateDescriptionService: ProjectStateDescriptionService) {}
  transform(value: any, ...args: unknown[]): unknown {
    return this.projectStateDescriptionService.getDescription(value);
  }
}
