import {Injectable} from '@angular/core';
import {INewPasswordValidationService} from './iNewPasswordValidationService';

const MINIMUM_PASSWORD_LENGTH = 9;

// Password special symbols from OWASP guidelines (https://owasp.org/www-community/password-special-characters)
const SpecialSymbols = " !\"#$%&'()*+,-./:;<=>?@[\]^_`{|}~".replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');

const ComplexityRegex = new RegExp(`^(?=.*[0-9])(?=.*[${SpecialSymbols}])(?=.*[a-z])(?=.*[A-Z]).*$`);

@Injectable()
export class NewPasswordValidationService implements INewPasswordValidationService {

  get minimumPasswordLength() {
    return MINIMUM_PASSWORD_LENGTH;
  }

  validatePassword(username: string, password: string): { [key: string]: any } | null {
    if (password === '') {
      return {required: true};
    }

    if (password.length < MINIMUM_PASSWORD_LENGTH) {
      return {tooShort: true};
    }

    if (!ComplexityRegex.test(password)) {
      return {notComplex: true};
    }

    if (username && this.failsUsernameCheck(username, password)) {
      return {cannotIncludeUsername: true};
    }

    return null;
  }

  private failsUsernameCheck(username: string, password: string) {
    if (password.includes(username)) {
      return true;
    }

    const atPosition = username.indexOf('@');
    if (atPosition >= 0) {
      const subUsername = username.substr(0, atPosition);
      if (password.includes(subUsername)) {
        return true;
      }
    }

    return false;
  }
}
