import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Params} from '@angular/router';
import {PagedResponse} from '@models/pagedResponse';
import {TestPlan} from '@models/testPlan';
import {TestCase} from '@models/tests/testCase';
import {ValidationCategory} from '@models/ValidationCategory';
import {Observable} from 'rxjs';
import {AppConfigService} from '../../configuration/app-config.service/app-config.service';
import {ITestPlanService} from './iTestPlanService';

@Injectable()
export class TestPlanService implements ITestPlanService {
  private readonly testPlanUrl: string;
  private readonly testCaseUrl: string;

  constructor(private readonly http: HttpClient,
              private readonly configService: AppConfigService) {
    this.testPlanUrl = configService.getRestUrl('/testplans');
    this.testCaseUrl = configService.getRestUrl('/testcases');
  }

  activateTestPlan(testPlanId: number): Observable<TestPlan> {
    return this.http.put(`${this.testPlanUrl}/${testPlanId}/activate`, {});
  }

  createTestPlan(testPlan: TestPlan): Observable<TestPlan> {
    return this.http.post(`${this.testPlanUrl}`, testPlan);
  }

  deactivateTestPlan(testPlanId: number): Observable<TestPlan> {
    return this.http.put(`${this.testPlanUrl}/${testPlanId}/deactivate`, {});
  }

  getTestPlan(testPlanId: number): Observable<TestPlan> {
    return this.http.get(`${this.testPlanUrl}/${testPlanId}`);
  }

  getTestPlans(): Observable<PagedResponse<TestPlan>> {
    const params = new HttpParams().set('size', String(999999));
    return this.http.get<PagedResponse<TestPlan>>(this.testPlanUrl, {params});
  }

  updateTestPlan( testPlan: TestPlan): Observable<TestPlan> {
    return this.http.put(`${this.testPlanUrl}/${testPlan.id}`, testPlan);
  }

  updateTestCase( testCase: TestCase): Observable<TestCase> {
    return this.http.put( `${this.testCaseUrl}/${testCase.id}`, testCase);
  }

  updateTestCases(testPlanId: number, testCaseDtos: TestCase[]): Observable<TestCase> {
    return this.http.put( `${this.testPlanUrl}/${testPlanId}/testcases`,
      testCaseDtos);
  }

  deactivateTestCase(testCaseId: number): Observable<TestCase>{
    return this.http.put(`${this.testCaseUrl}/${testCaseId}/deactivate`, {});
  }

  updateValidationCategories(testCaseId: number, name: string, validationCategorySet: ValidationCategory[]): Observable<TestCase> {
    return this.http.put(`${this.testCaseUrl}/${testCaseId}/assignValidation`, {name, validationCategorySet});
  }

  updateVerbiage(testPlanId: number): Observable<any> {
    return this.http.post(`${this.testPlanUrl}/${testPlanId}/verbiage`, {});
  }

  activateTestCase(testCaseId: number): Observable<any> {
    return this.http.put(`${this.testCaseUrl}/${testCaseId}/activate`, {});
  }

  uploadTestCases(testPlanId: number, file: File, httpParams: Params = {}): Observable<any> {
    const form: FormData = new FormData();

    form.append('file', file);

    return this.http.post(`${this.testPlanUrl}/${testPlanId}/testcases`, form, httpParams);
  }
}
