import {Client} from '../clients';
import {Role} from './role';

export enum UserState {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED',
  DeactivateClients = 'DEACTIVATED_CLIENTS'
}

export enum TOTPDeviceStatus {
  NONE = 'NONE',
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED'
}
/**
 * System User
 */
export interface User {
  id?: number;
  firstName?: string;
  lastName?: string;
  password?: string;
  role?: Role;
  username?: string;
  lastSuccessfulLogin?: Date;
  merchants: Client[];
  global: boolean;
  state: UserState;
  eid?: string;
  lanId?: string;
  startDate?: Date;
  totpDeviceStatus: TOTPDeviceStatus;
}
