export enum Resource {
  projectFile = 'project_file',
  projectTestPlan = 'project_test_plan',
  intakeForm = 'intake_form',
  user = 'user',
  client = 'client',
  project = 'project',
  hostlog = 'hostlog',
  cardlog = 'cardlog',
  testplan = 'testplan',
  loa = 'letter_of_approval',
  testCaseVerbiage = 'test_case_verbiage',
  folder = 'folder',
  knowledgeFile = 'knowledge_file',
  projectPhase = 'project_phase',
  iccNotification = 'icc_notification',
  sandboxProject = 'sandbox_project',
  hostLogTransaction = 'hostlogtransaction',
  internalProjectFile = 'internal_project_file',
  mfadevice = 'mfadevice'
}
