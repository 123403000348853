<h2 mat-dialog-title>
  <ng-container *ngIf="isAdding; else editDialogTitle">Add New Comment</ng-container>
</h2>
<mat-dialog-content>
  <div [formGroup]="form">
    <mat-form-field>
      <mat-label>Comment</mat-label>
      <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="5"
                cdkAutosizeMaxRows="15" formControlName="comment">

    </textarea>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="" cdkFocusInitial>Cancel</button>
  <button mat-button [mat-dialog-close]="form.value"
          [disabled]="form.invalid">
    <ng-container *ngIf="isAdding; else editDialogOKButtonText">Add</ng-container>
  </button>
</mat-dialog-actions>

<ng-template #editDialogTitle>Edit Comment</ng-template>
<ng-template #editDialogOKButtonText>Update</ng-template>
