// tslint:disable-next-line:no-namespace
namespace WaiverFilter {

  export enum SearchField {
    AfterWaiverExpiryDate = 'afterExpiryDate',
    BeforeWaiverExpiryDate = 'beforeExpiryDate',
    Client = 'merchantId',
    Comment = 'comment',
    ProjectId = 'projectId',
    ProjectName = 'projectName',
    ProjectPlatform = 'projectPlatform',
    TestCaseName = 'testCaseName',
    WaiverNumber = 'statusNumber'
  }

  export interface SearchType {
    field: SearchField;
    fieldDescription: string;
  }

  export type SearchParameterType = string | number | Date;

  export interface SearchParameter {
    type: SearchType;
    value: SearchParameterType;
  }

  export const ALLSEARCHTYPES: Array<SearchType> = [
    {
      field: SearchField.Client,
      fieldDescription: 'Client'
    },
    {
      field: SearchField.WaiverNumber,
      fieldDescription: 'Waiver Number'
    },
    {
      field: SearchField.Comment,
      fieldDescription: 'Waiver Comment'
    },
    {
      field: SearchField.BeforeWaiverExpiryDate,
      fieldDescription: 'Before Expiry Date'
    },
    {
      field: SearchField.AfterWaiverExpiryDate,
      fieldDescription: 'After Expiry Date'
    },
    {
      field: SearchField.ProjectId,
      fieldDescription: 'Project ID'
    },
    {
      field: SearchField.ProjectName,
      fieldDescription: 'Project Name'
    },
    {
      field: SearchField.ProjectPlatform,
      fieldDescription: 'Project Platform'
    },
    {
      field: SearchField.TestCaseName,
      fieldDescription: 'Test Case Name'
    }
  ];
}

export default WaiverFilter;
