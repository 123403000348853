import {Injectable} from '@angular/core';
import {ProjectFileType} from '@models/projectFile';
import {IProjectFileTypeDescriptionService} from './iProjectFileTypeDescriptionService';

@Injectable()
export class ProjectFileTypeDescriptionService implements IProjectFileTypeDescriptionService {

  private readonly descriptions: Record<ProjectFileType, string> = {
    [ProjectFileType.Attachment]: 'Attachment',
    [ProjectFileType.L1Certificate]: 'L1 Certificate',
    [ProjectFileType.L2Certificate]: 'L2 Certificate',
    [ProjectFileType.PCICompliance]: 'PCI Compliance',
    [ProjectFileType.Receipt]: 'Receipt(s)',
    [ProjectFileType.MastercardTestCases]: 'Mastercard Test Cases',
    [ProjectFileType.NonMCTestCases]: 'Non-TSE Test Cases',
    [ProjectFileType.ULTestCases]: 'UL Test Cases',
    [ProjectFileType.CardLogs]: 'Card Logs',
    [ProjectFileType.TPPFile]: 'TPP File',
    [ProjectFileType.MASLog]: 'MAS Log',
    [ProjectFileType.DiscoverTestCases]: 'Discover Test Cases',
    [ProjectFileType.InternalOnly]: 'Internal Only',
    [ProjectFileType.VisaTestCases]: 'Visa Test Cases',
    [ProjectFileType.AmexTestCases]: 'American Express Test Cases',
    [ProjectFileType.JcbTestCases]: 'JCB Test Cases',
    [ProjectFileType.UnionPayTestCases]: 'UnionPay Test Cases',
    [ProjectFileType.TseTestCases]: 'TSE Test Cases'
  };

  getDescription(projectFileType: ProjectFileType): string {
    return this.descriptions[projectFileType];
  }
}
