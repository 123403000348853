import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthInfo, TOTPDeviceSecret} from '@models/security';
import {Either, left, right} from 'fp-ts/lib/Either';
import {BehaviorSubject, catchError, finalize, map, Observable, of, switchMap, tap, throwError} from 'rxjs';
import {AppConfigService} from '../../configuration/app-config.service/app-config.service';
import {AuthenticationStorageService} from './authentication-storage.service';
import {IAuthenticationService} from './iAuthenticationService';

export enum LoginResult {
  Success,
  NotRecognised,
  LoginExpired,
  AccountDisabled,
  ClientDisabled,
  DuplicateSession,
  ServerUnavailable,
  TOTPDeviceRegistrationRequired,
  TOTPRequired,
  TOTPDeviceActivationRequired,
  IncorrectOTP,
  TooManyAttempts
}

enum LoginResponseMessage {
  REGISTER_TOTP_DEVICE = 'REGISTER_TOTP_DEVICE', // make them register a device
  TOTP_REQUIRED = 'TOTP_REQUIRED', // make the user enter a TOTP
  ACTIVATE_TOTP_DEVICE = 'ACTIVATE_TOTP_DEVICE', // make the user enter a TOTP
  INCORRECT_OTP = 'INCORRECT_OTP', // User provided an invalid OTP code
  TOO_MANY_MFA_ATTEMPTS = 'TOO_MANY_MFA_ATTEMPTS', // User provided an invalid OTP code too many times
  EXPIRED_MSG = 'User credentials have expired',
  DISABLED_MSG = 'Account disabled. Disabled users cannot login.',
  CLIENT_DISABLED_MSG = 'Client deactivated. Deactivated client\'s users cannot login.',
  ACCOUNT_LOGGED_IN_ELSEWHERE_MSG = 'Token Invalid. Account has logged in somewhere else.',
  ACCOUNT_LOGGED_IN_ELSEWHERE_ALT_MSG = 'Your account has logged in somewhere else and made your session invalid',
  DUPLICATE_SESSION_MSG = 'You\'re already signed in from another browser/device. Please sign out of that session first, or try again in a few minutes',
}

/**
 * Let them manually register (not QR code)
 *
 * Display Key and ask them to enter it into authenticator
 * Time Based Key
 *
 * UI must make a request to the Cert API /totpdevices or something
 * - This will give me secret and uri for QR code
 */
type TOTPResponseMessage = LoginResponseMessage.REGISTER_TOTP_DEVICE 
  | LoginResponseMessage.TOTP_REQUIRED 
  | LoginResponseMessage.ACTIVATE_TOTP_DEVICE;

interface LoginResponse extends AuthInfo {
  message?: TOTPResponseMessage
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements IAuthenticationService {
  private _shouldRefresh: boolean;

  private _authInfo$: BehaviorSubject<AuthInfo>;
  
  private _totpAuthToken$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private configService: AppConfigService,
              private http: HttpClient,
              private router: Router,
              private authenticationStorageService: AuthenticationStorageService) {
    this._shouldRefresh = true;
    this._authInfo$ = new BehaviorSubject<AuthInfo>(this.sessionStoredAuthInfo);
  }

  get authInfo$(): Observable<AuthInfo> {
    return this._authInfo$.asObservable();
  }

  getAuthInfo(): AuthInfo {
    return this._authInfo$.getValue();
  }

  private get sessionStoredAuthInfo(): AuthInfo {
    return this.authenticationStorageService.get();
  }

  get RefreshToken(): string {
    const tokens = this.sessionStoredAuthInfo;
    return tokens ? tokens.refreshToken : null;
  }

  private static handleUnauthorized(e: HttpErrorResponse): Observable<Either<string, boolean>> {
    let message;
    if (e.error.error === 'Unauthorized') {
      message = 'Session expired';
    } else {
      message = e.error.details
        ? e.error.details.map(d => d.message).join('; ')
        : e.error.message;
    }
    return of(left<string, boolean>(message));
  }

  login(user: { username: string, password: string }): Observable<LoginResult> {
    const loginUrl: string = this.configService.getRestUrl('login');

    return this.http.post<LoginResponse>(loginUrl, user)
      .pipe(
        switchMap((response: LoginResponse) => {
          if (response.message) {
            this.storeAuthInfo(response, true);
            
            const error: Error = {
              name: 'Login Error',
              message: response.message
            };

            return throwError(() => error);
          }

          return of(response);
        }),
        tap((response: LoginResponse) => this.storeAuthInfo(response, false)),
        map(() => LoginResult.Success),
        catchError((error: HttpErrorResponse | Error) => {
          return of(this.determineLoginErrorResult(error));
        }));
  }

  loadTOTPDeviceSecret(): Observable<TOTPDeviceSecret> {
    const endpoint: string = this.configService.getRestUrl('totp/device');
    
    return this.http.post<TOTPDeviceSecret>(endpoint, null);
  }
  
  authenticateTOTP(otp: string): Observable<LoginResult> {
    const endpoint: string = this.configService.getRestUrl('totp/authenticate');
    
    return this.http.post(endpoint, { otp })
      .pipe(
        tap((response: LoginResponse) => this.storeAuthInfo(response, false)),
        map(() => LoginResult.Success),
        catchError((error: HttpErrorResponse | Error) => {
          return of(this.determineLoginErrorResult(error));
        })
      );
  }
  
  removeTOTPDevice(): Observable<void> {
    const endpoint: string = this.configService.getRestUrl('totp/device');

    return this.http.delete<void>(endpoint);
  }

  changePassword(currentPassword: string, newPassword: string): Observable<Either<string, boolean>> {
    const userId = this.sessionStoredAuthInfo.userId;
    const changeUrl = this.configService.getRestUrl(`users/${userId}/password`);
    return this.http.put(changeUrl, {currentPassword, newPassword}).pipe(
      map(_ => right<string, boolean>(true)),
      catchError(AuthenticationService.handleUnauthorized));
  }

  changePasswordWithUsername(username: string, currentPassword: string, newPassword: string): Observable<Either<string, boolean>> {
    const changeUrl = this.configService.getRestUrl(`users/password/reset`);
    return this.http.put(changeUrl, {username, currentPassword, newPassword}).pipe(
      map(_ => right<string, boolean>(true)),
      catchError(AuthenticationService.handleUnauthorized));
  }

  refreshToken() {
    const refreshUrl = this.configService.getRestUrl('refresh');
    return this.http.post<any>(refreshUrl, {
      refreshToken: this.RefreshToken
    });
  }

  getJwtToken(): string {
    const tokens = this.sessionStoredAuthInfo;
    return tokens ? tokens.token : null;
  }

  getTOTPAuthToken(): string {
    return this._totpAuthToken$.getValue();
  }

  logout() {
    const logoutUrl: string = this.configService.getRestUrl('logout');

    this.http.get(logoutUrl)
      .pipe(
        finalize(() => {
          this.storeAuthInfo(null, false);

          // Ensure that any session stored data is removed
          sessionStorage.clear();
          
          this.router.navigate(['login']);
        })
      )
      .subscribe();
  }

  async endSession() {
    // prevents the automatic refresh request after a 401 response,
    // as this would reawaken the session.
    this._shouldRefresh = false;

    const token: string = this.getJwtToken() ?? this.getTOTPAuthToken();

    if (token) {
      const endSessionUrl: string = this.configService.getRestUrl(`endSession`);

      // // Force the next API call to refresh
      this.clearJwtToken();

      await window.fetch(endSessionUrl, {
        method: 'POST',
        keepalive: true,
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    }
  }

  determineLoginErrorResult(error: HttpErrorResponse | Error): LoginResult {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 502 || error.status === 0) {
        return LoginResult.ServerUnavailable;
      }
    }

    const isHttpError: boolean = error instanceof HttpErrorResponse;

    let errorMessage: string = isHttpError ? (error as HttpErrorResponse).error.message : error.message;

    switch (errorMessage) {
      case LoginResponseMessage.EXPIRED_MSG:
        return LoginResult.LoginExpired;
      case LoginResponseMessage.DISABLED_MSG:
        return LoginResult.AccountDisabled;
      case LoginResponseMessage.CLIENT_DISABLED_MSG:
        return LoginResult.ClientDisabled;
      case LoginResponseMessage.DUPLICATE_SESSION_MSG:
        return LoginResult.DuplicateSession;
      case LoginResponseMessage.REGISTER_TOTP_DEVICE:
      case LoginResponseMessage.ACTIVATE_TOTP_DEVICE:
        return LoginResult.TOTPDeviceRegistrationRequired;
      case LoginResponseMessage.TOTP_REQUIRED:
        return LoginResult.TOTPRequired;
      case LoginResponseMessage.INCORRECT_OTP:
        return LoginResult.IncorrectOTP;
      case LoginResponseMessage.TOO_MANY_MFA_ATTEMPTS:
        return LoginResult.TooManyAttempts;
      default:
        return LoginResult.NotRecognised;
    }
  }

  storeAuthInfo(authInfo: AuthInfo, isIntermediarySession: boolean) {
    if (isIntermediarySession) {
      this._totpAuthToken$.next(authInfo.token);
    } else {
      this.authenticationStorageService.set(authInfo);
      
      this._authInfo$.next(authInfo);
      this._totpAuthToken$.next(null);
    }
  }

  clearJwtToken() {
    const tokens: AuthInfo = this.sessionStoredAuthInfo;
    
    if (tokens) {
      tokens.token = null;

      this.storeAuthInfo(tokens, false);
    }
  }

  updateJwtTokens(jwtToken: string, refreshToken: string) {
    const tokens: AuthInfo = this.sessionStoredAuthInfo;
    
    if (tokens) {
      tokens.token = jwtToken;
      tokens.refreshToken = refreshToken;

      this.storeAuthInfo(tokens, false);
    }
  }

  get shouldRefresh() {
    return this._shouldRefresh;
  }

  set shouldRefresh(shouldRefresh: boolean) {
    this._shouldRefresh = shouldRefresh;
  }
}
