import {ElementRef, Injectable} from '@angular/core';
import {est_timezone, momentDateTimeFormat} from '@models/constants';
import {jsPDF} from 'jspdf';
import * as moment from 'moment';

export interface IPdfDrawingService {
  drawReportTitle(doc: any, title: string): void;

  createDocument(): void;

  drawSubTitle(doc: any, title: string, ypos: number): void;

  drawChart(doc: any, chart: ElementRef, ypos: number): Promise<number>;
}

@Injectable()
export class PdfDrawingService implements IPdfDrawingService {

  imageScale = 6;

  drawReportTitle(doc: jsPDF, title: string) {
    const pageWidth = doc.internal.pageSize.getWidth();
    doc.setFont('Helvetica', 'bold');
    doc.setFontSize(20);
    doc.text(title, pageWidth / 2, 30, {align: 'center'}, null);
    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(10);
    const now = moment().tz(est_timezone).format(momentDateTimeFormat);
    doc.text(`Generated ${now}`, pageWidth / 2, 42, {align: 'center'}, null);
  }

  createDocument() {
    return new jsPDF({
      orientation: 'p',
      unit: 'px',
      format: 'letter',
      compress: true
    });
  }

  drawSubTitle(doc: any, title: string, ypos: number) {
    doc.setFontSize(14);
    doc.setFont('Helvetica', 'bold');
    doc.setTextColor(0, 0, 0);
    doc.text(title, 30, ypos);
  }

  drawSectionHeading(doc: any, title: string, ypos: number) {
    doc.setFontSize(12);
    doc.setTextColor(148, 148, 192);
    doc.setFont('Helvetica', 'italic');
    doc.text(title, 30, ypos);
  }

  async drawChart(doc: jsPDF, chart: ElementRef, ypos: number): Promise<number> {
    const pageWidth = doc.internal.pageSize.getWidth();
    const chartProperties = doc.getImageProperties(chart as unknown as HTMLCanvasElement);
    const chartWidth = chartProperties.width / this.imageScale;
    const chartHeight = chartProperties.height / this.imageScale;
    const chartXpos = (pageWidth - chartWidth) / 2;
    await doc.addImage(chart as unknown as HTMLCanvasElement, 'PNG', chartXpos, ypos, chartWidth, chartHeight);
    return ypos + chartHeight;
  }

  drawText(doc: any, text: string, yPosition: number) {
    doc.setFontSize(10);
    doc.setTextColor(80, 80, 80);
    doc.text(text, 30, yPosition);
  }
}
