import {HttpErrorResponse} from '@angular/common/http';
import {ErrorHandler, Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {AppConfigService} from '../configuration/app-config.service/app-config.service';
import HttpStatusCode from '../httpStatusCode';
import {NotificationService} from '../notification.service/notification.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private notificationService: NotificationService,
              private appConfigService: AppConfigService) {
  }

  /**
   * Provides a hook for centralized exception handling.
   * @param error - the generated error
   */
  handleError(error: Error | HttpErrorResponse) {

    if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.UNAUTHORIZED) {
      // let the auth interceptor handle this one
    } else {
      if (environment.production) {

        this.notificationService.error('Sorry something went wrong');

        // Display the error in the Javascript console if we are a QA mode
        if (this.appConfigService.current.qaMode) {
          console.error(error);
        }
      } else {
        this.notificationService.error(error.message);
        console.error(error);
      }
    }
  }
}
