import {Injectable, OnDestroy} from '@angular/core';
import {ProjectFileDataSource} from '@models/project-file-data-source';
import {ProjectDetail} from '@models/projectDetail';
import {ProjectFile} from '@models/projectFile';
import {ProjectPhase} from '@models/projectPhase';
import {ProjectState} from '@models/projectState';
import {Action, AuthInfo, Resource, Role} from '@models/security';
import {ProjectTestPlan} from '@models/tests/projectTestPlan';
import {TestResultUpdate} from '@models/tests/testResultUpdate';
import {AuthenticationService} from '@services/auth/authentication.service/authentication.service';
import {PermissionsService} from '@services/auth/permissions.service/permissions.service';
import {ProjectFilesService} from '@services/data/project-files.service/project-files.service';
import {BehaviorSubject, merge, Observable, Subscription} from 'rxjs';
import {map, switchMap, withLatestFrom} from 'rxjs/operators';
import {OverridesDataService} from '../overrides/overrides-data.service/overrides-data.service';
import {ProjectCommentsDataSourceService} from '../project-comments/project-comments-data.service/project-comments-data-source.service';
import {WaiversDataService} from '../waivers/waivers-data.service/waivers-data.service';
import {IProjectDetailStateService} from './iProjectDetailStateService';
import {UserNameDetails} from "@models/userNameDetails";

export enum DetailSubPanel {
  None,
  Draft,
  OnBoarding,
  TestExecution,
  PreValidation,
  Validation,
  Approval,
  Closed
}

@Injectable()
export class ProjectDetailStateService implements OnDestroy, IProjectDetailStateService {

  private projectFileRefresh$ = new BehaviorSubject<undefined>(undefined);
  private fileRefreshSubscription: Subscription;
  private project$ = new BehaviorSubject<ProjectDetail>(null);
  private projectTestPlans$ = new BehaviorSubject<ProjectTestPlan>(null);
  private projectUpdating$ = new BehaviorSubject<boolean>(false);
  private projectEditing$ = new BehaviorSubject<boolean>(false);

  constructor(private authenticationService: AuthenticationService,
              private projectFilesService: ProjectFilesService,
              private permissionsService: PermissionsService,
              private projectCommentsDataSourceService: ProjectCommentsDataSourceService,
              private waiversDataService: WaiversDataService,
              private overridesDataService: OverridesDataService) {
  }

  private _showProjectFiles$ = new BehaviorSubject<boolean>(false);
  private _hasSnapshots$ = new BehaviorSubject<boolean>(false);
  private _movingPhase$ = new BehaviorSubject<boolean>(false);

  get showProjectFiles$(): Observable<boolean> {
    return this._showProjectFiles$.asObservable();
  }
  get showTestCertify$(): Observable<boolean> {
    return this._hasSnapshots$.asObservable();
  }

  get isMovingPhase$(): BehaviorSubject<boolean> {
    return this._movingPhase$;
  }

  private _currentSubPanel$ = new BehaviorSubject<DetailSubPanel>(DetailSubPanel.None);

  get currentSubPanel$(): Observable<DetailSubPanel> {
    return this._currentSubPanel$.asObservable();
  }

  private _projectOpen$ = new BehaviorSubject<boolean>(true);

  get projectOpen$(): Observable<boolean> {
    return this._projectOpen$.asObservable();
  }

  _projectFiles$: BehaviorSubject<ProjectFileDataSource> = new BehaviorSubject<ProjectFileDataSource>(null);

  get projectFiles$(): Observable<ProjectFileDataSource> {
    return this._projectFiles$.asObservable();
  }

  get currentState$(): Observable<ProjectState> {
    return this.project$.pipe(map(project => project?.state));
  }

  get totalProjectFiles$(): Observable<number> {
    return this.projectFileRefresh$
      .pipe(
        switchMap(() => this.projectFiles$),
        map(files => files?.fileListCount() ?? 0)
      );
  }

  get projectDetail$(): Observable<ProjectDetail> {
    return this.project$.asObservable();
  }

  get showTestPlans$(): Observable<boolean> {
    return this.project$.pipe(map(project => project?.phase !== ProjectPhase.Onboarding));
  }

  get canUploadTestCases$(): Observable<boolean> {
    return merge(
      this.permissionsService.hasPermission([Resource.project, Action.edit]),
      this.project$
    ).pipe(
      withLatestFrom(this.permissionsService.hasPermission([Resource.project, Action.edit])),
      withLatestFrom(this.project$),
      map(([[_, canUpload], project]) =>
        canUpload && (project?.phase === ProjectPhase.Onboarding || project?.phase === ProjectPhase.TestExecution))
    );
  }

  get canDeleteProjectFiles$(): Observable<boolean> {
    return merge(
      this.permissionsService.hasPermission([Resource.projectFile, Action.delete]),
      this.project$
    ).pipe(
      withLatestFrom(this.permissionsService.hasPermission([Resource.projectFile, Action.delete])),
      withLatestFrom(this.project$),
      map(([[_, canDelete], project]) =>
        (canDelete || project?.phase === ProjectPhase.Onboarding) && project?.phase !== ProjectPhase.Closed));
  }

  get totalComments$(): Observable<number> {
    return this.projectCommentsDataSourceService.commentCount$;
  }

  get totalWaivers$(): Observable<number> {
    return this.waiversDataService.waiverCount$;
  }

  get totalOverrides$(): Observable<number> {
    return this.overridesDataService.overrideCount$;
  }

  private _sandboxProject$ = new BehaviorSubject<boolean>(true);

  get isSandboxProject$(): Observable<boolean> {
    return this._sandboxProject$.asObservable();
  }

  private static canDeleteUsersFile(authInfo: AuthInfo, owner: UserNameDetails) {
    const isAdminRole: boolean = authInfo.role === Role.admin;
    const isFileOwner: boolean = authInfo.userId === owner.id;

    if (isAdminRole) {
      return true;
    }

    return isFileOwner;
  }

  /**
   * Work out which project sub panel should be displayed based
   * on the phase of the current project
   */
  private static determineCurrentProjectSubPanel(project): DetailSubPanel {
    if (project == null) {
      return DetailSubPanel.None;
    }

    switch (project.phase) {
      case ProjectPhase.Approval:
        return DetailSubPanel.Approval;

      case ProjectPhase.Closed:
        return DetailSubPanel.Closed;

      case ProjectPhase.Onboarding:
        return DetailSubPanel.OnBoarding;

      case ProjectPhase.TestExecution:
        return DetailSubPanel.TestExecution;

      case ProjectPhase.PreValidation:
        return DetailSubPanel.PreValidation;

      case ProjectPhase.Validation:
        return DetailSubPanel.Validation;

      case ProjectPhase.Draft:
        return DetailSubPanel.Draft;

      default:
        return DetailSubPanel.None;
    }
  }

  canDeleteFile(file: ProjectFile): Observable<boolean> {
    return merge(
      this.permissionsService.hasPermission([Resource.projectFile, Action.delete]),
      this.authenticationService.authInfo$
    ).pipe(
      withLatestFrom(this.permissionsService.hasPermission([Resource.projectFile, Action.delete])),
      withLatestFrom(this.authenticationService.authInfo$),
      map(([[_, canDelete], authInfo]) =>
        canDelete && ProjectDetailStateService.canDeleteUsersFile(authInfo, file.owner)));
  }

  setProject(project: ProjectDetail) {
    this.project$.next(project);
    this.projectUpdating$.next(false);

    const subPanel = ProjectDetailStateService.determineCurrentProjectSubPanel(project);
    this._currentSubPanel$.next(subPanel);

    this._showProjectFiles$.next(project.phase !== ProjectPhase.Draft);
    this._hasSnapshots$.next(project.hasProjectSnapshot);
    this._projectOpen$.next(project.phase !== ProjectPhase.Closed);
    this.fileRefreshSubscription = this.projectFileRefresh$.pipe(
      withLatestFrom(this.project$),
      switchMap(([_, currentProject]) =>
        this.projectFilesService.getProjectFiles('' + currentProject.id)),
      withLatestFrom(this.authenticationService.authInfo$),
      map(([fileResult, authInfo]) => new ProjectFileDataSource(fileResult.data, (project.sandbox || this.canSeeFiles(authInfo)))),
    ).subscribe(
      dataSource => this._projectFiles$.next(dataSource)
    );

    this.projectCommentsDataSourceService.setProject(project);
    this.waiversDataService.setProject(project);
    this.overridesDataService.setProject(project);
    this._sandboxProject$.next(project.sandbox);
  }

  canSeeFiles(authInfo: AuthInfo): boolean {
    return authInfo?.role && ![
      Role.client,
      Role.thirdPartyClient
    ].includes(authInfo.role);
  }

  updateProjectFiles() {
    this.projectFileRefresh$.next(undefined);
  }

  ngOnDestroy(): void {
    this.fileRefreshSubscription.unsubscribe();
  }

  updateTestCaseResults(results: Array<TestResultUpdate>) {
    if (!results.length) {
      return;
    }

    const projectDetail = this.project$.value;
    if (!projectDetail) {
      return;
    }

    this.projectTestPlans$.value.projectTestCases.forEach(tc => {
      const result = results.find(r => r.projectTestCaseId === tc.id);
      tc.validationState = result?.validationState ?? tc.validationState;
      tc.waived = result?.waived ?? false;
    });
  }

  setIsMovingPhase(movingPhase: boolean) {
    this._movingPhase$.next(movingPhase);
  }

  resetProject() {
    this.project$.next(null);
  }

  setUpdating() {
    this.projectUpdating$.next(true);
  }

  get isUpdating$(): BehaviorSubject<boolean> {
    return this.projectUpdating$;
  }

  get isEditing$(): BehaviorSubject<boolean> {
    return this.projectEditing$;
  }
}

