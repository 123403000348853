<h2 mat-dialog-title>
  <span *ngIf="!isSandboxProject; else sandbox"> Create a new Project </span>
  <ng-template #sandbox><span> Create a new Sandbox Project </span></ng-template>
</h2>
<mat-dialog-content>
  <div [formGroup]="projectForm">
    <div class="row">
      <div class="col-4">
        <fieldset class="dialog-fieldset">
          <legend>Details</legend>
          <mat-form-field>
            <input matInput placeholder="Project Name" formControlName="name" required>
            <mat-error *ngIf="projectForm.get('name').errors?.required">
              You must specify the name of the project
            </mat-error>
            <mat-error *ngIf="projectForm.get('name').errors?.alreadyExists">
              This project name is already in use
            </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="!isSandboxProject">
            <input matInput placeholder="Fiserv Project ID" formControlName="projectId" required maxlength="64">
            <mat-error *ngIf="projectForm.get('projectId').errors?.required">
              You must enter a project ID
            </mat-error>
            <mat-error *ngIf="projectForm.get('projectId').errors?.alreadyExists">
              This project ID is already in use
            </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="!isSandboxProject">
            <input matInput placeholder="Sandbox Project ID" formControlName="sandboxProjectId" required maxlength="150">
            <mat-error *ngIf="projectForm.get('sandboxProjectId').errors?.required">
              Sandbox Project ID cannot be blank, please enter valid value
            </mat-error>
          </mat-form-field>

          <div [class.client-selector-row]="canCreateClients$ | async"
               *ngIf="(clients$ | async) as clients">

            <mat-form-field *ngIf="clients.length > 0">
              <mat-label>Client</mat-label>
              <mat-select formControlName="merchantId">
                <mat-option *ngFor="let client of clients" [value]="client.id">
                  {{client.merchantName}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="projectForm.get('merchantId').invalid">
                You must specify a client
              </mat-error>
            </mat-form-field>

            <div *ngIf="clients.length === 0" class="error" id="noClientsErrorMessage">
              There are no clients current defined. You must create one.
            </div>

            <button *ngIf="canCreateClients$ | async"
                    id="create-client-button"
                    mat-button mat-flat-button
                    (keydown)="onNewClientKeyDown($event)"
                    (click)="onNewClientClick($event)">Create Client
            </button>
          </div>

          <div *ngIf="(selectedClientUsers$ | async) as clientUsers">
            <app-assigned-user-select formControlName="clientLead"
                                      label="Client Lead"
                                      [users]="clientUsers"
                                      [required]="true"
                                      [errorMessage]="getClientLeadErrorMessage()">
            </app-assigned-user-select>
            <div *ngIf="!gettingUsers && clientUsers.length === 0 && projectForm.get('merchantId').value" class="error"
                 id="noUserErrorMessage">
              This client has no users defined. You need to create one before you can create a project.
            </div>
          </div>

          <app-assigned-user-select formControlName="certLead"
                                    label="Certification Lead Analyst"
                                    [users]="users$ | async"
                                    [required]="true"
                                    [errorMessage]="getCertLeadErrorMessage()">
          </app-assigned-user-select>

          <app-assigned-user-select formControlName="backupAnalyst"
                                    label="Back up Analyst"
                                    [users]="backupAnalysts$ | async"
                                    [required]="false">
          </app-assigned-user-select>

          <app-assigned-user-select formControlName="ipLead"
                                    label="Integrated Partner Lead"
                                    [users]="ipUsers$ | async"
                                    [required]="false">
          </app-assigned-user-select>
          <mat-form-field>
            <mat-label>Project Target Date</mat-label>
            <input matInput [min]="minTargetDate$" [ngxMatDatetimePicker]="targetDate"
                   formControlName="targetDate">
            <mat-datepicker-toggle matSuffix [for]="$any(targetDate)">
            </mat-datepicker-toggle>
            <ngx-mat-datetime-picker #targetDate [hideTime]="true"></ngx-mat-datetime-picker>
          </mat-form-field>
        </fieldset>
      </div>
      <div class="col-3">
        <fieldset class="dialog-fieldset">
          <legend>Terminal</legend>
          <mat-form-field>
            <input matInput placeholder="Terminal Brand" formControlName="terminalBrand" required maxlength="100">
            <mat-error *ngIf="projectForm.get('terminalBrand').invalid">
              You must specify a terminal brand
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="Model" formControlName="terminalModel" required maxlength="100">
            <mat-error *ngIf="projectForm.get('terminalModel').invalid">
              You must specify a terminal model
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="Payment Software Name" formControlName="paymentSoftwareVersion" required maxlength="100">
            <mat-error *ngIf="projectForm.get('paymentSoftwareVersion').invalid">
              You must specify the payment software name
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Platform</mat-label>
            <mat-select formControlName="platform">
              <mat-option *ngFor="let platform of platforms" [value]="platform.name">
                {{platform.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="projectForm.get('platform').invalid">
              You must specify a platform
            </mat-error>
          </mat-form-field>
          <mat-form-field style="margin-bottom: 10px;">
            <mat-label>Host Message Specification</mat-label>
            <mat-select formControlName="hostMessageSpecification">
              <mat-option *ngFor="let spec of availableHostSpecs$ | async" [value]="spec">
                {{spec}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="projectForm.get('hostMessageSpecification').invalid">
              You must specify the host message specification
            </mat-error>
          </mat-form-field>
        </fieldset>
      </div>
      <div class="col-5" style="display: flex; justify-content: space-between">
        <fieldset class="dialog-fieldset">
          <legend>Terminal and Merchant IDs</legend>
          <app-terminal-ids-editor #terminalIdsEditor
                                   height="338px"
                                   [editable]="true"></app-terminal-ids-editor>
        </fieldset>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button [mat-dialog-close]="" cdkFocusInitial>Cancel</button>
  <button mat-raised-button color="accent" [class.bright-button]="!projectForm?.invalid" [mat-dialog-close]="{project: formValue, isDraft: true}"
          [disabled]="projectForm?.invalid || projectForm?.pending || terminalIdsEditor.form?.invalid">Create as Draft
  </button>
  <button mat-raised-button color="primary" [mat-dialog-close]="{project: formValue, isDraft: false}"
          [disabled]="projectForm?.invalid || projectForm?.pending  || terminalIdsEditor.form?.invalid">Create
  </button>
</mat-dialog-actions>

