import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ErrorHandler, InjectionToken, NgModule} from '@angular/core';
import {MerchantIdValidationService} from '@services/data/projects.service/merchantIdValidation.service';
import {AuthenticationStorageService} from './auth/authentication.service/authentication-storage.service';
import {NewPasswordValidationService} from './auth/new-password-validation.service/new-password-validation.service';
import {PermissionsService} from './auth/permissions.service/permissions.service';
import {BlobDownloadService} from './data/blob-download.service/blob-download.service';
import {ClientTypeDescriptionService} from './data/client-type-description.service/client-type-description.service';
import {ClientsService} from './data/clients.service/clients.service';
import {CommentsService} from './data/comments.service/comments.service';
import {EmvTagService} from './data/emv-tag.service/emv-tag.service';
import {HostLogTransactionService} from './data/host-log-transaction.service/host-log-transaction.service';
import {ProjectAuditService} from './data/project-audit.service/project-audit.service';
import {ProjectFileTypeDescriptionService} from './data/project-file-type-description.service/project-file-type-description.service';
import {ProjectPhaseDescriptionService} from './data/project-phase-description.service/project-phase-description.service';
import {ProjectStateDescriptionService} from './data/project-state-description.service/project-state-description.service';
import {ProjectTestCasesService} from './data/project-test-cases.service/project-test-cases.service';
import {ProjectsService} from './data/projects.service/projects.service';
import {ReportsService} from './data/reports.service/reports.service';
import {TestCasesService} from './data/test-cases.service/test-cases.service';
import {TestPlanService} from './data/test-plans.service/test-plan.service';
import {TestToolDescriptionService} from './data/test-tool-description.service';
import {TestsOverviewReportStateService} from './data/tests-overview-report-state.service/tests-overview-report-state.service';
import {UsersService} from './data/users.service/users.service';
import {DownloadIntakeFormAsPdfService} from './download-intake-form-as-pdf.service/download-intake-form-as-pdf.service';
import {RendererFactory} from './download-intake-form-as-pdf.service/question-renderers';
import {GlobalErrorHandler} from './error-handler/global-error-handler';
import {NotificationService} from './notification.service/notification.service';
import {ClientTypeDescriptionPipe} from './pipes/client-type-description.pipe';
import {EstDateFormatPipe} from './pipes/est-date-format.pipe';
import {EstDateTimePipe} from './pipes/est-date-time.pipe';
import {EstDatePipe} from './pipes/est-date.pipe';
import {LocalDatePipe} from './pipes/local-date.pipe';
import {NullLinePipe} from './pipes/null-line.pipe';
import {ProjectActiveDescriptionPipe} from './pipes/project-active-description.pipe';
import {ProjectFileTypeDescriptionPipe} from './pipes/project-file-type-description.pipe';
import {ProjectPhaseDescriptionPipe} from './pipes/project-phase-description.pipe';
import {ProjectStateDescriptionPipe} from './pipes/project-state-description.pipe';
import {RoleDescriptionPipe} from './pipes/role-description.pipe';
import {SpaceToHashPipe} from './pipes/space-to-hash.pipe';
import {TestToolDescriptionPipe} from './pipes/test-tool-description.pipe';
import {ReportsServiceModule} from './reports/reports-service.module';
import {SpinnerInterceptor} from './spinner/spinner.interceptor';
import {SpinnerService} from './spinner/spinner.service/spinner.service';
import {UserInactivityService} from './user-inactivity.service/user-inactivity.service';
import {EmailValidationService} from './validation/email-validation/email-validation.service';
import {NonAlphanumericValidationService} from './validation/non-alphanumeric-validation/non-alphanumeric-validation.service';
import {NonNumericValidationService} from './validation/non-number-validation/non-numeric-validation.service';
import {ZipcodeValidatorService} from './validation/zipcode-validator/zipcode-validator.service';
import {MfaDeviceStatusDescriptionPipe} from "@services/pipes/mfa-device-status-description.pipe";

export const DatePipe = new InjectionToken<EstDatePipe>('DatePipe');
export const ShortDatePipe = new InjectionToken<LocalDatePipe>('ShortDatePipe');

@NgModule({
  declarations: [
    ProjectFileTypeDescriptionPipe,
    RoleDescriptionPipe,
    ClientTypeDescriptionPipe,
    ProjectPhaseDescriptionPipe,
    SpaceToHashPipe,
    ProjectStateDescriptionPipe,
    ProjectActiveDescriptionPipe,
    NullLinePipe,
    EstDatePipe,
    EstDateFormatPipe,
    EstDateTimePipe,
    LocalDatePipe,
    TestToolDescriptionPipe,
    MfaDeviceStatusDescriptionPipe
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true,
    },
    ClientsService,
    SpinnerService,
    PermissionsService,
    ProjectFileTypeDescriptionService,
    ProjectPhaseDescriptionService,
    ProjectsService,
    ProjectTestCasesService,
    UsersService,
    NewPasswordValidationService,
    TestPlanService,
    TestCasesService,
    EmvTagService,
    CommentsService,
    HostLogTransactionService,
    ProjectAuditService,
    NonNumericValidationService,
    MerchantIdValidationService,
    ProjectAuditService,
    ZipcodeValidatorService,
    EmailValidationService,
    ReportsService,
    ClientTypeDescriptionService,
    ProjectStateDescriptionService,
    BlobDownloadService,
    NotificationService,
    UserInactivityService,
    DownloadIntakeFormAsPdfService,
    RendererFactory,
    TestToolDescriptionService,
    TestsOverviewReportStateService,
    {provide: ShortDatePipe, useClass: LocalDatePipe},
    {provide: DatePipe, useClass: EstDatePipe},
    AuthenticationStorageService,
    NonAlphanumericValidationService
  ],
  imports: [
    CommonModule,
    ReportsServiceModule
  ],
    exports: [
        ProjectFileTypeDescriptionPipe, RoleDescriptionPipe,
        ClientTypeDescriptionPipe, ProjectPhaseDescriptionPipe, ProjectActiveDescriptionPipe,
        SpaceToHashPipe, ProjectStateDescriptionPipe, NullLinePipe, EstDateTimePipe, EstDatePipe, LocalDatePipe,
        TestToolDescriptionPipe, MfaDeviceStatusDescriptionPipe
    ]
})
export class ServicesModule {
}
