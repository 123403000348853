import {jsPDF} from 'jspdf';

export class FormPdf {
  readonly TopPageMargin = 30;
  readonly BottomPageMargin = 10;
  readonly LeftPageMargin = 40;
  readonly RightPageMargin = 40;
  pageHeight: any;

  pageWidth: any;

  private readonly doc: jsPDF;

  private fontSize: number;

  constructor() {
    this.doc = new jsPDF({
      orientation: 'p',
      unit: 'px',
      format: 'letter',
      compress: true
    });

    const pageDimensions = this.doc.internal.pageSize;
    this.pageHeight = pageDimensions.getHeight() - this.TopPageMargin - this.BottomPageMargin;
    this.pageWidth = pageDimensions.getWidth() - this.LeftPageMargin - this.RightPageMargin;
  }

  get pdfDoc(): jsPDF {
    return this.doc;
  }

  getWrappedTextSize(text: string | Array<string>): { width: number, height: number } {
    const textToSplit = typeof text === 'object' ? text.join(' ') : text;
    const splitText = this.doc.splitTextToSize(textToSplit, this.pageWidth, {});
    const dim = this.doc.getTextDimensions(splitText);
    return {width: dim.h, height: dim.h};
  }

  drawWrappedText(text: string | Array<string>, ypos: number, xpos = 0) {
    this.doc.text(text, this.LeftPageMargin + xpos, ypos, {maxWidth: this.pageWidth - xpos});
  }

  addPage() {
    this.doc.addPage();
  }

  save(filename: string) {
    this.doc.save(filename);
  }

  reset() {
    this.setFontSize(10);
    this.setFontType(false, false);
    this.setTextColour('#000000');
  }

  setFontSize(fontSize: number) {
    this.fontSize = fontSize;
    this.doc.setFontSize(fontSize);
  }

  setFontType(isBold: boolean, isItalic: boolean) {
    this.doc.setFont('Helvetica', `${isBold ? 'Bold' : ''}${isItalic ? 'Oblique' : ''}`);
    this.setFontSize(this.fontSize);
  }

  setTextColour(colour: string) {
    this.doc.setTextColor(colour);
  }

  drawLine(x1: number, y1: number, x2: number, y2: number, colour = '#a0a0a0') {
    this.doc.setDrawColor(colour);
    this.doc.line(x1, y1, x2, y2);
  }

  drawRect(x: number, y: number, width: number, height: number, borderColour?: string, fillColour?: string) {
    if (borderColour) {
      this.doc.setDrawColor(borderColour);
    }

    if (fillColour) {
      this.doc.setFillColor(fillColour);
    }

    this.doc.rect(x, y, width, height, fillColour ? 'F' : 'S');
  }
}
