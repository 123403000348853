import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-project-test-summary-tooltip',
  styleUrls: ['./project-test-summary-tooltip.component.scss'],
  templateUrl: './project-test-summary-tooltip.component.html'
})
export class ProjectTestSummaryTooltip {
  @Input() testsNotStarted = 0;
  @Input() testsInProgress = 0;
  @Input() testsPassed = 0;
  @Input() testsFailed = 0;
  @Input() testsWaived = 0;
  @Input() testsOverridden = 0;
}
