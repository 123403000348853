import {ElementRef, Injectable} from '@angular/core';
import {ProjectDetail} from '@models/projectDetail';
import {DaysInPhaseReport} from '@models/reports/daysInPhaseReport';
import {TestsOverviewReport} from '@models/reports/testsOverviewReport';
import {jsPDF} from 'jspdf';
import {PdfDrawingService} from '../../pdf-drawing.service/pdf-drawing.service';
import {PhaseReportTableProviderService} from './phase-report-table-provider.service/phase-report-table-provider.service';
import {ProjectInfoTableProviderService} from './project-info-table-provider.service/project-info-table-provider.service';
import {TestsReportTableProviderService} from './tests-report-table-provider.service/tests-report-table-provider.service';

export interface IProjectReportProviderService {
  downloadReport(projectDetail: ProjectDetail,
                 phaseReport: Array<DaysInPhaseReport>,
                 testsReport: TestsOverviewReport,
                 daysInPhaseChartCanvas: ElementRef,
                 testsChartCanvas: ElementRef,
                 filename: string): Promise<void>;
}

@Injectable()
export class ProjectReportProviderService implements IProjectReportProviderService {

  constructor(private projectInfoTableProvider: ProjectInfoTableProviderService,
              private phaseReportTableProvider: PhaseReportTableProviderService,
              private testsReportTableProvider: TestsReportTableProviderService,
              private pdfDrawingService: PdfDrawingService) { }


  async downloadReport(projectDetail: ProjectDetail,
                       phaseReport: Array<DaysInPhaseReport>,
                       testsReport: TestsOverviewReport,
                       daysInPhaseChartCanvas: ElementRef,
                       testsChartCanvas: ElementRef,
                       filename: string) {

    const doc: jsPDF = this.pdfDrawingService.createDocument();
    this.pdfDrawingService.drawReportTitle(doc, 'Project Report');

    let yPosition = 70;
    this.pdfDrawingService.drawSubTitle(doc, 'Project Details', yPosition);
    await this.projectInfoTableProvider.renderInfo(doc, projectDetail, yPosition + 10);
    yPosition = (doc as any).lastAutoTable.finalY + 30;

    this.pdfDrawingService.drawSubTitle(doc, 'Days In Phase Report', yPosition);
    yPosition = await this.pdfDrawingService.drawChart(doc, daysInPhaseChartCanvas, yPosition + 5);
    this.phaseReportTableProvider.drawTable(doc, phaseReport, yPosition + 20);

    doc.addPage();
    yPosition = 40;
    this.pdfDrawingService.drawSubTitle(doc, 'Tests Report', yPosition);
    yPosition = await this.pdfDrawingService.drawChart(doc, testsChartCanvas, yPosition + 20);
    this.testsReportTableProvider.drawTable(doc, testsReport, yPosition + 15);

    if (!filename.toLowerCase().endsWith('.pdf')) {
      filename = filename + '.pdf';
    }
    doc.save(filename);
  }
}
