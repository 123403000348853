import {Pipe, PipeTransform} from '@angular/core';
import {Project} from '@models/project';
import {ProjectPhase} from '@models/projectPhase';
import {ProjectState} from '@models/projectState';

@Pipe({
  name: 'projectActiveDescription'
})
export class ProjectActiveDescriptionPipe implements PipeTransform {

  transform(value: Project): string {
    if (value.phase === ProjectPhase.Closed || value.state === ProjectState.Cancelled) {
      return 'Inactive';
    } else {
      return value.state === ProjectState.OnHold ? 'On-Hold' : 'Active';
    }
}}
