import {Project} from '../project';
import {User} from '../security/user';
import {ClientType} from './clientType';

export interface ClientSummary {
    id?: number;
    merchantName: string;
    merchantRef: string;
    projects?: Array<Project>;
    users?: Array<User>;
    clientType: ClientType;
    companyId: string;
    disabled: boolean;
}
