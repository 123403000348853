import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, publishReplay, refCount} from 'rxjs/operators';
import {ProjectsService} from './projects.service/projects.service';

interface ProjectLookup {
  id: number;
  projectName: string;
  projectId: string;
}


@Injectable()
export class UniqueProjectAttributesService {

  private projectNames$: Observable<Array<ProjectLookup>>;

  constructor(projectsService: ProjectsService) {
    this.projectNames$ = projectsService.getAllProjectsSummary().pipe(
      map(data => data.map<ProjectLookup>(p =>
        ({id: p.id, projectName: p.name.toLowerCase(), projectId: p.projectId.toLowerCase()}))),
      publishReplay(1),
      refCount());
  }

  isUniqueProjectName(projectName: string, excludingProjectId: number = 0): Observable<boolean> {
    projectName = projectName.toLowerCase();
    return this.projectNames$.pipe(
      map(projects => projects.every(p => p.projectName !== projectName || p.id === excludingProjectId))
    );
  }

  isUniqueProjectId(projectId: string, excludingProjectId: number = 0): Observable<boolean> {
    projectId = projectId.toLowerCase();
    return this.projectNames$.pipe(
      map(projects => projects.every(p => p.projectId !== projectId || p.id === excludingProjectId))
    );
  }
}
