import {Injectable, NgZone, OnDestroy} from '@angular/core';
import {from, fromEvent, interval, merge, Observable, of, Subject, Subscription, timer} from 'rxjs';
import {bufferTime, distinctUntilChanged, filter, finalize, map, scan, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {IUserInactivityService} from './iUserInactivityService';

/**
 * Time the user can be inactive before being warned of logout
 */
export const InActivityPeriod = 13  * 60;

/**
 * Time the user has to either dismiss prompt or be logged out
 */
export const LogoutWarningTimer = 2 * 60;

@Injectable()
export class UserInactivityService implements OnDestroy, IUserInactivityService {

  /**
   * Inactivity value in milliseconds.
   */
  protected readonly inactivityMillisec = InActivityPeriod * 1000;

  /**
   * Inactivity buffer wait time milliseconds to collect user action
   */
  protected readonly inactivitySensitivityMillisec = 1000;

  /**
   * Timeout value in seconds.
   */
  protected readonly timeout = LogoutWarningTimer;

  /**
   * Events that can interrupts user's inactivity timer.
   */
  protected activityEvents$: Observable<any>;

  protected timerStart$ = new Subject<boolean>();
  protected idleDetected$ = new Subject<boolean>();
  protected timeout$ = new Subject<boolean>();
  protected inactivity$: Observable<any>;
  protected timer$: Observable<any>;


  /**
   * Timeout status.
   */
  protected isTimeout: boolean;

  /**
   * Timer of user's inactivity is in progress.
   */
  protected isInactivityTimer: boolean;
  protected isInactivityDetected: boolean;

  protected inactivitySubscription: Subscription;

  constructor(private _ngZone: NgZone) {
  }

  ngOnDestroy() {
    if (this.inactivitySubscription) {
      this.inactivitySubscription.unsubscribe();
    }
  }

  /**
   * Start watching for user idle and setup timer and ping.
   */
  startWatching() {
    if (!this.activityEvents$) {
      this.activityEvents$ = merge(
        fromEvent(window, 'click'),
        fromEvent(window, 'resize'),
        fromEvent(document, 'keydown')
      );
    }

    this.inactivity$ = from(this.activityEvents$);

    if (this.inactivitySubscription) {
      this.inactivitySubscription.unsubscribe();
    }

    // If any of user events is not active for inactivity-seconds when start timer.
    this.inactivitySubscription = this.inactivity$
      .pipe(
        bufferTime(this.inactivitySensitivityMillisec), // Starting point of detecting of user's inactivity
        filter(
          arr => !arr.length && !this.isInactivityDetected && !this.isInactivityTimer
        ),
        tap(() => {
          this.isInactivityDetected = true;
          this.idleDetected$.next(true);
        }),
        switchMap(() =>
          this._ngZone.runOutsideAngular(() =>
            interval(1000).pipe(
              takeUntil(
                merge(
                  this.activityEvents$,
                  timer(this.inactivityMillisec).pipe(
                    tap(() => {
                      this.isInactivityTimer = true;
                      this.timerStart$.next(true);
                    })
                  )
                )
              ),
              finalize(() => {
                this.isInactivityDetected = false;
                this.idleDetected$.next(false);
              })
            )
          )
        )
      )
      .subscribe();

    this.setupTimer(this.timeout);
  }

  stopWatching() {
    this.stopTimer();
    if (this.inactivitySubscription) {
      this.inactivitySubscription.unsubscribe();
    }
  }

  stopTimer() {
    this.isInactivityTimer = false;
    this.timerStart$.next(false);
  }

  resetTimer() {
    this.stopTimer();
    this.isTimeout = false;
  }

  /**
   * Return observable for timer's countdown number that emits after idle.
   */
  onTimerStart(): Observable<number> {
    return this.timerStart$.pipe(
      distinctUntilChanged(),
      switchMap(start => (start ? this.timer$ : of(null)))
    );
  }

  /**
   * Return observable for idle status changed
   */
  onIdleStatusChanged(): Observable<boolean> {
    return this.idleDetected$.asObservable();
  }

  /**
   * Return observable for timeout is fired.
   */
  onTimeout(): Observable<boolean> {
    return this.timeout$.pipe(
      filter(timeout => !!timeout),
      tap(() => (this.isTimeout = true)),
      map(() => true)
    );
  }

  /**
   * Set custom activity events
   *
   * @param customEvents Example: merge(
   *   fromEvent(window, 'mousemove'),
   *   fromEvent(window, 'resize'),
   *   fromEvent(document, 'keydown'),
   *   fromEvent(document, 'touchstart'),
   *   fromEvent(document, 'touchend')
   * )
   */
  setCustomActivityEvents(customEvents: Observable<any>) {
    if (this.inactivitySubscription && !this.inactivitySubscription.closed) {
      console.error('Call stopWatching() before set custom activity events');
      return;
    }

    this.activityEvents$ = customEvents;
  }

  /**
   * Setup timer.
   *
   * Counts every seconds and return n+1 and fire timeout for last count.
   * @param timeout Timeout in seconds.
   */
  setupTimer(timeout: number) {
    this._ngZone.runOutsideAngular(() => {
      this.timer$ = interval(1000).pipe(
        take(timeout),
        map(() => 1),
        scan((acc, n) => acc + n),
        tap(count => {
          if (count === timeout) {
            this.timeout$.next(true);
          }
        })
      );
    });
  }
}
