import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PagedResponse} from '@models/pagedResponse';
import {TransactionLog} from '@models/transactionLog';
import {Observable} from 'rxjs';
import {AppConfigService} from '../../configuration/app-config.service/app-config.service';
import {FilterParameters, populateFilterParameters} from '../filterParameters';
import {SortDirection} from '../sortDirection';
import {IHostLogTransactionService} from './iHostLogTransactionService';

@Injectable()
export class HostLogTransactionService implements IHostLogTransactionService {

  private readonly restUrl: string;

  constructor(private http: HttpClient, configService: AppConfigService) {
    this.restUrl = configService.getRestUrl('/hostlogtransactions');
  }

  /**
   * Get a list of all transactions
   * @param sort - sort member
   * @param dir - sort direction
   * @param page - page index
   * @param pageSize - size of pages
   * @param searchParams - search parameters
   * @returns {Observable<PagedResponse<TransactionLog>>}
   */
  getTransactions(sort: string = 'transmissionTime', dir: SortDirection = 'desc', page: number = 0, pageSize: number = 99999,
                  searchParams: FilterParameters = null): Observable<PagedResponse<TransactionLog>> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('size', pageSize.toString())
      .set('sort', `${sort},${dir}`);

    if (searchParams) {
      params = populateFilterParameters(searchParams, params);
    }

    return this.http.get<PagedResponse<TransactionLog>>(this.restUrl, {params});
  }

  /**
   * Get a single transaction (will include onlineMessageList)
   * @param {number} transactionId
   * @returns {Observable<TransactionLog>}
   */
  getTransaction(transactionId: number): Observable<TransactionLog> {
    return this.http.get<TransactionLog>(`${this.restUrl}/${transactionId}`);
  }
}
