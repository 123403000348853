import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthInfo} from '@models/security';
import {Observable} from 'rxjs';
import {AuthenticationService} from './authentication.service/authentication.service';
import {PermissionsService} from './permissions.service/permissions.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuard implements CanActivate {
  constructor(private readonly _authenticationService: AuthenticationService,
              private readonly _permissionsService: PermissionsService,
              private readonly _router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const authInfo: AuthInfo = this._authenticationService.getAuthInfo();

    const isAuthenticated: boolean = Boolean(authInfo);

    // If not authenticated, send the user back to the login screen
    if (!isAuthenticated) {
      return this._router.createUrlTree(['login']);
    }

    // Site section check
    const siteSection = route.data?.siteSection;

    if (siteSection && this._permissionsService.canAccessSiteSection(authInfo, siteSection)) {
      return true;
    }

    // Role check
    const roles = route.data?.permittedRoles;

    if (!roles || roles.includes(authInfo.role)) {
      return true;
    }

    return this._router.createUrlTree(['/']);
  }
}
