import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {EmailNoteDialogComponent, EmailNoteText} from './email-note-dialog.component';

export interface IEmailNoteDialService {getEmailNote: () => Promise<EmailNoteText | null>; }

@Injectable()
export class EmailNoteDialogService implements IEmailNoteDialService {

  constructor(private dialog: MatDialog) {}

  getEmailNote = (): Promise<EmailNoteText | null> =>
    this.dialog.open(EmailNoteDialogComponent, {
      minWidth: '500px',
      width: '50%',
    }).afterClosed().toPromise()
}
