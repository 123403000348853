import {ElementRef, Injectable} from '@angular/core';
import {ActiveProjectsReport} from '@models/reports/activeProjectsReport';
import {
  AllProjectCategories,
  AllProjectCategory,
  AllProjectCategoryDescriptions,
  AllProjectsReport
} from '@models/reports/allProjectsReport';
import {CompletedProjectsReport} from '@models/reports/completedProjectsReport';
import {jsPDF} from 'jspdf';
import {PdfDrawingService} from '../../pdf-drawing.service/pdf-drawing.service';
import {ActiveProjectsTableProviderService} from './active-projects-table-provider.service/active-projects-table-provider.service';
import {AllProjectsTableProviderService} from './all-projects-table-provider.service/all-projects-table-provider.service';
import {AnalystInfoTableProviderService} from './analyst-info-table-provider.service/analyst-info-table-provider.service';
import {ClientInfoTableProviderService} from './client-info-table-provider.service/client-info-table-provider.service';
import {CompletedProjectsTableProviderService} from './completed-projects-table-provider.service/completed-projects-table-provider.service';

export interface IOverviewReportProviderService {
  downloadReport(clientId: number | null,
                 analystId: number | null,
                 allProjectsReport: AllProjectsReport,
                 activeProjectsReport: ActiveProjectsReport,
                 completedProjectsReport: CompletedProjectsReport,
                 allProjectsChartCanvas: ElementRef,
                 activeProjectsChartCanvas: ElementRef,
                 completedProjectsReportChartCanvas: ElementRef,
                 sandbox: boolean): Promise<void>;
}

@Injectable()
export class OverviewReportProviderService implements IOverviewReportProviderService {

  constructor(private allProjectsTableProvider: AllProjectsTableProviderService,
              private activeProjectsTableProvider: ActiveProjectsTableProviderService,
              private completedProjectsTableProvider: CompletedProjectsTableProviderService,
              private clientInfoTableProvider: ClientInfoTableProviderService,
              private analystInfoTableProvider: AnalystInfoTableProviderService,
              private pdfDrawingService: PdfDrawingService) { }

  async downloadReport(clientId: number | null,
                       analystId: number | null,
                       allProjectsReport: AllProjectsReport,
                       activeProjectsReport: ActiveProjectsReport,
                       completedProjectsReport: CompletedProjectsReport,
                       allProjectsChartCanvas: ElementRef,
                       activeProjectsChartCanvas: ElementRef,
                       completedProjectsReportChartCanvas: ElementRef,
                       sandbox: boolean) {
    const doc: jsPDF = this.pdfDrawingService.createDocument();
    const allProjects = sandbox ? 'All Sandbox Projects Reports' : 'All Projects Report';
    this.pdfDrawingService.drawReportTitle(doc, clientId ? 'Client Projects Report' : allProjects);

    let yPosition = 70;

    if (clientId) {
      await this.clientInfoTableProvider.renderInfo(doc, clientId, yPosition);
      yPosition = (doc as any).lastAutoTable.finalY + 20;
    }

    if (analystId) {
      await this.analystInfoTableProvider.renderInfo(doc, analystId, yPosition);
      yPosition = (doc as any).lastAutoTable.finalY + 20;
    }

    this.pdfDrawingService.drawSubTitle(doc, 'All Projects', yPosition);
    if (this.hasAllProjectsReport(allProjectsReport)) {
      yPosition = allProjectsChartCanvas ?
                  await this.pdfDrawingService.drawChart(doc, allProjectsChartCanvas, yPosition + 5) :
                  yPosition;

      yPosition = yPosition + 10;
      this.pdfDrawingService.drawSectionHeading(doc, 'Overview', yPosition);
      this.allProjectsTableProvider.drawTable(doc, allProjectsReport, yPosition + 5);

      for (const category of AllProjectCategories) {
        if (allProjectsReport[category] != null) {
          yPosition = (doc as any).lastAutoTable.finalY + 20;
          this.pdfDrawingService.drawSectionHeading(doc, `All ${AllProjectCategoryDescriptions[category]} Projects`, yPosition);
          this.allProjectsTableProvider.drawProjectsTable(doc, allProjectsReport, category, yPosition + 5);
        }
      }
    } else {
      this.pdfDrawingService.drawText(doc, 'No projects', yPosition + 12);
    }

    doc.addPage();
    yPosition = 40;
    this.pdfDrawingService.drawSubTitle(doc, 'Active Projects', yPosition);
    yPosition = await this.pdfDrawingService.drawChart(doc, activeProjectsChartCanvas, yPosition + 5);
    this.activeProjectsTableProvider.drawTable(doc, activeProjectsReport, yPosition, sandbox);

    doc.addPage();
    yPosition = 40;
    this.pdfDrawingService.drawSubTitle(doc, 'Completed Projects', yPosition);
    if (completedProjectsReportChartCanvas) {
      yPosition = await this.pdfDrawingService.drawChart(doc, completedProjectsReportChartCanvas, yPosition + 5);
      this.completedProjectsTableProvider.drawTable(doc, completedProjectsReport, yPosition);
    } else {
      this.pdfDrawingService.drawText(doc, 'No completed projects', yPosition + 12);
    }
    const reportName = sandbox ? `sandbox-projects-report.pdf` : `projects-report.pdf`;
    doc.save(reportName);
  }

  private hasAllProjectsReport(report: AllProjectsReport): boolean {
    return report[AllProjectCategory.Active] != null || report[AllProjectCategory.Closed] != null || report[AllProjectCategory.Cancelled] != null;
  }
}
