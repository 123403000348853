import {Injectable} from '@angular/core';
import {est_timezone, momentDateTimeFormat} from '@models/constants';
import {AllPhases} from '@models/projectPhase';
import {DaysInPhaseReport} from '@models/reports/daysInPhaseReport';
import * as _ from 'lodash';
import * as moment from 'moment';
import {ProjectPhaseDescriptionService} from '../../../data/project-phase-description.service/project-phase-description.service';
import commonTableStyle from '../../commonTableStyle';

const columnAlignments = ['left', 'center', 'center', 'center', 'center', 'center', 'center'];

export interface IPhaseReportTableProviderService {drawTable(doc: any, phaseReport: Array<DaysInPhaseReport>, yPos: number): void;}

@Injectable()
export class PhaseReportTableProviderService implements IPhaseReportTableProviderService {

  constructor(private phaseDescriptionService: ProjectPhaseDescriptionService) {}

  drawTable(doc: any, phaseReport: Array<DaysInPhaseReport>, yPos: number) {
    doc.autoTable({
      ...this.buildPhaseReport(phaseReport),
      ...commonTableStyle,
      startY: yPos,
      didParseCell: data => {
        data.cell.styles.halign = columnAlignments[data.column.index];
      }
    });
  }

  private buildPhaseReport(phaseReport: Array<DaysInPhaseReport>): { head: Array<any>, body: Array<Array<string>> } {
    const head = [{
      name: 'Phase Name',
      start: 'Start',
      end: 'End',
      daysInPhase: 'Days In Phase',
      currentDaysOnHold: 'Current Days On Hold',
      totalDaysOnHold: 'Total Days On Hold',
      totalCumulativeDays: 'Total Days Cumulative'
    }];
    const body = AllPhases
      .filter(phase => phaseReport.some(phaseReport => phaseReport.phase === phase))
      .map<Array<string>>(phase => {
        const reportsForThisPhase = phaseReport.filter(pr => pr.phase === phase);
        return [
          this.phaseDescriptionService.getDescription(phase),
          moment.min([...reportsForThisPhase.map(pr => moment(pr.startDate))]).tz(est_timezone).format(momentDateTimeFormat),
          reportsForThisPhase.some(pr => pr.endDate == null) ? '-' :
            moment.max([...reportsForThisPhase.map(pr => moment(pr.endDate))]).tz(est_timezone).format(momentDateTimeFormat),
          _.sumBy(reportsForThisPhase, pr => pr.daysInPhase).toString(),
          _.sumBy(reportsForThisPhase, pr => pr.currentDaysOnHold).toString(),
          _.sumBy(reportsForThisPhase, pr => pr.totalDaysOnHold).toString(),
          this.getLastCumulativeDays(reportsForThisPhase).toString()
        ];

      });
    return {head, body};
  }

  private getLastCumulativeDays(reportsForThisPhase: DaysInPhaseReport[]): number {
    const current = reportsForThisPhase.find(d => d.endDate == null);
    if (current) {
      return current.totalCumulativeDays;
    }
    const sortedByEndDate = _.sortBy(reportsForThisPhase, [r => r.endDate]);
    return _.last(sortedByEndDate)?.totalCumulativeDays ?? 0;
  }
}
