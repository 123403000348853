<h2 mat-dialog-title>
  {{params.title}}
</h2>
<mat-dialog-content>
  <div [formGroup]="form">
    <mat-form-field style="width: 100%">
      <mat-label>Filename</mat-label>
      <input matInput placeholder="Filename" formControlName="filename" required>
      <mat-error *ngIf="form.get('filename').errors?.typeBreach">
        <span *ngIf="validExtensions.length > 1">
          This report requires a file extension in {{ validExtensions }} in order to download correctly.
        </span>
        <span *ngIf="validExtensions.length <= 1">
          This report requires an '{{ validExtensions[0] || 'xlsx' }}' extension in order to download correctly.
        </span>
      </mat-error>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="" cdkFocusInitial>Cancel</button>
  <button mat-button [mat-dialog-close]="form.value.filename" [disabled]="form.invalid">
    Download
  </button>
</mat-dialog-actions>
