import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface ExcelDownloadDialogParams {
  title: string;
  defaultFilename: string;
  validExtensions: Array<string>;
}

@Component({
  selector: 'app-excel-down-dialog',
  templateUrl: './excel-download-dialog.component.html',
  styleUrls: ['./excel-download-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExcelDownloadDialogComponent implements OnInit {
  form: UntypedFormGroup;
  validExtensions: Array<string>;

  constructor(private formBuilder: UntypedFormBuilder,
              @Inject(MAT_DIALOG_DATA) public params: ExcelDownloadDialogParams) { }

  ngOnInit(): void {
    this.validExtensions = this.params.validExtensions ?? [];
    this.form = this.formBuilder.group({
      filename: [this.params.defaultFilename, [Validators.required, this.hasTypeConflict.bind(this)]]
    });
  }

  private hasTypeConflict(control: UntypedFormControl): { [key: string]: any } {
    const value = control.value;
    return value.includes('.') && !this.validExtensions.some(ext => value.endsWith(ext))
      ? {typeBreach: true}
      : null;
  }
}
