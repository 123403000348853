import {Injectable} from '@angular/core';
import {AbstractControl, ValidatorFn} from '@angular/forms';

const nonAlphaNumericRegx = /[^a-z\d\s]/i;

export interface INonAlphanumericValidationService {
  readonly nonAlphaNumericValidator: ValidatorFn;
  isValidNonAlphaNumericValue(value: string): boolean;
}

@Injectable()
export class NonAlphanumericValidationService implements INonAlphanumericValidationService{

  constructor() { }

  isValidNonAlphaNumericValue(value: string): boolean {
    return !nonAlphaNumericRegx.test(value);
  }

  get nonAlphaNumericValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      return this.isValidNonAlphaNumericValue(control.value) ? null : {nonAlphaNumeric: true};
    };
  }
}
