import {Injectable} from '@angular/core';
import {est_timezone, momentDateTimeFormat} from '@models/constants';
import {
  AllProjectCategories,
  AllProjectCategory,
  AllProjectCategoryDescriptions,
  AllProjectsReport,
  ProjectInCategory
} from '@models/reports/allProjectsReport';
import * as moment from 'moment';
import commonTableStyle from '../../commonTableStyle';

export interface IAllProjectsTableProviderService {
  drawTable(doc: any, allProjectsReport: AllProjectsReport, yPos: number): void;
  drawProjectsTable(doc: any, allProjectsReport: AllProjectsReport, category: AllProjectCategory, yPosition: number): void;
}

@Injectable()
export class AllProjectsTableProviderService implements IAllProjectsTableProviderService {

  drawTable(doc: any, allProjectsReport: AllProjectsReport, yPos: number) {
    doc.autoTable({
      ...this.buildAllProjectsReport(allProjectsReport),
      ...commonTableStyle,
      startY: yPos,
      didParseCell: data => {
        data.cell.styles.halign = data.column.index === 0 ? 'left' : 'center';
      }
    });
  }

  drawProjectsTable(doc: any, allProjectsReport: AllProjectsReport, category: AllProjectCategory, yPosition: number) {
    doc.autoTable({
      ...this.buildProjectsReport(allProjectsReport[category].branches),
      ...commonTableStyle,
      startY: yPosition,
      didParseCell: data => {
        data.cell.styles.halign = data.column.index === 0 ? 'left' : 'center';
      }
    });
  }

  private buildAllProjectsReport(allProjectsReport: AllProjectsReport): { head: Array<any>, body: Array<Array<string>> } {
    const head = [{
      category: 'Projects',
      projectCount: 'Project Count',
      onHold: 'On Hold',
      averageDays: 'Avg. Days'
    }];

    const body = [];
    for (let category of AllProjectCategories) {
      if (allProjectsReport[category] != null) {
        body.push(this.createCategoryRow(allProjectsReport, AllProjectCategoryDescriptions[category], category as AllProjectCategory));
      }
    }

    return {head, body};
  }

  private createCategoryRow(report: AllProjectsReport, name: string, category: AllProjectCategory): string[] {
    const reportLine = report[category];
    return [
      name,
      reportLine.projectCount.toString(),
      reportLine.onHold.toString(),
      reportLine.averageDays.toFixed(2),
    ];
  };

  private buildProjectsReport(projects: Array<ProjectInCategory>): { head: Array<any>, body: Array<Array<string>> } {
    const head = [{
      name: 'Name',
      projectId: 'Project ID',
      startDate: 'Start Date',
      endDate: 'End Date',
      onHold: 'On Hold',
    }];

    const body = projects.map<Array<string>>(project => [
      project.name,
      project.projectId,
      moment(project.startDate).tz(est_timezone).format(momentDateTimeFormat),
      project.endDate ? moment(project.endDate).tz(est_timezone).format(momentDateTimeFormat) : '-',
      project.onHold ? 'Yes' : 'No'
    ]);

    return {head, body};
  }

}

