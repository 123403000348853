import {animate, state, style, transition, trigger} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {HostLogMessageField} from '@models/hostLogMessageField';
import {defaults, flatMap} from 'lodash';
import {Subject} from 'rxjs';
import {defaultOptions} from '../default.options';
import {Node, Options, SearchableNode, TreeTableNode} from '../models';
import {ConverterService} from '../services/converter/converter.service';
import {TreeService} from '../services/tree/tree.service';
import {ValidatorService} from '../services/validator/validator.service';

export interface ColumnDefinition {
  valueField: string;
  header?: string;
  template?: TemplateRef<any>;
}

@Component({
  selector: 'app-treetable',
  templateUrl: './treetable.component.html',
  styleUrls: ['./treetable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('250ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TreetableComponent<T> implements OnInit, OnChanges {
  @Input()
  public itemTemplate: TemplateRef<any>;

  @Input() tree: Node<T> | Node<T>[];
  @Input() options: Options<T> = {};
  @Input() columnDefinitions: ColumnDefinition[] = [];
  @Output() nodeClicked: Subject<TreeTableNode<T>> = new Subject();
  private searchableTree: SearchableNode<T>[];
  private treeTable: TreeTableNode<T>[];
  displayedColumns: string[];
  dataSource: MatTableDataSource<TreeTableNode<T>>;
  expanded = false;
  expandedElement: HostLogMessageField | null;

  constructor(
    private treeService: TreeService,
    private validatorService: ValidatorService,
    private converterService: ConverterService,
    private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.options = this.parseOptions(defaultOptions);
    this.updateTree();
    if (this.columnDefinitions.length) {
      this.displayedColumns = this.columnDefinitions.map(def => def.valueField);
    } else {
      this.displayedColumns = this.options.customColumnOrder
        ? this.options.customColumnOrder
        : this.extractNodeProps(this.tree[0]);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tree) {
      this.updateTree();
    }
  }

  hasValidationError(element: string): boolean {
    return element.length > 0;
  }

  splitIntoParagraph(text: string): string[] {
     return  text.split(',');
  }

  updateTree() {
    this.tree = Array.isArray(this.tree) ? this.tree : [this.tree];
    this.searchableTree = this.tree.map(t => this.converterService.toSearchableTree(t));
    const treeTableTree = this.searchableTree.map(st => this.converterService.toTreeTableTree(st));
    this.treeTable = flatMap(treeTableTree, this.treeService.flatten);
    this.dataSource = this.generateDataSource();
  }

  extractNodeProps(tree: Node<T> & { value: { [k: string]: any } }): string[] {
    return Object.keys(tree.value).filter(x => typeof tree.value[x] !== 'object');
  }

  generateDataSource(): MatTableDataSource<TreeTableNode<T>> {
    return new MatTableDataSource(this.treeTable.filter(x => x.isVisible));
  }

  formatIndentation(node: TreeTableNode<T>, step: number = 5): string {
    return '&nbsp;'.repeat(node.depth * step);
  }

  formatElevation(): string {
    return `mat-elevation-z${this.options.elevation}`;
  }

  onNodeClick(clickedNode: TreeTableNode<T>): void {
    this.expandNode(clickedNode, !clickedNode.isExpanded);
    this.nodeClicked.next(clickedNode);
  }

  expandNode(node: TreeTableNode<T>, isExpanded: boolean = true): void {
    node.isExpanded = isExpanded;
    this.treeTable.forEach(el => {
      el.isVisible = this.searchableTree.every(st => {
        return this.treeService.searchById(st, el.id).fold([], n => n.pathToRoot)
          .every(p => this.treeTable.find(x => x.id === p.id).isExpanded);
      });
    });
    this.dataSource = this.generateDataSource();
    this.changeDetectorRef.detectChanges();
  }

  // Overrides default options with those specified by the user
  parseOptions(defaultOpts: Options<T>): Options<T> {
    return defaults(this.options, defaultOpts);
  }

}
