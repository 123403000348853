<h2 mat-dialog-title>{{params.title}}</h2>
<mat-dialog-content>
  <div [formGroup]="form">

    <div style="display: flex; justify-content: space-between; align-items: center">
      <mat-form-field style="width: 70%">
        <mat-label>Search Parameter</mat-label>
        <mat-select formControlName="newParameter">
          <mat-option *ngFor="let searchType of sortedAvailableParameters"
                      [disabled]="searchType.field === SearchField.ClientLeadId && !canAddClientLeadFilter"
                      [value]="searchType.field">{{searchType.fieldDescription}}</mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-button mat-raised-button (click)="onAddParameterClicked()"
              [disabled]="!form.get('newParameter').value">Add Parameter
      </button>

    </div>
    <div class="search-item-list" formArrayName="parameters">
      <ng-container *ngFor="let _ of parameterArray.controls; let i = index">
        <app-project-filter-parameter [params]="existingParams[i]"
                                      [formControlName]="i"
                                      [sandbox]="params.isSandbox"
                                      (delete)="onRemoveParameter(i)"
                                      (valueChanged)="onSearchParameterChanged($event)">
        </app-project-filter-parameter>
      </ng-container>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="">Cancel</button>
  <button mat-button [mat-dialog-close]="parameterArray.value" [disabled]="form.invalid">Filter</button>
</mat-dialog-actions>
