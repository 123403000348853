import {ElementRef, Injectable} from '@angular/core';
import {ProjectDetail} from '@models/projectDetail';
import {ActiveProjectsReport} from '@models/reports/activeProjectsReport';
import {AllProjectsReport} from '@models/reports/allProjectsReport';
import {CompletedProjectsReport} from '@models/reports/completedProjectsReport';
import {DaysInPhaseReport} from '@models/reports/daysInPhaseReport';
import {TestsOverviewReport} from '@models/reports/testsOverviewReport';
import 'jspdf-autotable';
import {OverviewReportProviderService} from '../overview-report-provider.service/overview-report-provider.service';
import {ProjectReportProviderService} from '../project-report-provider.service/project-report-provider.service';

export interface IDownloadReportService {
  downloadProjectReport(projectDetail: ProjectDetail,
                        phaseReport: Array<DaysInPhaseReport>,
                        testsReport: TestsOverviewReport,
                        daysInPhaseChartCanvas: ElementRef,
                        testsChartCanvas: ElementRef,
                        filename: string): Promise<void>;

  downloadOverviewReport(clientId: number | null,
                         analystId: number | null,
                         allProjectsReport: AllProjectsReport,
                         activeProjectsReport: ActiveProjectsReport,
                         completedProjectsReport: CompletedProjectsReport,
                         allProjectsChartCanvas: ElementRef,
                         activeProjectsChartCanvas: ElementRef,
                         completedProjectsReportChartCanvas: ElementRef,
                         sandbox: boolean | false): Promise<void>;
}

@Injectable()
export class DownloadReportService implements IDownloadReportService {


  constructor(private projectReportProvider: ProjectReportProviderService,
              private overviewReportProvider: OverviewReportProviderService) { }


  downloadProjectReport(projectDetail: ProjectDetail,
                        phaseReport: Array<DaysInPhaseReport>,
                        testsReport: TestsOverviewReport,
                        daysInPhaseChartCanvas: ElementRef,
                        testsChartCanvas: ElementRef,
                        filename: string): Promise<void> {
    return this.projectReportProvider.downloadReport(projectDetail, phaseReport, testsReport, daysInPhaseChartCanvas, testsChartCanvas, filename);
  }

  downloadOverviewReport(clientId: number | null,
                         analystId: number | null,
                         allProjectsReport: AllProjectsReport,
                         activeProjectsReport: ActiveProjectsReport,
                         completedProjectsReport: CompletedProjectsReport,
                         allProjectsChartCanvas: ElementRef,
                         activeProjectsChartCanvas: ElementRef,
                         completedProjectsReportChartCanvas: ElementRef,
                         sandbox= false): Promise<void> {
    return this.overviewReportProvider.downloadReport(clientId, analystId, allProjectsReport, activeProjectsReport, completedProjectsReport, allProjectsChartCanvas, activeProjectsChartCanvas, completedProjectsReportChartCanvas, sandbox);
  }
}
