import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Icon} from '../../../svg/svg.component';

export interface HostLogValueInfo {
  value: string;
  hasChildren: boolean;
}


@Component({
  selector: 'app-host-log-value-display',
  templateUrl: './host-log-value-display.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HostLogValueDisplayComponent {
  @Input() field: HostLogValueInfo;
  private userWantsToSeeValue = false;

  readonly Icon = Icon;

  get showValue() {
    return !this.field.hasChildren || this.userWantsToSeeValue || !this.field.value?.length;
  }

  toggleDisplayValue() {
    if (this.field.value?.length) {
      this.userWantsToSeeValue = !this.userWantsToSeeValue;
    }
  }
}
