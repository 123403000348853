import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Comment} from '@models/comment';
import {PagedResponse} from '@models/pagedResponse';
import {Observable} from 'rxjs';
import {AppConfigService} from '../../configuration/app-config.service/app-config.service';
import {SortDirection} from '../sortDirection';
import {ICommentsService} from './iCommentsService';

@Injectable()
export class CommentsService implements ICommentsService {
  private readonly restUrl: string;

  constructor(private http: HttpClient,
              appConfig: AppConfigService) {
    this.restUrl = appConfig.current.restUrl;
  }

  getCommentsForProject(projectId: number, sort = 'creationDate', dir: SortDirection = 'desc',
                        page = 0, pageSize = 99999): Observable<PagedResponse<Comment>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', pageSize.toString())
      .set('sort', `${sort},${dir}`);
    return this.http.get<PagedResponse<Comment>>(this.getProjectCommentsUrl(projectId), {params});
  }

  getCommentsForTestCase(projectId: number, testCaseId: number, snapshot = false, sort = 'creationDate', dir: SortDirection = 'desc',
                         page = 0, pageSize = 99999): Observable<PagedResponse<Comment>> {

    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', pageSize.toString())
      .set('sort', `${sort},${dir}`);
    if (snapshot) {
      return this.http.get<PagedResponse<Comment>>(this.getSnapshotTestCaseCommentUrl(projectId, testCaseId), {params});
    }
    return this.http.get<PagedResponse<Comment>>(this.getTestCaseCommentUrl(projectId, testCaseId), {params});

  }

  createProjectComment(projectId: number, comment: Comment): Observable<Comment> {
    return this.http.post<Comment>(this.getProjectCommentsUrl(projectId), comment);
  }

  createTestCaseComment(projectId: number, testCaseId: number, isSnapshot: boolean, comment: Comment): Observable<Comment> {
    return isSnapshot ?
    this.http.post<Comment>(this.getSnapshotTestCaseCommentUrl(projectId, testCaseId), comment) :
    this.http.post<Comment>(this.getTestCaseCommentUrl(projectId, testCaseId), comment);
  }

  updateProjectComment(projectId: number, comment: Comment): Observable<Comment> {
    return this.http.put<Comment>(`${this.getProjectCommentsUrl(projectId)}/${comment.id}`, comment);
  }

  updateTestCaseComment(projectId: number, testCaseId: number, isSnapshot: boolean, comment: Comment): Observable<Comment> {
    return isSnapshot ?
    this.http.put<Comment>(`${this.getSnapshotTestCaseCommentUrl(projectId, testCaseId)}/${comment.id}`, comment) :
    this.http.put<Comment>(`${this.getTestCaseCommentUrl(projectId, testCaseId)}/${comment.id}`, comment);
  }

  deleteProjectComment(projectId: number, comment: Comment): Observable<any> {
    return this.http.delete(`${this.getProjectCommentsUrl(projectId)}/${comment.id}`);
  }

  deleteTestCaseComment(projectId: number, testCaseId: number, isSnapshot: boolean, comment: Comment): Observable<any> {
    return isSnapshot ?
    this.http.delete(`${this.getSnapshotTestCaseCommentUrl(projectId, testCaseId)}/${comment.id}`) :
    this.http.delete(`${this.getTestCaseCommentUrl(projectId, testCaseId)}/${comment.id}`);
  }

  private getProjectCommentsUrl = (projectId: number) => `${this.restUrl}/projects/${projectId}/comments`;
  private getTestCaseCommentUrl = (projectId: number, testCaseId: number) => `${this.restUrl}/projects/${projectId}/tests/${testCaseId}/comments`;
  private getSnapshotTestCaseCommentUrl = (projectId: number, testCaseId: number) => `${this.restUrl}/projects/${projectId}/tests/${testCaseId}/commentsSnapshot`;
}
