<div class="progress-container" *ngIf="projectDetail">
  <div class="project-name-display">
        <span>
          <em *ngIf="projectDetail.state === ProjectState.OnHold" matTooltip="On-hold"
              class="fa fa-pause-circle paused-icon"></em>
          <em *ngIf="projectDetail.state === ProjectState.Cancelled" matTooltip="Cancelled"
              class="fa fa-times-circle cancelled-icon"></em>
          {{projectDetail.name}}</span> - <span>{{projectDetail.projectId}}</span></div>

  <ul class='phase-progress' *ngIf="currentPhaseIndex >= 0">

    <ng-container *ngFor="let phase of displayedPhases; let i = index">
      <li [class.current]="currentPhaseIndex == i" [class.done]="currentPhaseIndex > i">
        <em *ngIf="i < currentPhaseIndex" class="fa fa-check"></em>
        <em *ngIf="i === currentPhaseIndex" class="fa fa-pen"></em>
        <span>{{phase | projectPhaseDescription}}</span>
      </li>
      <li *ngIf="i < (displayedPhases.length - 1)">
        <em class="fa fa-long-arrow-alt-right" [class.done]="currentPhaseIndex > i"></em>
      </li>
    </ng-container>

  </ul>
  <div *ngIf="projectDetail?.phase === ProjectPhase.Draft" class="draft-display">
    This project is currently in draft.
  </div>
</div>
