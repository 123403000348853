const commonTableStyle = {
  bodyStyles: {
    fontSize: 8,
  },
  headStyles: {
    fillColor: [230, 230, 230],
    fontSize: 7,
    textColor: [80, 80, 80]
  },
  footStyles: {
    fillColor: [230, 230, 230],
    textColor: [80, 80, 80],
    fontSize: 7
  },
  alternateRowStyles: {
    fillColor: window.getComputedStyle(document.body).getPropertyValue('--table-alternate-row-colour').trim(),
  },
};

export default commonTableStyle;
