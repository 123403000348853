<div class="col-container" [class.collapsed]="sideIsCollapsed">
  <div class="side-container">
    <ng-content select="[side]"></ng-content>
  </div>
  <div class="divider" (click)="toggleCollapsed()">
    <div class="floater"><div class="float-button"><i class="fa-4x fas fa-arrow-left"></i></div></div>
  </div>
  <div class="main-container">
    <ng-content select="[main]"></ng-content>
  </div>
</div>
