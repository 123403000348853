import {Injectable} from '@angular/core';
import {AbstractControl, ValidatorFn} from '@angular/forms';

const numberRegEx = /\d/;

export interface INonNumericValidationService {
  readonly nonNumberValidator: ValidatorFn;

  isValidNonNumberString(value: string): boolean;
}

@Injectable()
export class NonNumericValidationService implements INonNumericValidationService {

  isValidNonNumberString(value: string): boolean {
    return !numberRegEx.test(value);
  }

  get nonNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      return this.isValidNonNumberString(control.value) ? null : {nonNumeric: true};
    };
  }
}
