import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, MatSortHeader} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {Project} from '@models/project';
import {ProjectPhase} from '@models/projectPhase';
import {ProjectState} from '@models/projectState';
import {UsersService} from '@services/data/users.service/users.service';
import {BehaviorSubject, fromEvent, merge, Observable, Subscription} from 'rxjs';
import {debounceTime, map, tap} from 'rxjs/operators';
import {ProjectFilterService} from '../project-filter.service/project-filter.service';
import {ProjectTableDataSourceService} from './project-table-data-source.service/project-table-data-source.service';
import {TableSettingsStorageService} from './table-settings-storage.service';

export enum OwningPage {
  projects = 'projects',
  sandbox = 'sandbox-projects',
  client = 'client-projects'
}

@Component({
  selector: 'app-projects-table',
  templateUrl: './projects-table.component.html',
  styleUrls: ['./projects-table.component.scss']
})
export class ProjectsTableComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() canAddProjects: boolean;
  @Input() canReadProjects: boolean;
  @Input() filterClientId: number;
  @Input() owningPage: OwningPage = OwningPage.projects;
  @Input() canCopyFromSandbox = false;

  @Input() showAdvancedFilter = true;

  @Output() createProject = new EventEmitter();
  @Output() copySandboxProject = new EventEmitter<number>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('favouriteProjectsCheckbox') favouriteProjectsCheckbox: MatCheckbox;
  @ViewChild('filterInput', {static: true}) filterInput: ElementRef;
  private favouritesFilterToggled$: Observable<any>;
  private filterChanged$: Observable<any>;

  ProjectPhase = ProjectPhase;
  ProjectState = ProjectState;

  displayedColumns: string[];

  private readonly refreshToken$ = new BehaviorSubject(undefined);
  private subscription: Subscription;
  rows$: Observable<MatTableDataSource<Project>>;
  totalRows$: Observable<number>;
  private tableSettingsStorageService: TableSettingsStorageService;

  constructor(private projectTableDataSource: ProjectTableDataSourceService,
              private projectsFilter: ProjectFilterService,
              private usersService: UsersService,
              private router: Router,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  async ngOnInit() {
    this.displayedColumns =  ['name', 'projectId',  'platform', 'phase', 'creationDate', 'completion', 'test-progress', 'cycleTime', 'favourite'];

    if (this.isSandbox() && this.canAddProjects) {
      this.displayedColumns.push('clone');
    }

    this.rows$ = this.projectTableDataSource.rows$.pipe(
      map(transactions => new MatTableDataSource(transactions))
    );

    this.totalRows$ = this.projectTableDataSource.totalRows$;

    this.projectTableDataSource.filterClientId = this.filterClientId;
    this.projectTableDataSource.sandboxProjects = this.isSandbox();
    this.projectTableDataSource.showFavouritesOnly = false;
    this.tableSettingsStorageService = new TableSettingsStorageService(this.owningPage);
  }

  ngAfterViewInit(): void {
    const settings = this.tableSettingsStorageService?.get();
    if (settings) {
      this.favouriteProjectsCheckbox.writeValue(settings.showFavouritesOnly);
      this.setSort(settings.sort, settings.sortDirection as ('asc'|'desc'));
      this.filterInput.nativeElement.value = settings.filter;
      this.paginator.pageIndex = settings.pageIndex;
      this.paginator.pageSize = settings.pageSize;
      this.projectTableDataSource.filters = new Map(JSON.parse(settings.filters));
    } else {
      this.favouriteProjectsCheckbox.writeValue(false);
    }
    this.filterChanged$ = fromEvent(this.filterInput.nativeElement, 'input');
    this.favouritesFilterToggled$ = this.favouriteProjectsCheckbox.change.asObservable();
    this.subscription = merge(
      this.refreshToken$.pipe(debounceTime(500)),
      this.favouritesFilterToggled$.pipe(tap(() => this.paginator.pageIndex = 0)),
      this.filterChanged$.pipe(debounceTime(250), tap(() => this.paginator.pageIndex = 0)),
      this.sort.sortChange, this.paginator.page
    ).subscribe(async () => {
      await this.projectTableDataSource.setTable(this.sort.active, this.sort.direction,
        this.paginator.pageIndex, this.paginator.pageSize, this.favouriteProjectsCheckbox.checked,
        this.filterInput.nativeElement.value);
      this.saveTableSettingsToStorage();
    });

    this.changeDetectorRef.detectChanges();
  }

  isSandbox(): boolean {
    return this.owningPage === OwningPage.sandbox;
  }

  public setSort(id: string, start?: 'asc' | 'desc') {
    start = start || 'asc';
    const toState = 'active';
    const disableClear = false;

    // reset state so that start is the first sort direction that you will see
    this.sort.sort({ id: null, start, disableClear });
    this.sort.sort({ id, start, disableClear });

    // ugly hack
    (this.sort.sortables.get(id) as MatSortHeader)._setAnimationTransitionState({ toState });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.projectTableDataSource.resetFiltersAndTable();
  }

  refresh() {
    this.refreshToken$.next(undefined);
  }

  async projectDetail(id: number) {
    await this.router.navigate([this.isSandbox() ? 'sandbox-projects' : 'projects', id]);
  }

  async goToProjectTests(id: any) {
    await this.router.navigate([this.isSandbox() ? 'sandbox-projects' : 'projects', id, 'tests']);
  }

  async markFavourite(projectId: number, isFavourite: boolean) {
    await this.usersService.updateUsersFavouriteProject(isFavourite, projectId).toPromise();
    this.refresh();
  }

  async updateFilter() {
    const response = await this.projectsFilter.updateFilterParameters(this.projectTableDataSource.filters,
      'Filter Projects', this.isSandbox());
    if (response) {
      const filters = new Map<string, string>();
      response.forEach(res => filters.set(res.type.field, res.value.toString()));
      await this.projectTableDataSource.setFilters(filters);
      this.saveTableSettingsToStorage();
    }
    this.paginator.firstPage();
  }

  async onClearPressed() {
    const clearedFilters = new Map<string, string>();
    await this.projectTableDataSource.setFilters(clearedFilters);
    this.saveTableSettingsToStorage();
    this.paginator.firstPage();
  }

  hasNoFilters() {
    return !this.projectTableDataSource.filters?.size;
  }

  private saveTableSettingsToStorage() {
    if (this.tableSettingsStorageService) {
      this.tableSettingsStorageService.set({
        filter: this.filterInput.nativeElement.value,
        filters: JSON.stringify(Array.from(this.projectTableDataSource.filters.entries())),
        pageIndex: this.paginator.pageIndex,
        pageSize: this.paginator.pageSize,
        showFavouritesOnly: this.favouriteProjectsCheckbox.checked,
        sortDirection: this.sort.direction,
        sort: this.sort.active
      });
    }
  }
}
