export enum ProjectState {
    Active = 'ACTIVE',
    InActive = 'INACTIVE',
    OnHold = 'ON_HOLD',
    Cancelled = 'CANCELLED',
    Closed = 'CLOSED'
}

export const AllStates = [
  ProjectState.Active,
  ProjectState.InActive,
  ProjectState.OnHold,
  ProjectState.Cancelled,
  ProjectState.Closed
];
