import {Injectable} from '@angular/core';
import {ClientType} from '@models/clients';

const descriptions: Record<ClientType, string> = {
  [ClientType.gateway]: 'Gateway',
  [ClientType.merchant]: 'Merchant',
  [ClientType.vendor]: 'Vendor'
};


export interface IClientTypeDescriptionService {getDescription(type: ClientType): string;}

@Injectable()
export class ClientTypeDescriptionService implements IClientTypeDescriptionService {
  getDescription(type: ClientType): string {
    return descriptions[type];
  }
}
