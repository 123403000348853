import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {FormType, IntakeForm} from '@models/intake-forms';
import {LetterOfApproval, LetterOfApprovalType} from '@models/loa';
import {PagedResponse} from '@models/pagedResponse';
import {Project, ProjectIdInfo} from '@models/project';
import {ProjectDetail} from '@models/projectDetail';
import {ProjectFile} from '@models/projectFile';
import {ProjectPhase} from '@models/projectPhase';
import {ProjectSummary} from '@models/projectSummary';
import {SandboxProjectSummary} from '@models/sandboxProjectSummary';
import {AuthInfo} from '@models/security';
import {TerminalId} from '@models/terminalId';
import {ProjectTestPlan} from '@models/tests/projectTestPlan';
import {TestCaseWaiver} from '@models/tests/testCaseWaiver';
import {Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthenticationService} from '../../auth/authentication.service/authentication.service';
import {AppConfigService} from '../../configuration/app-config.service/app-config.service';
import {FilterParameters, populateFilterParameters} from '../filterParameters';
import {SortDirection} from '../sortDirection';
import {IProjectsService} from './iProjectsService';

export enum TerminalIdState {
  Active = 'ACTIVE',
  InActive = 'INACTIVE',
  All = 'ALL'
}

export interface TerminalIdInUse extends TerminalId {
  projectId: number;
  longProjectId: string;
  projectName: string;
  platform: string;
}

@Injectable()
export class ProjectsService implements OnDestroy, IProjectsService {

  private readonly projectsUrl: string;
  private readonly cardLogsUrl: string;
  private readonly testRunsUrl: string;
  private readonly testRunsSnapshotUrl: string;
  private authInfo: AuthInfo;
  private authInfoSubscription: Subscription;
  isSnapshot: boolean;

  constructor(private http: HttpClient,
              appConfig: AppConfigService,
              private authService: AuthenticationService) {
    this.projectsUrl = appConfig.getRestUrl('/projects');
    this.cardLogsUrl = appConfig.getRestUrl('/cardlogs');
    this.testRunsUrl = appConfig.getRestUrl('/testruns');
    this.testRunsSnapshotUrl = appConfig.getRestUrl('/testruns/snapshot');

    this.authInfoSubscription = this.authService.authInfo$.subscribe(info => {
      this.authInfo = info;
    });
  }

  /**
   * Get all projects
   */
  getProjects(sort = 'name',
              dir = 'desc',
              page = 0,
              pageSize = 99999,
              searchParams: FilterParameters = null,
              favouriteFilter = false,
              filter = ''): Observable<PagedResponse<Project>> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('size', pageSize.toString())
      .set('sort', `${sort},${dir}`)
      .set('filter', filter);

    if (searchParams) {
      params = populateFilterParameters(searchParams, params);
    }

    if (favouriteFilter) {
      params = params.set('favourites', `${favouriteFilter}`);
    }

    return this.http.get<PagedResponse<Project>>(this.projectsUrl, {params});
  }

  getProjectSummaries(sort = 'name',
                      dir = 'asc',
                      searchParams: FilterParameters = null): Observable<Project[]> {
    let params = new HttpParams()
      .set('sort', `${sort},${dir}`);

    if (searchParams) {
      params = populateFilterParameters(searchParams, params);
    }

    return this.http.get<Project[]>(`${this.projectsUrl}/summary`, {params});
  }

  /**
   * Get a list of all sandbox projects
   * @returns {Observable<SandboxProjectSummary[]>}
   */
  getAllSandboxProjects(): Observable<SandboxProjectSummary[]> {
    const params = new HttpParams()
      .set('sandbox', 'true');
    return this.http.get<Array<SandboxProjectSummary>>(`${this.projectsUrl}/summary`, {params});
  }

  setIsSnapshot(snapshot: boolean): void {
    this.isSnapshot = snapshot;
  }

  /**
   * Create a new project
   * @param project - project information
   * @param isDraft - if true, project is created at a draft phase
   */
  createProject(project: Project, isDraft: boolean): Observable<Project> {
    const params = new HttpParams()
      .set('draftProject', isDraft.toString());
    return this.http.post<Project>(this.projectsUrl, project, {params});
  }

  /**
   * Get a summary list of all projects
   * @returns {Observable<Array<ProjectIdInfo>>}
   */
  getAllProjectIdInfo(): Observable<Array<ProjectIdInfo>> {
    return this.http.get<Array<ProjectIdInfo>>(`${this.projectsUrl}/summary`);
  }

  /**
   * Get the details of the given project
   * @param projectId - id of the project to retrieve
   */
  getProjectDetails(projectId: string): Observable<ProjectDetail> {
    return this.http.get<ProjectDetail>(`${this.projectsUrl}/${projectId}`)
      .pipe(map(project => {
        if (!project.assignedAnalysts) {
          project.assignedAnalysts = [];
        }

        return project;
      }));
  }

  /**
   * Get the test plans for given project
   * @param projectId - id of the project to retrieve tests for
   */
  getTestPlans(projectId: string): Observable<Array<ProjectTestPlan>> {
    return this.http.get<Array<ProjectTestPlan>>(`${this.projectsUrl}/${projectId}/projectTestPlans`);
  }

  /**
   * Get the test snapshots for given project
   * @param projectId - id of the project to retrieve snapshots for
   */
  getTestSnapshots(projectId: string): Observable<Array<ProjectTestPlan>> {
    return this.http.get<Array<ProjectTestPlan>>(`${this.projectsUrl}/${projectId}/projectTestPlansSnapshot`);
  }

  /**
   * Set the current phase of a project
   * @param projectId - id of the project to set the phase of
   * @param phase - new phase
   * @param validationState - state of validation
   * @param clearResults - when moving to implementation, should test results be cleared
   * @param restoreFromSnapshot - when moving back to test certify, should test results be restored from snapshot
   */
  setProjectPhase(projectId: string,
                  projectPhase: ProjectPhase,
                  validationState = false,
                  clearResults = false,
                  restoreFromSnapshot = false,
                  progress = false): Observable<ProjectDetail> {
    const params = new HttpParams()
      .set('validated', `${validationState}`)
      .set('clearResults', `${clearResults}`)
      .set('restoreFromSnapshot', `${restoreFromSnapshot}`);
    const body = {phase: projectPhase, isProgress: progress};
    return this.http.patch<ProjectDetail>(`${this.projectsUrl}/${projectId}`, body, {params});
  }

  /**
   * Fetch terminalIds used in projects
   * @param state - The state of the terminal IDs
   */
  getTerminalIdsInUse(state: TerminalIdState): Observable<Array<TerminalIdInUse>> {
    const params = new HttpParams().set('state', state);
    const headers = new HttpHeaders().append('background-request', 'true');
    return this.http.get<Array<TerminalIdInUse>>(`${this.projectsUrl}/terminals/ids`, {params, headers});
  }

  /**
   * Generate or re-generate the Acquirer Letter of Approval
   */
  generateAcquirerLoa(projectId: number): Observable<ProjectFile> {
    return this.http.post<ProjectFile>(`${this.projectsUrl}/${projectId}/loa`, null);
  }

  ngOnDestroy(): void {
    this.authInfoSubscription.unsubscribe();
  }

  updateProject(projectDetail: ProjectDetail): Observable<ProjectDetail> {
    return this.http.put<ProjectDetail>(`${this.projectsUrl}/${projectDetail.id}`, projectDetail);
  }

  /**
   * Get the intake form for this project
   * @param projectId - id of the project
   */
  getIntakeForm(projectId: number): Observable<IntakeForm> {
    return this.http.get<IntakeForm>(`${this.projectsUrl}/${projectId}/intake`);
  }

  /**
   * Save the answer of the intake form
   * @param projectId - id of the project
   * @param intakeForm - answers to save
   */
  saveIntakeForm(projectId: number, intakeForm: IntakeForm): Observable<IntakeForm> {
    return this.http.put<IntakeForm>(`${this.projectsUrl}/${projectId}/intake`, intakeForm);
  }

  downloadFormXml(projectId: number, formType: FormType): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders(),
      responseType: 'blob' as 'json'
    };
    return this.http.get<string>(`${this.projectsUrl}/${projectId}/intake/download?type=${formType}`, httpOptions);
  }

  getLoa(projectId: number, letterType: LetterOfApprovalType): Observable<LetterOfApproval> {
    return this.http.get<LetterOfApproval>(`${this.projectsUrl}/${projectId}/loa?loAType=${letterType}`);
  }

  updateLoa(projectId: number, loa: LetterOfApproval): Observable<LetterOfApproval> {
    return this.http.put<LetterOfApproval>(`${this.projectsUrl}/${projectId}/loa?loAType=${loa.type}`, loa);
  }

  downloadLoaForm(projectId: number, letterType: LetterOfApprovalType): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders(),
      responseType: 'blob' as 'json'
    };
    return this.http.get<string>(`${this.projectsUrl}/${projectId}/loa/download?loAType=${letterType}`, httpOptions);
  }

  regenerateLoa(projectId: number): Observable<any> {
    return this.http.post(`${this.projectsUrl}/${projectId}/loa/regenerate`, null);
  }

  /**
   * Delete a project
   * @param {number} projectId - id of the project to delete
   * @returns {Observable<any>}
   */
  deleteProject(projectId: number): Observable<any> {
    return this.http.delete(`${this.projectsUrl}/${projectId}`);
  }


  /**
   * Assign a host log to a test case
   * @param {number} projectId - project containing the test case log
   * @param {number} testCaseId - id of the test case
   * @param {number} hostLogId - id of the host log
   * @returns {Observable<Object>}
   */
  assignHostLogToTestCase(projectId: number, testCaseId: number, hostLogId: number): Observable<any> {
    const params = new HttpParams()
      .set('hostLogTransactionId', hostLogId.toString());
    return this.http.patch(`${this.projectsUrl}/${projectId}/tests/${testCaseId}`, {}, {params});
  }

  assignHostLogToCardLog(cardLogId: number, hostLogId: number): Observable<any> {
    const params = new HttpParams()
      .set('hostLogTransactionId', hostLogId.toString());
    return this.http.patch(`${this.cardLogsUrl}/${cardLogId}`, {}, {params});
  }

  downloadCardLog(testRunId: number, isSnapshot: boolean): Observable<any> {
    return this.http.get(`${isSnapshot ? this.testRunsSnapshotUrl : this.testRunsUrl}/${testRunId}/file`, {responseType: 'blob'});
  }

  /**
   * Get the test case waivers associated with a project
   * @param {number} projectId - id of the project
   * @param sort - sort for list
   * @param {SortDirection} dir - sort direction for list
   * @param page - page index for paging
   * @param pageSize - page size for paging
   * @returns {Observable<PagedResponse<any>>}
   */
  getWaiversForProject(projectId: number,
                       sort = 'name',
                       dir: SortDirection = 'desc',
                       page = 0,
                       pageSize = 99999): Observable<PagedResponse<TestCaseWaiver>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', pageSize.toString())
      .set('sort', `${sort},${dir}`);
    return this.http.get<PagedResponse<TestCaseWaiver>>(`${this.projectsUrl}/${projectId}/tests/waivers`, {params});
  }

  getAssociatedProjectsForUser(userId: number): Observable<Project[]>{
    const params = new HttpParams()
      .set('userId', userId.toString());
    return this.http.get<Project[]>(`${this.projectsUrl}/associated`, {params});
  }

  getAssociatedProjectsForClient(userId: number): Observable<Project[]>{
    const params = new HttpParams()
      .set('merchantId', userId.toString());
    return this.http.get<Project[]>(`${this.projectsUrl}/associated`, {params});
  }

  /**
   * Get the overrides for a project
   * @param {number} projectId - id of the project
   * @param sort - sort for list
   * @param {SortDirection} direction - sort direction for list
   * @param pageIndex - page index for paging
   * @param pageSize - page size for paging
   * @returns {Observable<PagedResponse<TestCaseWaiver>>}
   */
  getOverridesForProject(projectId: number,
                         sort: string,
                         direction: SortDirection,
                         pageIndex: number,
                         pageSize: number): Observable<PagedResponse<TestCaseWaiver>> {
    const params = new HttpParams()
      .set('page', pageIndex.toString())
      .set('size', pageSize.toString())
      .set('sort', `${sort},${direction}`);
    return this.http.get<PagedResponse<TestCaseWaiver>>(`${this.projectsUrl}/${projectId}/tests/overrides`, {params});
  }

  /**
   * Get all the projects in the system
   * @returns {Observable<Project[]>}
   */
  getAllProjects(): Observable<Project[]> {
    return this.http.get<PagedResponse<Project>>(this.projectsUrl + '?size=99999')
      .pipe(map(({data}) => data));
  }

  getAllProjectsSummary(): Observable<ProjectSummary[]> {
    return this.http.get<ProjectSummary[]>(`${this.projectsUrl}/summary/all`);
  }

  notifyICC(projectId: number, emailNote: string): Observable<ProjectDetail> {
    const params = new HttpParams()
      .set('emailNote', emailNote);
    return this.http.post<ProjectDetail>(`${this.projectsUrl}/${projectId}/notifyICC`, {}, {params});
  }

  /**
   * Set the current phase of a project
   * @param projectId - id of the project to set the phase of
   */
  restoreFromSnapshot(projectId: number): Observable<any> {
    return this.http.patch(`${this.projectsUrl}/${projectId}/restore`, {});
  }
}
