import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {VersionInfo} from '@models/versionInfo';
import {AppConfigService} from '@services/configuration/app-config.service/app-config.service';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import config from '../../../../package.json';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  private readonly apiVersionUrl: string;

  constructor(private http: HttpClient,
              configService: AppConfigService) {
    this.apiVersionUrl = configService.getRestUrl('/version');
  }

  getApiVersion(): Observable<VersionInfo> {
    return this.http.get<VersionInfo>(this.apiVersionUrl)
      .pipe(catchError(() => {
        return of({
          version: 'unknown',
          revision: 'unknown'
        });
      }));
  }

  getUiVersionInfo(): VersionInfo {
    return {version: config.publicVersion, revision: config.revision};
  }
}
