<h1 mat-dialog-title>Create a new user</h1>
<div mat-dialog-content [formGroup]="userForm">
  <div class="row">
    <div class="col-6">
      <mat-form-field appearance="standard">
        <mat-label>Email</mat-label>
        <input placeholder="user@company.com" matInput name="email" formControlName="email" maxlength="255"
               autocomplete="off">
        <mat-error *ngIf="email.errors?.required">
          You must enter an email
        </mat-error>
        <mat-error *ngIf="email.errors?.email">
          Not a valid email
        </mat-error>
        <mat-error *ngIf="email.errors?.alreadyUsed">
          This email address is already used by another user. Email address must be unique.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field appearance="standard">
        <mat-label>Password</mat-label>
        <input matInput placeholder="password" name="password" [type]="hide ? 'password' : 'text'"
               formControlName="password" maxlength="255"
               autocomplete="new-password">
        <mat-error *ngIf="password.errors?.tooShort">
          You must supply a password of at least {{minimumPasswordLength}} characters.
        </mat-error>
        <mat-error *ngIf="password.errors?.notComplex">
          The password should consist of at least one uppercase, one lowercase, one numeric and one symbolic character.
        </mat-error>
        <mat-error *ngIf="password.errors?.cannotIncludeUsername">
          The password should not include the user's email address.
        </mat-error>
        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
          <em class="fa" [class.fa-eye-slash]="hide" [class.fa-eye]="!hide"></em>
        </button>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <mat-form-field appearance="standard">
        <mat-label>First Name</mat-label>
        <input matInput preventNumeric placeholder="first name" name="firstName" formControlName="firstName"
               maxlength="255">
        <mat-error *ngIf="firstName.errors?.required">You must enter a first name</mat-error>
        <mat-error *ngIf="firstName.errors?.nonNumeric">Numbers cannot be included in first name</mat-error>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field appearance="standard">
        <mat-label>Surname</mat-label>
        <input matInput preventNumeric placeholder="surname" name="lastName" formControlName="lastName" maxlength="255">
        <mat-error *ngIf="lastName.errors?.required">You must enter a surname</mat-error>
        <mat-error *ngIf="lastName.errors?.nonNumeric">Numbers cannot be included in surname</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <mat-form-field appearance="standard">
        <mat-label>EID</mat-label>
        <input matInput placeholder="EID" name="eid" formControlName="eid" maxlength="20">
        <mat-error *ngIf="userForm.get('eid').errors?.maxLength">
          EID cannot exceed 20 characters.
        </mat-error>
        <mat-error *ngIf="userForm.get('eid').errors?.nonAlphaNumeric">
          EID must be alphanumeric.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field appearance="standard">
        <mat-label>LAN ID</mat-label>
        <input matInput placeholder="LAN ID" name="lanId" formControlName="lanId" maxlength="10">
        <mat-error *ngIf="userForm.get('lanId').errors?.maxLength">
          LAN ID cannot exceed 10 characters.
        </mat-error>
        <mat-error *ngIf="userForm.get('lanId').errors?.nonAlphaNumeric">
          LAN ID must be alphanumeric.
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <mat-form-field appearance="standard">
        <mat-label>User Role</mat-label>
        <mat-select formControlName="role">
          <mat-option *ngFor="let role of roles" [value]="role.role">
            {{role.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="userForm.get('role').errors?.required">
          You must select a user role
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</div>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="" cdkFocusInitial>Cancel</button>
  <button mat-button id="okButton" [mat-dialog-close]="userForm.value" [disabled]="userForm.invalid">OK</button>
</mat-dialog-actions>
