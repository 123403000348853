<div class="spinner-container" [class.with-box]="showBox">
  <div class="spinner">
    <div class="rect1"></div>
    <div class="rect2"></div>
    <div class="rect3"></div>
    <div class="rect4"></div>
    <div class="rect5"></div>
  </div>
  <ng-content></ng-content>
</div>
