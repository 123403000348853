<div class="item-container">

  <div>{{value.type.fieldDescription}}</div>
  <div [ngSwitch]="value.type.field">
    <ng-container *ngSwitchDefault [ngTemplateOutlet]="textInput"></ng-container>
    <ng-container *ngSwitchCase="SearchField.BeforeWaiverExpiryDate" [ngTemplateOutlet]="date"></ng-container>
    <ng-container *ngSwitchCase="SearchField.AfterWaiverExpiryDate" [ngTemplateOutlet]="date"></ng-container>
    <ng-container *ngSwitchCase="SearchField.Client" [ngTemplateOutlet]="clientInput"></ng-container>
    <ng-container *ngSwitchCase="SearchField.ProjectName" [ngTemplateOutlet]="projectNameInput"></ng-container>
    <ng-container *ngSwitchCase="SearchField.ProjectId" [ngTemplateOutlet]="projectIdInput"></ng-container>
    <ng-container *ngSwitchCase="SearchField.ProjectPlatform" [ngTemplateOutlet]="projectPlatformInput"></ng-container>
  </div>
  <div>
    <app-overlay-button imageUrl="assets/icons/delete.svg"
                        (click)="delete.emit()"
                        rolloverImageUrl="assets/icons/delete-hilight.svg"></app-overlay-button>
  </div>
</div>

<!-- General Text Field Parameter -->
<ng-template #textInput>
  <mat-form-field>
    <mat-label>Value</mat-label>
    <input type="text" matInput [(ngModel)]="paramValue" required/>
  </mat-form-field>
</ng-template>
<!-- Before Date Select -->
<ng-template #date>
  <mat-form-field style="width: 100%">
    <input matInput [ngxMatDatetimePicker]="date" [(ngModel)]="paramValue"
           placeholder="Select Date" (dateChange)="onDateChange($event)">
    <mat-datepicker-toggle matSuffix [for]="$any(date)">
    </mat-datepicker-toggle>
    <ngx-mat-datetime-picker #date [hideTime]="true"></ngx-mat-datetime-picker>
  </mat-form-field>
</ng-template>

<!-- Client Selector Parameter -->
<ng-template #clientInput>
  <mat-form-field>
    <input #client matInput placeholder="Client" [matAutocomplete]="autoClient" (focus)="onClientFocus($event)" (blur)="onClientBlur($event)">
    <mat-autocomplete #autoClient="matAutocomplete" [displayWith]="getClientName" (optionSelected)="paramValue = $event.option.value.id">
      <mat-option *ngFor="let client of (filteredClients$ | async)" [value]="client">
        {{client.merchantName}}</mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-template>

<!-- Project Name Selector Parameter -->
<ng-template #projectNameInput>
  <mat-form-field>
    <input #projectId matInput placeholder="Project Name" [matAutocomplete]="autoProjectName" (focus)="onProjectNameFocus($event)"
           (blur)="onProjectBlur($event)">
    <mat-autocomplete #autoProjectName="matAutocomplete" [displayWith]="getProjectName"
                      (optionSelected)="paramValue = $event.option.value.name">
      <mat-option *ngFor="let project of (filteredProjects$ | async)" [value]="project">
        {{project.name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-template>

<!-- Project ID Selector Parameter -->
<ng-template #projectIdInput>
  <mat-form-field>
    <input #projectId matInput placeholder="Project ID" [matAutocomplete]="autoProjectId" (focus)="onProjectIdFocus($event)"
           (blur)="onProjectBlur($event)">
    <mat-autocomplete #autoProjectId="matAutocomplete" [displayWith]="getProjectId"
                      (optionSelected)="paramValue = $event.option.value.projectId">
      <mat-option *ngFor="let project of (filteredProjects$ | async)" [value]="project">
        {{project.projectId}} ({{project.name}})
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-template>

<!-- Project Platform Selector Parameter -->
<ng-template #projectPlatformInput>
  <mat-form-field>
    <input #projectPlatform matInput placeholder="Platform" [matAutocomplete]="autoPlatform">
    <mat-autocomplete #autoPlatform="matAutocomplete" (optionSelected)="paramValue = $event.option.value">
      <mat-option *ngFor="let platform of allPlatforms" [value]="platform.name">
        {{platform.name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-template>
