import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ProjectTestSummaryTooltipDirective} from '../components/projects-table/project-test-summary-tooltip.directive';
import {DragDropDirective} from './drag-drop.directive';
import {ExpandableDirective} from './expandable.directive';
import {PreventNumericDirective} from './prevent-numeric.directive';

const DIRECTIVE_COMPONENTS = [
  ProjectTestSummaryTooltipDirective,
  ExpandableDirective,
  PreventNumericDirective,
  DragDropDirective
];

@NgModule({
            declarations: DIRECTIVE_COMPONENTS,
            imports: [
              CommonModule
            ],
            exports: DIRECTIVE_COMPONENTS
          })
export class DirectivesModule {}
