import {Injectable} from '@angular/core';
import {
  AllCompletedProjectMonths,
  CompletedProjectMonthDescriptions,
  CompletedProjectsReport
} from '@models/reports/completedProjectsReport';
import commonTableStyle from '../../commonTableStyle';

export interface ICompletedProjectsTableProviderService {
  drawTable(doc: any, completedProjectsReport: CompletedProjectsReport, yPos: number): void;
}

@Injectable()
export class CompletedProjectsTableProviderService implements ICompletedProjectsTableProviderService {

  drawTable(doc: any, completedProjectsReport: CompletedProjectsReport, yPos: number) {
    doc.autoTable({
      ...this.buildCompletedProjectsReport(completedProjectsReport),
      ...commonTableStyle,
      startY: yPos,
      showFoot: 'lastPage',
      didParseCell: data => {
        data.cell.styles.halign = data.column.index === 0 ? 'left' : 'center';
      },
    });
  }

  private buildCompletedProjectsReport(completedProjectsReport: CompletedProjectsReport): { head: Array<any>, body: Array<Array<string>>, foot: Array<any> } {
    const yearColumns = Object.getOwnPropertyNames(completedProjectsReport);
    let head = ['Month'];
    const totals: {[year: string]: number} = {};
    for (let year of yearColumns) {
      head.push(year.toString());
      totals[year] = 0;
    }

    const tableData = AllCompletedProjectMonths.map(month => {
      const row = [CompletedProjectMonthDescriptions[month]];
      for (let year of yearColumns) {
        row.push(completedProjectsReport[year][month]?.toString() ?? '-');
        totals[year] += completedProjectsReport[year][month] ?? 0;
      }
      return row;
    });
    const totalRow = ['Total'];
    for (let year of yearColumns) {
      totalRow.push(totals[year].toString());
    }
    return {head:[head], body: [...tableData], foot: [totalRow]}
  }
}
