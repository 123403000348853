import {Injectable} from '@angular/core';
import {Action, Resource} from '@models/security';
import {TerminalId} from '@models/terminalId';
import {Observable, of} from 'rxjs';
import {filter, first, map, mergeMap, shareReplay, switchMap} from 'rxjs/operators';
import {PermissionsService} from '../auth/permissions.service/permissions.service';
import {ProjectsService, TerminalIdInUse, TerminalIdState} from './projects.service/projects.service';

export class TerminalIdConflict {
  terminalId: TerminalIdInUse;
  conflictType: 'MID_TID' | 'MID';

  constructor(terminalId: TerminalIdInUse, conflictType: 'MID_TID' | 'MID') {
    this.terminalId = terminalId;
    this.conflictType = conflictType;
  }

  get conflictTypeDescription(): string {
    switch (this.conflictType) {
      case 'MID':
        return 'Merchant ID';
      case 'MID_TID':
        return 'Merchant ID/Terminal ID combination';
      default:
        return '';
    }
  }
}

@Injectable()
export class UniqueTerminalIDService {
  private activeTerminalIds$: Observable<Array<TerminalIdInUse>>;
  private inactiveTerminalIds$: Observable<Array<TerminalIdInUse>>;

  constructor(projectsService: ProjectsService,
              permissionsService: PermissionsService) {

    this.activeTerminalIds$ =
      permissionsService.hasPermission([Resource.project, Action.edit])
        .pipe(
          switchMap((res) => res ? projectsService.getTerminalIdsInUse(TerminalIdState.Active) : of([])),
          map(ids => ids.map<TerminalIdInUse>(id => UniqueTerminalIDService.trimTerminal(id) as TerminalIdInUse)));
    this.inactiveTerminalIds$ =
      permissionsService.hasPermission([Resource.project, Action.edit])
        .pipe(
          switchMap((res) => res ? projectsService.getTerminalIdsInUse(TerminalIdState.InActive) : of([])),
          map(ids => ids.map<TerminalIdInUse>(id => UniqueTerminalIDService.trimTerminal(id) as TerminalIdInUse)),
          shareReplay());
  }


  isUniqueActiveTerminalId(terminalId: TerminalId, platform: string, excludingProjectId: number): Observable<boolean> {
    terminalId = UniqueTerminalIDService.trimTerminal(terminalId);
    return this.activeTerminalIds$
      .pipe(
        map((terminals) => !terminals.some(t =>
          t.projectId !== excludingProjectId
          && (terminalId.terminalId === t.terminalId || this.platformIsNashvilleOrCardnet(t, platform))
          && terminalId.merchantId === t.merchantId
          && (!terminalId.id || terminalId.id !== t.id))),
        first());
  }

  private platformIsNashvilleOrCardnet(t: TerminalIdInUse, platform: string): boolean {
    return t.platform === 'Nashville' || platform === 'Nashville' || t.platform === 'Cardnet' || platform === 'Cardnet';
  }

  isUniqueInactiveTerminalId(terminalId: TerminalId, projectId: number, platform: string): Observable<TerminalIdConflict> {
    terminalId = UniqueTerminalIDService.trimTerminal(terminalId);
    return this.inactiveTerminalIds$
      .pipe(mergeMap(terminalIds => terminalIds),
            map(t => {
              let conflictType: 'MID' | 'MID_TID';
              if (projectId !== t.projectId
                  && terminalId.merchantId === t.merchantId
                  && (!terminalId.id || terminalId.id !== t.id)) {
                if (this.platformIsNashvilleOrCardnet(t, platform)) {
                  conflictType = 'MID';
                } else if (terminalId.terminalId === t.terminalId) {
                  conflictType = 'MID_TID';
                }
              }
              return new TerminalIdConflict(t, conflictType);
            }),
            filter(tidConflict => !!tidConflict.conflictType),
            first());
  }

  hasMatch(terminalId: TerminalId, terminalIds: Array<TerminalId>): boolean {
    terminalIds = terminalIds.map(t => UniqueTerminalIDService.trimTerminal(t));
    terminalId = UniqueTerminalIDService.trimTerminal(terminalId);
    return terminalIds.some(t =>
      terminalId.terminalId === t.terminalId
      && terminalId.merchantId === t.merchantId
      && (!terminalId.id || terminalId.id !== t.id));
  }

  private static trimTerminal(t: TerminalId): TerminalId {
    return ({
      ...t,
      terminalId: UniqueTerminalIDService.trimLeadingZeros(t.terminalId),
      merchantId: UniqueTerminalIDService.trimLeadingZeros(t.merchantId)
    });
  }

  private static trimLeadingZeros(s: string): string {
    return s.trim().replace(/^0+/, '');
  }
}
