import {Injectable} from '@angular/core';
import {AbstractControl, ValidatorFn} from '@angular/forms';

const UsZipCodeRegex = /^\d{5}$|^\d{5}-\d{4}$/;
const CanadianZipCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;

export interface IZipcodeValidatorService {
  readonly zipCodeValidator: ValidatorFn;
  isValidZipCode(code: string): boolean;
}

@Injectable()
export class ZipcodeValidatorService implements IZipcodeValidatorService {

  isValidZipCode(code: string): boolean {
    return UsZipCodeRegex.test(code) || CanadianZipCodeRegex.test(code);
  }

  get zipCodeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      return this.isValidZipCode(control.value) ? null : {zipcode: true};
    };
  }
}
