import {Directive, EventEmitter, HostBinding, HostListener, Output} from '@angular/core';

@Directive({
             selector: '[app-drag-drop]'
           })
export class DragDropDirective {
  @HostBinding('class.file-over') fileOver: boolean;

  @Output() fileDropped: EventEmitter<DragEvent> = new EventEmitter<DragEvent>();

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.fileOver = false;
  }

  @HostListener('drop', ['$event'])
  ondrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.fileOver = false;

    const files: FileList = event.dataTransfer.files;

    if (files.length > 0) {
      this.fileDropped.next(event);
    }
  }
}
