import {Component, forwardRef, Input, ViewChild} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {MatSelect} from '@angular/material/select';
import {InlineControlComponent} from './inline-control.component';

const INLINE_EDIT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InlineSelectComponent),
  multi: true
};

export interface SelectOption {
  value: any;
  label: string;
}

@Component({
  selector: 'app-inline-select',
  templateUrl: './inline-select.component.html',
  providers: [INLINE_EDIT_CONTROL_VALUE_ACCESSOR],
  styleUrls: ['./inline-select.component.scss']
})
export class InlineSelectComponent extends InlineControlComponent<any> {

  @ViewChild('inlineEditControl') inlineEditControl: MatSelect;
  @Input() options: SelectOption[] = [];

  constructor() {
    super();
  }

  edit() {
    if (this.readonly) {
      return;
    }

    this.editing = true;
    // Focus on the input element just as the editing begins
    setTimeout(_ => this.inlineEditControl.open());
  }

  onClosed() {
    this.editing = false;
  }

  getValueLabel(value: any) {
    return this.options?.find(op => op.value === value)?.label ?? '';
  }
}
