<h1 mat-dialog-title>ICC Notification</h1>
<div mat-dialog-content [formGroup]="form">
  <p>You can add an optional email Note</p>
  <mat-form-field>
    <mat-label>Email Note</mat-label>
    <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="5"
              cdkAutosizeMaxRows="15" formControlName="note">
    </textarea>
  </mat-form-field>
</div>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="">Cancel</button>
  <button mat-button id="okButton" [mat-dialog-close]="form.value" [disabled]="form.invalid">OK</button>
</mat-dialog-actions>
