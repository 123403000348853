import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SvgComponent {

  @Input() iconName: Icon;

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {

    iconRegistry.addSvgIcon(Icon.PROJECTS, sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/projects.svg'));
    iconRegistry.addSvgIcon(Icon.HOSTLOGS, sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/host-logs.svg'));
    iconRegistry.addSvgIcon(Icon.TESTPLANS, sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/test-plans.svg'));
    iconRegistry.addSvgIcon(Icon.CLIENTS, sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/clients.svg'));
    iconRegistry.addSvgIcon(Icon.USERS, sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/users.svg'));
    iconRegistry.addSvgIcon(Icon.REPORTS, sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/reports.svg'));
    iconRegistry.addSvgIcon(Icon.SEARCH, sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/search.svg'));
    iconRegistry.addSvgIcon(Icon.TRANSACTIONS, sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/search.svg'));
    iconRegistry.addSvgIcon(Icon.AUDIT, sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/audit.svg'));
    iconRegistry.addSvgIcon(Icon.KNOWLEDGEREPO, sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/knowledge-repo.svg'));
    iconRegistry.addSvgIcon(Icon.ICC, sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/tool.svg'));
    iconRegistry.addSvgIcon(Icon.FAQ, sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/faq.svg'));
    iconRegistry.addSvgIcon(Icon.SANDBOX, sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/projects-sandbox.svg'));
    iconRegistry.addSvgIcon(Icon.LOA, sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/loa-archive.svg'));
    iconRegistry.addSvgIcon(Icon.CLIENT_DETAILS, sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/client-details.svg'));
    iconRegistry.addSvgIcon(Icon.PROJECT_DETAILS, sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/project-details-title.svg'));
    iconRegistry.addSvgIcon(Icon.USER_DETAILS, sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/user-details-title.svg'));
    iconRegistry.addSvgIcon(Icon.PROJECT_TESTS, sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/projects-tests-title.svg'));
    iconRegistry.addSvgIcon(Icon.DOWNLOAD, sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/download.svg'));
    iconRegistry.addSvgIcon(Icon.VIEW, sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/view.svg'));
    iconRegistry.addSvgIcon(Icon.DELETE, sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/delete.svg'));
    iconRegistry.addSvgIcon(Icon.CHANGE_PW, sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/change-password-title.svg'));
    iconRegistry.addSvgIcon(Icon.GETSTARTED, sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/get-started-arrow.svg'));
    iconRegistry.addSvgIcon(Icon.EXPAND, sanitizer.bypassSecurityTrustResourceUrl('../../../assets/faqPlusButton.svg'));
    iconRegistry.addSvgIcon(Icon.CONTRACT, sanitizer.bypassSecurityTrustResourceUrl('../../../assets/faqMinusButton.svg'));
    iconRegistry.addSvgIcon(Icon.MOVEUP, sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/moveUp.svg'));
    iconRegistry.addSvgIcon(Icon.MOVEDOWN, sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/moveDown.svg'));
    iconRegistry.addSvgIcon(Icon.SANDBOX_REPORT, sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/sandbox-reports.svg'));
    iconRegistry.addSvgIcon(Icon.SANDBOX_REPORT_TITLE, sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/sandbox-reports-title.svg'));
  }
}

export enum Icon {
  PROJECTS = 'projects',
  HOSTLOGS = 'host-logs',
  TESTPLANS = 'test-plans',
  CLIENTS = 'clients',
  USERS = 'users',
  REPORTS = 'reports',
  SEARCH = 'search',
  TRANSACTIONS = 'transaction-list',
  AUDIT = 'audit',
  KNOWLEDGEREPO = 'knowledge-repository',
  ICC = 'iccsimtmat-tool',
  FAQ = 'faq',
  SANDBOX = 'sandbox-projects',
  LOA = 'loa-archive',
  CLIENT_DETAILS = 'client-details',
  PROJECT_DETAILS = 'project-details',
  USER_DETAILS = 'user-details',
  PROJECT_TESTS = 'project-tests',
  DOWNLOAD = 'download',
  VIEW = 'view',
  DELETE = 'delete',
  CHANGE_PW = 'change-password',
  GETSTARTED = 'get-started',
  EXPAND = 'expand',
  CONTRACT = 'contract',
  MOVEUP = 'moveup',
  MOVEDOWN = 'movedown',
  SANDBOX_REPORT = 'sandbox-reports',
  SANDBOX_REPORT_TITLE = 'sandbox-reports-title'
}
