import {Component} from '@angular/core';

@Component({
  selector: 'app-side-bar-layout',
  templateUrl: './side-bar-layout.component.html',
  styleUrls: ['./side-bar-layout.component.scss']
})
export class SideBarLayoutComponent {
  sideIsCollapsed = false;

  toggleCollapsed() {
    this.sideIsCollapsed = !this.sideIsCollapsed;
  }
}
