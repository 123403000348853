import {Injectable} from '@angular/core';
import {User} from '@models/security/user';
import {RoleDescriptionService} from '../../../data/role-description.service';
import {UsersService} from '../../../data/users.service/users.service';

export interface IAnalystInfoTableProviderService {renderInfo(doc: any, analystId: number, yPosition: number): Promise<void>;}

@Injectable()
export class AnalystInfoTableProviderService implements IAnalystInfoTableProviderService {

  constructor(private usersService: UsersService,
              private roleDescriptionService: RoleDescriptionService) { }

  async renderInfo(doc: any, analystId: number, yPosition: number) {
    const userInfo: User = await this.usersService.getUser(analystId).toPromise();
    doc.autoTable({
      head: [{firstName: 'First Name', lastName: 'Last Name', username: 'Email', role: 'Role', state: 'State'}],
      headStyles: {
        fillColor: [230, 230, 230],
        fontSize: 9,
        textColor: [80, 80, 80]
      },
      body: [[userInfo.firstName, userInfo.lastName, userInfo.username,
        this.roleDescriptionService.getRoleDescription(userInfo.role), userInfo.state]],
      startY: yPosition,
      theme: 'grid',
    });
  }
}
