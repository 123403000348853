import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ProjectsService} from '@services/data/projects.service/projects.service';
import {NotificationService} from '@services/notification.service/notification.service';
import {
  NewProjectDialogComponent,
  NewProjectDialogParam,
  NewProjectDialogResult
} from '../../../pages/projects/new-project-dialog.component';

export interface INewProjectDialogService {
  createNewProject(): Promise<boolean>;
  createSandboxProject(): Promise<boolean>;
  copyFromSandboxProject(sandboxProjectId: number): Promise<boolean>;
}

@Injectable()
export class NewProjectDialogService implements INewProjectDialogService {

  constructor(private dialog: MatDialog,
              private projectsService: ProjectsService,
              private notificationService: NotificationService) { }


  async createNewProject(): Promise<boolean> {
    return await this.createProject({isSandboxProject: false});
  }

  async createSandboxProject(): Promise<boolean> {
    return await this.createProject({isSandboxProject: true});
  }

  async copyFromSandboxProject(sandboxProjectId: number) {
    const projectDetail = await this.projectsService.getProjectDetails('' + sandboxProjectId).toPromise();
    return this.createProject({isSandboxProject: false, sandboxSourceProject: projectDetail});
  }

  private async createProject(data: Partial<NewProjectDialogParam>): Promise<boolean> {
    const result: NewProjectDialogResult = await this.dialog
      .open<NewProjectDialogComponent, Partial<NewProjectDialogParam>>(NewProjectDialogComponent, {
      minWidth: '1000px',
        width: '80vw',
        data
      }).afterClosed().toPromise();

    if (result) {
      try {
        const project = await this.projectsService.createProject({...result.project}, result.isDraft).toPromise();
        this.notificationService.success(`Successfully created new project ${project.name}${result.isDraft ? ' as draft project' : ''}`);
        return true;
      } catch (err) {
        if (err.status === 409) {
          this.notificationService.error(err.error.message);
        } else {
          this.notificationService.error('There was an error creating the new project');
        }
      }
    }
    return false;
  }
}
