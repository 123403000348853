import {SortDirection} from '@services/data/sortDirection';
import {SessionStorageService} from '@services/session-storage.service/session-storage.service';

export interface TableSettings {
  pageIndex: number;
  pageSize: number;
  sort: string;
  sortDirection: SortDirection;
  filters: string;
  filter: string;
  showFavouritesOnly: boolean;
}

export class TableSettingsStorageService extends SessionStorageService<TableSettings> {
  constructor(tableType: string) {
    super(`table-${tableType}`);
  }
}
