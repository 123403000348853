import {HostLogMessageField} from './hostLogMessageField';

/**
 * Host Log Message
 */
export interface HostLogMessage {
  id?: number;
  messageGroup: string;
  messageFields: Array<HostLogMessageField>;
  legNumber: number;
  dateTime: string;
  association?: Association;
  associationLabel?: string;
  messageType?: string;
  validationResult: string;
}

export enum Association {
  MasterCard = 'MASTERCARD',
  Visa = 'VISA',
  AmericanExpress = 'AMEX',
  Discover = 'DISCOVER',
  UMF = 'UMF',
  IsoGlobal = 'ISO_GLOBAL',
  IsoDual = 'ISO_DUAL',
  IsoDualVar = 'ISO_DUAL_VAR',
  EPC = 'EPC',
  EtcPlus = 'ETC_PLUS',
  DlHost = 'DL_HOST',
  DebitGateway = 'DEBIT_GATEWAY',
  Maestro = 'MAESTRO',
  Interlink = 'INTERLINK',
  Pulse = 'PULSE',
  Jeanie = 'JEANIE',
  NYCE = 'NYCE',
  BuypassISO = 'BUYPASS_ISO',
  EDC = 'EDC',
  HostAndController = 'HOST_CONTROLLER',
  NYCE_BuyPass = 'NYCE_BUYPASS',
  Voyager = 'VOYAGER',
}
