export interface TerminalPlatform {
  name: string;
  hostMessageSpecifications: string[];
}


export const allTerminalPlatforms: Array<TerminalPlatform> = [
  {
    name: 'Buypass',
    hostMessageSpecifications: [
      'UMF',
      'ATL105',
      'Buypass ISO 8583',
      'Host and Controller'
    ]
  },
  {
    name: 'Nashville',
    hostMessageSpecifications: [
      'UMF',
      'Global ISO8583',
      'EDC Terminal',
      'DL Host',
      'ISO Dual',
      'ISO Dual VAR'
    ]
  },
  {
    name: 'Cardnet',
    hostMessageSpecifications: [
      'UMF',
      'Global ISO8583',
      'EDC Terminal',
      'DL Host',
      'ISO Dual',
      'ISO Dual VAR'
    ]
  },
  {
    name: 'Omaha',
    hostMessageSpecifications: [
      'UMF',
      'ETC+'
    ]
  }
];

export const allHostMessageSpecifications = [...new Set([].concat(...allTerminalPlatforms.map(t => t.hostMessageSpecifications)))].sort();
