import {Component, EventEmitter, forwardRef, Input, Output, ViewChild} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {InlineControlComponent} from './inline-control.component';

const INLINE_EDIT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InlineEditComponent),
  multi: true
};


@Component({
  selector: 'app-inline-edit',
  templateUrl: './inline-edit.component.html',
  providers: [INLINE_EDIT_CONTROL_VALUE_ACCESSOR],
  styleUrls: ['./inline-edit.component.scss']
})
export class InlineEditComponent extends InlineControlComponent<string> {

  @ViewChild('inlineEditControl') inlineEditControl;
  @Input() type = 'text';
  @Input() enabledSpaces = true;
  @Input() maxLength = 255;

  @Output() blur = new EventEmitter();

  constructor() {
    super();
  }

  onBlur() {
    this.editing = false;
    if (!this.enabledSpaces) {
      this.value = this.value.replace(/\s/g, '');
    }
    this.blur.emit();
  }

  edit() {
    if (this.readonly) {
      return;
    }

    this.editing = true;
    // Focus on the input element just as the editing begins
    setTimeout(_ => this.inlineEditControl.nativeElement.focus());
  }

  onKeyDown($event: KeyboardEvent) {
    // Prevent the space being entered if spaces are not allowed
    if (!this.enabledSpaces && $event.code === 'Space') {
      $event.preventDefault();
    }
  }
}
