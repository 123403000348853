<div class="table-container">
  <div class="table-controls">
    <div class="table-head">
      <mat-form-field>
        <input matInput #filterInput placeholder="Filter">
      </mat-form-field>
      <div class="text-right" *ngIf="canCreateUsers">
  <span *ngIf="filterClientId">
    <button id="assignUser" style="margin-right: 5px" mat-raised-button color="primary" (click)="assignUser.emit()">Assign Existing User</button>
    </span>
        <button id="createUser" mat-raised-button color="primary" (click)="createUser.emit()">Create User</button>
      </div>
    </div>
    <mat-paginator [showFirstLastButtons]="true"
                   [length]="totalUsers"
                   [pageSize]="25"
                   [pageSizeOptions]="[10, 25, 50, 100]">
    </mat-paginator>
  </div>
  <table mat-table matSort matSortActive="username"

         matSortDisableClear matSortDirection="asc" [dataSource]="dataSource">

    <!-- User name Column -->
    <ng-container matColumnDef="username">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>User Name</th>
      <td mat-cell *matCellDef="let user">
        <div class="username-column-text">
          <span>{{user.username}}</span>

          <mat-icon *ngIf="user.state !== UserState.Active" 
                    matTooltipPosition="right"
                    matTooltip="User Disabled">person_off</mat-icon>

          <mat-icon *ngIf="user.totpDeviceStatus !== TOTPDeviceStatus.NONE" 
                    [matTooltip]="user.totpDeviceStatus | mfaDeviceStatus"
                    [ngClass]="user.totpDeviceStatus"
                    matTooltipPosition="right">phonelink_lock</mat-icon>
        </div>
      </td>
    </ng-container>

    <!-- First Name Column -->
    <ng-container matColumnDef="firstName">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>First Name</th>
      <td mat-cell *matCellDef="let user"> {{user.firstName}} </td>
    </ng-container>

    <!-- Last Name Column -->
    <ng-container matColumnDef="lastName">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Last Name</th>
      <td mat-cell *matCellDef="let user"> {{user.lastName}} </td>
    </ng-container>


    <!-- Merchant Name Column -->
    <ng-container matColumnDef="merchantId">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Client</th>
      <td mat-cell *matCellDef="let user">
        <div *ngFor="let merchant of user.merchants">{{merchant.merchantName}}</div>
      </td>
    </ng-container>

    <!-- Role Column -->
    <ng-container matColumnDef="role">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Role</th>
      <td mat-cell *matCellDef="let user"> {{user.role | roleDescription}}</td>
    </ng-container>

    <!-- EID Column-->
    <ng-container matColumnDef="eid">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>EID</th>
      <td mat-cell *matCellDef="let user">
        <span>{{user.eid}}</span>
        <span *ngIf="!user.eid"> - </span>
      </td>
    </ng-container>

    <!-- EID Column-->
    <ng-container matColumnDef="lanId">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>LAN ID</th>
      <td mat-cell *matCellDef="let user">
        <span *ngIf="user.lanId">{{user.lanId}}</span>
        <span *ngIf="!user.lanId"> - </span>
      </td>
    </ng-container>

    <!-- Last Login info Column -->
    <ng-container matColumnDef="lastSuccessfulLoginTime">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Last login</th>
      <td mat-cell *matCellDef="let user">
        <span *ngIf="user.lastSuccessfulLogin"> {{user.lastSuccessfulLogin | estDateTime}}</span>
        <span *ngIf="!user.lastSuccessfulLogin"> - </span>
      </td>
    </ng-container>

    <!-- Start Date Column -->
    <ng-container matColumnDef="startDate">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Start Date</th>
      <td mat-cell *matCellDef="let user">
        <span *ngIf="user.startDate"> {{user.startDate | localDate}}</span>
        <span *ngIf="!user.startDate"> - </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let user; columns: displayedColumns;" [class.selectable]="canReadUsers"
        (click)="userDetail(user.id)"></tr>
  </table>

</div>
