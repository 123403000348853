import {Injectable, OnDestroy} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Client} from '@models/clients';
import {Project} from '@models/project';
import {Role} from '@models/security';
import {User} from '@models/security/user';
import {AuthenticationService} from '@services/auth/authentication.service/authentication.service';
import {ClientsService} from '@services/data/clients.service/clients.service';
import {FilterParameters} from '@services/data/filterParameters';
import {ProjectsService} from '@services/data/projects.service/projects.service';
import {UsersService} from '@services/data/users.service/users.service';
import {BehaviorSubject, Observable, ReplaySubject, Subscription} from 'rxjs';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {ProjectFilterDialogComponent, ProjectFilterDialogParameters} from './project-filter-dialog/project-filter-dialog.component';
import ProjectFilter from './project-filter-dialog/projectFilter';
import SearchField = ProjectFilter.SearchField;
import SearchParameter = ProjectFilter.SearchParameter;
import SearchParameterType = ProjectFilter.SearchParameterType;

export interface IProjectFilterService {
  allProjects$: Observable<Array<Project>>;
  allClients$: Observable<Array<Client>>;
  allLeadUsers$: Observable<Array<User>>;

  updateFilterParameters(filters: FilterParameters, dialogTitle: string, isSandbox: boolean): Promise<any>;

  refreshAllProjects(isSandbox: boolean): void;

  refreshAllClients(): void;
}

@Injectable()
export class ProjectFilterService implements IProjectFilterService, OnDestroy {
  private projectRefresh$ = new ReplaySubject<void>(1);
  private clientRefresh$ = new ReplaySubject<void>(1);

  private filters: FilterParameters = new Map<string, string|Date>();

  allProjects$: Observable<Array<Project>>;
  allClients$: Observable<Array<Client>>;
  allLeadUsers$: Observable<Array<User>>;
  allBackupUsers$: Observable<Array<User>>;
  allIpLeadUsers$: Observable<Array<User>>;

  _clientLeadUsers = new BehaviorSubject<Array<User>>([]);
  private userRole: Role;
  private authenticationSubscription: Subscription;

  constructor(private dialog: MatDialog,
              projectsService: ProjectsService,
              private clientsService: ClientsService,
              authenticationService: AuthenticationService,
              usersService: UsersService) {

    this.allProjects$ = this.projectRefresh$.pipe(
      switchMap(_ => projectsService.getProjects('name', 'desc', 0, 99999, this.filters)),
      map(({data}) => data),
      shareReplay(1)
    );

    this.allClients$ = this.clientRefresh$.pipe(
      switchMap(_ => clientsService.getAllClients()),
      shareReplay(1)
    );

    this.allLeadUsers$ = usersService.listLeadUsers()
      .pipe(
        map(({data}) => data),
        shareReplay(1)
      );

    this.allBackupUsers$ = usersService.listBackupAnalyst()
      .pipe(
        map(({data}) => data),
        shareReplay(1)
      );

    this.allIpLeadUsers$ = usersService.listIpLead()
      .pipe(
        map(({data}) => data),
        shareReplay(1)
      );

    this.authenticationSubscription = authenticationService.authInfo$.subscribe(authInfo => this.userRole = authInfo?.role);
  }

  ngOnDestroy() {
    this.authenticationSubscription.unsubscribe();
  }

  get allClientLeadUsers$(): Observable<Array<User>> {
    return this._clientLeadUsers.asObservable();
  }

  private _currentClientId: SearchParameterType;

  get currentClientId(): ProjectFilter.SearchParameterType {
    return this._currentClientId;
  }

  set currentClientId(value: ProjectFilter.SearchParameterType) {
    this._currentClientId = value;
    if (value) {
      this.clientsService.getClientLeadUsers(value as number).toPromise().then(users => this._clientLeadUsers.next(users));
    }
  }

  async updateFilterParameters(filters: FilterParameters, dialogTitle: string, isSandbox: boolean = false): Promise<Array<SearchParameter> | null> {
    this._currentClientId = filters.get(SearchField.Client);
    return this.dialog.open<ProjectFilterDialogComponent, ProjectFilterDialogParameters>(ProjectFilterDialogComponent, {
      width: '700px',
      data: {
        existingParameters: filters,
        title: dialogTitle,
        canFilterByCertLead: this.userRole !== Role.client,
        isSandbox
      }
    }).afterClosed().toPromise();
  }

  refreshAllProjects(isSandbox: boolean) {
    this.filters.set('sandbox', String(isSandbox));
    this.projectRefresh$.next();
  }

  refreshAllClients() {
    this.clientRefresh$.next();
  }
}
