import {Directive, HostListener} from '@angular/core';

const digits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

@Directive({
  selector: '[preventNumeric]'
})
export class PreventNumericDirective {

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if (digits.includes(event.key)) {
      event.preventDefault();
    }
  }
}
