import {Component, Input} from '@angular/core';
import {Icon} from '../svg/svg.component';

@Component({
  selector: 'app-overlay-button',
  templateUrl: './overlay-button.component.html',
  styleUrls: ['./overlay-button.component.scss']
})
export class OverlayButtonComponent {
  @Input() iconName: Icon = null;
  @Input() imageUrl = '';
  @Input() rolloverImageUrl = '';
  @Input() tooltip = '';
  @Input() disabled = false;

  getTooltip() {
    return this.disabled ? '' : this.tooltip;
  }
}
