import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Icon} from '../svg/svg.component';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageTitleComponent {
  @Input() title: string;
  @Input() iconUrl: Icon;
  _isSubTitle = false;

  loaded = false;

  get isSubTitle() {
    return this._isSubTitle;
  }

  @Input()
  set isSubTitle(isSub: boolean) {
    this._isSubTitle = isSub;
  }
}
