import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Client, ClientSummary} from '@models/clients';
import {PagedResponse} from '@models/pagedResponse';
import {User} from '@models/security/user';
import {Observable, of, from} from 'rxjs';
import {map, first, switchMap} from 'rxjs/operators';
import {AppConfigService} from '../../configuration/app-config.service/app-config.service';
import {IClientsService} from './iClientsService';
import {Action, Resource} from '@models/security';
import {PermissionsService} from '@services/auth/permissions.service/permissions.service';

@Injectable()
export class ClientsService implements IClientsService {

  private clientsUrl = this.configService.getRestUrl('/merchants');
  private canListClients$: Observable<boolean>;

  constructor(private http: HttpClient, private configService: AppConfigService, private permissionService: PermissionsService) {
    this.canListClients$ = this.permissionService.hasPermission([Resource.client, Action.list]);
  }

  async canListClients(): Promise<boolean> {    
    return await this.canListClients$.pipe(first()).toPromise();
  }

  /**
   * Checks if user has access to list, then gets all the clients in the system
   * @returns {Observable<Client[]>}
   */
  getAllClients(): Observable<Client[]> {    
    return from(this.canListClients()).pipe(
      switchMap((canListClients) => {
        if (canListClients) {
          return this.http.get<PagedResponse<Client>>(this.clientsUrl + '?size=99999').pipe(
            map(({ data }) => data),
          );
        } else {
          // User does not have access, return empty array
          return of([]);
        }
      })
    );
  }

  /**
   * Create a new client
   * @param {Client} client
   * @returns {Observable<Client>}
   */
  createClient(client: Client): Observable<Client> {
    return this.http.post<Client>(this.clientsUrl, client);
  }

  /**
   * Get the client summary information (for tables)
   * @param {string} sort
   * @param {string} dir
   * @param {number} page
   * @param {number} pageSize
   * @param filter
   * @returns {Observable<PagedResponse<ClientSummary>>}
   */
  getClientSummaries(sort: string = 'merchantName', dir: string = 'asc', page: number = 0, pageSize: number = 99999, filter: any = '')
    : Observable<PagedResponse<ClientSummary>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', pageSize.toString())
      .set('sort', `${sort},${dir}`)
      .set('merchantName', filter);
    return this.http.get<PagedResponse<ClientSummary>>(this.clientsUrl + '/summary', {params});
  }

  /**
   * Get client information
   * @param {number} clientId
   * @returns {Observable<Client>}
   */
  getClient(clientId: number): Observable<Client> {
    return this.http.get<Client>(`${this.clientsUrl}/${clientId}`);
  }

  /**
   * Update the details of a client
   * @param {Client} client
   * @returns {Observable<Client>}
   */
  updateClient(client: Client): Observable<Client> {
    return this.http.put<Client>(`${this.clientsUrl}/${client.id}`, client);
  }

  /**
   * Delete a client
   * @param {number} clientId - ID of client to delete
   * @returns {Observable<any>}
   */
  deleteClient(clientId: number): Observable<any> {
    return this.http.delete(`${this.clientsUrl}/${clientId}`);
  }

  /**
   * Disable / Enable a client
   * @param {number} clientId - ID of client to disable or enable
   * @returns {Observable<any>}
   */
  updateClientState(clientId: number, deactivate: boolean): Observable<any> {
    const params = new HttpParams().set('disable', String(deactivate));
    return this.http.patch(`${this.clientsUrl}/${clientId}`, {}, {params});
  }

  /**
   * Get all the users for a client that have been assigned a client lead for one of their projects
   * @param {number} clientId
   * @returns {Observable<Array<User>>}
   */
  getClientLeadUsers(clientId: number): Observable<Array<User>> {
    return this.http.get<Array<User>>(`${this.clientsUrl}/${clientId}/getClientLeads`);
  }

  getClientsWhereUserIsLead(userId: number): Observable<Client[]> {
    return this.http.get<Client []>(`${this.clientsUrl}/getByLead/${userId}` );
  }
}
