import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AuthenticationService} from '@services/auth/authentication.service/authentication.service';
import {VersionDialogComponent} from './version-dialog/version-dialog.component';
import {AuthInfo} from "@models/security";
import {NotificationService} from "@services/notification.service/notification.service";
import {TOTPDeviceStatus} from "@models/security/user";
import {filter, switchMap} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {ConfirmDialogService} from "../components/confirm-dialog/confirm-dialog.service";

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss']
})
export class HeaderBarComponent implements OnInit {
  @Output() logout: EventEmitter<void> = new EventEmitter();
  
  username: string;

  isLoggedIn: boolean;

  hasMFADeviceConfigured: boolean;

  constructor(private readonly authenticationService: AuthenticationService,
              private readonly notificationService: NotificationService,
              private readonly confirmDialogService: ConfirmDialogService,
              private readonly dialog: MatDialog) {
  }
  
  ngOnInit() {
    this.authenticationService.authInfo$
      .subscribe((authInfo: AuthInfo | null) => {        
        this.isLoggedIn = Boolean(authInfo);
        
        this.username = `${authInfo?.firstName} ${authInfo?.lastName}`;
        
        this.hasMFADeviceConfigured = authInfo?.totpDeviceStatus === TOTPDeviceStatus.VERIFIED;
      });
  }

  openVersionDialog() {
    this.dialog.open(VersionDialogComponent, {
      width: '300px',
      height: 'auto',
      panelClass: 'version-dialog'
    });
  }

  removeMFADevice(): void {
    this.confirmDialogService.confirm('Remove Authenticator', 'Are you sure that you want to remove your Authenticator?\n You will be logged out and required to register a new Authenticator')
      .pipe(
        filter(Boolean),
        switchMap(() => this.authenticationService.removeTOTPDevice())
      )
      .subscribe(() => {
        this.authenticationService.logout();
      }, (error: HttpErrorResponse) => {
        this.notificationService.error('Failed to remove Authenticator');
      });
  }

  onLogoutClick() {
    this.logout.emit();
  }
}
