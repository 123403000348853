import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PagedResponse} from '@models/pagedResponse';
import {ProjectAudit} from '@models/projectAudit';
import {Observable} from 'rxjs';
import {AppConfigService} from '../../configuration/app-config.service/app-config.service';
import {SortDirection} from '../sortDirection';
import {IProjectAuditService} from './iProjectAuditService';

@Injectable()
export class ProjectAuditService implements IProjectAuditService {

  private readonly restUrl: string;

  constructor(private http: HttpClient,
              appConfig: AppConfigService) {
    this.restUrl = appConfig.current.restUrl;
  }

  /**
   * Get audit details of the given project, with sorting and paging
   * @param {number} projectId - id of porject
   * @param {string} sort - sort column
   * @param {SortDirection} dir - direction of sort
   * @param {number} page - number of page
   * @param {number} pageSize - size of pages
   * @returns {Observable<PagedResponse<ProjectAudit>>}
   */
  getProjectAudit(projectId: number, sort = 'date', dir: SortDirection = 'desc',
                  page = 0, pageSize = 99999): Observable<PagedResponse<ProjectAudit>> {

    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', pageSize.toString())
      .set('sort', `${sort},${dir}`);

    return this.http.get<PagedResponse<ProjectAudit>>(this.getProjectAuditUrl(projectId), {params});
  }

  private getProjectAuditUrl = (projectId: number) => `${this.restUrl}/projects/${projectId}/audit`;
}
