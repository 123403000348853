<div class="page-container">
  <div class="controls">
    <div class="filters">
      <div class="filter-buttons" *ngIf="showAdvancedFilter">
        <button mat-raised-button color="primary" (click)="updateFilter()">Filter</button>
        <button mat-raised-button (click)="onClearPressed()" [disabled]="hasNoFilters()">Clear</button>
      </div>
      <mat-form-field>
        <input matInput #filterInput placeholder="Filter">
      </mat-form-field>
    </div>
    <div class="download-button">
      <button mat-raised-button (click)="downloadAsExcel()">
        Download Report as Excel
      </button>
    </div>
  </div>
    <mat-paginator [showFirstLastButtons]="true"
                   [length]="totalRows$ | async"
                   [pageSize]="25"
                   [pageSizeOptions]="[10, 25, 50, 100]">
    </mat-paginator>

  <div class="table-container">
  <table mat-table matSort matSortActive="name"
         matSortDisableClear matSortDirection="asc" [dataSource]="row$ | async">

    <!-- testCaseName -->
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Test Case</th>
      <td mat-cell *matCellDef="let globalWaiverReport" class="text">{{globalWaiverReport.name}}</td>
    </ng-container>

    <!-- waiverNumber -->
    <ng-container matColumnDef="statusNumber">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Waiver Number</th>
      <td mat-cell *matCellDef="let globalWaiverReport" class="text">{{globalWaiverReport.waiverNumber}}</td>
    </ng-container>

    <!-- waiverExpDate -->
    <ng-container matColumnDef="statusExpiryDate">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Expiration Date</th>
      <td mat-cell *matCellDef="let globalWaiverReport" class="text">{{globalWaiverReport.expirationDate | localDate}}</td>
    </ng-container>

    <!-- waiverComment -->
    <ng-container matColumnDef="statusComment">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Comment</th>
      <td mat-cell *matCellDef="let globalWaiverReport" class="text">{{globalWaiverReport.waiverComment}}</td>
    </ng-container>

    <!-- ProjectNumber Column -->
    <ng-container matColumnDef="projectId">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Project Id</th>
      <td mat-cell *matCellDef="let globalWaiverReport" class="text">
        {{globalWaiverReport.projectId}}</td>
    </ng-container>

    <!-- projectName Column -->
    <ng-container matColumnDef="projectName">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Project Name</th>
      <td mat-cell *matCellDef="let globalWaiverReport" class="text"> {{globalWaiverReport.projectName}} </td>
    </ng-container>

    <!-- ClientName Column -->
    <ng-container matColumnDef="clientName">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Client Name</th>
      <td mat-cell *matCellDef="let globalWaiverReport" class="text"> {{globalWaiverReport.clientName}}</td>
    </ng-container>


    <!-- Platform Column -->
    <ng-container matColumnDef="platform">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Platform</th>
      <td mat-cell *matCellDef="let globalWaiverReport" class="text"> {{globalWaiverReport.platform}}</td>
    </ng-container>

    <!-- Specification Column -->
    <ng-container matColumnDef="hostMessageSpecification">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Specification</th>
      <td mat-cell *matCellDef="let globalWaiverReport" class="text"> {{globalWaiverReport.specification}}</td>
    </ng-container>

    <!-- certLead Date Column -->
    <ng-container matColumnDef="certLead">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Cert Lead</th>
      <td mat-cell *matCellDef="let globalWaiverReport" class="text"> {{globalWaiverReport.certLead}}</td>
    </ng-container>

    <!-- ipLead Column -->
    <ng-container matColumnDef="ipLead">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>IP Lead</th>
      <td mat-cell *matCellDef="let globalWaiverReport" class="text">{{globalWaiverReport.ipLead}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let waiver; columns: displayedColumns;"></tr>
  </table>
  </div>
</div>
