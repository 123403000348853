import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'spaceToHash'
})
export class SpaceToHashPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return typeof (value) === 'string' ? value.replace(/\s/g, '#') : value;
  }

}
