import {Injectable} from '@angular/core';
import {AbstractControl, ValidatorFn} from '@angular/forms';

const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,30})+$/;

export interface IEmailValidationService {

  isValidEmailAddress(emailAddress: string): boolean;
  emailValidator: ValidatorFn;
}

@Injectable()
export class EmailValidationService implements IEmailValidationService {
  isValidEmailAddress(emailAddress: string): boolean {
    return emailRegex.test(emailAddress);
  }

  get emailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      return this.isValidEmailAddress(control.value) ? null : {email: true};
    };
  }
}
