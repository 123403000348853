import {Pipe, PipeTransform} from '@angular/core';
import {shortDateFormat} from '@models/constants';
import * as moment from 'moment-timezone';

export interface ILocalDatePipe {
  transform(value: unknown, format: string, ...args: unknown[]): unknown;
}

@Pipe({
    name: 'localDate'
})
export class LocalDatePipe implements PipeTransform, ILocalDatePipe {
  transform(value: unknown, format: string = shortDateFormat, ...args: unknown[]): unknown {
    const date = moment(value);
    if (!date.isValid()) {
      return value;
    }
    // if the value contained timezone information, convert to UTC
    return date.creationData()?.format?.toString().includes('Z')
      ? date.utc().format(format)
      : date.format(format);
  }
}
