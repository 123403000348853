export enum Action {
    list = 'list',
    read = 'read',
    create = 'create',
    edit = 'edit',
    delete = 'delete',
    regress = 'regress',
    uncancel = 'uncancel',
    amend = 'amend'
}
