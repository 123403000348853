import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy} from '@angular/core';
import {est_timezone, momentDateTimeFormat} from '@models/constants';
import {HostLogMessage} from '@models/hostLogMessage';
import {AssociationDescriptionService} from '@services/data/association-description.service';
import * as moment from 'moment';
import {Subscription} from 'rxjs';
import {HostLogStateService} from '../../host-log-state.service/host-log-state.service';

@Component({
  selector: 'app-host-log-message-metadata',
  templateUrl: './host-log-message-metadata.component.html',
  styleUrls: ['./host-log-message-metadata.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HostLogMessageMetadataComponent implements AfterViewInit, OnDestroy {

  @Input()
  hostLogMessage: HostLogMessage;
  private visibilitySubscription: Subscription;

  constructor(private hostLogStateService: HostLogStateService,
              private associationDescriptionService: AssociationDescriptionService,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  ngAfterViewInit() {
    this.visibilitySubscription = this.hostLogStateService.getHiddenMessages().subscribe(() => this.changeDetectorRef.detectChanges());
  }

  ngOnDestroy() {
    if (this.visibilitySubscription) {
      this.visibilitySubscription.unsubscribe();
    }
  }

  convertDateTime(dateInfo: string): string {
    return moment.utc(dateInfo).tz(est_timezone).format(momentDateTimeFormat);
  }

  hasValidationResult(validationResult: string): boolean {
    return (validationResult !== undefined && validationResult?.length > 0);
  }

  getAssociationName(): string {
    return this.hostLogMessage && this.hostLogMessage.association ?
      this.associationDescriptionService.getAssociationDescription(this.hostLogMessage.association) : 'Unknown';
  }

  canHideMessage(): boolean {
    return this.hostLogStateService.canHideMessage();
  }

  toggleSize(event: MouseEvent) {
    if (event.altKey) {
      this.hostLogStateService.collapseAllButThis(this.hostLogMessage);
    } else if (this.canHideMessage()) {
      this.hostLogStateService.toggleSize(this.hostLogMessage);
    }
  }

  get isMac(): boolean {
    return (navigator.userAgent.indexOf('Mac OS X') != -1);
  }

  unHideAll() {
    this.hostLogStateService.unHideAll();
  }
}
