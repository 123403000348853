import {NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';
import {
  NgxMatMomentAdapter,
  NgxMatMomentModule,
  NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  NGX_MAT_MOMENT_FORMATS
} from '@angular-material-components/moment-adapter';
import {OverlayModule} from '@angular/cdk/overlay';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {DateAdapter, MAT_DATE_LOCALE, MatRippleModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import defaultExport from '@energiency/chartjs-plugin-piechart-outlabels';
import {AuthTokenInterceptor} from '@services/auth/auth-token-intercepter.service';
import {CacheInterceptor} from '@services/cache-interceptor';
import {AppConfigService} from '@services/configuration/app-config.service/app-config.service';
import {EstDateFormatPipe} from '@services/pipes/est-date-format.pipe';
import {UtcDatePipe} from '@services/pipes/utc-date.pipe';
import {ServicesModule} from '@services/services.module';
import 'chartjs-adapter-moment';
import {NgChartsModule} from 'ng2-charts';
import {QuillModule} from 'ngx-quill';
import {AppRoutingModule} from './app-routing.module';
import {ComponentsModule} from './components/components.module';
import {TreetableModule} from './components/treetable/treetable.module';
import {HeaderBarComponent} from './shell/header-bar.component';
import {ShellComponent} from './shell/shell.component';
import {SideMenuComponent} from './shell/side-menu.component';
import {TimeOutAlertComponent} from './shell/time-out-alert/time-out-alert.component';
import {VersionDialogComponent} from './shell/version-dialog/version-dialog.component';
import {MatMenuModule} from "@angular/material/menu";

const getConfigInitFactory = (appConfig: AppConfigService) => {
  return () => appConfig.loadAppConfig();
};

const windowFactory = (): Window => (typeof window !== 'undefined') ? window : null;

@NgModule({
            declarations: [
              ShellComponent,
              SideMenuComponent,
              HeaderBarComponent,
              TimeOutAlertComponent,
              VersionDialogComponent
            ],
            imports: [
              BrowserModule,
              RouterModule,
              TreetableModule,
              HttpClientModule,
              AppRoutingModule,
              BrowserAnimationsModule,
              MatTableModule,
              MatDialogModule,
              MatSnackBarModule,
              ServicesModule,
              OverlayModule,
              ComponentsModule,
              MatButtonModule,
              MatIconModule,
              MatDatepickerModule, // Required in AppModule for mat-dialog components to be able to use the timepicker
              NgxMatMomentModule, // Required in AppModule for mat-dialog components to be able to use the timepicker
              QuillModule.forRoot({
                                    modules: {
                                      toolbar: [
                                        [{header: [1, 2, 3, 4, 5, 6, false]},
                                          {size: ['small', false, 'large', 'huge']}],
                                        [{font: []}],
                                        ['bold', 'italic', 'underline', 'strike'],
                                        [{list: 'ordered'}, {list: 'bullet'}],
                                        [{script: 'sub'}, {script: 'super'}],
                                        [{indent: '-1'}, {indent: '+1'}],
                                        [{color: []}, {background: []}]
                                      ]
                                    }
                                  }),
              NgChartsModule.forRoot({
                                       plugins: [defaultExport],
                                       generateColors: true
                                     }),
              MatMenuModule,
              MatRippleModule
            ],
            providers: [
              AppConfigService,
              UtcDatePipe,
              EstDateFormatPipe,
              { provide: LOCALE_ID, useValue: navigator.language ?? 'en-US' },
              { provide: 'WINDOW', useFactory: windowFactory },
              {
                provide: APP_INITIALIZER,
                useFactory: getConfigInitFactory,
                multi: true,
                deps: [AppConfigService]
              },
              {
                provide: HTTP_INTERCEPTORS,
                useClass: AuthTokenInterceptor,
                multi: true
              },
              {
                provide: HTTP_INTERCEPTORS,
                useClass: CacheInterceptor,
                multi: true
              },
              {provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: false}},
              {
                provide: DateAdapter,
                useClass: NgxMatMomentAdapter,
                deps: [MAT_DATE_LOCALE, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS]
              },
              {provide: NGX_MAT_DATE_FORMATS, useValue: NGX_MAT_MOMENT_FORMATS}
            ],
            bootstrap: [ShellComponent]
})
export class AppModule {
}
