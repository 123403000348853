import {Injectable} from '@angular/core';
import {TestTool} from '@models/TestTool';
import {ITestToolDescriptionService} from './ITestToolDescriptionService';

const descriptions: Record<TestTool, string> = {
    [TestTool.None]: 'None',
    [TestTool.BTT]: 'BTT',
    [TestTool.ICC]: 'ICC',
    [TestTool.All]: 'All'
};

@Injectable()
export class TestToolDescriptionService implements ITestToolDescriptionService{

   getDescription(testTool: TestTool): string {
     return descriptions[testTool];
   }
}
