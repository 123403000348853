import {Injectable} from '@angular/core';
import {PagedResponse} from '@models/pagedResponse';
import {GlobalWaiverReport} from '@models/reports/globalWaiverReport';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {FilterParameters} from '../filterParameters';
import {ReportsService} from '../reports.service/reports.service';
import {SortDirection} from '../sortDirection';

export interface IGlobalWaiverReportService {
  readonly rows$: Observable<Array<GlobalWaiverReport>>;
  readonly totalRows$: Observable<number>;

  setTable(sort: string, direction: SortDirection, pageIndex: number, pageSize: number, filter: string): Promise<void>;
  setFilters(filter: FilterParameters): Promise<void>;
  updateTable(): Promise<void>;
  downloadExcelFile(): Observable<any>;

  resetFiltersAndTable(): void;
}


@Injectable()
export class GlobalWaiverReportService implements IGlobalWaiverReportService{


  _apiResponse = new BehaviorSubject<PagedResponse<GlobalWaiverReport>>({
    data: [],
    pageNumber: 0,
    pageSize: 0,
    totalPages: 0,
    totalResults: 0
  });
  pageIndex = 0;
  pageSize = 0;
  sort = '';
  sortDirection: SortDirection = '';
  filters: FilterParameters = null;

  filterClientId = 0;
  filter = '';

  constructor(private reportsService: ReportsService) {
    this.resetFiltersAndTable();
  }

  get totalRows$(): Observable<number> {
    return this._apiResponse.pipe(map(({totalResults}) => totalResults));
  }

  get rows$(): Observable<Array<GlobalWaiverReport>> {
    return this._apiResponse.pipe(map(({data}) => data));
  }

  resetFiltersAndTable(): void {
    this.filters = new Map<string, string>();
    this.sort = 'name';
    this.sortDirection = 'asc';
    this.pageIndex = 0;
    this.pageSize = 25;
  }

  setFilters(filter: FilterParameters): Promise<void> {
    this.filters = filter;
    return this.updateTable();
  }

  setTable(sort: string, direction: SortDirection, pageIndex: number, pageSize: number, filter: string): Promise<void> {
    this.sort = sort;
    this.sortDirection = direction;
    this.pageSize = pageSize;
    this.pageIndex = pageIndex;
    this.filter = filter;
    return this.updateTable();
  }

  async updateTable(): Promise<void> {
    const filters = this.filters;
    if (this.filterClientId) {
      filters.set('merchantId', this.filterClientId.toString());
    }
    const response = await this.reportsService.getGlobalWaivers(
      this.sort, this.sortDirection, this.pageIndex, this.pageSize, this.filters, this.filter).toPromise();
    this.pageIndex = response.pageNumber;
    this._apiResponse.next(response);
  }

  downloadExcelFile(): Observable<any> {
    return this.reportsService.getGlobalWaiverExcelFile(this.filters, this.sort, this.sortDirection, this.filter);
  }
}
