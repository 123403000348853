import {ValidationState} from '../validationState';

export interface ProjectTestCase {
  id?: number;
  projectTestPlanId?: number;
  baseTestCaseId?: number;
  validationState?: ValidationState;
  waived?: boolean;
  overridden?: boolean;
  statusNumber?: string;
  name?: string;
  objective?: string;
  procedure?: string;
  currentTestRunId?: number;
  lastUpdated: Date;
  hostLogProvided?: boolean;
  hostLogRequired?: boolean;
}

export enum ProjectTestCaseStatus {
  None = 'NONE',
  Waived = 'WAIVED',
  Overridden = 'OVERRIDDEN'
}
