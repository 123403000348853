<ng-template #timeout>
  <div class="timeout-container">
    <div>
      <img src="./assets/icons/stop-watch.svg" alt="stopwatch">

      Due to inactivity, you will be logged out in {{countdown}}.
    </div>
    <div>
      <button mat-raised-button
              (click)="onLogout()">Logout
      </button>
      <button mat-raised-button
              color="accent"
              (click)="onDismiss()">Dismiss
      </button>
    </div>
  </div>
</ng-template>
