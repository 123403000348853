<div class="comp-container">
  <div class="toggle-size-button"
       [hidden]="!canHideMessage()"
       (click)="toggleSize($event)"><em class="fas fa-compress-arrows-alt"
                                        [matTooltip]="isMac ? 'Collapse message. Option+Click to collapse all other messages.' : 'Collapse message. ALT+Click to collapse all other messages.'"
                                        matTooltipPosition="above"
  ></em></div>
  <div class="toggle-size-button"
       [hidden]="canHideMessage()"
       (click)="unHideAll()"><em class="fas fa-columns"
                                 matTooltip="Click to expand all messages"
                                 matTooltipPosition="above"></em></div>

  <div class="summary">
    <div class="summary-row">
      <div>Association</div>
      <div>{{getAssociationName()}}</div>
    </div>

    <div *ngIf="hostLogMessage?.associationLabel" class="summary-row">
      <div>Association Label</div>
      <div>{{hostLogMessage.associationLabel}}</div>
    </div>

    <div class="summary-row">
      <div>Message Type</div>
      <div>{{hostLogMessage?.messageType}}</div>
    </div>

    <div class="summary-row">
      <div>Leg</div>
      <div>{{hostLogMessage?.legNumber}}</div>
    </div>

    <div class="summary-row">
      <div>Transmitted</div>
      <div>{{convertDateTime(this.hostLogMessage?.dateTime)}}</div>
    </div>

    <div class="summary-row">
      <div *ngIf="hasValidationResult(this.hostLogMessage?.validationResult)">Validation Result</div>
      <div class="validation-result-text">{{this.hostLogMessage?.validationResult}}</div>
    </div>
  </div>
</div>
