import {Component, OnInit} from '@angular/core';
import {SpinnerService} from '@services/spinner/spinner.service/spinner.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  isVisible$: Observable<boolean>;
  isCentred$: Observable<boolean>;
  spinnerMessage: Observable<string>;
  constructor(private spinnerService: SpinnerService) {
  }

  ngOnInit() {
    this.isCentred$ = this.spinnerService.isCentred$;
    this.spinnerMessage = this.spinnerService.spinnerMessage$;
    this.isVisible$ = this.spinnerService.isVisible$;
  }

}
