import {Injectable} from '@angular/core';
import {CertQuestion, FormType, IntakeForm, TypeOfQuestion} from '@models/intake-forms';
import {ProjectsService} from '../data/projects.service/projects.service';
import {ProjectInfoTableProviderService} from '../reports/project-report-provider.service/project-info-table-provider.service/project-info-table-provider.service';
import {FormPdf} from './formPdf';
import {RendererFactory} from './question-renderers';

export interface IIntakeFormPdfDownloadService {downloadFormAsPdf(projectId: number, formType: FormType, filename: string): Promise<void>;}

@Injectable()
export class DownloadIntakeFormAsPdfService implements IIntakeFormPdfDownloadService {

  constructor(private projectsService: ProjectsService,
              private projectInfoTableProvider: ProjectInfoTableProviderService,
              private rendererFactory: RendererFactory) { }

  private static getQuestions(forms: IntakeForm, formType: FormType): Array<CertQuestion> {
    switch (formType) {
      case FormType.Common:
        return forms.commonQuestionsJson.ArrayOfCertQuestion.CertQuestion;
      case FormType.Contact:
        return forms.contactQuestionsJson.ArrayOfCertQuestion.CertQuestion;
      case FormType.Contactless:
        return forms.contactlessQuestionsJson.ArrayOfCertQuestion.CertQuestion;
      case FormType.Sandbox:
        return forms.sandboxQuestionsJson.ArrayOfCertQuestion.CertQuestion;
    }
  }

  async downloadFormAsPdf(projectId: number, formType: FormType, filename: string) {
    const project = await this.projectsService.getProjectDetails('' + projectId).toPromise();
    const forms: IntakeForm = await this.projectsService.getIntakeForm(projectId).toPromise();
    const questions = DownloadIntakeFormAsPdfService.getQuestions(forms, formType);
    this.senseCheckQuestions(questions);
    const renderers = this.rendererFactory.getRenderers(questions);

    const doc = new FormPdf();
    let yPos = doc.TopPageMargin;

    yPos = await this.projectInfoTableProvider.renderInfo(doc.pdfDoc, project, yPos) + 30;

    renderers.forEach(renderer => {
      doc.reset();
      renderer.layout(doc);

      // New page?
      if (yPos + renderer.height > doc.pageHeight) {
        yPos = doc.TopPageMargin;
        doc.addPage();
      }

      doc.reset();
      renderer.render(doc, yPos);
      yPos += renderer.height ?? 0;
    });
    doc.save(filename);
  }

  private senseCheckQuestions(questions: Array<CertQuestion>) {
    questions.forEach(q => {
      // Ensure that checkbox questions with more than two possible values are actually interpreted as drop downs
      if (q.TypeOfQuestion === TypeOfQuestion.Checkbox && q.PossibleValues.string && q.PossibleValues.string.length > 2) {
        q.TypeOfQuestion = TypeOfQuestion.DropDown;
      }
    });
  }
}
