import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {VersionInfo} from '@models/versionInfo';
import {VersionService} from '../version-service/version.service';

@Component({
  selector: 'app-version-dialog',
  templateUrl: './version-dialog.component.html',
  styleUrls: ['./version-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VersionDialogComponent implements OnInit {

  constructor(private versionService: VersionService) {
    this.uiVersionInfo = VersionDialogComponent.getVersionInfoString(versionService.getUiVersionInfo());
  }
  apiVersionInfo: string;
  readonly uiVersionInfo: string;

  static getVersionInfoString(versionInfo: VersionInfo): string {
    return `${versionInfo.version}-${versionInfo.revision}`;
  }

  ngOnInit(): void {
    this.versionService.getApiVersion()
      .subscribe((versionInfo) => this.apiVersionInfo = VersionDialogComponent.getVersionInfoString(versionInfo));
  }
}
