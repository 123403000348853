import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidationErrors} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FilterParameters} from '@services/data/filterParameters';
import * as _ from 'lodash';
import ProjectFilter from './projectFilter';
import ALLSEARCHTYPES = ProjectFilter.ALLSEARCHTYPES;
import SearchField = ProjectFilter.SearchField;
import SearchParameter = ProjectFilter.SearchParameter;
import SearchType = ProjectFilter.SearchType;
import {map, first, switchMap} from 'rxjs/operators';
import {Observable, from, take} from 'rxjs';
import {PermissionsService} from '@services/auth/permissions.service/permissions.service';
import {Action, Resource} from '@models/security';


export interface ProjectFilterDialogParameters {
  existingParameters: FilterParameters;
  title: string;
  canFilterByCertLead: boolean;
  isSandbox: boolean;
}

@Component({
  selector: 'app-custom-reports-filter-dialog',
  templateUrl: './project-filter-dialog.component.html',
  styleUrls: ['./project-filter-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectFilterDialogComponent implements OnInit {
  form: UntypedFormGroup;
  SearchField = SearchField;
  existingParams: SearchParameter[];

  availableParameters: Array<SearchType>;
  private canReadClients$: Observable<boolean>;


  constructor(private formBuilder: UntypedFormBuilder,
              @Inject(MAT_DIALOG_DATA) public params: ProjectFilterDialogParameters, private permissionService: PermissionsService) {
    this.canReadClients$ = this.permissionService.hasPermission([Resource.client, Action.read]);
  }

  private get AllSearchTypes(): Array<ProjectFilter.SearchType> {
    const searchTypes = this.params.canFilterByCertLead
      ? ALLSEARCHTYPES
      : ALLSEARCHTYPES.filter(type => type.field !== SearchField.CertLeadId);
    return searchTypes.filter(type => this.params.isSandbox ? type.sandbox : type.nonSandbox);
  }

  get sortedAvailableParameters() {
    return _.sortBy(this.availableParameters, p => p.fieldDescription);
  }

  get parameterArray(): UntypedFormArray {
    return this.form?.get('parameters') as UntypedFormArray;
  }

  get canAddClientLeadFilter() {
    return _.some(this.parameterArray.value, p => p.type.field === SearchField.Client && p.value);
  }

  ngOnInit(): void {
    this.existingParams = this.params.existingParameters ? this.mapExistingParameters(this.params.existingParameters) : [];
    const allUsedFields: SearchField[] = this.existingParams.map<SearchField>(param => param.type.field);

    this.availableParameters = [...this.AllSearchTypes.filter(st => !allUsedFields.includes(st.field))];
    this.canReadClients$.pipe(take(1))
    .subscribe(canReadClients => {
      if (!canReadClients) {
        this.removeClientFilters(this.availableParameters);
      }
    });

    const existingParamsWithFormControls: FormControl[] = [];

    // any existing params need to be recreated as formControls when loaded for validation
    this.existingParams.forEach((param: SearchParameter) => {
      existingParamsWithFormControls.push(this.formBuilder.control(param, {validators: [this.hasSearchParamValue.bind(this)]}));
    });

    this.form = this.formBuilder.group({
      newParameter: '',
      parameters: this.formBuilder.array(existingParamsWithFormControls)
    }, {validators: this.mustHaveOneSearchParameter.bind(this) });
  }

  private mapExistingParameters(existingParameters: FilterParameters): Array<SearchParameter> {
    const parameters: Array<SearchParameter> = [];
    existingParameters.forEach((value, key) => {
      const searchType = this.AllSearchTypes.find(st => st.field === key);
      if (searchType) {
        const searchParameter: SearchParameter = {type: searchType, value};

        if (searchType.field === 'authSpec') {
          searchParameter.value = decodeURIComponent(value as string);
        }

        parameters.push(searchParameter);
      }
    });
    return parameters;
  }

  removeClientFilters(availableParameters): void {
    let found = 0;
    for (let i = availableParameters.length - 1; i >= 0; i--) {
      if (availableParameters[i].fieldDescription === "Client" || availableParameters[i].fieldDescription === "Client Lead") {
        this.availableParameters.splice(i, 1);
        if (++found === 2) {
          break;
        }
      }
    }
  }


  mustHaveOneSearchParameter(): ValidationErrors | null {
    return this.parameterArray?.length > 0 ? null : {needParam: true};
  }

  onAddParameterClicked() {
    const newParameterControl = this.form.get('newParameter');
    const newParameterField = newParameterControl.value;
    const newParameterIndex = this.availableParameters.findIndex(p => p.field === newParameterField);
    const newParameter = this.availableParameters[newParameterIndex];

    if (newParameterField === SearchField.ClientLeadId && !this.canAddClientLeadFilter) {
      return;
    }

    if (newParameterIndex >= 0) {
      const newSearchParameter: SearchParameter = {
        type: newParameter,
        value: ProjectFilterDialogComponent.getDefaultValue(newParameter)
      };
      this.parameterArray.push(this.formBuilder.control(newSearchParameter, {validators: [this.hasSearchParamValue.bind(this)]}));
      this.availableParameters.splice(newParameterIndex, 1);

      newParameterControl.setValue('');
    }
  }

  hasSearchParamValue(control: AbstractControl) {
    const controlValue = control.value as SearchParameter;
    return controlValue.value ? null : {required: true};
  }

  onRemoveParameter(parameterIndex: number) {
    const control = this.parameterArray.at(parameterIndex);
    const removedParameter: SearchParameter = control.value as SearchParameter;

    this.parameterArray.removeAt(parameterIndex);
    this.availableParameters.push(removedParameter.type);

    // Do we need to remove the client lead as well if the client is selected?
    if (removedParameter.type.field === SearchField.Client) {
      for (let i = 0; i < this.parameterArray.length;) {
        const controlParameter = this.parameterArray.at(i).value as SearchParameter;
        if (controlParameter.type.field === SearchField.ClientLeadId) {
          this.parameterArray.removeAt(i);
          this.availableParameters.push(controlParameter.type);
        } else {
          i++;
        }
      }
    }
  }

  private static getDefaultValue(searchType: SearchType) {
    switch (searchType.field) {
      case SearchField.CommonFormStatus:
      case SearchField.ContactFormStatus:
      case SearchField.ContactlessFormStatus:
        return 'SUBMITTED';
      default:
        return '';
    }
  }

  onSearchParameterChanged(searchParameter: SearchParameter) {
    if (searchParameter.type.field === SearchField.Client) {
      // reset any fields that are the client lead user
      for (let i = 0; i < this.parameterArray.length; i++) {
        const parameter = this.parameterArray.at(i);
        const parameterValue = parameter.value as SearchParameter;
        if (parameterValue.type.field === SearchField.ClientLeadId) {
          parameter.setValue({...parameterValue, value: undefined});
        }
      }
    }
  }
}
