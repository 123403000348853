import {Injectable} from '@angular/core';
import {PagedResponse} from '@models/pagedResponse';
import PagingCriteria from '@models/pagingCriteria';
import {ProjectDetail} from '@models/projectDetail';
import {TestCaseWaiver} from '@models/tests/testCaseWaiver';
import {ProjectsService} from '@services/data/projects.service/projects.service';
import {SortDirection} from '@services/data/sortDirection';
import {BehaviorSubject, merge, Observable, of, Subject} from 'rxjs';
import {catchError, map, shareReplay, switchMap} from 'rxjs/operators';

export interface IWaiversDataService {
  readonly waivers$: Observable<any[]>;
  readonly waiverCount$: Observable<number>;

  setProject(project: ProjectDetail): Promise<void>;

  setPaging(sort: string, direction: SortDirection, pageIndex: number, pageSize: number): void;
}

@Injectable()
export class WaiversDataService implements IWaiversDataService {
  private projectId: number;

  private _pagingCriteria = new BehaviorSubject<PagingCriteria>(null);
  private refresh$ = new Subject<void>();

  private _waiversResponse$: Observable<PagedResponse<TestCaseWaiver>>;

  constructor(private projectsService: ProjectsService) {

    this._waiversResponse$ = merge(this.refresh$, this._pagingCriteria).pipe(
      switchMap(_ => {
        const pagingInfo = this._pagingCriteria.value;
        return this.projectId ? this.projectsService.getWaiversForProject(this.projectId, pagingInfo.sort, pagingInfo.direction, pagingInfo.pageIndex, pagingInfo.pageSize) :
          of(null);
      }),
      catchError(_ => of<PagedResponse<TestCaseWaiver>>({data: [], totalResults: 0, totalPages: 0, pageSize: 10, pageNumber: 0})),
      shareReplay()
    );
    this.setPaging('', 'desc', 0, 1);
  }

  get waivers$(): Observable<TestCaseWaiver[]> {
    return this._waiversResponse$.pipe(map(res => res?.data ?? []));
  }

  get waiverCount$(): Observable<number> {
    return this._waiversResponse$.pipe(map(res => res?.totalResults ?? 0));
  }

  async setProject(project: ProjectDetail) {
    this.projectId = project.id;
    this.refresh$.next();
  }

  setPaging(sort: string, direction: SortDirection, pageIndex: number, pageSize: number) {
    this._pagingCriteria.next({sort, direction, pageIndex, pageSize});
  }
}
