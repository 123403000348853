import {Injectable} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PermissionsService, SiteSection} from './permissions.service/permissions.service';

@Injectable({
  providedIn: 'root'
})
export class PermittedSectionService {
  static readonly sections = [SiteSection.Projects, SiteSection.HostLogs, SiteSection.TestPlans, SiteSection.Clients,
    SiteSection.Users, SiteSection.Reports, SiteSection.Search];

  constructor(private permissionsService: PermissionsService) {}

  get permittedSections$(): Observable<Array<SiteSection>> {
    return combineLatest(
      PermittedSectionService.sections.map(section =>
        this.permissionsService.currentUserCanAccessSiteSection(section).pipe(map(t => t ? section : null))))
      .pipe(map(arr => arr.filter(t => t !== null)));
  }
}
