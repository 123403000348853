<div class="state-container">
  <ng-container *ngIf="(canNotifyICC$ | async) && projectDetail && projectDetail.phase === ProjectPhase.Onboarding && !projectDetail.sandbox">
  <p> Notify the ICC that the intake forms are ready for review.</p>
    <div class="notify-buttons">
      <button *ngIf="projectDetail.phase === ProjectPhase.Onboarding"
              [disabled]="disable$ | async"
              mat-button mat-stroked-button mat-raised-button color="blue" (click)="notifyICC()">
        <mat-icon>mail_outline</mat-icon>
        {{sendNotification}}
      </button>
    </div>
  </ng-container>
</div>
