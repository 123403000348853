import {Pipe, PipeTransform} from '@angular/core';
import {RoleDescriptionService} from '../data/role-description.service';

@Pipe({
  name: 'roleDescription'
})
export class RoleDescriptionPipe implements PipeTransform {

  constructor(private roleDescriptionService: RoleDescriptionService) {}
  transform(value: any, ...args: unknown[]): unknown {
    return this.roleDescriptionService.getRoleDescription(value);
  }

}
