export enum TestTool {

  None = 'NONE',
  BTT = 'BTT',
  ICC = 'ICC',
  All = 'ALL'
}

export const AllTestTools = [
  TestTool.None,
  TestTool.BTT,
  TestTool.ICC,
  TestTool.All
]
