import {Component, forwardRef, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {AssignedUser} from '@models/assignedUser';
import {InlineControlComponent} from '../inline-edit/inline-control.component';

const CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AssignedUserSelectComponent),
  multi: true
};

@Component({
  selector: 'app-assigned-user-select',
  templateUrl: './assigned-user-select.component.html',
  providers: [CONTROL_VALUE_ACCESSOR],
  styleUrls: ['./assigned-user-select.component.scss']
})
export class AssignedUserSelectComponent extends InlineControlComponent<AssignedUser>{

  @Input() users: Array<AssignedUser> = [];
  @Input() errorMessage: string;

  disabled = false;

  get selectedId() {
    return this.value?.userId;
  }

  set selectedId(id: number) {
    this.value = this.users?.find(opt => opt.userId === id);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
