import {CertQuestion} from '@models/intake-forms';
import {FormPdf} from '../formPdf';
import {Renderer} from './renderer';

const QuestionToAnswerSpace = 4;
const AfterQuestionSpace = 8;
const SpaceBetweenAnswerLines = 2;
const QuestionWithAnswerFontSize = 8;
const AnswerFontSize = 10;

const QuestionTextColour = '#808080';
const AnswerTextColour = '#000000';
const NoAnswerTextColour = '#c0c0c0';

export class TextBoxAndDropDownRenderer extends Renderer {

  private questionTextHeight: number;

  constructor(q: CertQuestion) {super(q);}

  private get answer(): string {
    // Check if a valid value has been defined and should be used, else we'll default to 'No Answer'
    const hasDefinedValue: boolean = !([null, undefined, ''] as any[]).includes(this.question.Result.string);

    return hasDefinedValue ? '' + this.question.Result.string : 'No Answer';
  }

  layout(doc: FormPdf) {
    doc.setFontSize(QuestionWithAnswerFontSize);
    this.height = this.questionTextHeight = doc.getWrappedTextSize(this.question.QuestionText).height;
    doc.setFontSize(AnswerFontSize);
    const answerLines = this.answer.split('\n');
    answerLines.forEach(line => this.height += doc.getWrappedTextSize(line).height);
    this.height += AfterQuestionSpace + QuestionToAnswerSpace + ((answerLines.length - 1) * SpaceBetweenAnswerLines);
  }

  render(doc: FormPdf, ypos: number) {
    doc.setFontSize(QuestionWithAnswerFontSize);
    doc.setTextColour(QuestionTextColour);
    doc.drawWrappedText(this.question.QuestionText, ypos);
    if (this.question.Result.string) {
      doc.setTextColour(AnswerTextColour);
    } else {
      doc.setTextColour(NoAnswerTextColour);
    }
    doc.setFontSize(AnswerFontSize);
    const answerLines = this.answer.split('\n');
    ypos += QuestionToAnswerSpace + this.questionTextHeight;
    answerLines.forEach(line => {
      doc.drawWrappedText(line, ypos);
      ypos += doc.getWrappedTextSize(line).height + SpaceBetweenAnswerLines;
    });
  }
}
