import {Component, HostListener} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {NavigationEnd, Router} from '@angular/router';
import {AuthenticationService} from '@services/auth/authentication.service/authentication.service';
import {SiteSection} from '@services/auth/permissions.service/permissions.service';
import {PermittedSectionService} from '@services/auth/permitted-section.service';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {environment} from '../../environments/environment';

const noBarSections = ['login', 'faq', 'knowledge-repository', 'loa-archive', 'links'];

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent {

  sections$: Observable<Array<SiteSection>>;
  showSideNavigation$: Observable<boolean>;

  constructor(private authenticationService: AuthenticationService,
              permittedSectionService: PermittedSectionService,
              private router: Router,
              private titleService: Title) {
    this.titleService.setTitle(environment.pageTitle);
    this.showSideNavigation$ = router.events
      .pipe(
        filter(ev => ev instanceof NavigationEnd),
        map(({urlAfterRedirects}: NavigationEnd) =>
          urlAfterRedirects !== '/' &&
          !noBarSections.some(s => urlAfterRedirects.startsWith(`/${s}`))));

    this.sections$ = permittedSectionService.permittedSections$;
  }

  onLogout() {
    this.authenticationService.logout();
    this.router.navigate(['login']);
  }

  @HostListener('document:visibilitychange')
  async visibilityChange() {
    if (document.visibilityState === 'hidden') {
      await this.authenticationService.endSession();
    } else {
      this.authenticationService.shouldRefresh = true;
    }
  }

  /**
   * This gets called when leaving the page. This includes refreshing,
   * closing the tab/window, or navigating to another site. Does not include
   * navigating between pages within the app. It also gets called when
   * switching browser tabs.
   *
   * This can be inconsistent on mobile. For example, if a user switches apps,
   * then closes the browser app via the OS app manager, the beforeunload
   * event will not be fired. Fortunately, we don't officially support mobile.
   */
  @HostListener('window:beforeunload')
  async windowUnloaded() {
    await this.authenticationService.endSession();
  }
}
