import {Injectable} from '@angular/core';

import {ProjectState} from '@models/projectState';
import {IProjectStateDescriptionService} from './iProjectStateDescriptionService';

const descriptions: Record<ProjectState, string> = {
  [ProjectState.Active] : 'Active',
  [ProjectState.InActive]: 'Draft',
  [ProjectState.OnHold]: 'On Hold',
  [ProjectState.Cancelled]: 'Cancelled',
  [ProjectState.Closed]: 'Closed'
};


@Injectable()
export class ProjectStateDescriptionService implements IProjectStateDescriptionService {
  getDescription(state: ProjectState): string {
    return descriptions[state];
  }
}
