import {Injectable, NgZone} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {INotificationService} from './INotificationService';

@Injectable()
export class NotificationService implements INotificationService {
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly zone: NgZone
  ) {}

  default(message: string) {
    this.show(message, {
      duration: 2000,
      panelClass: 'default-notification-overlay'
    });
  }

  info(message: string, config: Partial<MatSnackBarConfig> = {}) {
    const defaultConfig = {
      duration: 2500,
      panelClass: 'info-notification-overlay'
    };

    const panelClass: string[] = [defaultConfig.panelClass];

    if (Array.isArray(config.panelClass)) {
      panelClass.push(...config.panelClass);
    }

    this.show(message, {...defaultConfig, ...config, panelClass});
  }

  success(message: string) {
    this.show(message, {
      duration: 2000,
      panelClass: 'success-notification-overlay'
    });
  }

  warn(message: string) {
    this.show(message, {
      duration: 2500,
      panelClass: 'warning-notification-overlay'
    });
  }

  error(message: string, config: Partial<MatSnackBarConfig> = {}) {
    const defaultConfig = {
      duration: 3000,
      panelClass: 'error-notification-overlay',
    };
    this.show(message, {...defaultConfig, ...config});
  }

  clear() {
    this.snackBar.dismiss();
  }

  private show(message: string, configuration: MatSnackBarConfig) {
    // Need to open snackBar from Angular zone to prevent issues with its position per
    // https://stackoverflow.com/questions/50101912/snackbar-position-wrong-when-use-errorhandler-in-angular-5-and-material
    this.zone.run(() => this.snackBar.open(message, null, configuration));
  }
}
