export enum TypeOfQuestion {
    /* Label is a static piece of text that requires no result */
    Label = 'Label',

    /* Line is a single horizontal line, usually proceeded by a label */
    Line = 'Line',

    /* Text entry */
    TextBox = 'Textbox',

    /* Multi line text box entry */
    MultiLineTextBox = 'MultilineTextbox',

    /* Boolean entry */
    Checkbox = 'Checkbox',

    /* Choice between a number of possible values, from the PossibleValues member */
    DropDown = 'DropDown',

    /* An inclusive multi-boolean entry */
    MultiSelectCheckbox = 'MultiSelectBox'
}
