import {NgxMatDateFormats} from '@angular-material-components/datetime-picker';

export const NGX_DATE_TIME_FORMAT_DATE_ONLY: NgxMatDateFormats = {
  parse: {
    dateInput: 'l, LTS'
  },
  display: {
    dateInput: 'l',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
