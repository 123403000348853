<div class="table-container">
  <div class="controls">
    <button *ngIf="canCreateComments$ | async" class="button-with-image" mat-button mat-raised-button (click)="addComment()">
      <img src="../../../assets/icons/comment.svg" width="24px" alt="Add Comment"/>
      Add Comment
    </button>
    <mat-paginator [showFirstLastButtons]="true"
                   [length]="commentCount$ | async"
                   [pageSize]="25"
                   [pageSizeOptions]="[10, 25, 50, 100]">
    </mat-paginator>
  </div>
  <table mat-table matSort matSortActive="creationDate"
         matSortDisableClear matSortDirection="asc" [dataSource]="dataSource$ | async">

    <!-- Creation date -->
    <ng-container matColumnDef="creationDate">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Created</th>
      <td mat-cell *matCellDef="let comment">{{comment.creationDate | estDateTime}}</td>
    </ng-container>

    <!-- Author Column -->
    <ng-container matColumnDef="author">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Author</th>
      <td mat-cell *matCellDef="let comment"> {{comment.author?.firstName}} {{comment.author?.lastName}}</td>
    </ng-container>

    <!-- Last Modified Column -->
    <ng-container matColumnDef="lastModifiedDate">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Last Modified</th>
      <td mat-cell *matCellDef="let comment"> {{comment.lastModifiedDate | estDateTime | dashIfNull}} </td>
    </ng-container>

    <!-- Comment Text Column -->
    <ng-container matColumnDef="commentText">
      <th mat-header-cell *matHeaderCellDef>Comment</th>
      <td mat-cell *matCellDef="let comment"><span class="line-breaker">{{comment.commentText}}</span></td>
    </ng-container>

    <!-- Delete Column -->
    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let comment">
        <div style="width: 24px">
          <app-overlay-button *ngIf="canDeleteComment(comment) | async"
                              imageUrl="assets/icons/delete.svg"
                              (click)="deleteComment(comment)"
                              matTooltip="Delete Comment"
                              rolloverImageUrl="assets/icons/delete-hilight.svg"></app-overlay-button>
        </div>
      </td>
    </ng-container>

    <!-- Delete Column -->
    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let comment">
        <div style="width: 24px">
          <app-overlay-button *ngIf="canEditComment(comment) | async"
                              imageUrl="assets/icons/edit.svg"
                              (click)="editComment(comment)"
                              matTooltip="Edit Comment"
                              rolloverImageUrl="assets/icons/edit-hilight.svg"></app-overlay-button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let comment; columns: displayedColumns;"></tr>
  </table>
</div>
