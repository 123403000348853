import {Component, EmbeddedViewRef, NgZone, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {AuthenticationService} from '@services/auth/authentication.service/authentication.service';
import {NotificationService} from '@services/notification.service/notification.service';
import {LogoutWarningTimer, UserInactivityService} from '@services/user-inactivity.service/user-inactivity.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-time-out-alert',
  templateUrl: './time-out-alert.component.html',
  styleUrls: ['./time-out-alert.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TimeOutAlertComponent implements OnInit, OnDestroy {
  @ViewChild('timeout') timeOutTemplate: TemplateRef<any>;

  private time: number;
  private snackBarRef: MatSnackBarRef<EmbeddedViewRef<any>>;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private timerStarted = false;
  private notificationPermission: 'default' | 'denied' | 'granted';

  constructor(private authenticationService: AuthenticationService,
              private userInactivityService: UserInactivityService,
              private notificationService: NotificationService,
              private readonly snackBar: MatSnackBar,
              private readonly zone: NgZone,
              private router: Router) {
  }

  get countdown(): string {
    const time = LogoutWarningTimer - this.time;
    return `${time} second${time === 1 ? '' : 's'}`;
  }

  private static async notificationPermissionCheck(): Promise<'default' | 'denied' | 'granted'> {
    if (Notification.permission === 'default') {
      const permission = await Notification.requestPermission();
    }
    return Notification.permission;
  }

  ngOnInit() {

    this.authenticationService.authInfo$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async auth => {
        if (auth) {
          this.notificationPermission = await TimeOutAlertComponent.notificationPermissionCheck();

          if (!this.timerStarted) {
            this.timerStarted = true;
            this.userInactivityService.startWatching();
          }
        } else {
          if (this.timerStarted) {
            this.timerStarted = false;
            this.userInactivityService.stopWatching();
          }
        }
      });

    this.userInactivityService.onTimerStart()
      .pipe(takeUntil(this.destroy$))
      .subscribe(time => {
        if (time === 1) {
          this.showTimeOutNotification();
        }
        if (time > 0) {
          this.time = time;
        }
      });

    this.userInactivityService.onTimeout()
      .pipe(takeUntil(this.destroy$))
      .subscribe(_ => {
          this.onLogout();
          this.notificationService.info('You were logged out due to inactivity', {duration: undefined, verticalPosition: 'top', panelClass: ['authentication-panel']});
        }
      );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  showTimeOutNotification() {
    if (this.notificationPermission === 'granted') {
      const notification = new Notification('Session about to expire', {
        body: 'Your t3Cert login session is about to expire',
        icon: 'assets/t3.png'
      });
      notification.onclick = _ => this.onDismiss();
    }
    this.zone.run(() => this.snackBarRef = this.snackBar.openFromTemplate(this.timeOutTemplate, {
      panelClass: 'time-out-popup',
      verticalPosition: 'top',
      horizontalPosition: 'center'
    }));
  }

  onDismiss() {
    this.snackBarRef.dismiss();
    this.snackBarRef = null;
    this.userInactivityService.resetTimer();
  }

  onLogout() {
    this.authenticationService.logout();
    this.router.navigate(['login']);
    if (this.snackBarRef) {
      this.snackBarRef.dismiss();
      this.snackBarRef = null;
    }
  }
}
