import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {GlobalWaiverReport} from '@models/reports/globalWaiverReport';
import {BlobDownloadService} from '@services/data/blob-download.service/blob-download.service';
import {GlobalWaiverReportService} from '@services/data/global-waiver-report/global-waiver-report.service';
import {NotificationService} from '@services/notification.service/notification.service';
import {BehaviorSubject, fromEvent, merge, Observable, Subscription} from 'rxjs';
import {debounceTime, map, tap} from 'rxjs/operators';
import {ExcelDownloadDialogService} from '../../pages/reports/custom-reports/excel-download-dialog.service/excel-download-dialog.service';
import {WaiverFilterService} from '../waiver-filter.service/waiver-filter.service';

@Component({
  selector: 'app-global-waiver-table',
  templateUrl: './global-waiver-table.component.html',
  styleUrls: ['./global-waiver-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalWaiverTableComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('filterInput', {static: true}) filterInput: ElementRef;
  private filterChanged$: Observable<any>;

  @Input() showAdvancedFilter = true;

  displayedColumns = ['projectId', 'projectName', 'clientName', 'platform', 'hostMessageSpecification', 'certLead', 'ipLead', 'name', 'statusNumber', 'statusExpiryDate', 'statusComment'];

  row$: Observable<MatTableDataSource<GlobalWaiverReport>>;
  totalRows$: Observable<number>;
  private readonly refreshToken$ = new BehaviorSubject(undefined);
  private subscription: Subscription;

  constructor(private globalWaiverDataSource: GlobalWaiverReportService,
              private waiverFilter: WaiverFilterService,
              private changeDetectorRef: ChangeDetectorRef,
              private excelDownloadDialog: ExcelDownloadDialogService,
              private notificationService: NotificationService,
              private blobDownloadService: BlobDownloadService) {
  }

  async ngOnInit() {
    this.row$ = this.globalWaiverDataSource.rows$.pipe(map(data => new MatTableDataSource(data)));
    this.totalRows$ = this.globalWaiverDataSource.totalRows$;
    await this.globalWaiverDataSource.updateTable();
  }

  async updateFilter() {
    const response = await this.waiverFilter.updateFilterParameters(this.globalWaiverDataSource.filters, 'Filter', false);
    if (response) {
      const filters = new Map<string, string>();
      response.forEach(res => filters.set(res.type.field, res.value.toString()));
      await this.globalWaiverDataSource.setFilters(filters);
    }
    this.paginator.firstPage();
  }

  ngAfterViewInit(): void {
    this.filterChanged$ = fromEvent(this.filterInput.nativeElement, 'input');
    this.subscription = merge(
      this.refreshToken$.pipe(debounceTime(500)),
      this.filterChanged$.pipe(debounceTime(250), tap(() => this.paginator.pageIndex = 0)),
      this.sort.sortChange, this.paginator.page
    ).subscribe(async () => {
      await this.globalWaiverDataSource.setTable(this.sort.active, this.sort.direction,
        this.paginator.pageIndex, this.paginator.pageSize, this.filterInput.nativeElement.value);
    });
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.waiverFilter.clearCaches();
    this.subscription.unsubscribe();
    this.globalWaiverDataSource.resetFiltersAndTable();
  }

  async onClearPressed() {
    const clearedFilters = new Map<string, string>();
    await this.globalWaiverDataSource.setFilters(clearedFilters);
    this.paginator.firstPage();
  }

  refresh() {
    this.refreshToken$.next(undefined);
  }

  hasNoFilters() {
    return !this.globalWaiverDataSource.filters?.size;
  }

  async downloadAsExcel() {
    const excelName = await this.excelDownloadDialog.getDownloadName('Download Global Waiver Report', 'global-waiver.xlsx');
    if (!excelName) {
      return;
    }

    try {
      const excelFile = await this.globalWaiverDataSource.downloadExcelFile().toPromise();
      this.blobDownloadService.downloadExcelBlob(excelFile, excelName);
    } catch {
      this.notificationService.error('There was a problem downloading this global waiver report');
    }
  }
}
