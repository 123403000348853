import {Injectable} from '@angular/core';
import {Role} from '@models/security';

@Injectable({
  providedIn: 'root'
})
export class RoleDescriptionService {

  private readonly descriptions: Record<Role, string> = {
    [Role.admin]: 'Admin',
    [Role.analyst]: 'Analyst',
    [Role.client]: 'Client User',
    [Role.gatekeeper]: 'Gate Keeper',
    [Role.iccManager]: 'ICC Manager',
    [Role.manager]: 'Manager',
    [Role.ipManager]: 'IP Managers',
    [Role.integratedPartner]: 'Integrated Partner',
    [Role.thirdPartyClient]: 'Third Party Client'
  };

  getRoleDescription(role?: Role): string {
    return role ? this.descriptions[role] : 'Un-recognised';
  }
}
