<div class="inline-input">
  <div *ngIf="editing">
    <mat-form-field style="width: 100%">
      <input #inlineEditControl (blur)="onBlur()" [(ngModel)]="value" [name]="value" [placeholder]="label" [required]="required"
             [type]="type" matInput (keydown)="onKeyDown($event)" [maxlength]="maxLength">
    </mat-form-field>
  </div>
  <div *ngIf="!editing" [class.editable]="!readonly" [class.no-value]="!value" class="non-edit-mode">
    <mat-form-field style="width: 100%">
      <input (focus)="edit()" [(ngModel)]="value" [name]="value" [placeholder]="label" [required]="required" matInput
             readonly>
    </mat-form-field>
  </div>
</div>
