<div [formGroup]="changePasswordFormGroup" class="form-container">
  <mat-form-field>
    <mat-label>Current Password</mat-label>
    <input placeholder="Current password" matInput name="currentPassword"
           formControlName="currentPassword"
           #currentPasswordField
           autocomplete="off"
           type="password"/>
    <mat-error *ngIf="currentPassword.errors?.required">
      You must supply your current password
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>New Password</mat-label>
    <input placeholder="New password" matInput name="newPassword"
           formControlName="newPassword"
           autocomplete="off"
           type="password"/>
    <mat-error *ngIf="newPassword.errors?.required">
      You must supply a new password
    </mat-error>
    <mat-error *ngIf="newPassword.errors?.tooShort">
      You must supply a password of at least {{minimumPasswordLength}} characters.
    </mat-error>
    <mat-error *ngIf="newPassword.errors?.notComplex">
      Password should have at least one uppercase, one lowercase, one numeric and one symbolic character.
    </mat-error>
    <mat-error *ngIf="newPassword.errors?.sameAsOriginal">
      You must choose a password that is different to your current password.
    </mat-error>
    <mat-error *ngIf="newPassword.errors?.cannotIncludeUsername">
      You cannot include your username as part of the password.
    </mat-error>
    <mat-error *ngIf="newPassword.errors?.notMatched || confirmPassword.errors?.notMatched">
      New password and confirm password must match
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Confirm New Password</mat-label>
    <input placeholder="Confirm new password" matInput name="confirmPassword"
           formControlName="confirmPassword"
           autocomplete="off"
           type="password"/>
    <mat-error *ngIf="confirmPassword.errors?.required">
      You must enter your new password again to confirm it.
    </mat-error>
    <mat-error *ngIf="newPassword.errors?.notMatched || confirmPassword.errors?.notMatched">
      New password and confirm password must match
    </mat-error>
  </mat-form-field>
  <div class="form-buttons">
    <button mat-raised-button color="primary"
            [disabled]="changePasswordFormGroup.invalid"
            (click)="onChangePasswordClicked()">Change Password
    </button>
  </div>
</div>
