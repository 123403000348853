import {CertQuestion} from '@models/intake-forms';
import {FormPdf} from '../formPdf';
import {Renderer} from './renderer';

export class LineRenderer extends Renderer {
  constructor(q: CertQuestion) {super(q);}

  layout(doc: FormPdf) {
    this.height = 4;
  }

  render(doc: FormPdf, ypos: number) {
    doc.drawLine(doc.LeftPageMargin - 10, ypos - 8, doc.pageWidth + doc.LeftPageMargin + 20, ypos - 8);
  }
}
