import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {AlertDialogComponent, AlertDialogParams} from './alert-dialog.component';
import {ConfirmDialogComponent, ConfirmDialogParams, DialogExtraOption} from './confirm-dialog.component';
import {IConfirmDialogService} from './iConfirmDialogService';

@Injectable()
export class ConfirmDialogService implements IConfirmDialogService {

  constructor(private dialog: MatDialog) {
  }

  alert(title: string, message: string, closeText = 'OK'): Observable<any> {
    return this.dialog.open<AlertDialogComponent, AlertDialogParams>(AlertDialogComponent, {
        minWidth: '400px',
        maxWidth: '75vw',
        data: {
          title,
          message,
          closeText
        }
      }
    ).afterClosed();
  }

  confirm(title: string, message: string, yesText = 'Yes', noText = 'No', option?: DialogExtraOption): Observable<boolean> {
    return this.doDialog(title, message, yesText, noText, false, option);
  }

  warning(title: string, message: string, yesText = 'Yes', noText = 'No', option?: DialogExtraOption): Observable<boolean> {
    return this.doDialog(title, message, yesText, noText, true, option);
  }

  private doDialog(title: string, message: string, yesText: string, noText: string, isWarning: boolean, option?: DialogExtraOption): Observable<boolean> {
    return this.dialog.open<ConfirmDialogComponent, ConfirmDialogParams>(ConfirmDialogComponent, {
      minWidth: '400px',
      maxWidth: '75vw',
      backdropClass: isWarning ? 'warning-dialog-backdrop' : '',
      data: {
        title,
        message,
        yesText,
        noText,
        isWarning,
        option
      }
    }).afterClosed();
  }
}
