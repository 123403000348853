import {ProjectFile, ProjectFileType} from './projectFile';

export class ProjectFileDataSource {
  static readonly testCases = [
    ProjectFileType.NonMCTestCases,
    ProjectFileType.MastercardTestCases,
    ProjectFileType.ULTestCases,
    ProjectFileType.DiscoverTestCases,
    ProjectFileType.VisaTestCases,
    ProjectFileType.AmexTestCases,
    ProjectFileType.JcbTestCases,
    ProjectFileType.UnionPayTestCases,
    ProjectFileType.TseTestCases];

  constructor(private source: Array<ProjectFile>,
              private canSeeTestFilesInFileList: boolean) {
  }

  static isTestCaseFile(file: ProjectFile): boolean {
    return ProjectFileDataSource.testCases.includes(file.fileType);
  }

  fileListCount(): number {
    if (this.canSeeTestFilesInFileList) {
      return this.source.length;
    } else {
      return this.source.filter(file => !ProjectFileDataSource.isTestCaseFile(file)).length;
    }
  }

  hasTestFiles(): boolean {
    return this.source.some(file => ProjectFileDataSource.isTestCaseFile(file));
  }

  getFileList(sort: string, sortDirection: string, page: number, pageSize: number): Array<ProjectFile> {
    const sortDir = sortDirection === 'asc' ? 1 : -1;
    const array = this.canSeeTestFilesInFileList ? this.source : this.source.filter(file => !ProjectFileDataSource.isTestCaseFile(file));
    return array.sort((a, b) => b[sort] < a[sort] ? sortDir : b[sort] === a[sort] ? 0 : -sortDir)
      .slice(page * pageSize, (page + 1) * pageSize);
  }

  hasFileByType(type: ProjectFileType): boolean {
    return this.source.some(file => file.fileType === type);
  }

  getFileByType(type: ProjectFileType) {
    return this.source.find(file => file.fileType === type);
  }
}
