import {CertQuestion} from '@models/intake-forms';

export abstract class Renderer {
  question: CertQuestion;
  height: number;

  protected constructor(q: CertQuestion) {
    this.question = q;
  }

  abstract layout(doc: any);

  abstract render(doc: any, ypos: number);
}
