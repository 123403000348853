import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Comment} from '@models/comment';

export interface CommentDialogParams {
  commentToEdit: Comment;
}

export interface CommentDialogResult {
  comment: string;
}

@Component({
  selector: 'app-comment-dialog',
  templateUrl: './comment-dialog.component.html',
  styleUrls: ['./comment-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentDialogComponent implements OnInit {

  isAdding: boolean;
  form: UntypedFormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) private params: CommentDialogParams,
              formBuilder: UntypedFormBuilder) {
    this.isAdding = params.commentToEdit == null;
    this.form = formBuilder.group({
      comment: [this.isAdding ? '' : this.params.commentToEdit.commentText, Validators.required]
    });
  }

  ngOnInit(): void {
  }

}
