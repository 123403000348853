import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthInfo} from '@models/security';
import * as _ from 'lodash';
import {Observable, of, Subject, throwError} from 'rxjs';
import {catchError, finalize, switchMap, take} from 'rxjs/operators';
import HttpStatusCode from '../httpStatusCode';
import {NotificationService} from '../notification.service/notification.service';
import {AuthenticationService} from './authentication.service/authentication.service';

const accountLoggedInSomewhereElse = 'Token Invalid. Account has logged in somewhere else.';
const accountLoggedInSomewhereElseMsg = 'Your account has logged in somewhere else and made your session invalid';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

  readonly publicUrls = ['login', 'refresh', 'appConfig.json'];

  private isRefreshing = false;
  private refreshTokenSubject: Subject<any> = new Subject<any>();

  constructor(private authService: AuthenticationService,
              private router: Router,
              private notificationService: NotificationService) {
  }

  private isPublicUrl(requestUrl: string): boolean {
    return _.some(this.publicUrls, url => requestUrl.endsWith(`/${url}`));
  }

  private isTOTPUrl(requestUrl: string, requestMethod: string): boolean {
    // DELETE methods require full auth access, so the user will be logged in at this point
    if (requestMethod === 'DELETE') {
      return false;
    }
      
    return requestUrl.includes('/totp/');
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isPublicUrl(request.url)) {
      return next.handle(request);
    }

    // Add token if not logging in or refreshing our token
    const authToken: string = !this.isTOTPUrl(request.url, request.method) ? this.authService.getJwtToken() : this.authService.getTOTPAuthToken();
    
    if (authToken) {
      request = this.addToken(request, authToken);
    }

    return next.handle(request)
      .pipe(
        catchError((httpError: HttpErrorResponse) => {
          const is401Error: boolean = httpError?.status === HttpStatusCode.UNAUTHORIZED;
          const is401ErrorUnauthorizedMessage: boolean = httpError.error?.error === 'Unauthorized';
          /**
           * When requests are transformed into blobs, the standard error response from the API gets obfuscated
           */
          const isBlobErrorResponse: boolean = is401Error && httpError.error instanceof Blob;

          /**
           * It's important we only process REAL 401 responses from API requests handled after the login process
           *
           * Since adding OTP authentication support, auth tokens are used to finish the authenticate process
           * This means they need to be 'refreshed' when the user returns to the product.
           *
           * 401 is now returned if the OTP verification is invalid, such as
           *
           * {@link LoginResponseMessage.REGISTER_TOTP_DEVICE}
           * {@link LoginResponseMessage.ACTIVATE_TOTP_DEVICE}
           * {@link LoginResponseMessage.TOO_MANY_MFA_ATTEMPTS}
           * {@link LoginResponseMessage.INCORRECT_OTP}
           */
          if ((isBlobErrorResponse || (is401Error && is401ErrorUnauthorizedMessage)) && this.authService.shouldRefresh) {
            return this.handle401Error(request, next);
          }
          
          return throwError(() => httpError);
        })
      );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    if (!token){
      return request;
    }

    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    // No need to retry logout request as session should already have been ended if 401 comes back for this.
    if (request.url.endsWith('/logout')) {
      return of(null);
    }

    if (!this.isRefreshing) {
      this.isRefreshing = true;

      return this.authService.refreshToken().pipe(
        switchMap((token: AuthInfo) => {
          this.refreshTokenSubject.next(token?.token);
          this.authService.updateJwtTokens(token.token, token.refreshToken);
          return next.handle(this.addToken(request, token?.token));
        }),
        catchError((error) => {
          if (error instanceof HttpErrorResponse && error.status !== HttpStatusCode.OK) {
            if (error?.error.message === accountLoggedInSomewhereElse) {
              this.notificationService.info(accountLoggedInSomewhereElseMsg, {
                verticalPosition: 'top'
              });
            } else if (!this.router.url.endsWith('/login')) {
              this.notificationService.info('You were logged out due to session expiry', {
                verticalPosition: 'top'
              });
            }
            this.authService.logout();
            return of(null);
          }

          return throwError(error);
        }),
        finalize(() => {
          this.isRefreshing = false;
        }));
    } else {
      return this.refreshTokenSubject.pipe(
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }
}
