import {Pipe, PipeTransform} from '@angular/core';
import {ProjectFileTypeDescriptionService} from '../data/project-file-type-description.service/project-file-type-description.service';

@Pipe({
  name: 'projectFileTypeDescription'
})
export class ProjectFileTypeDescriptionPipe implements PipeTransform {

  constructor(private descriptionService: ProjectFileTypeDescriptionService) {
  }
  transform(value: any, ...args: any[]): any {
    return this.descriptionService.getDescription(value);
  }
}
