import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthenticatedGuard} from './services/auth/authenticated.guard';
import {NotAuthenticatedGuard} from './services/auth/not-authenticated.guard';
import {SiteSection} from './services/auth/permissions.service/permissions.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'host-logs',
    loadChildren: () => import('./pages/host-logs/host-logs.module').then(m => m.HostLogsModule),
    canActivate: [AuthenticatedGuard],
    data: {siteSection: SiteSection.HostLogs}
  },
  {
    path: 'test-plans',
    loadChildren: () => import('./pages/test-plans/test-plans.module').then(m => m.TestPlansModule),
    canActivate: [AuthenticatedGuard],
    data: {siteSection: SiteSection.TestPlans}
  },
  {
    path: 'projects',
    loadChildren: () => import('./pages/projects/projects.module').then(m => m.ProjectsModule),
    canActivate: [AuthenticatedGuard],
    data: {siteSection: SiteSection.Projects}
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/transaction-list/search.module').then(m => m.SearchModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
    canActivate: [NotAuthenticatedGuard]
  },
  {
    path: 'clients',
    loadChildren: () => import('./pages/clients/clients.module').then(m => m.ClientsModule),
    canActivate: [AuthenticatedGuard],
    data: {siteSection: SiteSection.Clients}
  },
  {
    path: 'users',
    loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule),
    canActivate: [AuthenticatedGuard],
    data: {siteSection: SiteSection.Users}
  },
  {
    path: 'reports',
    loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule),
    canActivate: [AuthenticatedGuard],
    data: {siteSection: SiteSection.Reports}
  },
  {
    path: 'in-take',
    loadChildren: () => import('./pages/intake-form/intake-form.module').then(m => m.IntakeFormModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/user-change-password/user-change-password.module').then(m => m.UserChangePasswordModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'knowledge-repository',
    loadChildren: () => import('./pages/knowledge-repository/knowledge-repository.module').then(m => m.KnowledgeRepositoryModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule)
  },
  {
    path: 'loa-archive',
    loadChildren: () => import('./pages/loa-archive/loa-archive.module').then(m => m.LoaArchiveModule)
  },
  {
    path: 'sandbox-projects',
    loadChildren: () => import('./pages/sandbox-projects/sandbox-projects.module').then(m => m.SandboxProjectsModule),
    canActivate: [AuthenticatedGuard],
    data: {siteSection: SiteSection.Projects}
  },
  {
    path: 'sandbox-reports',
    loadChildren: () => import('./pages/reports/sandbox-reports.module').then(m => m.SandboxReportsModule),
    canActivate: [AuthenticatedGuard],
    data: {siteSection: SiteSection.Reports, sandbox: true}
  },
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'disabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
