import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {HostLogMessageField} from '@models/hostLogMessageField';
import {ColumnDefinition} from '../../../treetable/component/treetable.component';
import {Node} from '../../../treetable/models';
import {HostLogValueInfo} from './host-log-value-display.component';

interface HostLogMessageFieldNode {
  fieldId: string;
  description: string;
  value: HostLogValueInfo;
  validationResult: string;
}


@Component({
  selector: 'app-host-log-message-tree-table',
  templateUrl: './host-log-message-tree-table.component.html',
  styleUrls: ['./host-log-message-tree-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HostLogMessageTreeTableComponent implements OnInit, AfterViewInit {
  @Input()
  fields: Array<HostLogMessageField>;

  @ViewChild('treeContainer', {static: true}) treeContainer: ElementRef;
  @ViewChild('valueTemplate', {static: true}) valueTemplate: TemplateRef<any>;

  treeData: Node<HostLogMessageFieldNode>[];
  columnDefinitions: Array<ColumnDefinition>;

  @HostListener('window:resize')
  onResize() {
    this.updateLayout();
  }

  ngOnInit() {
    this.treeData = this.mapToTreeNodes(this.fields);
    this.columnDefinitions = [
      {valueField: 'fieldId', header: 'Field ID'},
      {valueField: 'description', header: 'Description'},
      {valueField: 'value', header: 'Value', template: this.valueTemplate}
    ];
  }

  ngAfterViewInit(): void {
    setTimeout(this.updateLayout.bind(this), 50);
  }

  private updateLayout() {
    this.treeContainer.nativeElement.style.height = (window.innerHeight - this.getOffsetTop() - 10) + 'px';
  }

  private getOffsetTop() {
    let top = 0;
    let element = this.treeContainer.nativeElement;
    while (element) {
      top += element.offsetTop;
      element = element.offsetParent;
    }

    return top;
  }

  mapToTreeNodes(messageFields: Array<HostLogMessageField>): Node<HostLogMessageFieldNode>[] {
    return messageFields.map(messageField => this.generateNode(messageField));
  }

  generateNode(messageField: HostLogMessageField): Node<HostLogMessageFieldNode> {
    const nodeHasChildren = messageField.fieldList?.length > 0;
    return {
      value: {
        fieldId: messageField.fieldId,
        value: {
          value: messageField.fieldViewable ? messageField.fieldViewable : '',
          hasChildren: nodeHasChildren
        },
        description: messageField.friendlyName,
        validationResult: messageField.validationResult ? messageField.validationResult : ''
      },
      children: nodeHasChildren ? this.mapToTreeNodes(messageField.fieldList) : []
    };
  }

}
