import {UserNameDetails} from '@models/userNameDetails';
import {AssignedUser} from './assignedUser';

export enum ProjectFileType {
  Attachment = 'ATTACHMENT',
  Receipt = 'RECEIPT',
  L1Certificate = 'L1_CERTIFICATE',
  L2Certificate = 'L2_CERTIFICATE',
  PCICompliance = 'PCI_COMPLIANCE',
  MastercardTestCases = 'MC_TEST_CASES',
  NonMCTestCases = 'NON_MC_TEST_CASES',
  ULTestCases = 'UL_TEST_CASES',
  CardLogs = 'CARD_LOGS',
  TPPFile = 'TPP_FILE',
  MASLog = 'MAS_LOG',
  DiscoverTestCases = 'DISCOVER_TEST_CASES',
  InternalOnly = 'INTERNAL_ONLY',
  VisaTestCases = 'VISA_TEST_CASES',
  AmexTestCases = 'AMEX_TEST_CASES',
  JcbTestCases = 'JCB_TEST_CASES',
  UnionPayTestCases = 'UNION_PAY_TEST_CASES',
  TseTestCases = 'TSE_TEST_CASES'
}

/**
 * Project File
 */

export interface ProjectFile {
  id: number;
  originalFileName: string;
  projectId: number;
  projectTestCaseId: number;
  mimeType: string;
  uploadTime: Date;
  fileType: ProjectFileType;
  keywords: any[];
  owner: UserNameDetails;
}
