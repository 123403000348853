import {NgxMatMomentModule} from '@angular-material-components/moment-adapter';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {RouterModule} from '@angular/router';
import {GlobalWaiverReportService} from '@services/data/global-waiver-report/global-waiver-report.service';
import {ServicesModule} from '@services/services.module';
import {NgChartsModule} from 'ng2-charts';
import {DirectivesModule} from '../directives/directives.module';
import {MaterialModule} from '../material';
import {AssignedUserSelectComponent} from './assigned-user-select/assigned-user-select.component';
import {BackButtonComponent} from './back-button/back-button.component';
import {ChangePasswordFormComponent} from './change-password-form/change-password-form.component';
import {CommentDialogComponent} from './comments/comment-dialog.component';
import {CommentsListComponent} from './comments/comments-list.component';
import {AlertDialogComponent} from './confirm-dialog/alert-dialog.component';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {ConfirmDialogService} from './confirm-dialog/confirm-dialog.service';
import {CreateUserDialogComponent} from './create-user-dialog/create-user-dialog.component';
import {EmailNoteDialogComponent} from './email-note-dialog/email-note-dialog.component';
import {GlobalWaiverTableComponent} from './global-waiver-table/global-waiver-table.component';
import {HexDisplayComponent} from './hex-display/hex-display.component';
import {HostLogMessageMetadataComponent} from './host-log-transaction-view/host-log-message/host-log-message-metadata/host-log-message-metadata.component';
import {HostLogMessageTreeTableComponent} from './host-log-transaction-view/host-log-message/host-log-message-tree-table/host-log-message-tree-table.component';
import {HostLogValueDisplayComponent} from './host-log-transaction-view/host-log-message/host-log-message-tree-table/host-log-value-display.component';
import {HostLogMessageComponent} from './host-log-transaction-view/host-log-message/host-log-message.component';
import {HostLogTransactionViewComponent} from './host-log-transaction-view/host-log-transaction-view.component';
import {InlineControlComponent} from './inline-edit/inline-control.component';
import {InlineEditComponent} from './inline-edit/inline-edit.component';
import {InlineSelectComponent} from './inline-edit/inline-select.component';
import {NotifyIccEmailComponent} from './notify-icc-email/notify-icc-email.component';
import {OverlayButtonComponent} from './overlay-button/overlay-button.component';
import {PageTitleComponent} from './page-title/page-title.component';
import {PanelComponent} from './panel/panel.component';
import {ProjectDetailsPhaseComponent} from './project-details-phase/project-details-phase.component';
import {ProjectFilterDialogComponent} from './project-filter.service/project-filter-dialog/project-filter-dialog.component';
import {ProjectFilterParameterComponent} from './project-filter.service/project-filter-dialog/project-filter-parameter.component';
import {ProjectFilterService} from './project-filter.service/project-filter.service';
import {NewProjectDialogService} from './projects-table/new-project-dialog.service/new-project-dialog.service';
import {ProjectTableDataSourceService} from './projects-table/project-table-data-source.service/project-table-data-source.service';
import {ProjectTestCaseStatusBarComponent} from './projects-table/project-test-case-status-bar.component';
import {ProjectTestSummaryTooltip} from './projects-table/project-test-summary-tooltip.component';
import {ProjectsTableComponent} from './projects-table/projects-table.component';
import {SideBarLayoutComponent} from './side-bar-layout/side-bar-layout.component';
import {SpinnerWrapperComponent} from './spinner/spinner-wrapper.component';
import {SpinnerComponent} from './spinner/spinner.component';
import {SvgComponent} from './svg/svg.component';
import {TerminalIdEditorComponent} from './terminal-id-editor/terminal-id-editor.component';
import {TerminalIdsEditorComponent} from './terminal-id-editor/terminal-ids-editor.component';
import {TreetableModule} from './treetable/treetable.module';
import {UsersTableComponent} from './users-table/users-table.component';
import {WaiverFilterDialogComponent} from './waiver-filter.service/waiver-filter-parameter/waiver-filter-dialog/waiver-filter-dialog.component';
import {WaiverFilterParameterComponent} from './waiver-filter.service/waiver-filter-parameter/waiver-filter-parameter.component';
import {WaiverFilterService} from './waiver-filter.service/waiver-filter.service';

@NgModule({
    declarations: [
        PageTitleComponent,
        PanelComponent,
        HostLogTransactionViewComponent,
        HostLogMessageComponent,
        HostLogMessageMetadataComponent,
        HostLogMessageTreeTableComponent,
        SideBarLayoutComponent,
        HexDisplayComponent,
        ProjectTestSummaryTooltip,
        OverlayButtonComponent,
        ConfirmDialogComponent,
        InlineEditComponent,
        InlineSelectComponent,
        TerminalIdsEditorComponent,
        ChangePasswordFormComponent,
        ProjectsTableComponent,
        ProjectTestCaseStatusBarComponent,
        UsersTableComponent,
        CreateUserDialogComponent,
        TerminalIdEditorComponent,
        AssignedUserSelectComponent,
        AlertDialogComponent,
        HostLogValueDisplayComponent,
        SpinnerComponent,
        BackButtonComponent,
        CommentsListComponent,
        CommentDialogComponent,
        ProjectDetailsPhaseComponent,
        InlineControlComponent,
        SpinnerWrapperComponent,
        ProjectFilterDialogComponent,
        ProjectFilterParameterComponent,
        EmailNoteDialogComponent,
        NotifyIccEmailComponent,
        SvgComponent,
        GlobalWaiverTableComponent,
        WaiverFilterParameterComponent,
        WaiverFilterDialogComponent
    ],
    exports: [
        PageTitleComponent,
        PanelComponent,
        HostLogTransactionViewComponent,
        HostLogMessageComponent,
        SideBarLayoutComponent,
        HexDisplayComponent,
        ProjectTestSummaryTooltip,
        OverlayButtonComponent,
        InlineEditComponent,
        InlineSelectComponent,
        TerminalIdsEditorComponent,
        ChangePasswordFormComponent,
        ProjectsTableComponent,
        ProjectTestCaseStatusBarComponent,
        UsersTableComponent,
        CreateUserDialogComponent,
        AssignedUserSelectComponent,
        SpinnerComponent,
        BackButtonComponent,
        CommentsListComponent,
        ProjectDetailsPhaseComponent,
        SpinnerWrapperComponent,
        NotifyIccEmailComponent,
        GlobalWaiverTableComponent,
        SvgComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        TreetableModule,
        MaterialModule,
        NgChartsModule,
        DirectivesModule,
        ServicesModule,
        MatDatepickerModule, // Required in AppModule for mat-dialog components to be able to use the timepicker
        NgxMatMomentModule, // Required in AppModule for mat-dialog components to be able to use the timepicker
    ],
    providers: [
        ConfirmDialogService,
        ProjectFilterService,
        ProjectTableDataSourceService,
        NewProjectDialogService,
        GlobalWaiverReportService,
        WaiverFilterService
    ]
})
export class ComponentsModule {
}
