<app-host-log-message-metadata [hostLogMessage]="message">
</app-host-log-message-metadata>
<app-host-log-message-tree-table *ngIf="message?.messageFields.length > 0; else unknownFormat"
                                 [fields]="message?.messageFields"
style="height: 100%">
</app-host-log-message-tree-table>
<ng-template #unknownFormat>
  Unable to parse message due to unknown format
</ng-template>

