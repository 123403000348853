import {HttpParams} from '@angular/common/http';

export declare type FilterParameters = Map<string, string | Date>;

export const populateFilterParameters = (searchParams: FilterParameters, httpParams: HttpParams): HttpParams => {
  searchParams.forEach((value: string | Date, key: string) => {
    if (value instanceof Date) {
      httpParams = httpParams.append(key, value.toISOString());
    } else {
      httpParams = httpParams.append(key, value.toString());
    }
  });
  return httpParams;
};
